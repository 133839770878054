import {memo, useEffect, useState} from 'react';
import moment from 'moment';
import parse from "html-react-parser";
import routes from '../../../routes';
import ReactPlayer from "react-player/lazy";
import default_bg from "../../../assets/images/form_background.png";
import {useTranslation} from "react-i18next";

const SpaceBenefit = (parentProps) => {
    return (
        <div id="cs-space-benefits-browse" class="grid-base col-span-full order-1 centerframe-grid gap-2gutter relative p-section-safeview">                        
            <div class="block-full">
                <div class="cs-stack-title-filters stack-row-wrap">
                    <h2>Access Benefit Name</h2>
                    <div class="cs-stack-filters">
                        <button class="filter-dropdown-gradient" id="menu-button" aria-expanded="true" aria-haspopup="true"><p>All</p><i class="fa-regular fa-angle-down"></i></button>
                        <button class="filter-dropdown-gradient" id="menu-button" aria-expanded="true" aria-haspopup="true"><p>Newest</p><i class="fa-regular fa-angle-down"></i></button>
                    </div>
                </div>
            </div>

            <div class="block-full">
                <p>Optional Note here.</p>
            </div>

            <div id="cs-space-search-results" class="grid-base col-span-full">
                
                
                <div class="cs-datarow-search-result block-full card">
                    <div class="datarow">
                        <div class="data-item stack-row">  
                            <div class="igroup stack-row">
                                <i class="fa-solid fa-file-invoice"></i>
                                <h4><a href="fam-space-updates-post.html">Post Title</a></h4>
                            </div>
                        </div>
                        <div class="data-item stack-row text-black">
                            <p>Preview text.</p>
                        </div>
                    </div>    
                </div>

                
                <div class="cs-datarow-search-result block-full card">
                    <div class="datarow">
                        <div class="data-item stack-row">  
                            <div class="igroup stack-row">
                                <i class="fa-solid fa-messages"></i>
                                <h4><a href="fam-space-updates-post.html">#Channel Name</a></h4>
                            </div>
                        </div>
                        <div class="data-item stack-row text-black">
                            <p>Channel Desc.</p>
                        </div>
                    </div>    
                </div>

                
            </div>

            

            <div class="cs-pagination block-full">
                <nav class="pagination-gradient" aria-label="Pagination">
                    <a href="#pprevious" class="pagination-previous">
                        <span class="sr-only">Previous</span>
                        <i class="fa-regular fa-angle-left"></i>
                    </a>
                    <a href="#p1">1</a>
                    <a href="#p2">2</a>
                    <a href="#p3" class="pagination-extra">3</a>
                    <span class="pagination-ellipsis">...</span>
                    <a href="#p8" class="pagination-extra">8</a>
                    <a href="#p9">9</a>
                    <a href="#10">10</a>
                    <a href="pnext" class="pagination-next">
                        <span class="sr-only">Next</span>
                        <i class="fa-regular fa-angle-right"></i>
                    </a>
                </nav>
            </div>

        </div>
        
    )
}


export default memo(SpaceBenefit);
