import React, {memo, useCallback, useState} from "react";
import {getSpaceOwnedMemberships, getSpaceOwnedMembershipsItems} from "../../../../../services/space";

const SpaceCollection = (parentsProps) => {
    const { space, goToSpaceCollections, handleClick } = parentsProps;

    const membershipCount = space?.memberships?.filter(membership => membership?.is_owned_from_payment)?.length ?? 0;
    const itemCount = space?.items?.filter(item => item?.is_owned_from_payment)?.length ?? 0;3
    //
    // const [memberships, setMemberships] = useState([]);
    // const [items, setItems] = useState([]);
    //
    // const getOwnedMemberships = useCallback(async (space_id, viewing_user_id) => {
    //     const data = {
    //         space_id: space_id,
    //         viewing_user_id: viewing_user_id,
    //         is_inactive: true,
    //     }
    //     const response = await getSpaceOwnedMembershipsItems(data);
    //     if (response && response.result) {
    //         const ownedMemberships = response.owned_memberships;
    //         const ownedItems = response.owned_items;
    //         setMemberships(ownedMemberships)
    //         setItems(ownedItems)
    //     }
    // }, [memberships, items]);

    return (
        <>
            <div className="cs-dash-card block-full card">
                <div className="formrow title">
                    <h3>{space.name + ' Collection'}</h3>
                    <p>Items & Memberships you've collected</p>
                </div>

                <div className="cs-stack-controlrows stack-form-col">
                    {space.items ? (
                        <div className="formrow">
                            <div className="data-item">
                                <div className="stack-avatar md">
                                    {space.items && space.items.length > 0 ? (
                                        // space.items.filter(item => item.is_owned_from_payment).slice(0, 4).map((key, index) => (
                                        space.items.filter(item => item.is_owned_from_payment).slice(0, 10).map((key, index) => (
                                            <div
                                                className="avatar gradient md cs-avatar-item"
                                                key={index}
                                            >
                                                <img src={key.photo} alt=""/>
                                            </div>
                                        ))
                                    ) : (
                                        <h3>
                                            0 Items Owned
                                        </h3>
                                    )}
                                </div>
                                <p className="data-count gradient-color-txt">{itemCount} Item{itemCount > 1? 's': ''} Owned</p>
                                {/*{itemCount > 4 && (*/}
                                {/*    <p className="data-count">+{itemCount - 4}</p>*/}
                                {/*)}*/}
                            </div>
                            {itemCount > 0 && (
                                <a
                                    onClick={() => {goToSpaceCollections().then(r => {})}}
                                    className="button gradient secondary">
                                    <span><p>View Items</p></span>
                                </a>
                            )}
                        </div>
                    ) : (
                        <h3>
                            0 Items Owned
                        </h3>
                    )}

                    {space.memberships ? (
                        <div className="formrow">
                            <div className="data-item">
                                <div className="stack-avatar md">
                                    {space.memberships && space.memberships.length > 0 ? (
                                        // space.memberships.filter(membership => membership.is_owned_from_payment).slice(0, 4).map((key, index) => (
                                        space.memberships.filter(membership => membership.is_owned_from_payment).slice(0, 10).map((key, index) => (
                                            <div
                                                className="avatar gradient md cs-avatar-membership"
                                                key={index}
                                            >
                                                <img src={key.photo} alt=""/>
                                            </div>
                                        ))
                                    ) : (
                                        <h3>
                                            0 Keys Owned
                                        </h3>
                                    )}
                                </div>
                                <p className="data-count gradient-color-txt">{membershipCount} Membership{membershipCount > 1? 's': ''} Owned</p>
                                {/*{membershipCount > 4 && (*/}
                                {/*    <p className="data-count">+{membershipCount - 4}</p>*/}
                                {/*)}*/}
                            </div>
                            {membershipCount > 0 && (
                                <a
                                    onClick={()=>handleClick()}
                                    className="button gradient secondary">
                                    <span><p>View Memberships</p></span>
                                </a>
                            )}
                        </div>
                    ) : (
                        <h3>
                            0 Keys Owned
                        </h3>
                    )}
                </div>
            </div>
        </>
    )
}

export default memo(SpaceCollection);