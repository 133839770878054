import { memo, useCallback, useEffect, useState } from 'react';
import { Button } from 'antd';
import { SPACE_VISIBILITY, SPACE_SIGNUP_METHOD } from '../../../constants/space';
import DropdownWithItems from '../../../components/commons/DropdownWithItems';
import { Link } from 'react-router-dom';
import routes from '../../../routes';
import { getSitewideLink } from '../../../utils/sitewideLinks';
import { updateSpaceVisibility } from '../../../services/space';
import SpaceProfilePassword from '../../../components/commons/Modals/SpaceProfilePassword';
import SpaceProfileInvite from '../../../components/commons/Modals/SpaceProfileInvite';
import SpaceProfileOfflineMessage from '../../../components/commons/Modals/SpaceProfileOfflineMessage';
import { displayPageContentLoader, hidePageContentLoader } from '../../../states/pageContentLoader';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const ManageSpace = (props) => {
	const { spaceInfo, authUserInfo } = props;
	const dispatch = useDispatch();
	const [selectedSpaceVisibility, setSelectedSpaceVisibility] = useState('public');
	const [selectedSignUp, setSelectedSignUp] = useState('all_sign');
	const [passwordModalOpen, setPasswordModalOpen] = useState(false);
	const [inviteModalOpen, setInviteModalOpen] = useState(false);
	const [offlineMessageModalOpen, setOfflineMessageModalOpen] = useState(false);
	const navigate = useNavigate();
	// const redirectToManageSpaceLink = useCallback(
	// 	(route, section = null) => {
	//         console.log(route, section)
	// 		if (spaceInfo) {
	// 			if (!section) {
	// 				return route.replace(':space_username', '@' + spaceInfo.username);

	// 			}
	// 			return route.replace(':space_username', '@' + spaceInfo.username) + '#' + section;
	// 		}
	// 		return '';
	// 	},
	// 	[spaceInfo]
	// );
	const redirectToManageSpaceLink = useCallback(
		(route, section = null) => {
			if (spaceInfo) {
				let path = route.replace(':space_username', '@' + spaceInfo.username);
				if (section) {
					path += '#' + section;
				}
				navigate(path);
			}
		},
		[spaceInfo, navigate]
	);

	const redirectToInformationPages = useCallback(() => {
		if (spaceInfo) {
			const route = routes.spaceManageInfoPages();
			return route.replace(':space_username', '@' + spaceInfo.username);
		}
		return '';
	}, [spaceInfo]);

	const handleSpaceVisibilityChange = useCallback(
		(value) => {
			setSelectedSpaceVisibility(value);
			updateSpace(value).then((r) => {});
		},
		[spaceInfo, authUserInfo]
	);

	const updateSpace = useCallback(
		async (value) => {
			if (spaceInfo && authUserInfo) {
				const data = {
					user_id: authUserInfo.id,
					space_id: spaceInfo.id,
					visibility: value
				};

				await updateSpaceVisibility(data);
			}
		},
		[spaceInfo, authUserInfo]
	);

	useEffect(() => {
		if (spaceInfo) {
			dispatch(hidePageContentLoader());
			setSelectedSpaceVisibility(spaceInfo.visibility);
		} else {
			dispatch(displayPageContentLoader());
		}
	}, [spaceInfo, authUserInfo]);

	return (
		<>
			<div className="cs-dash-card block-full card">
				<hgroup className="heading">
					<h2>Manage Space</h2>
					<p>You can manage the features, options, content, and style of your Space here. {getSitewideLink('learn_more_manage_space')}</p>
				</hgroup>
			</div>

			<div className="cs-dash-card block-full card">
				<div className="formrow title">
					<h3>Basics</h3>
					<p>Manage your homepage and general content</p>
				</div>
				<div className="cs-stack-controlrows stack-form-col">
					<div className="formrow split">
						<button
							type="button"
							onClick={() =>
								redirectToManageSpaceLink(routes.spaceManageProfile())
							}
							className="button gradient secondary"
						>
								<span>
									<p>Profile</p>
								</span>
						</button>
						<div><p>Edit and update your Space Profile details here, including your Space Name, Username, Social Links, and Images.</p></div>
					</div>
					<div className="formrow split">
						<button
							type="button"
							onClick={() =>
								redirectToManageSpaceLink(routes.spaceManageHomePage())
							}
							className="button gradient secondary"
						>
									<span>
										<p>Homepage</p>
									</span>
						</button>
						<div><p>Manage and edit your Welcome Section, Links Section, and other Content Blocks on the homepage of your Space.</p></div>
					</div>
					<div className="formrow split">
						<button
							type="button"
							onClick={() =>
								redirectToManageSpaceLink(routes.spaceManageInfoPages())
							}
							className="button gradient secondary"
						>
							<span>
								<p>Info Pages</p>
							</span>
						</button>
						<div><p>Edit and update your Information Pages, such as your About, FAQ, Privacy, and Terms of Service pages.</p></div>
					</div>
				</div>
			</div>

			<div className="cs-dash-card block-full card">
				<div className="formrow title">
					<h3>Community</h3>
					<p>Enable and customize your Community experience</p>
				</div>
				<div className="cs-stack-controlrows stack-form-col">
					<div className="formrow split">
						<button
							type="button"
							onClick={() =>
								redirectToManageSpaceLink(routes.spaceManageChannels())
							}
							className="button gradient secondary"
						>
									<span>
										<i className="fa-regular fa-comments"/>
										<p>Channels</p>
									</span>
						</button>
						<div><p>Channels is a place where Members can post and chat with each other in customizable topic channels that you set up. <a href="#link-via-admin" target="_blank">Learn more.</a></p></div>
					</div>
					<div className="formrow split">
						<button type="button" className="button gradient secondary">
							<span>
								<i className="fa-regular fa-star"/>
								<p>Points</p>
							</span>
						</button>
						<div><p>Points can reward Members based on their activity in your Space. Once earned, you can create ways for Members to spend them. <a href="#link-via-admin" target="_blank">Learn more.</a></p></div>
					</div>
					<div className="formrow split">
						<button type="button" className="button gradient secondary">
							<span>
								<i className="fa-regular fa-ranking-star"></i>
								<p>Leaderboards</p>
							</span>
						</button>
						<div><p>Leaderboards rank Members based on their Memberships, Items, and earned Points, and can have Unlockable Benefits. <a href="#link-via-admin" target="_blank">Learn more.</a></p></div>
					</div>
				</div>
			</div>

			{spaceInfo && spaceInfo.plan === 'pro' && (
				<div className="cs-dash-card block-full card">
					<div className="formrow title">
						<h3>Pro Customization</h3>
						<p>Manage and customize your Pro Space features</p>
					</div>
					<div className="cs-stack-controlrows stack-form-col">
						<div className="formrow split">
							<a
								onClick={() =>
									redirectToManageSpaceLink(
										routes.spaceManageCustomizeSpace(),
										'logo-identity'
									)
								}
							   className="button gradient secondary"><span><p>Color Themes</p></span></a>
							<div><p>Edit your Main and Alt Color Theme settings. <a href="#link-via-admin" target="_blank">Learn more.</a></p></div>
						</div>
						<div className="formrow split">
							<a
								onClick={() =>
									redirectToManageSpaceLink(
										routes.spaceManageCustomizeSpace(),
										'sections'
									)
								}
								className="button gradient secondary"><span><p>Sections & Page Elements</p></span></a>
							<div><p>Manage your Section and Page titles, blocks, and other details. <a href="#link-via-admin" target="_blank">Learn more.</a></p></div>
						</div>
						<div className="formrow split">
							<a
								onClick={() =>
									redirectToManageSpaceLink(
										routes.spaceManageCustomizeSpace(),
										'homepage'
									)
								}
								className="button gradient secondary"><span><p>Header & Footer</p></span></a>
							<div><p>Configure your Header and Footer theming and styles. <a href="#link-via-admin" target="_blank">Learn more.</a></p></div>
						</div>
						<div className="formrow split">
							<a
								onClick={() =>
									redirectToManageSpaceLink(
										routes.spaceManageCustomizeNav(),
										'navigations'
									)
								}
								className="button gradient secondary"><span><p>Navigation</p></span></a>
							<div><p>Customize your header and footer navigation links. <a href="#link-via-admin" target="_blank">Learn more.</a></p></div>
						</div>
						<div className="formrow split">
							<a
								onClick={() =>
									redirectToManageSpaceLink(
										routes.spaceManageCustomizeSpace(),
										'brand'
									)
								}
								className="button gradient secondary"><span><p>Brand Elements</p></span></a>
							<div><p>Edit and update your branding, logos, and other brand elements. <a href="#link-via-admin" target="_blank">Learn more.</a></p></div>
						</div>
						<div className="formrow split">
							<a
								onClick={() =>
									redirectToManageSpaceLink(
										routes.spaceManageCustomizeSpace(),
										'typography'
									)
								}
								className="button gradient secondary"><span><p>Typography</p></span></a>
							<div><p>Select fonts for your Space.</p></div>
						</div>
						<div className="formrow split">
							<a
								// href="cs-space-dash-space-questions.html"
								onClick={() =>
									redirectToManageSpaceLink(routes.spaceManageQuestions())
								}
								className="button gradient secondary"><span><p>Questions</p></span></a>
							<div><p>Set up custom questions and profile fields for Members. <a href="#link-via-admin" target="_blank">Learn more.</a></p></div>
						</div>
						<div className="formrow split">
							<a
								onClick={() =>
									redirectToManageSpaceLink(
										routes.spaceManageCustomizeNav(),
										'domain'
									)
								}
								className="button gradient secondary"><span><p>Domain</p></span></a>
							<div><p>Set up and manage your Space URL and domain settings. You can connect your Space to any domain or sub-domain. <a href="#link-via-admin" target="_blank">Learn more.</a></p></div>
						</div>
						<div className="formrow split">
							<a
								// href="#"
								className="button gradient secondary"><span><p>Pro Plan</p></span></a>
							<div><p>Manage the current Plan for your Space.</p></div>
						</div>
					</div>
				</div>
			)}

			<div className="cs-dash-card block-full card">
				<div className="formrow title">
					<h3>Access & Security</h3>
					<p>Manage your general Space settings</p>
				</div>
				<div className="cs-stack-controlrows stack-form-col">
					<div className="formrow split">
						<a
							onClick={() =>
								redirectToManageSpaceLink(routes.spaceManageAccessGroups())
							}
							className="button gradient secondary"><span><p>Access Groups</p></span></a>
						<div><p>Manage Access Groups for Memberships & Items. <a href="#link-via-admin" target="_blank">Learn more.</a></p></div>
					</div>
					<div className="formrow split">
						<a
							// href="#"
							className="button gradient secondary"><span><p>Invites</p></span></a>
						<div><p>Invite people to join your Space by email or @username, and manage pending invites. <a href="#link-via-admin" target="_blank">Learn more.</a></p></div>
					</div>
					<div className="formrow split">
						<a
							// href="#"
							className="button gradient secondary"><span><p>Security</p></span></a>
						<div><p>Set security requirements for your Space. <a href="#link-via-admin" target="_blank">Learn more.</a></p></div>
					</div>
					<div className="formrow split">
						<DropdownWithItems
							title="Update Visibility"
							dropDownClass="dropdown-bg"
							customButtonClass="button gradient secondary dropdown w-full"
							displayError={selectedSpaceVisibility === 'default'}
							selectedValue={selectedSpaceVisibility}
							listItems={SPACE_VISIBILITY}
							onDropdownChange={(value) => handleSpaceVisibilityChange(value)}
						/>
						{/*<button className="button gradient secondary centered dropdown"><span><p>Public</p><i className="fa-solid fa-caret-down"></i></span></button>*/}
						<div><p>Set your general Space access to Public, Password Protected, Invite Only, or Offline/Maintenance. <a href="#link-via-admin" target="_blank">Learn more.</a></p></div>
					</div>
					{selectedSpaceVisibility === 'password_protected' && (
						<div className="formrow split">
							<a
								onClick={() => setPasswordModalOpen(true)}
								className="button gradient secondary">
								<span>
									<p>Create/Edit Password</p>
								</span>
							</a>
							<SpaceProfilePassword
								spaceInfo={spaceInfo}
								authUserInfo={authUserInfo}
								modalOpen={passwordModalOpen}
								setModalOpen={setPasswordModalOpen}
							/>
							<div>
								<p>
									Set your Password for Visitors to access your Space. This will only apply to
									visitors who are not logged in. When accessing your Space, visitors will have the
									option to Log In or enter this password to view the Space.
								</p>
							</div>
						</div>
					)}
					{selectedSpaceVisibility === 'invite_only' && (
						<div className="formrow split">
							<a
								onClick={() => setInviteModalOpen(true)}
								className="button gradient secondary">
								<span>
									<p>Manage Invite</p>
								</span>
							</a>
							<SpaceProfileInvite
								modalOpen={inviteModalOpen}
								setModalOpen={setInviteModalOpen}
								spaceId={spaceInfo.id}
								userId={authUserInfo.id}
							/>
							<div>
								<p>
									You can set a message to display while your site is offline to
									explain to visitors the reason and share other details.
								</p>
							</div>
						</div>
					)}
					{selectedSpaceVisibility === 'offline_maintenance' && (
						<div className="formrow split">
							<a
								onClick={() => setOfflineMessageModalOpen(true)}
								className="button gradient secondary">
								<span>
									<p>Manage Offline Message</p>
								</span>
							</a>
							<SpaceProfileOfflineMessage
								spaceInfo={spaceInfo}
								authUserInfo={authUserInfo}
								modalOpen={offlineMessageModalOpen}
								setModalOpen={setOfflineMessageModalOpen}
							/>
							<div>
								<p>
									You can set a message to display while your site is offline to
									explain to visitors the reason and share other details.
								</p>
							</div>
						</div>
					)}
					<div className="formrow split">
						<DropdownWithItems
							title="Update Visibility"
							dropDownClass="dropdown-bg"
							customButtonClass="button gradient secondary dropdown w-full"
							displayError={selectedSignUp === 'default'}
							selectedValue={selectedSignUp}
							listItems={SPACE_SIGNUP_METHOD}
							onDropdownChange={(value) => setSelectedSignUp(value)}
						/>
						{/*<button className="button gradient secondary centered dropdown"><span><p>Email Sign-On & SSO</p><i className="fa-solid fa-caret-down"></i></span></button>*/}
						<div><p>Allow sign up and login using email and SSO only, or All Sign-On Methods (email, sso, wallets). <a href="#link-via-admin" target="_blank">Learn more.</a></p></div>
					</div>
				</div>
			</div>

			<div className="cs-dash-card block-full card">
				<div className="formrow title">
					<h3>Communications</h3>
					<p>Edit your communication styles and settings</p>
				</div>
				<div className="cs-stack-controlrows stack-form-col">
					<div className="formrow split">
						<a
							// href="#"
							className="button gradient secondary"><span><p>Email Styles</p></span></a>
						<div><p>Customize your Email styles for Updates, Member, and all other notification emails.</p></div>
					</div>
					<div className="formrow split">
						<a
							// href="#"
							className="button gradient secondary"><span><p>Email Settings</p></span></a>
						<div><p>Set which events generate emails to Members.</p></div>
					</div>
					<div className="formrow split">
						<a
							// href="#"
							className="button gradient secondary"><span><p>Notification Settings</p></span></a>
						<div><p>Customize other notification settings, such as SMS and Activity events.</p></div>
					</div>
				</div>
			</div>




			{/*<div className="block-full card">*/}
			{/*	<div className="memberships">*/}
			{/*		<div className="dashboard-wrapper">*/}
			{/*			<div className="grid grid-cols-12">*/}
			{/*				<div className="col-span-12">*/}
			{/*					<h3 className="header-medium">Manage Space</h3>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*			<div className="grid grid-cols-12 mt-5">*/}
			{/*				<div className="col-span-12">*/}
			{/*					<p className="body-txtitalic--small m-0">*/}
			{/*						You can manage the features, options, content and style of your{' '}*/}
			{/*						/!*<a className="gradient-color-txt">Space</a>*!/*/}
			{/*						{getSitewideLink('space_manage_space')} here. For an overview on,*/}
			{/*						you can browse the{' '}*/}
			{/*						/!*<a className="gradient-color-txt">Space Creation Guide</a> *!/*/}
			{/*						{getSitewideLink('space_creation_guide')} or{' '}*/}
			{/*						/!*<a className="gradient-color-txt">*!/*/}
			{/*						/!*	learn more about managing your Space.*!/*/}
			{/*						/!*</a>*!/*/}
			{/*						{getSitewideLink('learn_more_manage_space')}*/}
			{/*					</p>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*		</div>*/}
			{/*		<div className="dashboard-wrapper mt-5">*/}
			{/*			<div className="grid grid-cols-2 gap-6 text-center md:text-end ">*/}
			{/*				<div className="col-span-2 md:col-span-1 md:text-start">*/}
			{/*					<h3 className="header-small">Space Basics</h3>*/}
			{/*				</div>*/}
			{/*				<div className="col-span-2 md:col-span-1">*/}
			{/*					<p className="body-text--small  md:text-end m-0">*/}
			{/*						Manage your homepage and general content.*/}
			{/*					</p>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*			<div className="mt-4 flex gap-6 flex-col-reverse md:flex-row text-center md:text-end">*/}
			{/*				<div className="flex-1">*/}
			{/*					<button*/}
			{/*						type="button"*/}
			{/*						onClick={() =>*/}
			{/*							redirectToManageSpaceLink(routes.spaceManageProfile())*/}
			{/*						}*/}
			{/*						className="secondary-gradient w-full"*/}
			{/*					>*/}
			{/*					<span>*/}
			{/*						<p>Manage Space Profile</p>*/}
			{/*					</span>*/}
			{/*					</button>*/}
			{/*				</div>*/}
			{/*				<div className="flex-1">*/}
			{/*					<p className="body-txtitalic--smallest md:text-start m-0">*/}
			{/*						Edit and update your Space Profile Details here, including your*/}
			{/*						Space Name, Username, Social Links, and Profile Image/Backgrounds.*/}
			{/*					</p>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*			<div className="mt-4 gap-6 flex flex-col-reverse md:flex-row text-center md:text-end">*/}
			{/*				<div className="flex-1">*/}
			{/*					<button*/}
			{/*						type="button"*/}
			{/*						onClick={() =>*/}
			{/*							redirectToManageSpaceLink(routes.spaceManageHomePage())*/}
			{/*						}*/}
			{/*						className="secondary-gradient w-full"*/}
			{/*					>*/}
			{/*						<span>*/}
			{/*							<p>Manage Homepage</p>*/}
			{/*						</span>*/}
			{/*					</button>*/}
			{/*				</div>*/}
			{/*				<div className="flex-1">*/}
			{/*					<p className="body-txtitalic--smallest md:text-start m-0">*/}
			{/*						Manage and edit your Welcome Section, Links Section, and other*/}
			{/*						Content Blocks on the homepage of your Space.*/}
			{/*					</p>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*			<div className="mt-4 gap-6 flex flex-col-reverse md:flex-row text-center md:text-start">*/}
			{/*				<div className="flex-1">*/}
			{/*					<button*/}
			{/*						type="button"*/}
			{/*						onClick={() =>*/}
			{/*							redirectToManageSpaceLink(routes.spaceManageInfoPages())*/}
			{/*						}*/}
			{/*						className="secondary-gradient w-full"*/}
			{/*					>*/}
			{/*						<span>*/}
			{/*							<p>Edit Information Pages</p>*/}
			{/*						</span>*/}
			{/*					</button>*/}
			{/*				</div>*/}
			{/*				<div className="flex-1">*/}
			{/*					<p className="body-txtitalic--smallest md:text-start m-0">*/}
			{/*						Edit and update your Information Pages, such as your About, FAQ,*/}
			{/*						Privacy, and Terms of Service pages.*/}
			{/*					</p>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*		</div>*/}
			{/*		<div className="dashboard-wrapper mt-5">*/}
			{/*			<div className="grid grid-cols-2 gap-6 text-center md:text-end">*/}
			{/*				<div className="col-span-2 md:col-span-1 md:text-start">*/}
			{/*					<h3 className="header-small">Community Features</h3>*/}
			{/*				</div>*/}
			{/*				<div className="col-span-2 md:col-span-1">*/}
			{/*					<p className="body-text--small md:text-end m-0">*/}
			{/*						Enable and customize your Community experience.*/}
			{/*					</p>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*			<div className="mt-4 flex gap-6 flex-col-reverse md:flex-row text-center md:text-end">*/}
			{/*				<div className="flex-1">*/}
			{/*					/!*<Button className="btn btn-secondary btn-md">*!/*/}
			{/*					/!*	<i className="fa-regular fa-comments me-2"></i> Channels*!/*/}
			{/*					/!*</Button>*!/*/}

			{/*					<button*/}
			{/*						type="button"*/}
			{/*						onClick={() =>*/}
			{/*							redirectToManageSpaceLink(routes.spaceManageChannels())*/}
			{/*						}*/}
			{/*						className="secondary-gradient w-full"*/}
			{/*					>*/}
			{/*						<span>*/}
			{/*							<p>Channels</p>*/}
			{/*						</span>*/}
			{/*					</button>*/}
			{/*				</div>*/}
			{/*				<div className="flex-1">*/}
			{/*					<p className="body-txtitalic--smallest md:text-start m-0">*/}
			{/*						Community Channels is a place where Members can post and chat with*/}
			{/*						each other. It’s similar to a Facebook Group but with customizable*/}
			{/*						topic channels like Discord or Slack.{' '}*/}
			{/*						/!*<a className="gradient-color-txt">Learn more.</a>*!/*/}
			{/*						{getSitewideLink('learn_more_channels_manage_space')}*/}
			{/*					</p>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*			<div className="mt-4 flex gap-6 flex-col-reverse md:flex-row text-center md:text-end">*/}
			{/*				<div className="flex-1">*/}
			{/*					<button type="button" className="secondary-gradient w-full">*/}
			{/*						<span>*/}
			{/*							<p>*/}
			{/*								<i className="fa-regular fa-star me-2"></i>Points*/}
			{/*							</p>*/}
			{/*						</span>*/}
			{/*					</button>*/}
			{/*				</div>*/}
			{/*				<div className="flex-1">*/}
			{/*					<p className="body-txtitalic--smallest md:text-start m-0">*/}
			{/*						Community Points reward Members based on their activity in your*/}
			{/*						Space. Once earned, you can create ways for Members to spend them to*/}
			{/*						unlock Benefits or get Items.{' '}*/}
			{/*						/!*<a className="gradient-color-txt">Learn more.</a>*!/*/}
			{/*						{getSitewideLink('learn_more_points_manage_space')}*/}
			{/*					</p>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*			<div className="mt-4 flex gap-6 flex-col-reverse md:flex-row text-center md:text-end">*/}
			{/*				<div className="flex-1">*/}
			{/*					<button type="button" className="secondary-gradient w-full">*/}
			{/*						<span>*/}
			{/*							<p>*/}
			{/*								<i className="fa-regular fa-ranking-star me-2"></i>*/}
			{/*								Leaderboards*/}
			{/*							</p>*/}
			{/*						</span>*/}
			{/*					</button>*/}
			{/*				</div>*/}
			{/*				<div className="flex-1">*/}
			{/*					<p className="body-txtitalic--smallest md:text-start m-0">*/}
			{/*						Member Leaderboards are a Ranking of every Member based on their*/}
			{/*						activated Memberships, Items, and lifetime earned Community Points.*/}
			{/*						You can also use Rankings to create Unlockable Benefits.{' '}*/}
			{/*						/!*<a className="gradient-color-txt">Learn more.</a>*!/*/}
			{/*						{getSitewideLink('learn_more_leaderboards_manage_space')}*/}
			{/*					</p>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*		</div>*/}

			{/*		{spaceInfo && spaceInfo.plan === 'pro' && (*/}
			{/*			<div className="dashboard-wrapper mt-5">*/}
			{/*				<div className="grid grid-cols-2 gap-6 text-center md:text-end">*/}
			{/*					<div className="col-span-2 md:col-span-1 md:text-start">*/}
			{/*						<h3 className="header-small">Pro Customization</h3>*/}
			{/*					</div>*/}
			{/*					<div className="col-span-2 md:col-span-1">*/}
			{/*						<p className="body-txtitalic--small md:text-end m-0">*/}
			{/*							/!*<a className="gradient-color-txt">Manage Pro Plan</a>*!/*/}
			{/*							{getSitewideLink('manage_pro_plan')}*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*				</div>*/}
			{/*				<div className="mt-4 flex gap-6 flex-col-reverse md:flex-row text-center md:text-end">*/}
			{/*					<div className="flex-1">*/}
			{/*						<button*/}
			{/*							type="button"*/}
			{/*							onClick={() =>*/}
			{/*								redirectToManageSpaceLink(*/}
			{/*									routes.spaceManageCustomizeSpace(),*/}
			{/*									'logo-identity'*/}
			{/*								)*/}
			{/*							}*/}
			{/*							className="secondary-gradient w-full"*/}
			{/*						>*/}
			{/*						<span>*/}
			{/*							<p>Logo & Identity</p>*/}
			{/*						</span>*/}
			{/*						</button>*/}
			{/*						/!* <button type="button" onClick={() => redirectToManageSpaceLink((routes.spaceManageChannels())} className="secondary-gradient w-full">*/}
            {/*                <span><p>Logo & Identity</p></span></button> *!/*/}
			{/*					</div>*/}
			{/*					<div className="flex-1">*/}
			{/*						<p className="body-txtitalic--smallest md:text-start m-0">*/}
			{/*							Customize your Logo, Fonts, and Key Colors. This will apply to*/}
			{/*							your entire Space.*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*				</div>*/}
			{/*				<div className="mt-4 flex gap-6 flex-col-reverse md:flex-row text-center md:text-end">*/}
			{/*					<div className="flex-1">*/}
			{/*						<button*/}
			{/*							type="button"*/}
			{/*							onClick={() =>*/}
			{/*								redirectToManageSpaceLink(*/}
			{/*									routes.spaceManageCustomizeSpace(),*/}
			{/*									'homepage'*/}
			{/*								)*/}
			{/*							}*/}
			{/*							className="secondary-gradient w-full"*/}
			{/*						>*/}
			{/*						<span>*/}
			{/*							<p>Homepage</p>*/}
			{/*						</span>*/}
			{/*						</button>*/}
			{/*					</div>*/}
			{/*					<div className="flex-1">*/}
			{/*						<p className="body-txtitalic--smallest md:text-start m-0">*/}
			{/*							Customize your Homepage layout, colors, backgrounds, and title*/}
			{/*							content. This will only apply to your homepage.*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*				</div>*/}
			{/*				<div className="mt-4 flex gap-6 flex-col-reverse md:flex-row text-center md:text-end">*/}
			{/*					<div className="flex-1">*/}
			{/*						<button*/}
			{/*							type="button"*/}
			{/*							onClick={() =>*/}
			{/*								redirectToManageSpaceLink(*/}
			{/*									routes.spaceManageCustomizeSpace(),*/}
			{/*									'sections'*/}
			{/*								)*/}
			{/*							}*/}
			{/*							className="secondary-gradient w-full"*/}
			{/*						>*/}
			{/*						<span>*/}
			{/*							<p>Sections</p>*/}
			{/*						</span>*/}
			{/*						</button>*/}
			{/*					</div>*/}
			{/*					<div className="flex-1">*/}
			{/*						<p className="body-txtitalic--smallest md:text-start m-0">*/}
			{/*							Customize your Light and Dark Section colors and backgrounds.*/}
			{/*							This will apply as the default for all pages.*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*				</div>*/}
			{/*				<div className="mt-4 flex gap-6 flex-col-reverse md:flex-row text-center md:text-end">*/}
			{/*					<div className="flex-1">*/}
			{/*						/!* <button*/}
			{/*						type="button"*/}
			{/*						onClick={() =>*/}
			{/*							redirectToManageSpaceLink(*/}
			{/*								routes.spaceManageCustomizeNav(),*/}
			{/*								'navigations'*/}
			{/*							)*/}
			{/*						}*/}
			{/*						className="secondary-gradient w-full"*/}
			{/*					> *!/*/}
			{/*						<button*/}
			{/*							type="button"*/}
			{/*							onClick={() =>*/}
			{/*								redirectToManageSpaceLink(*/}
			{/*									routes.spaceManageCustomizeNav(),*/}
			{/*									'navigations'*/}
			{/*								)*/}
			{/*							}*/}
			{/*							className="secondary-gradient w-full"*/}
			{/*						>*/}
			{/*						<span>*/}
			{/*							<p>Navigation</p>*/}
			{/*						</span>*/}
			{/*						</button>*/}
			{/*					</div>*/}
			{/*					<div className="flex-1">*/}
			{/*						<p className="body-txtitalic--smallest md:text-start m-0">*/}
			{/*							Customize your primary and footer navigation links.*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*				</div>*/}
			{/*				<div className="mt-4 flex gap-6 flex-col-reverse md:flex-row text-center md:text-end">*/}
			{/*					<div className="flex-1">*/}
			{/*						<button*/}
			{/*							type="button"*/}
			{/*							onClick={() =>*/}
			{/*								redirectToManageSpaceLink(*/}
			{/*									routes.spaceManageCustomizeNav(),*/}
			{/*									'domain'*/}
			{/*								)*/}
			{/*							}*/}
			{/*							className="secondary-gradient w-full"*/}
			{/*						>*/}
			{/*						<span>*/}
			{/*							<p>Domain</p>*/}
			{/*						</span>*/}
			{/*						</button>*/}
			{/*					</div>*/}
			{/*					<div className="flex-1">*/}
			{/*						<p className="body-txtitalic--smallest md:text-start m-0">*/}
			{/*							Set up and manage your domain settings. You can connect your*/}
			{/*							Space to any domain or sub-domain.*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*		)}*/}
			{/*		<div className="dashboard-wrapper mt-5">*/}
			{/*			<div className="grid grid-cols-2 gap-6 text-center md:text-end">*/}
			{/*				<div className="col-span-2 md:col-span-1 md:text-start">*/}
			{/*					<h3 className="header-small">Space Settings</h3>*/}
			{/*				</div>*/}
			{/*				<div className="col-span-2 md:col-span-1">*/}
			{/*					<p className="body-text--small md:text-end m-0">*/}
			{/*						Manage your general Space settings.*/}
			{/*					</p>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*			<div className="mt-4 flex gap-6 flex-col-reverse md:flex-row text-center md:text-end">*/}
			{/*				<div className="flex-1">*/}
			{/*					<DropdownWithItems*/}
			{/*						title="Update Visibility"*/}
			{/*						dropDownClass="dropdown-bg"*/}
			{/*						customButtonClass="button-dropdown-gradient-alt secondary "*/}
			{/*						displayError={selectedSpaceVisibility === 'default'}*/}
			{/*						selectedValue={selectedSpaceVisibility}*/}
			{/*						listItems={SPACE_VISIBILITY}*/}
			{/*						onDropdownChange={(value) => handleSpaceVisibilityChange(value)}*/}
			{/*					/>*/}
			{/*				</div>*/}
			{/*				<div className="flex-1">*/}
			{/*					<p className="body-txtitalic--smallest md:text-start m-0">*/}
			{/*						Make your Space viewable to the Public, Password Protected, Invite*/}
			{/*						Only, or Offline/Maintenance. Learn more about Space Visibility.*/}
			{/*					</p>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*			{selectedSpaceVisibility === 'password_protected' && (*/}
			{/*				<div className="mt-4 flex gap-6 flex-col-reverse md:flex-row text-center md:text-end">*/}
			{/*					<div className="flex-1">*/}
			{/*						<button*/}
			{/*							type="button"*/}
			{/*							onClick={() => setPasswordModalOpen(true)}*/}
			{/*							className="secondary-gradient w-full"*/}
			{/*						>*/}
			{/*						<span>*/}
			{/*							<p>Create/Edit Password</p>*/}
			{/*						</span>*/}
			{/*						</button>*/}
			{/*						<SpaceProfilePassword*/}
			{/*							spaceInfo={spaceInfo}*/}
			{/*							authUserInfo={authUserInfo}*/}
			{/*							modalOpen={passwordModalOpen}*/}
			{/*							setModalOpen={setPasswordModalOpen}*/}
			{/*						/>*/}
			{/*					</div>*/}
			{/*					<div className="flex-1">*/}
			{/*						<p className="body-txtitalic--smallest md:text-start m-0">*/}
			{/*							Set your Password for Visitors to access your Space. This will*/}
			{/*							only apply to visitors who are not logged in. When accessing*/}
			{/*							your Space, visitors will have the option to Log In or enter*/}
			{/*							this password to view the Space.*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*				</div>*/}
			{/*			)}*/}
			{/*			{selectedSpaceVisibility === 'invite_only' && (*/}
			{/*				<div className="mt-4 flex gap-6 flex-col-reverse md:flex-row text-center md:text-end">*/}
			{/*					<div className="flex-1">*/}
			{/*						<button*/}
			{/*							type="button"*/}
			{/*							onClick={() => setInviteModalOpen(true)}*/}
			{/*							className="secondary-gradient w-full"*/}
			{/*						>*/}
			{/*						<span>*/}
			{/*							<p>Manage Invite</p>*/}
			{/*						</span>*/}
			{/*						</button>*/}
			{/*						<SpaceProfileInvite*/}
			{/*							modalOpen={inviteModalOpen}*/}
			{/*							setModalOpen={setInviteModalOpen}*/}
			{/*							spaceId={spaceInfo.id}*/}
			{/*							userId={authUserInfo.id}*/}
			{/*						/>*/}
			{/*					</div>*/}
			{/*					<div className="flex-1">*/}
			{/*						<p className="body-txtitalic--smallest md:text-start m-0">*/}
			{/*							You can set a message to display while your site is offline to*/}
			{/*							explain to visitors the reason and share other details.*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*				</div>*/}
			{/*			)}*/}
			{/*			{selectedSpaceVisibility === 'offline_maintenance' && (*/}
			{/*				<div className="mt-4 flex gap-6 flex-col-reverse md:flex-row text-center md:text-end">*/}
			{/*					<div className="flex-1">*/}
			{/*						<button*/}
			{/*							type="button"*/}
			{/*							onClick={() => setOfflineMessageModalOpen(true)}*/}
			{/*							className="secondary-gradient w-full"*/}
			{/*						>*/}
			{/*						<span>*/}
			{/*							<p>Manage Offline Message</p>*/}
			{/*						</span>*/}
			{/*						</button>*/}
			{/*						<SpaceProfileOfflineMessage*/}
			{/*							spaceInfo={spaceInfo}*/}
			{/*							authUserInfo={authUserInfo}*/}
			{/*							modalOpen={offlineMessageModalOpen}*/}
			{/*							setModalOpen={setOfflineMessageModalOpen}*/}
			{/*						/>*/}
			{/*					</div>*/}
			{/*					<div className="flex-1">*/}
			{/*						<p className="body-txtitalic--smallest md:text-start m-0">*/}
			{/*							You can set a message to display while your site is offline to*/}
			{/*							explain to visitors the reason and share other details.*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*				</div>*/}
			{/*			)}*/}
			{/*			<div className="mt-4 flex gap-6 flex-col-reverse md:flex-row text-center md:text-end">*/}
			{/*				<div className="flex-1">*/}
			{/*					<DropdownWithItems*/}
			{/*						title="Update Visibility"*/}
			{/*						dropDownClass="dropdown-bg"*/}
			{/*						customButtonClass="button-dropdown-gradient-alt secondary "*/}
			{/*						displayError={selectedSignUp === 'default'}*/}
			{/*						selectedValue={selectedSignUp}*/}
			{/*						listItems={SPACE_SIGNUP_METHOD}*/}
			{/*						onDropdownChange={(value) => setSelectedSignUp(value)}*/}
			{/*					/>*/}
			{/*				</div>*/}
			{/*				<div className="flex-1">*/}
			{/*					<p className="body-txtitalic--smallest md:text-start m-0">*/}
			{/*						Members can sign up and login using email, wallets, SSO, or all*/}
			{/*						methods. /!*<a className="gradient-color-txt">*!/*/}
			{/*						/!*	{' '}*!/*/}
			{/*						/!*	Learn more about Sign-On Methods.*!/*/}
			{/*						/!*</a>*!/*/}
			{/*						{getSitewideLink('all_sign_on_methods')}*/}
			{/*					</p>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*			<div className="mt-4 flex gap-6 flex-col-reverse md:flex-row text-center md:text-end">*/}
			{/*				<div className="flex-1">*/}
			{/*					<button type="button" className="secondary-gradient w-full">*/}
			{/*					<span>*/}
			{/*						<p>Security</p>*/}
			{/*					</span>*/}
			{/*					</button>*/}
			{/*				</div>*/}
			{/*				<div className="flex-1">*/}
			{/*					<p className="body-txtitalic--smallest md:text-start m-0">*/}
			{/*						Set up and manage your domain settings. You can connect your Space*/}
			{/*						to any domain or sub-domain.*/}
			{/*					</p>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*		</div>*/}
			{/*		<div className="dashboard-wrapper mt-5">*/}
			{/*			<div className="grid grid-cols-2 gap-6 text-center md:text-end">*/}
			{/*				<div className="col-span-2 md:col-span-1 md:text-start">*/}
			{/*					<h3 className="header-small">Communications</h3>*/}
			{/*				</div>*/}
			{/*				<div className="col-span-2 md:col-span-1 md:text-start">*/}
			{/*					<p className="body-txtitalic--small md:text-end m-0">*/}
			{/*						Edit your communication styles and settings.*/}
			{/*					</p>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*			<div className="mt-4 flex gap-6 flex-col-reverse md:flex-row text-center md:text-end">*/}
			{/*				<div className="flex-1">*/}
			{/*					<button type="button" className="secondary-gradient w-full">*/}
			{/*					<span>*/}
			{/*						<p>Email Styles</p>*/}
			{/*					</span>*/}
			{/*					</button>*/}
			{/*				</div>*/}
			{/*				<div className="flex-1">*/}
			{/*					<p className="body-txtitalic--smallest md:text-start m-0">*/}
			{/*						Customize your Email styles for Updates, Member, and all other*/}
			{/*						notification emails.*/}
			{/*					</p>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*			<div className="mt-4 flex gap-6 flex-col-reverse md:flex-row text-center md:text-end">*/}
			{/*				<div className="flex-1">*/}
			{/*					<button type="button" className="secondary-gradient w-full">*/}
			{/*					<span>*/}
			{/*						<p>Email Settings</p>*/}
			{/*					</span>*/}
			{/*					</button>*/}
			{/*				</div>*/}
			{/*				<div className="flex-1">*/}
			{/*					<p className="body-txtitalic--smallest md:text-start m-0">*/}
			{/*						Customize your settings for what events generate emails to Members.*/}
			{/*					</p>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*			<div className="mt-4 flex gap-6 flex-col-reverse md:flex-row text-center md:text-end">*/}
			{/*				<div className="flex-1">*/}
			{/*					<button type="button" className="secondary-gradient w-full">*/}
			{/*					<span>*/}
			{/*						<p>Notifications</p>*/}
			{/*					</span>*/}
			{/*					</button>*/}
			{/*				</div>*/}
			{/*				<div className="flex-1">*/}
			{/*					<p className="body-txtitalic--smallest md:text-start m-0">*/}
			{/*						Customize other notification settings, such as SMS and Activity*/}
			{/*						events.*/}
			{/*					</p>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*		</div>*/}
			{/*	</div>*/}
			{/*</div>*/}
		</>

	);
};

export default memo(ManageSpace);
