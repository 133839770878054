import React, { memo, useEffect, useCallback, useState } from 'react';
import ReactPlayer from 'react-player/lazy';
import { Button, Form, Input } from 'antd';
import Alert from 'react-bootstrap/Alert';
import { useSelector } from 'react-redux';
import { Link, useSearchParams, useNavigate, useLocation } from 'react-router-dom';

import {ROOT_DOMAIN, VALIDATE_MESSAGES} from '../../../constants/common';
import { hideTooltip, showTooltip } from '../../../utils/input';
import { getSpaceUpdates, subscribeNewsletter } from '../../../services/space';
import Communities from '../Communities';
import LatestUpdates from '../LatestUpdates';
import MemberBenefits from '../MemberBenefits';
import FeaturedLinks from '../FeaturedLinks';
import Benefits from '../Tabs/Benefits';
import HeaderProMenu from './HeaderPro/HeaderProMenu';
import Channel from '../../Channel';
import BenefitsPro from './Tabs/BenefitsPro';
import UpdatesPro from './Tabs/UpdatesPro';
import SpaceClaimMembership from '../../SpaceClaimMembership';
import SpaceClaimItem from '../../SpaceClaimItem';
import CustomPagination from '../../../components/commons/CustomPagination';

import './SpaceViewPro.scss';
// import default_profile_card_bg from '../../../assets/images/profile_header.png';
import UpdatesContent from '../LatestUpdates/UpdatesContent';

import SpaceBuyMembership from '../../SpaceBuyMembership';
import SpaceBuyItem from '../../SpaceBuyItem';
import SpaceInformationPages from '../SpaceInformationPages';
import routes from '../../../routes';
import { CustomValidators } from '../../../utils/CustomValidators';
import channels_cover_image from '../../../assets/images/fam-access-1.jpg';
import zoom_cover_image from '../../../assets/images/fam-access-2.jpg';
import shop_cover_image from '../../../assets/images/fam-access-3.jpg';
import default_user_profile from "../../../assets/images/default_user_profile.png";
import { space } from 'postcss/lib/list';
import SocialLinks from "../../../components/commons/SocialLinks";
import {createFullRoute} from "../../../utils/redirects";
import {useTranslation} from "react-i18next";
import SpaceBenefit from '../SpaceBenefit';

const SpaceViewPro = (props) => {
    const { t, i18n } = useTranslation();
    const { spaceData, globalActiveTab, updateGlobalActiveTab } = props;
    const currentLocation = useLocation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const urlTab = searchParams.get('tab');

    const { authUserInfo, loading } = useSelector((state) => state.general);
    const { isPublicUrl } = useSelector((state) => state.spaceViewMode);

    const [subscriptionForm] = Form.useForm();

    const [playVideo, setPlayVideo] = useState(false);
    const [initialLoad, setInitialLoad] = useState(true);
    const [linksViewAll, setLinksViewAll] = useState(false);
    const [isViewAllMemberships, setIsViewAllMemberships] = useState(false);
    const [submittedSubscription, setSubmittedSubscription] = useState(false);
    const [spaceUpdatesIsFetching, setSpaceUpdatesIsFetching] = useState(false);
    const [updatesViewingUserIsMember, setUpdatesViewingUserIsMember] = useState(false);
    const [showBenefitDetailPage, setShowBenefitDetailPage] = useState(false);

    const [alert, setAlert] = useState(null);
    const [spaceUpdates, setSpaceUpdates] = useState(null);
    const [spaceUsername, setSpaceUsername] = useState(null);

    const [organizedSpaceUpdates] = useState([]);

    const [currentPage, setCurrentPage] = useState(0);
    const pro_styles = spaceData.pro_style;
    const organizedSpaceMembership = [];
    const organizedSpaceBenefits = [];
    const pageSize = 6;

    const onPageChange = (page) => {
        setCurrentPage(page);
        const section = document.getElementById('cs-space-updates-browse');
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };


    let selectedWelcomeAssetType = 'image';
    if (spaceData.welcome_youtube_url) {
        selectedWelcomeAssetType = 'youtube';
    } else {
        if (spaceData.welcome_video) {
            selectedWelcomeAssetType = 'video';
        }
    }

    useEffect(() => {
    }, [
        organizedSpaceUpdates.length,
        currentPage,
        organizedSpaceBenefits.length
    ])

    let homepage_bg = spaceData && spaceData.background_video ? spaceData.background_video : spaceData.background_photo;

    let show_default_video = false;
    let default_profile_card_bg;
    if (pro_styles?.home_banner_visibility === 'show') {
        if (pro_styles?.bannerBackgroundVideo) {
            default_profile_card_bg = pro_styles.bannerBackgroundVideo;
            show_default_video = true
        } else if (pro_styles?.bannerBackgroundImage) {
            default_profile_card_bg = pro_styles.bannerBackgroundImage;
        } else {
            show_default_video = spaceData && spaceData.background_video;
            default_profile_card_bg = homepage_bg;
        }
    } else {
        show_default_video = spaceData && spaceData.background_video;
        default_profile_card_bg = homepage_bg;
    }

    let header_background_image =
        spaceData && spaceData.background_photo
            ? spaceData.background_photo
            : homepage_bg;
    header_background_image =
        pro_styles && pro_styles?.header_background_image
            ? pro_styles?.header_background_image
            : header_background_image;

    let header_background_video =
        spaceData && pro_styles && pro_styles?.header_background_video
            ? pro_styles?.header_background_video
            : spaceData.background_video;


    const getHeaderBackgroundVideo = () => {
        if (spaceData){
            // console.log(globalActiveTab)
            if (
                globalActiveTab === 'home' ||
                globalActiveTab === "claim_membership" ||
                globalActiveTab === "buy_item" ||
                globalActiveTab === "buy_membership"
            ){
                if (pro_styles) {
                    if (pro_styles?.home_banner_visibility === 'show'){
                        if (pro_styles && pro_styles?.bannerBackgroundVideo){
                            show_default_video = true;
                            return pro_styles?.bannerBackgroundVideo;
                        } else if (pro_styles && pro_styles?.bannerBackgroundImage){
                            show_default_video = false;
                            return pro_styles?.bannerBackgroundImage;
                        } else {
                            if(spaceData.background_video || spaceData.background_photo){
                                show_default_video = !!spaceData.background_video;
                                return spaceData.background_video || spaceData.background_photo;
                            }
                            return homepage_bg;
                        }
                    } else {
                        if(pro_styles?.home_banner_visibility === 'hide'){
                            return homepage_bg;
                        }
                    }
                } else {
                    show_default_video = !!spaceData.background_video;
                    return spaceData.background_video || spaceData.background_photo;
                }
            } else if (globalActiveTab === 'updates' || globalActiveTab === "update_content") {
                if (pro_styles?.updates_banner_visibility === 'show'){
                    if (pro_styles?.updatesBannerImage){
                        return pro_styles?.updatesBannerImage;
                    }
                }
                return default_profile_card_bg;
            } else if (globalActiveTab === 'members'){
                if (pro_styles?.members_banner_visibility === 'show'){
                    if (pro_styles?.membersBannerImage){
                        return pro_styles?.membersBannerImage;
                    }
                }
                return default_profile_card_bg;
            } else if (globalActiveTab === 'benefits') {
                if (pro_styles?.benefits_banner_visibility === 'show'){
                    if (pro_styles?.benefitsBannerImage){
                        return pro_styles?.benefitsBannerImage;
                    }
                }
                return default_profile_card_bg;
            } else if (globalActiveTab === 'channel') {
                if (pro_styles?.channels_banner_visibility === 'show'){
                    if (pro_styles?.channelBannerImage) {
                        return pro_styles?.channelBannerImage;
                    }
                }
                return default_profile_card_bg;
            } else if (
                globalActiveTab === 'faq' ||
                globalActiveTab === 'about' ||
                globalActiveTab === 'contact' ||
                globalActiveTab === 'support' ||
                globalActiveTab === 'privacy-policy' ||
                globalActiveTab === 'terms-services' ||
                globalActiveTab === 'community-guidelines'
            ) {
                if (pro_styles?.information_banner_visibility === 'show') {
                    if (pro_styles?.informationBannerImage) {
                        return pro_styles?.informationBannerImage;
                    }
                }
                return default_profile_card_bg;
            } else {
                return default_profile_card_bg;
            }
        }
    }

    // if (
    // 	spaceData &&
    // 	spaceData.background_video &&
    // 	pro_styles &&
    // 	pro_styles?.header_background_video
    // ) {
    // 	// override space default video
    // 	show_default_video = true;
    // }

    //google fonts
    const fontTitle = spaceData && pro_styles ? pro_styles?.title_font : null; //change value from database
    const fontBody = spaceData && pro_styles ? pro_styles?.body_font : null; //change value from database
    const googleFontUrl = 'https://fonts.googleapis.com/css2?family=';

    // Assign a constant variable and get them by the favicon Id
    const favicon = document.getElementById("favicon");
    const appicon = document.getElementById("appicon");

    if (pro_styles?.favIcon) {
        favicon.setAttribute("href", pro_styles?.favIcon);
    }

    if (pro_styles?.appIcon) {
        appicon.setAttribute("href", pro_styles?.appIcon);
    }

    if (fontTitle && fontTitle !== "DIN 2014") {
        const proFontTitle = document.createElement('link');
        proFontTitle.rel = 'stylesheet';
        proFontTitle.href = googleFontUrl + fontTitle.replace(' ', '+') + ':wght@400;700&display=swap';
        document.head.appendChild(proFontTitle);
    }

    if (fontBody) {
        const proFontBody = document.createElement('link');
        proFontBody.rel = 'stylesheet';
        proFontBody.href = googleFontUrl + fontBody.replace(' ', '+') + ':wght@400;700&display=swap';
        document.head.appendChild(proFontBody);
    }

    const hexToRgb = (hex) => {
        // Remove the hash at the start if it's there
        if(!hex){
            return;
        }
        hex = hex.replace(/^#/, '');
        // Parse the hex values
        let bigint = parseInt(hex, 16);
        let r = (bigint >> 16) & 255;
        let g = (bigint >> 8) & 255;
        let b = bigint & 255;
        // Return the RGB values as a string
        return `${r} ${g} ${b}`;
    }

    const hexToRgbWithCommas = (hex) => {
        // Remove the hash at the start if it's there
        if(!hex){
            return;
        }
        hex = hex.replace(/^#/, '');
        // Parse the hex values
        let bigint = parseInt(hex, 16);
        let r = (bigint >> 16) & 255;
        let g = (bigint >> 8) & 255;
        let b = bigint & 255;
        // Return the RGB values as a string
        return `${r}, ${g}, ${b}`;
    }

    //Logo & Key Color
    const root = document.documentElement;

    //Main Theme Dual
    let selected_theme = "main";
    switch(globalActiveTab){
        case 'home':
            selected_theme = pro_styles?.home_theme || 'main';
            break;
        case 'updates':
            selected_theme = pro_styles?.updates_theme || 'main';
            break;
        case 'members':
            selected_theme = pro_styles?.members_theme || 'main';
            break;
        case 'benefits':
            selected_theme = pro_styles?.benefits_theme || 'main';
            break;
        case 'channel':
            selected_theme = pro_styles?.channels_theme || 'main';
            break;
        case 'links':
            selected_theme = pro_styles?.channels_theme || 'main';
            break;
        case 'faq':
        case 'about':
        case 'contact':
        case 'support':
        case 'privacy-policy':
        case 'terms-services':
        case 'community-guidelines':
            selected_theme = pro_styles?.information_theme || 'main';
            break;
        default:
            selected_theme = "main"
    }

    useEffect(() => {
        
        if (spaceData && ((spaceData.username && window.location.pathname.includes(`/@${spaceData.username}`)) || ROOT_DOMAIN !== window.location.hostname)) {
            applyProStyle();
        } else {
            root.removeAttribute('style');
        }

        // Cleanup function
        return () => {
            root.removeAttribute('style');
        };
    }, []);

    const applyProStyle = () => {
           
      
       
        if(pro_styles && pro_styles?.homeThemeType === "dual"){
            // if(selected_theme === "main"){
            
            // }
            if (pro_styles && pro_styles.key_color) {
                if(selected_theme === "main"){
                    root.style.setProperty('--qs-color-key', hexToRgb(pro_styles?.key_color));
                    root.style.setProperty('--qs-color-key-rgb', hexToRgbWithCommas(pro_styles?.key_color));
                    root.style.setProperty('--qs-color-key-alt', hexToRgb(pro_styles?.dark_text_color));
                    root.style.setProperty('--qs-color-key-alt-rgb', hexToRgbWithCommas(pro_styles?.dark_text_color));
                }else{
                    root.style.setProperty('--qs-color-key', hexToRgb(pro_styles?.dark_text_color));
                    root.style.setProperty('--qs-color-key-rgb', hexToRgbWithCommas(pro_styles?.dark_text_color));
                    root.style.setProperty('--qs-color-key-alt', hexToRgb(pro_styles?.key_color));
                    root.style.setProperty('--qs-color-key-alt-rgb', hexToRgbWithCommas(pro_styles?.key_color));
                }
            }
            if (pro_styles && pro_styles.gradient_color) {
                if(selected_theme === "main"){
                    root.style.setProperty('--qs-color-key-end', hexToRgb(pro_styles?.gradient_color));
                    root.style.setProperty('--qs-color-key-end-rgb', hexToRgbWithCommas(pro_styles?.gradient_color));
                    root.style.setProperty('--qs-color-key-end-alt', hexToRgb(pro_styles?.dark_background_color));
                    root.style.setProperty('--qs-color-key-end-alt-rgb', hexToRgbWithCommas(pro_styles?.dark_background_color));
                }else{
                    root.style.setProperty('--qs-color-key-end', hexToRgb(pro_styles?.dark_background_color));
                    root.style.setProperty('--qs-color-key-end-rgb', hexToRgbWithCommas(pro_styles?.dark_background_color));
                    root.style.setProperty('--qs-color-key-end-alt', hexToRgb(pro_styles?.gradient_color));
                    root.style.setProperty('--qs-color-key-end-alt-rgb', hexToRgbWithCommas(pro_styles?.gradient_color));
                }
            }
            if (pro_styles && pro_styles.light_text_color) {
                if(selected_theme === "main"){
                    root.style.setProperty('--qs-color-primary', hexToRgb(pro_styles?.light_text_color));
                    root.style.setProperty('--qs-color-primary-alt', hexToRgb(pro_styles?.primaryAltColor));
                }else{
                    root.style.setProperty('--qs-color-primary', hexToRgb(pro_styles?.primaryAltColor));
                    root.style.setProperty('--qs-color-primary-alt', hexToRgb(pro_styles?.light_text_color));
                }
            }
            if (pro_styles && pro_styles.light_background_color) {
                if(selected_theme === "main"){
                    root.style.setProperty('--qs-color-secondary', hexToRgb(pro_styles?.light_background_color));
                    root.style.setProperty('--qs-color-secondary-alt', hexToRgb(pro_styles?.secondaryAltColor));
                }else{
                    root.style.setProperty('--qs-color-secondary', hexToRgb(pro_styles?.secondaryAltColor));
                    root.style.setProperty('--qs-color-secondary-alt', hexToRgb(pro_styles?.light_background_color));
                }
            }
            if (pro_styles && pro_styles.invert_color) {
                if(selected_theme === "main"){
                    root.style.setProperty('--qs-color-invert', hexToRgb(pro_styles?.invert_color));
                    root.style.setProperty('--qs-color-invert-alt', hexToRgb(pro_styles?.invertAltColor));
                }else{
                    root.style.setProperty('--qs-color-invert', hexToRgb(pro_styles?.invertAltColor));
                    root.style.setProperty('--qs-color-invert-alt', hexToRgb(pro_styles?.invert_color));
                }
            }
            if (pro_styles && pro_styles.disable_color) {
                if(selected_theme === "main"){
                    root.style.setProperty('--qs-color-disabled', hexToRgb(pro_styles?.disable_color));
                    root.style.setProperty('--qs-color-disabled-alt', hexToRgb(pro_styles?.disableAltColor));
                }else{
                    root.style.setProperty('--qs-color-disabled', hexToRgb(pro_styles?.disableAltColor));
                    root.style.setProperty('--qs-color-disabled-alt', hexToRgb(pro_styles?.disable_color));
                }
            }
            if (pro_styles && pro_styles.background_end_color) {
                if(selected_theme === "main"){
                    root.style.setProperty('--qs-color-background-end', hexToRgb(pro_styles?.background_end_color));
                    root.style.setProperty('--qs-color-background-end-rgb', hexToRgbWithCommas(pro_styles?.background_end_color));
                    root.style.setProperty('--qs-color-background-end-alt', hexToRgb(pro_styles?.backgroundAltEndColor));
                }else{
                    root.style.setProperty('--qs-color-background-end', hexToRgb(pro_styles?.backgroundAltEndColor));
                    root.style.setProperty('--qs-color-background-end-rgb', hexToRgbWithCommas(pro_styles?.backgroundAltEndColor));
                    root.style.setProperty('--qs-color-background-end-alt', hexToRgb(pro_styles?.background_end_color));
                }
            }
            if (pro_styles && pro_styles.background_color) {
                if(selected_theme === "main"){
                    root.style.setProperty('--qs-color-background-start', hexToRgb(pro_styles?.background_color));
                    root.style.setProperty('--qs-color-background-start-rgb', hexToRgbWithCommas(pro_styles?.background_color));
                    root.style.setProperty('qs-color-background-start-alt', pro_styles?.backgroundAltColor);
                }else{
                    root.style.setProperty('--qs-color-background-start', hexToRgb(pro_styles?.backgroundAltColor));
                    root.style.setProperty('--qs-color-background-start-rgb', hexToRgbWithCommas(pro_styles?.backgroundAltColor));
                    
                    root.style.setProperty('qs-color-background-start-alt', pro_styles?.background_color);
                }
            }
            if (pro_styles && pro_styles.selected_background_direction) {
                if(selected_theme === "main"){
                    root.style.setProperty('--qs-color-background-direction', pro_styles?.selected_background_direction);
                    root.style.setProperty('--qs-gradient-background-alt-direction', pro_styles?.selected_background_alt_direction);
                }else{
                    root.style.setProperty('--qs-color-background-direction', pro_styles?.selected_background_alt_direction);
                    root.style.setProperty('--qs-gradient-background-alt-direction', pro_styles?.selected_background_direction);
                }
            }
            //IMAGE/VIDEO
            if (pro_styles && !pro_styles?.light_background_video && pro_styles?.light_background_image) {
                if(selected_theme === "main"){
                    root.style.setProperty(
                        // '--light-section-bg-image',
                        '--cs-theme-bg-image',
                        'url("' + pro_styles?.light_background_image + '")'
                    );
                    root.style.setProperty(
                        '--dark-section-bg-image',
                        'url("' + pro_styles?.dark_background_image + '")'
                    );
                    root.style.setProperty(
                        '--cs-theme-alt-bg-image',
                        'url("' + pro_styles?.dark_background_image + '")'
                    );
                } else {
                    root.style.setProperty(
                        // '--light-section-bg-image',
                        ' --dark-section-bg-image',
                        'url("' + pro_styles?.light_background_image + '")'
                    );
                    root.style.setProperty(
                        '--cs-theme-bg-image',
                        'url("' + pro_styles?.dark_background_image + '")'
                    );
                    root.style.setProperty(
                        '--cs-theme-bg-image',
                        'url("' + pro_styles?.dark_background_image + '")'
                    );
                }
            } else {
                if (pro_styles && !pro_styles?.light_background_video && !pro_styles?.light_background_image) {
                    root.style.setProperty(
                        // '--light-section-bg-image',
                        '--cs-theme-bg-image',
                        'url("' + spaceData?.background_photo + '")'
                    );

                }
            }
        } else {//Single Theme
            if (pro_styles && pro_styles.key_color) {
                if(selected_theme === "main"){
                    root.style.setProperty('--qs-color-key', hexToRgb(pro_styles?.key_color));
                    root.style.setProperty('--qs-color-key-rgb', hexToRgbWithCommas(pro_styles?.key_color));
                    root.style.setProperty('--qs-color-key-alt', hexToRgb(pro_styles?.key_color));
                    root.style.setProperty('--qs-color-key-alt-rgb', hexToRgbWithCommas(pro_styles?.key_color));
                }else{
                    root.style.setProperty('--qs-color-key', hexToRgb(pro_styles?.dark_text_color));
                    root.style.setProperty('--qs-color-key-rgb', hexToRgbWithCommas(pro_styles?.dark_text_color));
                    root.style.setProperty('--qs-color-key-alt', hexToRgb(pro_styles?.dark_text_color));
                    root.style.setProperty('--qs-color-key-alt-rgb', hexToRgbWithCommas(pro_styles?.dark_text_color));
                }
            }
            if (pro_styles && pro_styles.gradient_color) {
                if(selected_theme === "main"){
                    root.style.setProperty('--qs-color-key-end', hexToRgb(pro_styles?.gradient_color));
                    root.style.setProperty('--qs-color-key-end-rgb', hexToRgbWithCommas(pro_styles?.gradient_color));
                    root.style.setProperty('--qs-color-key-end-alt', hexToRgb(pro_styles?.gradient_color));
                    root.style.setProperty('--qs-color-key-end-alt-rgb', hexToRgbWithCommas(pro_styles?.gradient_color));
                }else{
                    root.style.setProperty('--qs-color-key-end', hexToRgb(pro_styles?.dark_background_color));
                    root.style.setProperty('--qs-color-key-end-rgb', hexToRgbWithCommas(pro_styles?.dark_background_color));
                    root.style.setProperty('--qs-color-key-end-alt', hexToRgb(pro_styles?.dark_background_color));
                    root.style.setProperty('--qs-color-key-end-alt-rgb', hexToRgbWithCommas(pro_styles?.dark_background_color));
                }
            }
            if (pro_styles && pro_styles.light_text_color) {
                if(selected_theme === "main"){
                    root.style.setProperty('--qs-color-primary', hexToRgb(pro_styles?.light_text_color));
                    root.style.setProperty('--qs-color-primary-alt', hexToRgb(pro_styles?.light_text_color));
                }else{
                    root.style.setProperty('--qs-color-primary', hexToRgb(pro_styles?.primaryAltColor));
                    root.style.setProperty('--qs-color-primary-alt', hexToRgb(pro_styles?.primaryAltColor));
                }
            }
            if (pro_styles && pro_styles.light_background_color) {
                if(selected_theme === "main"){
                    root.style.setProperty('--qs-color-secondary', hexToRgb(pro_styles?.light_background_color));
                    root.style.setProperty('--qs-color-secondary-alt', hexToRgb(pro_styles?.light_background_color));
                }else{
                    root.style.setProperty('--qs-color-secondary', hexToRgb(pro_styles?.secondaryAltColor));
                    root.style.setProperty('--qs-color-secondary-alt', hexToRgb(pro_styles?.secondaryAltColor));
                }
            }
            if (pro_styles && pro_styles.invert_color) {
                if(selected_theme === "main"){
                    root.style.setProperty('--qs-color-invert', hexToRgb(pro_styles?.invert_color));
                    root.style.setProperty('--qs-color-invert-alt', hexToRgb(pro_styles?.invert_color));
                }else{
                    root.style.setProperty('--qs-color-invert', hexToRgb(pro_styles?.invertAltColor));
                    root.style.setProperty('--qs-color-invert-alt', hexToRgb(pro_styles?.invertAltColor));
                }
            }
            if (pro_styles && pro_styles.disable_color) {
                if(selected_theme === "main"){
                    root.style.setProperty('--qs-color-disabled', hexToRgb(pro_styles?.disable_color));
                    root.style.setProperty('--qs-color-disabled-alt', hexToRgb(pro_styles?.disable_color));
                }else{
                    root.style.setProperty('--qs-color-disabled', hexToRgb(pro_styles?.disableAltColor));
                    root.style.setProperty('--qs-color-disabled-alt', hexToRgb(pro_styles?.disableAltColor));
                }
            }
            if (pro_styles && pro_styles.background_end_color) {
                if(selected_theme === "main"){
                    root.style.setProperty('--qs-color-background-end', hexToRgb(pro_styles?.background_end_color));
                    root.style.setProperty('--qs-color-background-end-alt', hexToRgb(pro_styles?.background_end_color));
                }else{
                    root.style.setProperty('--qs-color-background-end', hexToRgb(pro_styles?.backgroundAltEndColor));
                    root.style.setProperty('--qs-color-background-end-alt', hexToRgb(pro_styles?.backgroundAltEndColor));
                }
            }
            if (pro_styles && pro_styles.background_color) {
                if(selected_theme === "main"){
                    root.style.setProperty('--qs-color-background-start', hexToRgb(pro_styles?.background_color));
                    root.style.setProperty('--qs-color-background-start-rgb', hexToRgbWithCommas(pro_styles?.background_color));                    
                    root.style.setProperty('--qs-color-background-start-alt', hexToRgb(pro_styles?.background_color));
                    root.style.setProperty('--qs-color-background-start-alt-rgb', hexToRgbWithCommas(pro_styles?.background_color));
                }else{
                    root.style.setProperty('--qs-color-background-start', hexToRgb(pro_styles?.backgroundAltColor));
                    root.style.setProperty('--qs-color-background-start-rgb', hexToRgbWithCommas(pro_styles?.backgroundAltColor));
                    root.style.setProperty('--qs-color-background-start-alt-rgb', hexToRgbWithCommas(pro_styles?.backgroundAltColor));
                }
            }
            if (pro_styles && pro_styles.selected_background_direction) {
                if(selected_theme === "main"){
                    root.style.setProperty('--qs-color-background-direction', pro_styles?.selected_background_direction);
                    root.style.setProperty('--qs-gradient-background-alt-direction', pro_styles?.selected_background_direction);
                }else{
                    root.style.setProperty('--qs-color-background-direction', pro_styles?.selected_background_alt_direction);
                    root.style.setProperty('--qs-gradient-background-alt-direction', pro_styles?.selected_background_alt_direction);
                }
            }
            if(selected_theme === "main"){
                root.style.setProperty(
                    // '--light-section-bg-image',
                    '--cs-theme-bg-image',
                    'url("' + pro_styles?.light_background_image + '")'
                );
                root.style.setProperty(
                    '--dark-section-bg-image',
                    'url("' + pro_styles?.light_background_image + '")'
                );
                root.style.setProperty(
                    '--cs-theme-alt-bg-image',
                    'url("' + pro_styles?.light_background_image + '")'
                );
            }else{
                root.style.setProperty(
                    // '--light-section-bg-image',
                    ' --dark-section-bg-image',
                    'url("' + pro_styles?.dark_background_image + '")'
                );
                root.style.setProperty(
                    '--cs-theme-bg-image',
                    'url("' + pro_styles?.dark_background_image + '")'
                );
                root.style.setProperty(
                    '--cs-theme-bg-image',
                    'url("' + pro_styles?.dark_background_image + '")'
                );
            }
        }

        // cs-space-banner-hero-fg-content-justify



        if (pro_styles && pro_styles.background_header_color) {
            root.style.setProperty('--qs-color-header-background', hexToRgb(pro_styles?.background_header_color));
        }

        if (pro_styles && pro_styles.navigation_header_font_color) {
            root.style.setProperty('--qs-color-header-nav-link', hexToRgb(pro_styles?.navigation_header_font_color));
        }

        if (pro_styles && pro_styles.navigation_header_font_weight) {
            root.style.setProperty('--qs-color-header-nav-weight', hexToRgb(pro_styles?.navigation_header_font_color));
        }

        if (pro_styles && pro_styles.primary_header_color) {
            root.style.setProperty('--qs-color-header-primary', hexToRgb(pro_styles?.primary_header_color));
        }

        if (pro_styles && pro_styles.primary_footer_color) {
            root.style.setProperty('--qs-color-footer-primary', hexToRgb(pro_styles?.primary_footer_color));
        }

        if (pro_styles && pro_styles.background_footer_color) {
            root.style.setProperty('--qs-color-footer-background', hexToRgb(pro_styles?.background_footer_color));
        }

        if (pro_styles && pro_styles.navigation_footer_font_weight) {
            root.style.setProperty('--qs-color-footer-nav-weight', hexToRgb(pro_styles?.navigation_footer_font_weight));
        }

        if (pro_styles && pro_styles.navigation_footer_font_color) {
            root.style.setProperty('--qs-color-footer-nav-link', hexToRgb(pro_styles?.navigation_footer_font_color));
        }


        if (pro_styles && (pro_styles?.key_color || pro_styles?.gradient_color)) {
            root.style.setProperty(
                '--key-color-gradient',
                hexToRgb(pro_styles?.has_gradient ? pro_styles?.gradient_color : pro_styles?.key_color)
            );
        }
        root.style.setProperty('--font-heading', fontTitle === "DIN 2014" ? 'headingFontFamily' : fontTitle);
        root.style.setProperty('--font-body', fontBody);

        //Section Colors & Backgrounds
        //Light Section Text & Background Colors



        if (pro_styles && pro_styles.light_image_position) {
            root.style.setProperty('--light-section-image-pos', pro_styles?.light_image_position);
            root.style.setProperty('--cs-theme-bg-image-position', pro_styles?.light_image_position);
        }

        if (pro_styles && pro_styles.light_video_position) {
            root.style.setProperty('--light-section-video-pos', pro_styles?.light_video_position);
            root.style.setProperty('--cs-theme-bg-video-position', pro_styles?.light_video_position);
        }

        if(pro_styles && pro_styles.selected_banner_content_position){
            if(pro_styles.selected_banner_content_position === 'center' || pro_styles.selected_banner_content_position === 'top' || pro_styles.selected_banner_content_position === 'bottom'){
                root.style.setProperty('--cs-space-banner-hero-fg-content-align', pro_styles.selected_banner_content_position);
            }else if(pro_styles.selected_banner_content_position === 'right' || pro_styles.selected_banner_content_position === 'left'){
                root.style.setProperty('--cs-space-banner-hero-fg-content-justify', pro_styles.selected_banner_content_position);
            }else if(pro_styles.selected_banner_content_position === 'top-right'){
                root.style.setProperty('--cs-space-banner-hero-fg-content-align', 'start');
                root.style.setProperty('--cs-space-banner-hero-fg-content-justify', 'right');
            }else if(pro_styles.selected_banner_content_position === 'top-left'){
                root.style.setProperty('--cs-space-banner-hero-fg-content-align', 'start');
                root.style.setProperty('--cs-space-banner-hero-fg-content-justify', 'left');
            }else if(pro_styles.selected_banner_content_position === 'bottom-left'){
                root.style.setProperty('--cs-space-banner-hero-fg-content-align', 'end');
                root.style.setProperty('--cs-space-banner-hero-fg-content-justify', 'left');
            }else if(pro_styles.selected_banner_content_position === 'bottom-right'){
                root.style.setProperty('--cs-space-banner-hero-fg-content-align', 'end');
                root.style.setProperty('--cs-space-banner-hero-fg-content-justify', 'right');
            }
        }
        if(pro_styles && pro_styles.selected_banner_background_video_position){
            root.style.setProperty('--cs-space-banner-hero-bg-video-position', pro_styles.selected_banner_background_video_position);
        }
        if(pro_styles && pro_styles.selected_banner_background_image_position){
            root.style.setProperty('--cs-space-banner-hero-bg-image-position', pro_styles.selected_banner_background_image_position);
        }

        if(pro_styles && pro_styles.dark_video_position){
            root.style.setProperty('--cs-theme-bg-video-position', pro_styles.dark_video_position);
        }

        if(pro_styles && pro_styles.dark_image_position){
            root.style.setProperty('--cs-theme-bg-image-position', pro_styles.dark_image_position);
        }
        if(pro_styles && pro_styles.light_video_position){
            root.style.setProperty('--cs-theme-alt-bg-video-position', pro_styles.light_video_position);
        }

        if(pro_styles && pro_styles.light_image_position){
            root.style.setProperty('--cs-theme-alt-bg-image-position', pro_styles.light_image_position);
        }

        //Dark Section Text & Background Colors

        if (pro_styles && pro_styles.homeBannerImage) {

            root.style.setProperty(
                '--cs-space-banner-hero-bg-image',
                'url("' + pro_styles?.homeBannerImage + '")'
            );
        }

        if (pro_styles && pro_styles.selected_banner_background_image_position) {

            root.style.setProperty(
                '--cs-space-banner-hero-bg-image-position',
                pro_styles?.selected_banner_background_image_position
            );
        }

        if (pro_styles && pro_styles.selected_banner_background_video_position) {

            root.style.setProperty(
                '--cs-space-banner-hero-bg-video-position',
                pro_styles?.selected_banner_background_video_position
            );
        }

        if (pro_styles && pro_styles.benefitsBannerImage) {

            root.style.setProperty(
                '--cs-space-banner-benefits-image',
                'url("' + pro_styles?.benefitsBannerImage + '")'
            );
        }

        if (pro_styles && pro_styles.membersBannerImage) {

            root.style.setProperty(
                '--cs-space-banner-members-image',
                'url("' + pro_styles?.membersBannerImage + '")'
            );
        }

        if (pro_styles && pro_styles.accessBannerImage) {

            root.style.setProperty(
                '(--cs-space-banner-access-image',
                'url("' + pro_styles?.accessBannerImage + '")'
            );
        }

        if (pro_styles && pro_styles.updatesBannerImage) {

            root.style.setProperty(
                '--cs-space-banner-updates-image',
                'url("' + pro_styles?.updatesBannerImage + '")'
            );
        }

        if (pro_styles && pro_styles.channelBannerImage) {

            root.style.setProperty(
                '--cs-space-banner-channels-image',
                'url("' + pro_styles?.channelBannerImage + '")'
            );
        }

        if (pro_styles && pro_styles.informationBannerImage) {

            root.style.setProperty(
                '--cs-space-banner-info-image',
                'url("' + pro_styles?.informationBannerImage + '")'
            );
        }

        if (pro_styles && pro_styles.dark_image_position) {
            root.style.setProperty('--dark-section-image-pos', pro_styles?.dark_image_position);
            root.style.setProperty('--cs-theme-alt-bg-image-position', pro_styles?.dark_image_position);
        }

        if (pro_styles && pro_styles.dark_video_position) {
            root.style.setProperty('--dark-section-video-pos', pro_styles?.dark_video_position);
            root.style.setProperty('--cs-theme-alt-bg-video-position', pro_styles?.dark_video_position);
        }
    }


    const getUpdates = useCallback(
        async (data) => {
            if (!spaceUpdates || (spaceUpdates && spaceUpdates.length === 0)) {
                setSpaceUpdatesIsFetching(true);
            }

            try {
                const publish_statuses = ['immediately', 'published', 'publishing'];
                const params = {
                    space_id: data.id,
                    in_space_view: true,
                    publish_statuses: JSON.stringify(publish_statuses)
                };

                if (authUserInfo) {
                    params.viewing_user_id = authUserInfo.id;
                }

                const result = await getSpaceUpdates(params);
                if (result && result.result) {
                    if (result.data) {
                        setSpaceUpdates(result.data);
                        setUpdatesViewingUserIsMember(result.viewing_user_is_member);
                        const chunkSize = 6;
                        let data = result.data;
                        for (let i = 0; i < data.length; i += chunkSize) {
                            const chunk = data.slice(i, i + chunkSize);
                            organizedSpaceUpdates.push(chunk);
                        }
                    } else {
                        setSpaceUpdates(null);
                    }
                }

                setSpaceUpdatesIsFetching(false);
            } catch (error) {
                setSpaceUpdatesIsFetching(false);
            }
        },
        [authUserInfo, spaceUpdates]
    );

    let latestUpdatesCount = 0;
    const latestUpdates = [];
    if (spaceData.updates) {
        latestUpdatesCount = spaceData.updates.length;
        spaceData.updates.forEach((item, index) => {
            if (index <= 2) {
                latestUpdates.push(item);
            }
        });
    }

    const featuredLinks = [];
    // const limitedFeaturedLinks = [];
    if (spaceData.links) {
        spaceData.links.forEach((item, index) => {
            // if (index <= 2) {
            //     limitedFeaturedLinks.push(item);
            // }

            featuredLinks.push(item);
        });
    }

    let spaceMembershipsAndItems = [];
    const memberShipsAndItems = spaceData.memberships ? spaceData?.memberships.concat(spaceData?.items ? spaceData?.items : [] ) : [];

    // Filter the memberships, then process each one
    spaceMembershipsAndItems = memberShipsAndItems.filter(membershipItem =>
        !(membershipItem?.enable_expiration_and_subscription && membershipItem?.benefits.length < 1)
    );

    // Check if there are owned memberships in spaceData
    if (spaceData.owned_memberships) {
        // Filter out memberships without valid benefits
        let filteredOwnedMemberships = spaceData.owned_memberships.filter(membershipItem =>
            !(membershipItem?.enable_expiration_and_subscription && membershipItem?.benefits.length < 1)
        );
        // Concatenate the filtered memberships to the main array
        spaceMembershipsAndItems = spaceMembershipsAndItems.concat(filteredOwnedMemberships);
    }

    // Check if there are owned items in spaceData
    if (spaceData.owned_items) {
        // Filter out items without valid benefits
        let filteredOwnedItems = spaceData.owned_items.filter(membershipItem =>
            !(membershipItem?.enable_expiration_and_subscription && membershipItem?.benefits.length < 1)
        );
        // Concatenate the filtered items to the main array
        spaceMembershipsAndItems = spaceMembershipsAndItems.concat(filteredOwnedItems);
    }

    // Define the chunk size for splitting the array
    const chunkSize = 6;

    // Split the array into chunks and push to organizedSpaceMembership
    for (let i = 0; i < spaceMembershipsAndItems.length; i += chunkSize) {
        const chunk = spaceMembershipsAndItems.slice(i, i + chunkSize);
        organizedSpaceMembership.push(chunk);
    }

    // // Check if there are fewer than 3 memberships and add empty objects if needed
    // if (spaceData && spaceMembershipsAndItems && spaceMembershipsAndItems.length < 3) {
    //     const membershipCount = spaceMembershipsAndItems.length;
    //     let remainingMembershipCount = 3 - membershipCount;
    //
    //     while (remainingMembershipCount > 0) {
    //         // Add empty objects only if the authenticated user is the space owner
    //         if (authUserInfo && authUserInfo.id === spaceData.owner)
    //             spaceMembershipsAndItems.push({});
    //
    //         remainingMembershipCount--;
    //     }
    // }

    // Array to store the first three valid memberships
    const onlyThreeSpaceMembershipsAndItems = [];
    // Check if there are any memberships
    if (spaceMembershipsAndItems.length > 0) {
        // Iterate through memberships and add up to three valid items
        spaceMembershipsAndItems.some((membershipItems, index) => {
            // Only process the first three items
            if (index < 3) {
                // Only add items with valid benefits
                if (!(membershipItems?.enable_expiration_and_subscription && membershipItems?.benefits.length < 1)) {
                    onlyThreeSpaceMembershipsAndItems.push(membershipItems);
                }
            }
            // Stop iteration after three items
            return index >= 2;
        });
    }

    let benefitsCount = 0;
    const benefits = [];
    if (spaceData.benefits) {
        benefitsCount = spaceData.benefits.length;
        spaceData.benefits.forEach((item, index) => {
            if (index <= 2) {
                benefits.push(item);
            }
        });
        const chunkSize = 6;
        let data = spaceData.benefits;

        for (let i = 0; i < data.length; i += chunkSize) {
            const chunk = data.slice(i, i + chunkSize);
            organizedSpaceBenefits.push(chunk);
        }
    }

    const handleSubscriptionFormSubmit = useCallback(async (values) => {
        if (values.email) {
            setSubmittedSubscription(true);
            setAlert(null);

            const data = {
                space_id: spaceData.id,
                email: values.email
            };

            const response = await subscribeNewsletter(data);
            if (response && response.result) {
                setAlert({
                    variant: 'success',
                    message: response.message
                });
            } else {
                setAlert({
                    variant: 'danger',
                    message: response.message
                });
            }
            subscriptionForm.resetFields();
            setSubmittedSubscription(false);
        }
    }, []);

    let foreground_position = 'center';

    if (pro_styles && (pro_styles?.foreground_position === 'top' && !pro_styles?.foreground_text)) {
        foreground_position = 'start';
    } else if (pro_styles?.foreground_position === 'bottom' && !pro_styles?.foreground_text) {
        foreground_position = 'end';
    }

    let lightVideoStyle = {
        objectFit: 'cover',
        height: '100%'
    };

    if (pro_styles && pro_styles.light_video_position === 'top') {
        lightVideoStyle = {
            top: '0'
        };
    } else if (pro_styles && pro_styles.light_video_position === 'center') {
        lightVideoStyle = {
            top: '50%',
            left: '50%',
            transform: 'translateX(-50%) translateY(-50%)'
        };
    } else if (pro_styles && pro_styles.light_video_position === 'bottom') {
        lightVideoStyle = {
            bottom: '0'
        };
    }

    let darkVideoStyle = {
        objectFit: 'cover',
        height: '100%'
    };

    if (pro_styles && pro_styles.dark_video_position === 'top') {
        darkVideoStyle = {
            top: '0'
        };
    } else if (pro_styles && pro_styles.dark_video_position === 'center') {
        darkVideoStyle = {
            top: '50%',
            left: '50%',
            transform: 'translateX(-50%) translateY(-50%)'
        };
    } else if (pro_styles && pro_styles.dark_video_position === 'bottom') {
        darkVideoStyle = {
            bottom: '0'
        };
    }

    const setActiveTabChange = (item) => {
        updateGlobalActiveTab(item);
        setIsViewAllMemberships(false);
        if (item === 'home' || item === 'members') {
            if (ROOT_DOMAIN === window.location.hostname) {
                let url_username = spaceData.username;
                if (isPublicUrl) {
                    url_username = spaceData.public_url_string;
                }

                // force page url navigate to reset location path when going back to home
                navigate(routes.viewUserSpace() + url_username, { replace: true });
            } else {
                if (item === 'members') {
                    window.history.replaceState(null, null, '/member');
                } else {
                    window.history.replaceState(null, null, '/');
                }
            }
        }
    };

    const setVideoPlay = () => {
        if (globalActiveTab !== 'home') {
            setPlayVideo(false);
        }
    };

    const setLinksViewAllChange = useCallback(async () => {
        if (linksViewAll) {
            setLinksViewAll(false);
        } else {
            setLinksViewAll(true);
        }
    }, [linksViewAll]);

    useEffect(() => {
        const path = window.location.pathname;
        if (path && path.startsWith('/@') && path.length > 3) {
            let directory = path.replace('/@', '');
            const split = directory.split('/');
            const username = split[0];
            setSpaceUsername(username);
        }

        if (spaceData) {
            if (spaceData.viewing_user_is_member) {
                setUpdatesViewingUserIsMember(spaceData.viewing_user_is_member);
            } else {
                setUpdatesViewingUserIsMember(false);
            }
        }

        if (spaceUsername != null) {
            if (globalActiveTab === 'updates') {
                getUpdates(spaceData).then();
                if (!path.includes('update')) {
                    window.history.replaceState(null, null, '/@' + spaceUsername + '/update');
                }
            } else if (globalActiveTab === 'home') {
                if (!path.includes('home') && !path.includes('verify-email')) {
                    window.history.replaceState(null, null, '/@' + spaceUsername);
                }
            } else if (globalActiveTab === 'benefits') {
                if (!path.includes('benefit')) {
                    window.history.replaceState(null, null, '/@' + spaceUsername + '/benefit');
                }
            } else if (globalActiveTab === 'members') {
                if (!path.includes('member')) {
                    window.history.replaceState(null, null, '/@' + spaceUsername + '/member');
                }
            } else if (globalActiveTab === 'support') {
                if (!path.includes('support')) {
                    window.history.replaceState(null, null, '/@' + spaceUsername + '/support');
                }
            } else if (globalActiveTab === 'about') {
                if (!path.includes('about')) {
                    window.history.replaceState(null, null, '/@' + spaceUsername + '/about');
                }
            } else if (globalActiveTab === 'links') {
                if (!path.includes('link')) {
                    window.history.replaceState(null, null, '/@' + spaceUsername + '/link');
                }
            } else if (globalActiveTab === 'faq') {
                if (!path.includes('faq')) {
                    window.history.replaceState(null, null, '/@' + spaceUsername + '/faq');
                }
            }
        }

        const availableTabs = [
            'home',
            'updates',
            'benefits',
            'members',
            'links'
        ];

        if (initialLoad) {
            setInitialLoad(false);
            if (availableTabs.includes(urlTab)) {
                setActiveTabChange(urlTab);
                searchParams.delete('tab');
                setSearchParams(searchParams);
            }
        }

        if (globalActiveTab === 'update_content') {
            if (availableTabs.includes(globalActiveTab)) {
                let baseUrl = '/@' + spaceUsername;
                // if (globalActiveTab === 'home') {
                // 	baseUrl = baseUrl;
                // }
                if (globalActiveTab === 'updates') {
                    baseUrl = baseUrl + '/update';
                }
                if (globalActiveTab === 'benefits') {
                    baseUrl = baseUrl + '/benefit';
                }
                if (globalActiveTab === 'members') {
                    baseUrl = baseUrl + '/member';
                }
                if (globalActiveTab === 'support') {
                    baseUrl = baseUrl + '/support';
                }
                if (globalActiveTab === 'about') {
                    baseUrl = baseUrl + '/about';
                }
                if (globalActiveTab === 'links') {
                    baseUrl = baseUrl + '/link';
                }
                if (globalActiveTab === 'faq') {
                    baseUrl = baseUrl + '/faq';
                }
                window.history.replaceState(null, null, baseUrl);
                navigate(baseUrl);
            }
        }

        setVideoPlay();
    }, [spaceData, initialLoad, urlTab, globalActiveTab, spaceUsername, playVideo]);
    // console.log('spaceviewpro: ' + globalActiveTab);

    useEffect(()=> {
        setIsViewAllMemberships(false);
    },[globalActiveTab])

    const getImageBGUrl = (data) => {
        if(data === 'light'){
            if(pro_styles){
                if(pro_styles?.homeThemeType === 'dual') {
                    if(pro_styles?.home_theme === 'main'){
                        return pro_styles?.light_background_image
                    }else{
                        return pro_styles?.dark_background_image
                    }
                }else{//single
                    if(pro_styles?.home_theme === 'main'){
                        return pro_styles?.light_background_image
                    }else{
                        return pro_styles?.dark_background_image
                    }
                }
            }

        }
        if(data === 'dark'){
            if(pro_styles?.homeThemeType === 'dual') {
                if(pro_styles?.home_theme === 'alt'){
                    return pro_styles?.light_background_image
                }else{
                    return pro_styles?.dark_background_image
                }

            }else{//single
                if(pro_styles?.home_theme === 'main'){
                    return pro_styles?.light_background_image
                }else{
                    return pro_styles?.dark_background_image
                }
            }

        }
    }

    const featuredChannelBannerImage = () => {
        if (pro_styles?.channels_banner_visibility === 'show' && pro_styles?.channelBannerImage) {
            return pro_styles?.channelBannerImage;
        } else if (pro_styles?.home_banner_visibility === 'show' && pro_styles?.bannerBackgroundImage) {
            return pro_styles.bannerBackgroundImage;
        }
        return spaceData.background_photo;
    }

    const getBGClass = (data) => {
        if(data === 'light'){
            if(pro_styles){
                if(pro_styles?.homeThemeType === 'dual') {
                    if(pro_styles?.home_theme === 'main'){
                        if(pro_styles?.light_background_image){
                            return 'theme-bg-image'
                        }
                    }else{
                        if(pro_styles?.dark_background_image){
                             return 'theme-alt-bg-image'
                        }
                    }
                }else{//single
                    if(pro_styles?.home_theme === 'main'){
                        if(pro_styles?.light_background_image){
                            return 'theme-bg-image'
                        }
                    }else{
                        if(pro_styles?.dark_background_image){
                            return 'theme-alt-bg-image'
                       }
                    }
                }
            }
            return ''
        }
        if(data === 'dark'){
            if(pro_styles?.homeThemeType === 'dual') {
                if(pro_styles?.home_theme === 'alt'){
                    return 'theme-alt-bg-image'
                }else{
                    return 'theme-bg-image'
                }

            }else{//single
                if(pro_styles?.home_theme === 'main'){
                    return 'theme-bg-image'
                }else{
                    return 'theme-alt-bg-image'
                }
            }

        }
    }

    

    const getVideoBGUrl = (data) => {
        if(data === 'light'){
            // console.log("pro_styles" + pro_styles)
            if(pro_styles){
                if(pro_styles?.homeThemeType === 'dual') {
                    if(pro_styles?.home_theme === 'main'){
                        return pro_styles?.light_background_video
                    }else{
                        return pro_styles?.dark_background_video
                    }
                }else{//single
                    if(pro_styles?.home_theme === 'main'){
                        return pro_styles?.light_background_video
                    }else{
                        return pro_styles?.dark_background_video
                    }
                }
            }

        }
        if(data === 'dark'){
            if(pro_styles?.homeThemeType === 'dual') {
                if(pro_styles?.home_theme === 'alt'){
                    return pro_styles?.light_background_video
                }else{
                    return pro_styles?.dark_background_video
                }

            }else{//single
                if(pro_styles?.home_theme === 'main'){
                    return pro_styles?.light_background_video
                }else{
                    return pro_styles?.dark_background_video
                }
            }
        }
    }

    const channelsCardRedirection = () => {
        if (ROOT_DOMAIN !== window.location.hostname) {
            if (spaceData.viewing_user_is_owner && !spaceData.channels_enable) {
                return createFullRoute(ROOT_DOMAIN, routes.viewUserSpace() + spaceData?.username + `/manage/manage-channels`);
            } else {
                return createFullRoute(ROOT_DOMAIN, routes.viewUserSpace() + spaceData?.username + '/channel');
            }
        } else {
            if (spaceData.viewing_user_is_owner && !spaceData.channels_enable) {
                return routes.viewUserSpace() + spaceData?.username + `/manage/manage-channels`;
            } else {
                return routes.viewUserSpace() + spaceData?.username + '/channel';
            }
        }
    }

    useEffect(() => {

    }, [isPublicUrl]);

    return (
        <main id="cs-space-main" className={`space-view-pro cs-main ${pro_styles ? pro_styles?.home_theme === 'alt' ? 'main-theme-alt' : 'main-theme' : 'main-theme'} ${pro_styles && pro_styles?.has_gradient ? 'has-gradient' : 'no-gradient'}`}>
            {/*SECTION COMPONENT: Hero Section*/}
            <section id='cs-space-banner-hero' className='p-section-safeview h-5row' //Needs to remove the padding in hero video because padding will show in background hero video
                     style={pro_styles?.bannerBackgroundImage ? {backgroundImage: `url(${getHeaderBackgroundVideo()})`} : {backgroundImage: `url(${getHeaderBackgroundVideo()})`}}
            >

                {show_default_video && (
                    <video
                        key={getHeaderBackgroundVideo()}
                        autoPlay
                        muted
                        loop
                        playsInline
                        id="cs-hero-bg-video"
                    >
                        <source src={getHeaderBackgroundVideo()} type="video/mp4"/>
                    </video>
                )}
                {(globalActiveTab !== 'channel') &&
                    // CSJS Apply bg-cover-* based on {theme_background_position} value, where * sets background position: center (default), top, bottom, left, right, left-top, left-bottom, right-top, or right-bottom as inline class. Background-image URL is {space_hero_background_image}.
                    // ANCHOR OPTION: Hero Image/Text
                    <div className={`cs-hero-frame centerframe-grid`}>
                        {/* CSJS The default "place-items-center" class changes based on {space_hero_foreground_position}.*/}
                        {/* <div className="flex col-span-full">


							{pro_styles && (pro_styles?.header_foreground_image) && (
								<img
									className={`max-h-[416px]  ${pro_styles?.selected_banner_content_position === 'cover' ? 'object-cover' : 'object-' + pro_styles?.selected_banner_content_position}`}
									alt="SPACENAME"
									src={pro_styles?.header_foreground_image}
								/>
							)}
							{(pro_styles && !pro_styles?.header_foreground_image &&  pro_styles?.foreground_text) && (
								<h1 className="text-d2">{pro_styles?.foreground_text}</h1>
							)}

						</div> */}
                        <div className="cs-hero-content">
                            {pro_styles && (pro_styles?.header_foreground_image) && (
                                <img src={pro_styles?.header_foreground_image} alt="SPACENAME" className={` ${pro_styles?.selected_banner_content_position === 'cover' ? 'object-cover' : 'object-' + pro_styles?.selected_banner_content_position}`}/>
                            )}
                            {(pro_styles && !pro_styles?.header_foreground_image &&  pro_styles?.foreground_text) && (
                                <h1 className="text-d2">{pro_styles?.foreground_text}</h1>
                            )}
                        </div>
                    </div>
                }
            </section>

            {/* Start of Welcome Home Section */}
            <section id="cs-space-home-c1" className={`${pro_styles && pro_styles?.light_background_video ? '!p-0 relative' : 'p-section-safeview'} ${(pro_styles?.home_theme === 'main' && pro_styles?.homeThemeType === 'single') ? 'theme-transparent' : (pro_styles?.home_theme === 'main' && pro_styles?.homeThemeType === 'dual' ? 'theme' : (pro_styles?.home_theme === 'alt' && pro_styles?.homeThemeType === 'single' ? 'theme-alt-transparent' : pro_styles?.home_theme === 'alt' && pro_styles?.homeThemeType === 'dual' ? 'theme-alt' : '')) }  ${globalActiveTab === 'home' ? 'show active' : 'hidden'} ${getBGClass('light')}`}
                     style={{
                         backgroundImage: `url(${pro_styles && pro_styles?.light_background_video ? '' : getImageBGUrl('light')})`,
                         backgroundSize: pro_styles?.light_image_position === 'cover' ? pro_styles?.light_image_position : '', // Adjust this value as needed
                         objectPosition: pro_styles?.light_image_position === 'cover' ? '' : pro_styles?.light_image_position
                     }}
            >
                {((pro_styles && pro_styles?.light_background_video) || spaceData.background_video) &&
                    <video
                        key={getVideoBGUrl('light')}
                        autoPlay
                        muted
                        loop
                        playsInline
                        id="cs-theme-bg-video"
                    >
                        <source src={getVideoBGUrl('light')} type="video/mp4"/>
                    </video>
                }

                <div className={`${pro_styles && pro_styles?.light_background_video ? 'relative p-section-safeview' : '' } centerframe-grid gap-2gutter`}
                     style={ {paddingLeft: 0, paddingRight: 0} }
                >
                    <div id="cs-space-home-welcome" className="grid-base col-span-full order-1 ">
                        <div id="cs-space-home-welcome-image" className={`block-lgx card ${selectedWelcomeAssetType === 'image' ? '' : 'p-0'}`}
                             style={ {backgroundImage: 'url(' + (selectedWelcomeAssetType === 'image' ? spaceData?.welcome_photo : '') + ')'} }
                        >
                            {selectedWelcomeAssetType === 'video' && (

                                <video
                                    key={spaceData.welcome_video}
                                    autoPlay
                                    muted
                                    loop
                                    playsInline
                                    id="cs-theme-bg-video"
                                >
                                    <source src={spaceData.welcome_video} type="video/mp4"/>
                                </video>
                            )}
                            {selectedWelcomeAssetType === 'youtube' && (
                                <ReactPlayer
                                    playing={true}
                                    loop={true}
                                    muted={true}
                                    controls={false}
                                    width="100%"
                                    height="100%"
                                    className="youtubeVideoPreview"
                                    url={spaceData.welcome_youtube_url}
                                />
                            )}
                        </div>
                        <div id="cs-space-home-welcome-intro" className="block-mdx">
                            <hgroup>
                                <h2>{spaceData.welcome_title}</h2>
                                <p>{spaceData.welcome_message}</p>
                            </hgroup>
                            <div className="stack-col">
                                {(spaceData && !spaceData.viewing_user_is_member) &&
                                    <button type="button" className="primary-gradient"
                                            onClick={(event) =>
                                                setActiveTabChange(
                                                    'members'
                                                )
                                            }
                                    >
                                        <span><p>{spaceData?.join_title ?? t('button_joincommunity')}</p></span>
                                    </button>
                                }
                                { (spaceData.has_newsletters_signup
                                    //&& !updatesViewingUserIsMember
                                ) && (
                                    <Form
                                        form={subscriptionForm}
                                        name="subscriptionForm"
                                        id="subscriptionForm"
                                        validateMessages={
                                            VALIDATE_MESSAGES
                                        }
                                        onFinish={
                                            handleSubscriptionFormSubmit
                                        }
                                        onFocus={() => {
                                            setAlert(null);
                                        }}
                                        onBlur={() => {
                                            setAlert(null);
                                        }}
                                    >
                                        {alert && (
                                            <div className={`alert callout ${(alert.variant === 'danger' ? 'warning' : 'success')} items-center`}>
                                                <div className="action">
                                                    <i className="fa-solid fa-triangle-exclamation"></i>
                                                </div>
                                                <div className="content">
                                                    <div>
                                                        <h6>{alert.message}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        <Form.Item
                                            name="email"
                                            rules={[
                                                { required: false },
                                                { validator: CustomValidators.email }
                                            ]}
                                        >
                                            <div className="form-input-combo">
                                                <label htmlFor="email-address">Email address</label>
                                                <input id="email-address" disabled={submittedSubscription} name="email" type="email" autoComplete="email" required placeholder="Enter your email"/>
                                                <button disabled={submittedSubscription} type="submit" className="secondary-gradient">
													<span>
														<p>
														{submittedSubscription && (
                                                            <i className="fa-solid fa-spinner fa-spin me-2"></i>
                                                        )}{t('button_emailsubscribe')}
														</p>
													</span>
                                                </button>
                                            </div>
                                        </Form.Item>
                                    </Form>
                                )}
                                {(spaceData && spaceData?.social_links) && (
                                    <SocialLinks
                                        socialLinks={spaceData.social_links}
                                        parentClassName="cs-stack-link-icons stack-row-wrap"
                                        childClassName="button-icon-round-gradient-alt"
                                    />
                                )}
                                {/*<div className="cs-stack-link-icons stack-row">*/}
                                {/*	<a target="_blank" className="button-icon-round-gradient"><span><p><i className="fa-brands fa-x-twitter"></i></p></span></a>*/}
                                {/*	<a target="_blank" className="button-icon-round-gradient"><span><p><i className="fa-brands fa-instagram"></i></p></span></a>*/}
                                {/*	<a target="_blank" className="button-icon-round-gradient"><span><p><i className="fa-brands fa-facebook-f"></i></p></span></a>*/}
                                {/*	<a target="_blank" className="button-icon-round-gradient"><span><p><i className="fa-brands fa-tiktok"></i></p></span></a>*/}
                                {/*	<a target="_blank" className="button-icon-round-gradient"><span><p><i className="fa-brands fa-youtube"></i></p></span></a>*/}
                                {/*	<a target="_blank" className="button-icon-round-gradient"><span><p><i className="fa-brands fa-linkedin"></i></p></span></a>*/}
                                {/*	<a target="_blank" className="button-icon-round-gradient"><span><p><i className="fa-brands fa-discord"></i></p></span></a>*/}
                                {/*	<a target="_blank" className="button-icon-round-gradient"><span><p><i className="fa-brands fa-twitch"></i></p></span></a>*/}
                                {/*	<a target="_blank" className="button-icon-round-gradient"><span><p><i className="fa-regular fa-globe"></i></p></span></a>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                    {spaceData && onlyThreeSpaceMembershipsAndItems && onlyThreeSpaceMembershipsAndItems.length > 0 && (
                        <div id="cs-space-products-join" className="grid-base col-span-full order-2">
                            <div className="block-full">
                                <div className="cs-stack-title stack-row">
                                    <h2><span>{spaceData?.community_title_first_half ?? 'Join'}</span> {spaceData?.community_title_second_half ?? spaceData?.name}</h2>
                                    <p><a className="pr-1"
                                          onClick={() =>
                                              setActiveTabChange('members')
                                          }
                                    >{t('link_viewall')}</a></p>
                                </div>
                            </div>
                            {onlyThreeSpaceMembershipsAndItems.map((data, index) => (
                                <Communities
                                    key={index}
                                    data={data}
                                    user={authUserInfo}
                                    spaceData={spaceData}
                                    updateGlobalActiveTab={
                                        updateGlobalActiveTab
                                    }
                                />
                            ))}
                        </div>
                    )}
                </div>
            </section>
            {/* End of Welcome Home Section */}

            {/* Start of Light Section */}
            <section id="cs-space-home-c2" className={`${pro_styles && pro_styles?.dark_background_video ? '!p-0 relative' : 'p-section-safeview'} ${(pro_styles?.home_theme === 'main' && pro_styles?.homeThemeType === 'single') ? 'theme-transparent' : (pro_styles?.home_theme === 'main' && pro_styles?.homeThemeType === 'dual' ? 'theme-alt' : (pro_styles?.home_theme === 'alt' && pro_styles?.homeThemeType === 'single' ? 'theme-alt-transparent' : pro_styles?.home_theme === 'alt' && pro_styles?.homeThemeType === 'dual' ? 'theme' : '')) }  ${globalActiveTab !== 'channel' && globalActiveTab === 'home' ? 'show active' : 'hidden'} ${getBGClass('dark')}`}
                     style={{
                         backgroundImage: `url(${pro_styles && pro_styles?.dark_background_video ? '' : getImageBGUrl('dark')})`,
                         backgroundSize: pro_styles?.dark_image_position === 'cover' ? pro_styles?.dark_image_position : '', // Adjust this value as needed
                         objectPosition: pro_styles?.dark_image_position === 'cover' ? '' : pro_styles?.dark_image_position
                     }}
            >
                {((pro_styles && pro_styles?.dark_background_video) || (pro_styles?.home_theme === 'main' && pro_styles?.light_background_video)) &&
                    <video
                        key={getVideoBGUrl('dark')}
                        autoPlay
                        muted
                        loop
                        playsInline
                        id="cs-theme-alt-bg-video"
                    >
                        <source src={getVideoBGUrl('dark')} type="video/mp4"/>
                    </video>

                }

                {/* Start of Home Latest Updates */}
                <div className={`${pro_styles && pro_styles?.dark_background_video ? 'relative p-section-safeview' : '' } centerframe-grid gap-2gutter`}
                     style={ {paddingLeft: 0, paddingRight: 0} }
                >
                    <div id="cs-space-updates-latest" className="grid-base col-span-full order-1">
                        <div className="block-full">
                            <div className="cs-stack-title stack-row">
                                <h2><span>Latest</span> Updates</h2>
                                <p><a className="pr-1"
                                      onClick={() =>
                                          setActiveTabChange('updates')
                                      }
                                >{t('link_viewall')}</a></p>
                            </div>
                        </div>

                        <div className="cs-stack-excerpts grid grid-cols">
                            {latestUpdates.slice(0, 3).map((data, index) => (
                                <LatestUpdates
                                    key={index}
                                    data={data}
                                    user={authUserInfo}
                                    spaceData={spaceData}
                                    updatesViewingUserIsMember={
                                        updatesViewingUserIsMember
                                    }
                                    updateGlobalActiveTab={
                                        updateGlobalActiveTab
                                    }
                                    setActiveTabChange={setActiveTabChange}
                                />
                            ))}
                        </div>
                    </div>

                    {spaceData && benefits && benefits.length > 0 && (
                        <div id="cs-space-benefits-latest" className="grid-base col-span-full order-2">
                            <div className="block-full">
                                <div className="cs-stack-title stack-row">
                                    <h2><span>Member</span> Benefits</h2>
                                    <p><a className="pr-1" onClick={() => setActiveTabChange('benefits')}>{t('link_viewall')}</a></p>
                                </div>
                            </div>
                            <div className="cs-stack-excerpts grid grid-cols">
                                {benefits.slice(0, 2).map((data, index) => (
                                    <MemberBenefits
                                        key={index}
                                        data={data}
                                        user={authUserInfo}
                                        spaceData={spaceData}
                                    />
                                ))}
                            </div>
                        </div>
                    )}

                    {spaceData && featuredLinks.length > 0 && (
                        <div id="cs-space-links-latest" className="grid-base col-span-full order-3">
                            <div className="block-full">
                                <div className="cs-stack-title stack-row">
                                    {spaceData &&spaceData.link_title_first ? (
                                        <h2><span>{spaceData.link_title_first}{' '}</span>
                                            {spaceData.link_title_second}
                                        </h2>
                                    ) : (
                                        <h2><span>Featured</span> Links</h2>
                                    )}
                                    {spaceData &&
                                        spaceData.plan === 'pro' &&
                                        featuredLinks.length > 3 && (
                                            <p>
                                                <a onClick={() =>
                                                    setActiveTabChange('links')
                                                }>
                                                    {t('link_viewall')} all
                                                </a>
                                            </p>
                                        )
                                    }
                                </div>
                            </div>
                            {featuredLinks.slice(0, 3).map((data, index) => (
                                <FeaturedLinks
                                    key={index}
                                    data={data}
                                    index={index}
                                    space={spaceData}
                                />
                            ))}
                            {/*ANCHOR LINK: Mobile View All*/}
                            {spaceData &&
                                spaceData.plan === 'pro' &&
                                featuredLinks.length > 3 && (
                                    <p className="cs-mobile-link-viewall">
                                        <a onClick={() =>
                                            setActiveTabChange('links')
                                        }>
                                            {t('link_viewall')}
                                        </a>
                                    </p>
                                )
                            }
                        </div>
                    )}
                </div>
            </section>

            {/* Start of Updates Tab */}
            <section id="cs-space-updates-c1" className={`${pro_styles?.updates_theme === 'main' ? 'theme-transparent' : 'theme-alt-transparent' } ${pro_styles && pro_styles?.dark_background_video ? '!p-0 relative ' : 'p-section-safeview '} ${globalActiveTab === 'updates' ? 'show active' : 'hidden'}`}
                     style={{
                         backgroundImage: `url(${pro_styles && pro_styles?.light_background_video ? '' : getImageBGUrl('light')})`,
                         backgroundSize: pro_styles?.light_image_position === 'cover' ? pro_styles?.light_image_position : '', // Adjust this value as needed
                         objectPosition: pro_styles?.light_image_position === 'cover' ? '' : pro_styles?.light_image_position
                     }}
            >
                {((pro_styles && pro_styles?.light_background_video) || spaceData.background_video) &&
                    <video
                        key={getVideoBGUrl('light')}
                        autoPlay
                        muted
                        loop
                        playsInline
                        id="cs-theme-bg-video"
                    >
                        <source src={getVideoBGUrl('light')} type="video/mp4"/>
                    </video>
                }
                <div className={`centerframe-grid gap-2gutter ${pro_styles && pro_styles?.dark_background_video ? 'relative p-section-safeview' : ''}`}>
                    <div id="cs-space-updates-browse" className="grid-base col-span-full order-1">
                        <div className="block-full">
                            <div className="cs-stack-title-filters stack-row-wrap">
                                <h2><span>Newest</span> Updates</h2>
                                <div className="cs-stack-filters">
                                    <button className="filter-dropdown-gradient" id="menu-button" aria-expanded="true" aria-haspopup="true"><p>All</p><i className="fa-regular fa-angle-down"></i></button>
                                    <button className="filter-dropdown-gradient" id="menu-button" aria-expanded="true" aria-haspopup="true"><p>Newest</p><i className="fa-regular fa-angle-down"></i></button>
                                </div>
                            </div>
                        </div>
                        <div className="cs-stack-excerpts grid grid-cols">
                            {spaceUpdatesIsFetching ? (
                                <section id="page-content-loading-overlay" className="screen-modal p-section-safeview theme-transparent">
                                    <div className="centerframe-flex frame-modal">
                                        <div className="block-lg modal-block">
                                            <i className="fa-regular fa-solar-system"/>
                                        </div>
                                    </div>
                                </section>
                            ) : (
                                <div className="cs-stack-excerpts grid grid-cols pb-5">
                                    {
                                        spaceData &&
                                        organizedSpaceUpdates &&
                                        organizedSpaceUpdates.length > 0 &&
                                        organizedSpaceUpdates[currentPage] &&
                                        organizedSpaceUpdates[currentPage].length > 0 && (
                                            organizedSpaceUpdates[currentPage].map((data, index) => (
                                                <LatestUpdates
                                                    key={index}
                                                    data={data}
                                                    user={
                                                        authUserInfo
                                                    }
                                                    spaceData={spaceData}
                                                    updatesViewingUserIsMember={
                                                        updatesViewingUserIsMember
                                                    }
                                                    updateGlobalActiveTab={
                                                        updateGlobalActiveTab
                                                    }
                                                />
                                            ))
                                        )
                                    }
                                    {spaceData && spaceUpdates && spaceUpdates.length > 6 && (
                                        <div className="cs-pagination block-full">
                                            <CustomPagination
                                                items={spaceUpdates.length} // 100
                                                currentPage={currentPage} // 1
                                                pageSize={pageSize} // 10
                                                onPageChange={onPageChange}
                                            />
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>
            {/* End of Updates Tab */}

            {/* Start of Benefits Tab */}
            <section id="cs-space-benefits-post-c1" className={`theme-transparent ${globalActiveTab === 'benefits' ? 'show active ' : 'hidden '} ${pro_styles && pro_styles?.dark_background_video ? '!p-0 relative ' : 'p-section-safeview '}`}
                     style={ {backgroundImage: 'url(' + (pro_styles && pro_styles?.dark_background_video ? '' : pro_styles?.dark_background_image) + ')'} }
            >
                {(pro_styles && pro_styles?.dark_background_video) &&
                    <ReactPlayer
                        playing={true}
                        loop={true}
                        muted={true}
                        controls={false}
                        width="100%"
                        height="100%"
                        className={`videoPreview absolute`}
                        url={pro_styles?.dark_background_video}
                    />
                }
                {showBenefitDetailPage && (
                    <SpaceBenefit/>
                )}
                {!showBenefitDetailPage && (
                    <div className={`centerframe-grid gap-2gutter ${pro_styles && pro_styles?.dark_background_video ? 'relative p-section-safeview' : ''}`}>
                        <div id="cs-space-benefits-browse" className="grid-base col-span-full order-1">
                            <div className="block-full">
                                <div className="cs-stack-title-filters stack-row-wrap">
                                    {/*CSJS <span> text is based on selected filter dropdown with "Newest" as default. Non-span text is always "Benefits". */}
                                    <h2><span>Newest</span> Benefits</h2>
                                    {/*CSJS Filters function on Benefits categories and sort options.*/}
                                    <div className="cs-stack-filters">
                                        <button className="filter-dropdown-gradient" id="menu-button" aria-expanded="true" aria-haspopup="true"><p>All</p><i className="fa-regular fa-angle-down"></i></button>
                                        <button className="filter-dropdown-gradient" id="menu-button" aria-expanded="true" aria-haspopup="true"><p>Newest</p><i className="fa-regular fa-angle-down"></i></button>
                                        <button onClick={() => setShowBenefitDetailPage(true)} className="filter-dropdown-gradient !hidden" id="menu-button" aria-expanded="true" aria-haspopup="true"><p>Show</p></button>
                                    </div>
                                </div>
                            </div>

                            {/* Modified Spacing for Excerpt Stacks */}
                            <div className="cs-stack-excerpts grid grid-cols">
                            
                                {/*ANCHOR EXCERPT: Benefits Post Preview*/}
                                {/*<article className="cs-excerpt-benefit block-md stack-y">*/}
                                {/*	<a*/}
                                {/*		// href="fam-space-benefits-post.html"*/}
                                {/*		target="_self">*/}
                                {/*		<img className="card" src="images/fam-benefit-1.jpg" alt="Description of image"/>*/}
                                {/*	</a>*/}
                                {/*	/!*CSJS Link URL links to specific Benefits Post, img src and alt are from {space_benefits_post_image} and {space_benefits_post_image_alttext} of specific Benefits Post OR if viewer does not have auth/access then {space_benefits_post_image_public} and {space_benefits_post_image_alttext_public} of specific Benefits Post.*!/*/}
                                {/*	<div className="cs-stack-subtitle-published stack-row">*/}
                                {/*		/!*CSJS Date text is from {space_benefits_post_published} date value of specific Benefits Post.*!/*/}
                                {/*		<time>March 15, 2024</time>*/}
                                {/*		/!*CSJS Chip text and URL is from the category values based on the {space_benefits_post_category} of specific Benefits Post.*!/*/}
                                {/*		<a className="chip-gradient">Music</a>*/}
                                {/*	</div>*/}
                                {/*	<hgroup>*/}
                                {/*		/!*CSJS Title text is from {space_benefits_post_title} (OR {space_benefits_post_title_public} if viewer does not have auth/access) of specific Benefits Post and URL links to the Specific Benefits Post's Page.*!/*/}
                                {/*		<h4><a*/}
                                {/*			// href="fam-space-benefits-post.html"*/}
                                {/*			target="_self">Nala x Mi Domina Records Sound Selects</a></h4>*/}
                                {/*		/!*CSJS P text is {space_benefits_post_preview_text} OR {space_benefits_post_preview_text_public} of specific Benefits Post, based on viewer auth/access.*!/*/}
                                {/*		<p>Nala, an emerging DJ, producer, foodie, and Friend of Mike recently launched her music imprint Mi Domina Records, merging punk rock aesthetics and underground dance music. To celebrate, she’s made 12 exclusive tracks available to members, inspired by her global travels.</p>*/}
                                {/*	</hgroup>*/}
                                {/*	<div className="cs-stack-fill">*/}
                                {/*		/!*CSJS Button text is based on viewer auth/access.*!/*/}
                                {/*		<a*/}
                                {/*			// href="fam-space-benefits-post.html"*/}
                                {/*			className="button-secondary-gradient"><span><p><i className="fa-regular fa-lock"></i> Login to unlock</p></span></a>*/}
                                {/*	</div>*/}
                                {/*</article>*/}

                            </div>

                            {/*ANCHOR FUNCTION: Pagination*/}
                            {/*<div className="cs-pagination block-full"><!-- CSJS Pagination based on the Post Count of Space -->*/}
                            {/*	<nav className="pagination-gradient" aria-label="Pagination">*/}
                            {/*		<a href="#pprevious" className="pagination-previous">*/}
                            {/*			<span className="sr-only">Previous</span>*/}
                            {/*			<i className="fa-regular fa-angle-left"></i>*/}
                            {/*		</a>*/}
                            {/*		<a href="#p1">1</a>*/}
                            {/*		<a href="#p2">2</a>*/}
                            {/*		<a href="#p3" className="pagination-extra">3</a>*/}
                            {/*		<span className="pagination-ellipsis">...</span>*/}
                            {/*		<a href="#p8" className="pagination-extra">8</a>*/}
                            {/*		<a href="#p9">9</a>*/}
                            {/*		<a href="#10">10</a>*/}
                            {/*		<a href="pnext" className="pagination-next">*/}
                            {/*			<span className="sr-only">Next</span>*/}
                            {/*			<i className="fa-regular fa-angle-right"></i>*/}
                            {/*		</a>*/}
                            {/*	</nav>*/}
                            {/*</div>*/}
                        </div>
                        {/*{spaceData &&*/}
                        {/*	benefits &&*/}
                        {/*	benefits.length > 0 && (*/}
                        {/*		<>*/}
                        {/*			{benefits.map((data, index) => (*/}
                        {/*				<Benefits*/}
                        {/*					key={index}*/}
                        {/*					data={data}*/}
                        {/*					user={authUserInfo}*/}
                        {/*				></Benefits>*/}
                        {/*			))}*/}
                        {/*		</>*/}
                        {/*	)}*/}
                        {/*	<div className="grid grid-cols-12 grid-rows-12 gap-4 md:gap-8 pt-5  ">*/}
                        {/*		{spaceData &&*/}
                        {/*			organizedSpaceBenefits[currentPage] &&*/}
                        {/*			organizedSpaceBenefits[currentPage].length > 0 && (*/}
                        {/*				<>*/}
                        {/*					{organizedSpaceBenefits[currentPage].map((data, index) => (*/}
                        {/*						<div key={index} className="row-span-3 col-span-12 md:col-span-6 lg:col-span-4 p-0">*/}
                        {/*							<MemberBenefits*/}
                        {/*								data={data}*/}
                        {/*								user={authUserInfo}*/}
                        {/*								spaceData={spaceData}*/}
                        {/*							></MemberBenefits>*/}
                        {/*						</div>*/}
                        {/*					))}*/}
                        {/*				</>*/}
                        {/*			)*/}
                        {/*		}*/}
                        {/*		{   spaceData &&*/}
                        {/*			benefits &&*/}
                        {/*			benefits.length > 6 && (*/}
                        {/*				<div className="cs-pagination block-full">*/}
                        {/*					<CustomPagination*/}
                        {/*						items={benefits.length} // 100*/}
                        {/*						currentPage={currentPage} // 1*/}
                        {/*						pageSize={pageSize} // 10*/}
                        {/*						onPageChange={onPageChange}*/}
                        {/*					></CustomPagination>*/}
                        {/*				</div>*/}
                        {/*			)}*/}
                        {/*	</div>*/}
                    </div>
                )}
            </section>
            {/* End of Benefits Tab */}

            {/* Start of Members Tab */}
            <section id="cs-space-members-c1" className={`${pro_styles?.members_theme === 'main' ? 'theme-transparent' : 'theme-alt-transparent' } ${pro_styles && pro_styles?.dark_background_video ? '!p-0 relative ' : 'p-section-safeview '} ${globalActiveTab === 'members' ? 'show active' : 'hidden'}`}
                     style={ {backgroundImage: 'url(' + (pro_styles && pro_styles?.dark_background_video ? '' : pro_styles?.dark_background_image) + ')'} }
            >
                {(pro_styles && pro_styles?.dark_background_video) &&
                    <ReactPlayer
                        playing={true}
                        loop={true}
                        muted={true}
                        controls={false}
                        width="100%"
                        height="100%"
                        className={`videoPreview absolute`}
                        url={pro_styles?.dark_background_video}
                    />
                }
                <div className={`centerframe-grid gap-2gutter ${pro_styles && pro_styles?.dark_background_video ? 'relative p-section-safeview' : ''}`}>

                    {/*SECTION COMPONENT: ACCESS - LATEST*/}
                    {/* CSJS FOR FUTURE: The "order-1" class is the current non-editable default based on future*/}
                    {/* {space_members_c1_component_order} array values and should be included for future customizable ordering features. */}
                    {(spaceData.viewing_user_is_owner || (spaceData.viewing_user_is_member && spaceData.channels_enable)) &&
                        <div id="cs-space-access-latest" className={`grid-base col-span-full order-1 ${isViewAllMemberships ? 'hidden' : ''}`}>
                            <div className="block-full">
                                {/*ANCHOR TITLE: Member Access*/}
                                <div className="cs-stack-title stack-row">
                                    {/*CSJS <span> text is {space_access_latest_title_part1} */}
                                    {/*and non-span text is {space_access_latest_title_part2}. */}
                                    {/*<h2><span>Member</span> Access</h2>*/}
                                    <h2><span>{spaceData?.member_access_first_title ?? 'Member'}</span> {spaceData?.member_access_second_title ?? 'Access'}</h2>
                                    {/*CSJS Links to fam-space-access.html*/}
                                    <p><a className="pr-1">{t('link_viewall')}</a></p>
                                </div>
                            </div>


                            {/*CSJS Inline background-image URL is {space_access_image} for specific Access Module. channels_cover_image*/}
                            <article className="cs-featured-access block-md card" style={ {backgroundImage: 'url(' + featuredChannelBannerImage() + ')'} }>
                                {/*CSJS Links to fam-space-channels-channelname.html*/}
                                <a href={channelsCardRedirection()} target="_self" className="rounded-card p-card">
                                    <div className="spacer-element-min-row"></div>
                                    <hgroup>
                                        {/* CSJS <i> is {space_access_icon} for specific Access Module. */}
                                        <p><i className="fa-solid fa-messages"></i></p>
                                        {/* CSJS <i>> is {space_access_name} for specific Access Module. */}
                                        <h4>{t('card_channels')}</h4>
                                    </hgroup>
                                    <div className="cs-stack-access-split stack-row">
                                        <div className="stack-row-min">
                                            {/*CSJS Display up to 5 most recent active Member profile avatars, based on activity in past 24 hours across*/}
                                            {/*all channels. Image is {cs_profile_image} and alt text is "Image of {cs_profile_username}".*/}
                                            <div className="stack-avatar-min">
                                                {spaceData && spaceData.members && spaceData.members.length > 0 && spaceData.members.slice(0, 5).map((member, index) => (
                                                    <div key={index} className="avatar-gradient-min" style={{zIndex: (spaceData.members.length > 5 ? 5 : spaceData.members.length ) - (index + 1)}}
                                                        // className={`z-${(spaceData.memberships.length > 10 ? 10 : spaceData.memberships.length ) - (index + 1)}`}
                                                    >
                                                        <img src={member.profile.photo_thumb ? member.profile.photo_thumb : default_user_profile} alt="Image of cs_profile_username"/>
                                                    </div>
                                                ))}
                                            </div>
                                            {/*CSJS If more than 5 active, then display additional count of recent active Member profile avatars,*/}
                                            {/*based on activity in past 24 hours across all channels, excluding the 5 displayed as avatars.*/}
                                            <p>
                                                {spaceData.members.length > 0 ?
                                                    (
                                                        spaceData.members.length - 5 > 0 ?
                                                            ('+' + (spaceData.members.length - 5) + ' active')
                                                            : ''
                                                    ) :
                                                    "No members yet"
                                                }
                                            </p>
                                        </div>
                                        {/*CSJS Display count based on new recent posts (new posts and comments combined)*/}
                                        {/*in past 24 hours across all channels.*/}
                                        <p>{spaceData.new_posts_count + ' Recent Posts'}</p>
                                    </div>
                                </a>
                            </article>

                            {/*ANCHOR LINKBOX: Featured Access Block*/}
                            {/*CSJS Inline background-image URL is {space_access_image} for specific Access Module.*/}
                            <article className="cs-featured-access block-md card hidden" style={ {backgroundImage: 'url(' + zoom_cover_image + ')'} }>
                                {/*CSJS Links to fam-space-access-zoom.html*/}
                                <a target="_self" className="rounded-card p-card">
                                    <div className="spacer-element-min-row"></div>
                                    <hgroup>
                                        {/*CSJS <i> is {space_access_icon} for specific Access Module.*/}
                                        <p><i className="fa-solid fa-video"></i></p>
                                        {/*CSJS <i>> is {space_access_name} for specific Access Module.*/}
                                        <h4>FAM Hour on Zoom</h4>
                                    </hgroup>
                                    <div className="cs-stack-access stack-row">
                                        {/*CSJS Next scheduled Zoom date for Zoom Access Module*/}
                                        <p>Upcoming on July 1, 6pm PT</p>
                                    </div>
                                </a>
                            </article>

                            {/*ANCHOR LINKBOX: Featured Access Block*/}
                            {/*CSJS Inline background-image URL is {space_access_image} for specific Access Module.*/}
                            <article className="cs-featured-access block-md card hidden" style={ {backgroundImage: 'url(' + shop_cover_image + ')'} }>
                                {/*CSJS Links to {space_access_url} for Link Access Module.*/}
                                <a target="_blank" className="rounded-card p-card">
                                    <div className="spacer-element-min-row"></div>
                                    <hgroup>
                                        {/*CSJS <i> is {space_access_icon} for specific Access Module.*/}
                                        <p><i className="fa-solid fa-store"></i></p>
                                        {/*CSJS <i>> is {space_access_name} for specific Access Module.*/}
                                        <h4>FAM Shop</h4>
                                    </hgroup>
                                    <div className="cs-stack-access stack-row">
                                        {/*CSJS Text is {space_access_description} for Link Access Module.*/}
                                        <p>FAM Members Only Gear</p>
                                    </div>
                                </a>
                            </article>

                            {/*ANCHOR LINK: Mobile View All*/}
                            {/*CSJS Links to fam-space-access.html*/}
                            <p className="cs-mobile-link-viewall"><a className="pr-1">{t('link_viewall')}</a></p>
                        </div>
                    }

                    {/*SECTION COMPONENT: PRODUCTS - FEATURED*/}
                    <div id="cs-space-products-featured" className="grid-base col-span-full order-2">
                        <div className="block-full">
                            <div className="cs-stack-title stack-row">
                                { isViewAllMemberships ? (
                                    <h2><span>Newest</span> Memberships & Items</h2>
                                ) : (
                                    <h2><span>{spaceData?.featured_memberships_first_title ?? 'Featured'}</span> {spaceData?.featured_memberships_second_title ?? 'Memberships & Items'}</h2>

                                )}
                                { isViewAllMemberships ?
                                    <div className="cs-stack-filters">
                                        <button className="filter-dropdown-gradient" id="menu-button" aria-expanded="true" aria-haspopup="true"><p>All</p><i className="fa-regular fa-angle-down"></i></button>
                                        <button className="filter-dropdown-gradient" id="menu-button" aria-expanded="true" aria-haspopup="true"><p>Newest</p><i className="fa-regular fa-angle-down"></i></button>
                                    </div>
                                    :
                                    <p><a className="pr-1" onClick={()=>{
                                        setIsViewAllMemberships(!isViewAllMemberships)
                                    }}>{t('link_viewall')}</a></p>
                                }
                            </div>
                        </div>
                        {!isViewAllMemberships && onlyThreeSpaceMembershipsAndItems.map((data, index) => (
                            <article key={index} className="cs-product-membership card block-md" >
                                <Communities
                                    data={data}
                                    user={authUserInfo}
                                    spaceData={spaceData}
                                    updateGlobalActiveTab={
                                        updateGlobalActiveTab
                                    }
                                />
                            </article>
                        ))}
                        {isViewAllMemberships && spaceData && organizedSpaceMembership[currentPage] && organizedSpaceMembership[currentPage].length > 0 && (
                            <>
                                {organizedSpaceMembership[currentPage].map(
                                    (data, index) => (
                                        <article key={index} className="cs-product-membership card block-md">
                                            <Communities
                                                data={data}
                                                user={authUserInfo}
                                                spaceData={spaceData}
                                                updateGlobalActiveTab={
                                                    updateGlobalActiveTab
                                                }
                                            />
                                        </article>
                                    )
                                )}
                            </>
                        )}
                        {isViewAllMemberships && spaceData && spaceMembershipsAndItems && spaceMembershipsAndItems.length > 6 && (
                            <div className="cs-pagination block-full">
                                <CustomPagination
                                    items={spaceMembershipsAndItems.length} // 100
                                    currentPage={currentPage} // 1
                                    pageSize={pageSize} // 10
                                    onPageChange={onPageChange}
                                ></CustomPagination>
                            </div>
                        )}
                    </div>

                    {/*SECTION COMPONENT: MEMBERS - TOP*/}
                    {/*The "order-2" class is the current non-editable default for the $blockOrder value for this component and should be included for future Section Ordering features.*/}
                    {/*<div id="cs-space-products-featured" className="grid-base col-span-full order-2">*/}

                    {/*	/!*ANCHOR TITLE: Join*!/*/}
                    {/*	<div className="block-full">*/}
                    {/*		<div className="cs-stack-title stack-row">*/}
                    {/*			<h2><span>Top</span> Members</h2>*/}
                    {/*			/!*CSJS Links to fam-space-members-leaderboard.html*!/*/}
                    {/*			<p><a>view all</a></p>*/}
                    {/*		</div>*/}
                    {/*	</div>*/}

                    {/*	/!*ANCHOR MEMBERS: Leaderboard - Row*!/*/}
                    {/*	<div className="cs-datarow-leaderboard block-full card">*/}
                    {/*		<div className="datarow">*/}
                    {/*			<div className="data-item stack-row">*/}
                    {/*				<div className="igroup stack-row">*/}
                    {/*					/!*CSJS fa-crown is always the icon for the #1 position.*!/*/}
                    {/*					<i className="fa-solid fa-crown"/>*/}
                    {/*					/!*CSJS position for this specific Leaderboard Row.*!/*/}
                    {/*					<h2>1</h2>*/}
                    {/*				</div>*/}
                    {/*				<div className="igroup stack-row">*/}
                    {/*					/!*CSJS Profile Image URL {cs_profile_url} with Space Domain Prefix from the specific Member for this specific Leaderboard Row. *!/*/}
                    {/*					/!*Image is {cs_profile_image} and alt text is "Image of {cs_profile_username}".*!/*/}
                    {/*					<a className="avatar-gradient"><img src={avatar_1} alt="Image of cs_profile_username"/></a>*/}
                    {/*					/!*CSJS Profile Username {cs_profile_username} for the specific Member for this specific Leaderboard Row,*!/*/}
                    {/*					/!*which links to specific Profile {cs_profile_url} page using the Space Domain prefix.*!/*/}
                    {/*					<h4><a>@mikeprasad</a></h4>*/}
                    {/*				</div>*/}
                    {/*			</div>*/}
                    {/*			<div className="data-item-2 stack-row">*/}
                    {/*				<div className="igroup stack-row">*/}
                    {/*					<i className="fa-regular fa-star"></i>*/}
                    {/*					/!*CSJS Total points earned for specific Member for this specific Leaderboard Row.*!/*/}
                    {/*					/!*This should be Total lifetime points earned on thi Space, regardless of current point balance/usage.*!/*/}
                    {/*					<p>54.4k points</p>*/}
                    {/*				</div>*/}
                    {/*				<div className="igroup stack-row">*/}
                    {/*					<i className="fa-regular fa-rectangle-history"></i>*/}
                    {/*					/!*CSJS Total products owned for specific Member for this specific Leaderboard Row. Format is COUNT + "collected".*!/*/}
                    {/*					<p>7 collected</p>*/}
                    {/*				</div>*/}
                    {/*			</div>*/}
                    {/*			<div className="data-item stack-row-base">*/}
                    {/*				/!*CSJS Most recent 5 products owned by specific Member, with Memberships displaying first, then items. (Note: the avatar classes are a format, *!/*/}
                    {/*				/!*not specific to a profile picture). Each image is {space_product_image} and each alt text is "Image of {space_product_name}".*!/*/}
                    {/*				<div className="stack-avatar">*/}
                    {/*					<div className="avatar-square-gradient"><img src={fam_product_1} alt="Image of space_product_name"/></div>*/}
                    {/*					<div className="avatar-square-gradient"><img src={fam_product_2} alt="Image of space_product_name"/></div>*/}
                    {/*					<div className="avatar-square-gradient"><img src={fam_product_3} alt="Image of space_product_name"/></div>*/}
                    {/*					<div className="avatar-square-gradient"><img src={fam_product_4} alt="Image of space_product_name"/></div>*/}
                    {/*					<div className="avatar-square-gradient"><img src={fam_product_5} alt="Image of space_product_name"/></div>*/}
                    {/*				</div>*/}
                    {/*				/!*CSJS Count of additional products owned by specific Member if more than 5. If the Member has <5 items then add the "hidden" *!/*/}
                    {/*				/!*class to this element and the count should be "0". See Leaderboard Row @ and 3 below for this example.*!/*/}
                    {/*				<div className="block-element"><p>+2</p></div>*/}
                    {/*			</div>*/}
                    {/*		</div>*/}
                    {/*	</div>*/}

                    {/*	/!*ANCHOR MEMBERS: Leaderboard - Row*!/*/}
                    {/*	/!*CSJS See first Leaderboard Row item above for comments.*!/*/}
                    {/*	<div className="cs-datarow-leaderboard block-full card">*/}
                    {/*		<div className="datarow">*/}
                    {/*			<div className="data-item stack-row">*/}
                    {/*				<div className="igroup stack-row">*/}
                    {/*					<i className="fa-solid fa-caret-up"></i>*/}
                    {/*					<h2>2</h2>*/}
                    {/*				</div>*/}
                    {/*				<div className="igroup stack-row">*/}
                    {/*					<a className="avatar-gradient"><img src={avatar_5} alt="Image of cs_profile_username"/></a>*/}
                    {/*					<h4><a>@isaactheone</a></h4>*/}
                    {/*				</div>*/}
                    {/*			</div>*/}
                    {/*			<div className="data-item stack-row">*/}
                    {/*				<div className="igroup stack-row">*/}
                    {/*					<i className="fa-regular fa-star"></i>*/}
                    {/*					<p>42.4k points</p>*/}
                    {/*				</div>*/}
                    {/*				<div className="igroup stack-row">*/}
                    {/*					<i className="fa-regular fa-rectangle-history"></i>*/}
                    {/*					<p>5 collected</p>*/}
                    {/*				</div>*/}
                    {/*			</div>*/}
                    {/*			<div className="data-item stack-row-base">*/}
                    {/*				<div className="stack-avatar">*/}
                    {/*					<div className="avatar-square-gradient"><img src={fam_product_1} alt="Image of space_product_name"/></div>*/}
                    {/*					<div className="avatar-square-gradient"><img src={fam_product_2} alt="Image of space_product_name"/></div>*/}
                    {/*					<div className="avatar-square-gradient"><img src={fam_product_3} alt="Image of space_product_name"/></div>*/}
                    {/*					<div className="avatar-square-gradient"><img src={fam_product_4} alt="Image of space_product_name"/></div>*/}
                    {/*					<div className="avatar-square-gradient"><img src={fam_product_5} alt="Image of space_product_name"/></div>*/}
                    {/*				</div>*/}
                    {/*				<div className="block-element hidden"><p>0</p></div>*/}
                    {/*			</div>*/}
                    {/*		</div>*/}
                    {/*	</div>*/}

                    {/*	/!*ANCHOR MEMBERS: Leaderboard - Row*!/*/}
                    {/*	/!*CSJS See first Leaderboard Row item above for comments.*!/*/}
                    {/*	<div className="cs-datarow-leaderboard block-full card">*/}
                    {/*		<div className="datarow">*/}
                    {/*			<div className="data-item stack-row">*/}
                    {/*				<div className="igroup stack-row">*/}
                    {/*					<i className="fa-solid fa-caret-down"></i>*/}
                    {/*					<h2>3</h2>*/}
                    {/*				</div>*/}
                    {/*				<div className="igroup stack-row">*/}
                    {/*					<a className="avatar-gradient"><img src={avatar_4} alt="Image of cs_profile_username"/></a>*/}
                    {/*					<h4><a>@nala</a></h4>*/}
                    {/*				</div>*/}
                    {/*			</div>*/}
                    {/*			<div className="data-item-2 stack-row">*/}
                    {/*				<div className="igroup stack-row">*/}
                    {/*					<i className="fa-regular fa-star"></i>*/}
                    {/*					<p>41.1k points</p>*/}
                    {/*				</div>*/}
                    {/*				<div className="igroup stack-row">*/}
                    {/*					<i className="fa-regular fa-rectangle-history"></i>*/}
                    {/*					<p>3 collected</p>*/}
                    {/*				</div>*/}
                    {/*			</div>*/}
                    {/*			<div className="data-item stack-row-base">*/}
                    {/*				<div className="stack-avatar">*/}
                    {/*					<div className="avatar-square-gradient"><img src={fam_product_1} alt="Image of space_product_name"/></div>*/}
                    {/*					<div className="avatar-square-gradient"><img src={fam_product_2} alt="Image of space_product_name"/></div>*/}
                    {/*					<div className="avatar-square-gradient"><img src={fam_product_3} alt="Image of space_product_name"/></div>*/}
                    {/*				</div>*/}
                    {/*				<div className="block-element hidden"><p>0</p></div>*/}
                    {/*			</div>*/}
                    {/*		</div>*/}
                    {/*	</div>*/}

                    {/*	/!*ANCHOR LINK: Mobile View All*!/*/}
                    {/*	/!*CSJS Links to fam-space-members-leaderboard.html*!/*/}
                    {/*	<p className="cs-mobile-link-viewall"><a>view all</a></p>*/}
                    {/*</div>*/}

                </div>
                {/*!SECTION COMPONENT: MEMBERS - TOP*/}
            </section>
            {/* End of Members Tab */}

            {/* Start of Links Tab */}
            <section id="cs-space-links-c1" className={`p-section-safeview ${pro_styles?.information_theme === 'main' ? 'theme-transparent' : 'theme-alt-transparent' } ${globalActiveTab === 'links' ? 'show active' : 'hidden'}`}>
                <div className="centerframe-grid gap-2gutter">
                    <div id="cs-space-updates-browse" className="grid-base col-span-full order-1">
                        <div className="block-full">
                            <div className="cs-stack-title-filters stack-row-wrap">
                                <h2><span>Newest</span> Links</h2>
                                <div className="cs-stack-filters">
                                    <button className="filter-dropdown-gradient" id="menu-button" aria-expanded="true" aria-haspopup="true"><p>All</p><i className="fa-regular fa-angle-down"></i></button>
                                    <button className="filter-dropdown-gradient" id="menu-button" aria-expanded="true" aria-haspopup="true"><p>Newest</p><i className="fa-regular fa-angle-down"></i></button>
                                </div>
                            </div>
                        </div>
                        {spaceUpdatesIsFetching ? (
                            <section id="page-content-loading-overlay" className="screen-modal p-section-safeview theme-transparent">
                                <div className="centerframe-flex frame-modal">
                                    <div className="block-lg modal-block">
                                        <i className="fa-regular fa-solar-system"/>
                                    </div>
                                </div>
                            </section>
                        ) : (
                            <>
                                {featuredLinks.map((data, index) => (
                                    <FeaturedLinks
                                        key={index}
                                        data={data}
                                        index={index}
                                        space={spaceData}
                                    />
                                ))}
                                {spaceData && featuredLinks.length > 6 && (
                                    <div className="cs-pagination block-full">
                                        <CustomPagination
                                            items={featuredLinks.length} // 100
                                            currentPage={currentPage} // 1
                                            pageSize={pageSize} // 10
                                            onPageChange={onPageChange}
                                        />
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </section>

            {(globalActiveTab === 'faq' ||
                globalActiveTab === 'about' ||
                globalActiveTab === 'contact' ||
                globalActiveTab === 'support' ||
                globalActiveTab === 'privacy-policy' ||
                globalActiveTab === 'terms-services' ||
                globalActiveTab === 'community-guidelines') && (
                <SpaceInformationPages
                    spaceData={spaceData}
                    isPro={true}
                    activeTab={globalActiveTab}
                />
            )}
            {/*<div*/}
            {/*	className={*/}
            {/*		'tab-pane ' +*/}
            {/*		(globalActiveTab === 'faq' ||*/}
            {/*		globalActiveTab === 'about' ||*/}
            {/*		globalActiveTab === 'contact' ||*/}
            {/*		globalActiveTab === 'support' ||*/}
            {/*		globalActiveTab === 'privacy-policy' ||*/}
            {/*		globalActiveTab === 'terms-services' ||*/}
            {/*		globalActiveTab === 'community-guidelines'*/}
            {/*			? 'show active'*/}
            {/*			: 'hidden')*/}
            {/*	}*/}
            {/*>*/}
            {/*	{(globalActiveTab === 'faq' ||*/}
            {/*		globalActiveTab === 'about' ||*/}
            {/*		globalActiveTab === 'contact' ||*/}
            {/*		globalActiveTab === 'support' ||*/}
            {/*		globalActiveTab === 'privacy-policy' ||*/}
            {/*		globalActiveTab === 'terms-services' ||*/}
            {/*		globalActiveTab === 'community-guidelines') && (*/}
            {/*		<div className="dark-section">*/}
            {/*			<SpaceInformationPages*/}
            {/*				spaceData={spaceData}*/}
            {/*				isPro={true}*/}
            {/*				activeTab={globalActiveTab}*/}
            {/*			/>*/}
            {/*		</div>*/}
            {/*	)}*/}
            {/*</div>*/}

            <div className={'tab-pane ' + (globalActiveTab === 'channel' ? 'show active' : 'hidden')}>
                {(globalActiveTab === 'channel' ) && (
                    <div className="bg-transparent">
                        <Channel spaceData={spaceData}/>
                    </div>
                )}
            </div>

            {/*<div className={'tab-pane ' + (globalActiveTab === 'claim_membership' ? 'show active' : 'hidden')}>*/}
            {/*	<div className="dark-section">*/}
            {/*		<video autoPlay muted loop style={darkVideoStyle}>*/}
            {/*			<source*/}
            {/*				src={pro_styles?.dark_background_video}*/}
            {/*				type="video/mp4"*/}
            {/*			/>*/}
            {/*		</video>*/}
            {/*		{globalActiveTab === 'claim_membership' && (*/}
            {/*			<SpaceClaimMembership*/}
            {/*				hasGradient={pro_styles?.has_gradient}*/}
            {/*				url={spaceData.website}*/}
            {/*			/>*/}
            {/*		)}*/}
            {/*	</div>*/}
            {/*</div>*/}
            {globalActiveTab === 'claim_membership' && (
                <SpaceClaimMembership
                    hasGradient={pro_styles?.has_gradient}
                    url={spaceData.website}
                />
            )}

            {globalActiveTab === 'buy_membership' && (
                <SpaceBuyMembership
                    hasGradient={pro_styles?.has_gradient} url={spaceData.website}
                />
            )}

            {/*<div className={'tab-pane ' + (globalActiveTab === 'buy_membership' ? 'show active' : 'hidden')}>*/}
            {/*	<div className="dark-section">*/}
            {/*		<video autoPlay muted loop style={darkVideoStyle}>*/}
            {/*			<source*/}
            {/*				src={pro_styles?.dark_background_video}*/}
            {/*				type="video/mp4"*/}
            {/*			/>*/}
            {/*		</video>*/}
            {/*		{globalActiveTab === 'buy_membership' && (*/}
            {/*			<SpaceBuyMembership*/}
            {/*				hasGradient={pro_styles?.has_gradient} url={spaceData.website}*/}
            {/*			></SpaceBuyMembership>*/}
            {/*		)}*/}
            {/*	</div>*/}
            {/*</div>*/}

            {globalActiveTab === 'buy_item' && (
                <SpaceBuyItem hasGradient={pro_styles?.has_gradient}/>
            )}

            {/*<div className={'tab-pane ' + (globalActiveTab === 'buy_item' ? 'show active' : 'hidden')}>*/}
            {/*	<div className="dark-section">*/}
            {/*		<video autoPlay muted loop style={darkVideoStyle}>*/}
            {/*			<source*/}
            {/*				src={pro_styles?.dark_background_video}*/}
            {/*				type="video/mp4"*/}
            {/*			/>*/}
            {/*		</video>*/}
            {/*		{globalActiveTab === 'buy_item' && (*/}
            {/*			<SpaceBuyItem*/}
            {/*				hasGradient={pro_styles?.has_gradient}*/}
            {/*			/>*/}
            {/*		)}*/}
            {/*	</div>*/}
            {/*</div>*/}
            {globalActiveTab === 'claim_item' && (
                <SpaceClaimItem hasGradient={pro_styles?.has_gradient} url={spaceData.website}/>
            )}
            {/*<div className={'tab-pane ' + (globalActiveTab === 'claim_item' ? 'show active' : 'hidden')}>*/}
            {/*	<div className="dark-section">*/}
            {/*		<video autoPlay muted loop style={darkVideoStyle}>*/}
            {/*			<source*/}
            {/*				src={pro_styles?.dark_background_video}*/}
            {/*				type="video/mp4"*/}
            {/*			/>*/}
            {/*		</video>*/}
            {/*		{globalActiveTab === 'claim_item' && (*/}
            {/*			<SpaceClaimItem*/}
            {/*				hasGradient={pro_styles?.has_gradient}*/}
            {/*			/>*/}
            {/*		)}*/}
            {/*	</div>*/}
            {/*</div>*/}

            <div className={'tab-pane ' + (globalActiveTab === 'update_content' ? 'show active' : 'hidden')}>
                {globalActiveTab === 'update_content' && (
                    <UpdatesContent
                        updatesViewingUserIsMember={
                            updatesViewingUserIsMember
                        }
                        spaceData={spaceData}
                        updateGlobalActiveTab={updateGlobalActiveTab}
                    />
                )}
            </div>
        </main>
    );
};

export default memo(SpaceViewPro);
