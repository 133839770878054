import { memo, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import routes from '../../../../routes';
import moment from 'moment';
import { MEMBERSHIP_TYPES } from '../../../../constants/space';
import { getItemBenefits } from '../../../../services/space';
import {getDetailsGift, getDetailsMonthlyPrice, getManageDetailsPrice} from "../../../../components/commons/helpers/MembershipItemHelpers";
import {API_DOMAIN} from "../../../../constants/common";

const ManageItem = (props) => {
	const { itemData, spaceInfo, authUserInfo } = props;
	const [benefits, setBenefits] = useState(null);

	const space_bg = {
		backgroundImage:
			'url(' + (itemData && itemData.photo_medium ? itemData.photo_medium : '') + ')'
	};

	const getItemBenefitsRequest = useCallback(async (item_id) => {
		const response = await getItemBenefits({
			item_id: item_id
		});
		if (response && response.result) {
			setBenefits(response.item_benefits);
		}
	}, []);

	const redirectToItemEditLink = useCallback(
		(route, item_id) => {
			if (spaceInfo) {
				let url = route.replace(':space_username', '@' + spaceInfo.username);
				return url.replace(':item_id', item_id);
			}
			return '';
		},
		[spaceInfo]
	);

	const redirectToItemAddBenefitLink = useCallback(
		(route, item_id) => {
			if (spaceInfo) {
				let url = route.replace(':space_username', '@' + spaceInfo.username);
				return url.replace(':item_id', item_id);
			}
			return '';
		},
		[spaceInfo]
	);

	const redirectToItemUpdateBenefitLink = useCallback(
		(route, item_id, benefit_id) => {
			if (spaceInfo) {
				let url = route.replace(':space_username', '@' + spaceInfo.username);
				url = url.replace(':benefit_id', benefit_id);
				return url.replace(':item_id', item_id);
			}
			return '';
		},
		[spaceInfo]
	);

	const BenefitRow = (data) => {
		return (
			<>
				<div className="grid grid-cols-12  gap-2 md:gap-8  flex items-center mt-5">
					<div className="col-span-3">
						<p className="body-text--small gradient-color-txt p-0">{data.title}</p>
					</div>
					<div className="col-span-6">
						<p className="body-txtitalic--small color-light p-0 text-ellipsis overflow-hidden ...">{data.description}</p>
					</div>
					<div className="col-span-3 flex items-center gap-x-2">
						<Link className="button-secondary-gradient w-full">
							<span><p><i className="fa-light fa-arrow-up-right-from-square"/></p></span>
						</Link>
						<Link
							className="button-secondary-gradient w-full"
							to={redirectToItemUpdateBenefitLink(
								routes.editItemMonthBenefit(),
								itemData.id,
								data.id
							)}
						>
							<span><p><i className="fa-light fa-pen-to-square"/></p></span>
						</Link>
					</div>
				</div>
			</>
		);
	};

	const parseItemMembers = useCallback(()=> {
		if (itemData.members != null) {
			if (itemData.members.length === 1) {
				return itemData.members[0];
			}
			if (itemData.members.length === 2) {
				return itemData.members[0] +' and '+itemData.members[1];
			}
			if (itemData.members.length === 3) {
				return itemData.members[0] +', '+itemData.members[1]+' and '+ itemData.members[2];
			}
			if (itemData.members.length > 3) {
				const othersCount = itemData.members.length - 2;
				return itemData.members[0] +', '+itemData.members[1]+' and '+othersCount+' others';
			}
		} else {
			return '';
		}
	},[itemData]);

	const itemMembers = itemData?.members?.filter(item => item !== ('@' + authUserInfo?.profile?.username)) || [];
	const formattedMembers = itemMembers.length > 1 ? itemMembers.join(', ') : itemMembers[0];

	useEffect(() => {
		getItemBenefitsRequest(itemData.id).then();
	}, [itemData]);

	return (
		<div className="block-full">
			{spaceInfo && itemData && (
				<>
					<div className="dashboard-profile mt-4 card p-0">
						<div className="grid grid-cols-12 md:gap-8">
							<div className="col-span-12 md:col-span-6 p-6">
								<div className="card-btn-wrapper">
									<h2 className="header-small mt-4">{itemData.name}</h2>
									<h3 className="header-title color-light text-gray-400">
										{itemData.sku_shortcode}
									</h3>
									<p className="mt-4 body-text--small text-ellipsis overflow-hidden ...">{itemData.summary}</p>
									<div className="flexjustify-between mt-5">
										<div>
											<p className="header-title color-reg">{getManageDetailsPrice(false, itemData)}</p>
											{/*<p className="header-title color-reg">$20 USD/year</p>*/}
											{/*<p className="header-title color-light">*/}
											{/*	26 of ∞ Editions Issued*/}
											{/*</p>*/}
										</div>
										<div>
											<h4>
												<i className="fa-regular fa-hexagon-image"></i>{' '}
												{getDetailsGift(false, itemData)}
												{/*{itemData.type ===*/}
												{/*MEMBERSHIP_TYPES.NFT_COLLECTION ? (*/}
												{/*	<>NFT</>*/}
												{/*) : (*/}
												{/*	<>Community</>*/}
												{/*)}{' '}*/}
												{/*Membership*/}
											</h4>
											<h4>
												<i className="fa-regular fa-gift"></i>  {itemData.benefits_count} Benefit{itemData.benefits_count > 1? 's': ''}
											</h4>
										</div>
									</div>
									<p className="mt-5 mb-0 body-text--reg color-light">
										Added to{' '}
										<span className="gradient-color-txt">
											<i className="fa-regular fa-solar-system"/>{' '}
											{spaceInfo.name}
										</span>{' '}
										on {moment(itemData.created_on).format('LL')}
									</p>
									<p className="body-text--reg color-light">
										Owned by{' '}
										<span className="gradient-color-txt">
											{parseItemMembers()}
										</span>
									</p>
									<div className="flex items-center justify-center py-4 gap-x-4">
										<Link className="button-secondary-gradient w-full">
											<span><p><i className="fa-light fa-list-timeline"/></p></span>
										</Link>
										<Link className="button-secondary-gradient w-full">
											<span><p><i className="fa-regular fa-arrow-up-from-bracket"/></p></span>
										</Link>
										<Link
											className="button-secondary-gradient w-full"
											to={redirectToItemEditLink(
												routes.editItem(),
												itemData.id
											)}
										>
											<span><p><i className="fa-light fa-pen-to-square"/></p></span>
										</Link>
										<Link className="button-secondary-gradient w-full">
											<span><p><i className="fa-light fa-magnifying-glass-plus"/></p></span>
										</Link>
										<Link className="button-secondary-gradient w-full">
											<span><p><i className="fa-light fa-arrow-up-right-from-square"/></p></span>
										</Link>
									</div>
								</div>
							</div>
							<div className="col-span-12 md:col-span-6">
								<div
									className="profile-card-item w-auto pt-3 pb-3"
									style={space_bg}
								>
									<img src={itemData.photo_medium} />
									<div className="bg-overlay"></div>
								</div>
							</div>
						</div>
					</div>

					{spaceInfo.owner === authUserInfo.id &&
						<div className="dashboard-wrapper overflow-auto mt-4  card">
							<div className="grid grid-cols-12 gap-2 md:gap-8 mt-4">
								<div className="col-span-9">
									<h3 className="header-small">Benefits</h3>
								</div>
								<div className="col-span-12 md:col-span-3">
									<Link
										className="button-secondary-gradient w-full"
										to={redirectToItemAddBenefitLink(
											routes.addItemMonthBenefit(),
											itemData.id
										)}
									>
										<span><p>Add Benefit</p></span>
									</Link>
								</div>
							</div>
							<div className="grid grid-cols-12 flex items-center mt-5">
								<div className="col-span-3">
									<p className="body-text--small gradient-color-txt p-0">
										Member Access
									</p>
								</div>
								<div className="col-span-9">
									<p className="body-txtitalic--small color-light p-0">
										Members Only access to{' '}
										<i className="fa-regular fa-solar-system"/> {spaceInfo.name}.
									</p>
								</div>
							</div>
							{benefits && (
								<>
									{benefits.map((data, index) => (
										<div key={index}>{BenefitRow(data)}</div>
									))}
								</>
							)}
						</div>
					}

					{/*<div className="dashboard-wrapper mt-4  overflow-auto  card">*/}
					{/*	<div className="grid grid-cols-12 gap-2 md:gap-8 mt-4">*/}
					{/*		<div className="col-span-9">*/}
					{/*			<h3 className="header-small">Activity</h3>*/}
					{/*		</div>*/}
					{/*		<div className="col-span-3 text-end">*/}
					{/*			<Link className="body-txtitalic--small">View All</Link>*/}
					{/*		</div>*/}
					{/*	</div>*/}
					{/*	<div className="grid grid-cols-12 mt-4">*/}
					{/*		<div className="col-span-12">*/}
					{/*			<table className="table w-full">*/}
					{/*				<thead>*/}
					{/*					<tr>*/}
					{/*						<th scope="col" className="header-title color-light">*/}
					{/*							Event*/}
					{/*						</th>*/}
					{/*						<th scope="col" className="header-title color-light">*/}
					{/*							By*/}
					{/*						</th>*/}
					{/*						<th scope="col" className="header-title color-light">*/}
					{/*							Detail*/}
					{/*						</th>*/}
					{/*						<th scope="col" className="header-title color-light">*/}
					{/*							Amount*/}
					{/*						</th>*/}
					{/*						<th scope="col" className="header-title color-light">*/}
					{/*							Date*/}
					{/*						</th>*/}
					{/*					</tr>*/}
					{/*				</thead>*/}
					{/*				<tbody>*/}
					{/*					<tr>*/}
					{/*						<th*/}
					{/*							scope="grid grid-cols-12"*/}
					{/*							className="body-text--small gradient-color-txt"*/}
					{/*						>*/}
					{/*							<i className="fa-regular fa-repeat"></i> Recharge*/}
					{/*						</th>*/}
					{/*						<th*/}
					{/*							scope="grid grid-cols-12"*/}
					{/*							className="body-text--smallest gradient-color-txt"*/}
					{/*						>*/}
					{/*							@mikeprasad*/}
					{/*						</th>*/}
					{/*						<th*/}
					{/*							scope="grid grid-cols-12"*/}
					{/*							className="body-text--smallest gradient-color-txt"*/}
					{/*						>*/}
					{/*							Monthly Subscription (12)*/}
					{/*						</th>*/}
					{/*						<th scope="grid grid-cols-12" className="body-text--smallest">*/}
					{/*							$20.000 USD*/}
					{/*						</th>*/}
					{/*						<th*/}
					{/*							scope="grid grid-cols-12"*/}
					{/*							className="body-text--smallest gradient-color-txt"*/}
					{/*						>*/}
					{/*							1 day ago*/}
					{/*						</th>*/}
					{/*					</tr>*/}
					{/*					<tr>*/}
					{/*						<th*/}
					{/*							scope="grid grid-cols-12"*/}
					{/*							className="body-text--small gradient-color-txt"*/}
					{/*						>*/}
					{/*							<i className="fa-solid fa-right-left"></i>{' '}*/}
					{/*							Transferred*/}
					{/*						</th>*/}
					{/*						<th*/}
					{/*							scope="grid grid-cols-12"*/}
					{/*							className="body-text--smallest gradient-color-txt"*/}
					{/*						>*/}
					{/*							@mikeprasad*/}
					{/*						</th>*/}
					{/*						<th*/}
					{/*							scope="grid grid-cols-12"*/}
					{/*							className="body-text--smallest gradient-color-txt"*/}
					{/*						>*/}
					{/*							@smi*/}
					{/*						</th>*/}
					{/*						<th scope="grid grid-cols-12" className="body-text--smallest">*/}
					{/*							<i className="fa-brands fa-ethereum"></i> 0.00 ETH*/}
					{/*						</th>*/}
					{/*						<th*/}
					{/*							scope="grid grid-cols-12"*/}
					{/*							className="body-text--smallest gradient-color-txt"*/}
					{/*						>*/}
					{/*							1 day ago*/}
					{/*						</th>*/}
					{/*					</tr>*/}
					{/*					<tr>*/}
					{/*						<th*/}
					{/*							scope="grid grid-cols-12"*/}
					{/*							className="body-text--small gradient-color-txt"*/}
					{/*						>*/}
					{/*							<i className="fa-solid fa-link-slash"></i>{' '}*/}
					{/*							Deactivated*/}
					{/*						</th>*/}
					{/*						<th*/}
					{/*							scope="grid grid-cols-12"*/}
					{/*							className="body-text--smallest gradient-color-txt"*/}
					{/*						>*/}
					{/*							@mikeprasad*/}
					{/*						</th>*/}
					{/*						<th*/}
					{/*							scope="grid grid-cols-12"*/}
					{/*							className="body-text--smallest gradient-color-txt"*/}
					{/*						>*/}
					{/*							@mikeprasad*/}
					{/*						</th>*/}
					{/*						<th scope="grid grid-cols-12" className="body-text--smallest">*/}
					{/*							<span className="body-txtitalic--smallest">*/}
					{/*								n/a*/}
					{/*							</span>*/}
					{/*						</th>*/}
					{/*						<th*/}
					{/*							scope="grid grid-cols-12"*/}
					{/*							className="body-text--smallest gradient-color-txt"*/}
					{/*						>*/}
					{/*							1 day ago*/}
					{/*						</th>*/}
					{/*					</tr>*/}
					{/*					<tr>*/}
					{/*						<th*/}
					{/*							scope="grid grid-cols-12"*/}
					{/*							className="body-text--small gradient-color-txt"*/}
					{/*						>*/}
					{/*							<i className="fa-solid fa-link"></i> Activated*/}
					{/*						</th>*/}
					{/*						<th*/}
					{/*							scope="grid grid-cols-12"*/}
					{/*							className="body-text--smallest gradient-color-txt"*/}
					{/*						>*/}
					{/*							@mikeprasad*/}
					{/*						</th>*/}
					{/*						<th*/}
					{/*							scope="grid grid-cols-12"*/}
					{/*							className="body-text--smallest gradient-color-txt"*/}
					{/*						>*/}
					{/*							@mikeprasad*/}
					{/*						</th>*/}
					{/*						<th scope="grid grid-cols-12" className="body-text--smallest">*/}
					{/*							<span className="body-txtitalic--smallest">*/}
					{/*								n/a*/}
					{/*							</span>*/}
					{/*						</th>*/}
					{/*						<th*/}
					{/*							scope="grid grid-cols-12"*/}
					{/*							className="body-text--smallest gradient-color-txt"*/}
					{/*						>*/}
					{/*							1 day ago*/}
					{/*						</th>*/}
					{/*					</tr>*/}
					{/*					<tr>*/}
					{/*						<th*/}
					{/*							scope="grid grid-cols-12"*/}
					{/*							className="body-text--small gradient-color-txt"*/}
					{/*						>*/}
					{/*							<i className="fa-solid fa-cart-shopping"></i>{' '}*/}
					{/*							Purchased*/}
					{/*						</th>*/}
					{/*						<th*/}
					{/*							scope="grid grid-cols-12"*/}
					{/*							className="body-text--smallest gradient-color-txt"*/}
					{/*						>*/}
					{/*							@mikeprasad*/}
					{/*						</th>*/}
					{/*						<th*/}
					{/*							scope="grid grid-cols-12"*/}
					{/*							className="body-text--smallest gradient-color-txt"*/}
					{/*						>*/}
					{/*							Monthly Subscription (12)*/}
					{/*						</th>*/}
					{/*						<th scope="grid grid-cols-12" className="body-text--smallest">*/}
					{/*							$20.000 USD*/}
					{/*						</th>*/}
					{/*						<th*/}
					{/*							scope="grid grid-cols-12"*/}
					{/*							className="body-text--smallest gradient-color-txt"*/}
					{/*						>*/}
					{/*							1 day ago*/}
					{/*						</th>*/}
					{/*					</tr>*/}
					{/*				</tbody>*/}
					{/*			</table>*/}
					{/*		</div>*/}
					{/*	</div>*/}
					{/*</div>*/}

					{/*<div className="dashboard-wrapper mt-4  card">*/}
					{/*	<div className="grid grid-cols-12 mt-4">*/}
					{/*		<div className="col-span-12">*/}
					{/*			<h3 className="header-small">Traits</h3>*/}
					{/*		</div>*/}
					{/*	</div>*/}
					{/*	<div className="grid grid-cols-12 mt-4">*/}
					{/*		<div className="col-span-3">*/}
					{/*			<h5 className="body-text--small gradient-color-txt">Collection</h5>*/}
					{/*		</div>*/}
					{/*		<div className="col-span-9">*/}
					{/*			<p className="body-text--small">DRMVRSE Membership 2023</p>*/}
					{/*		</div>*/}
					{/*	</div>*/}
					{/*	<div className="grid grid-cols-12 mt-4">*/}
					{/*		<div className="col-span-3">*/}
					{/*			<h5 className="body-text--small gradient-color-txt">Summary</h5>*/}
					{/*		</div>*/}
					{/*		<div className="col-span-9">*/}
					{/*			<p className="body-text--small">*/}
					{/*				First edition membership for the NGHTMRE fan community.*/}
					{/*			</p>*/}
					{/*		</div>*/}
					{/*	</div>*/}
					{/*	<div className="grid grid-cols-12 mt-4">*/}
					{/*		<div className="col-span-3">*/}
					{/*			<h5 className="body-text--small gradient-color-txt">Properties</h5>*/}
					{/*		</div>*/}
					{/*		<div className="col-span-9">*/}
					{/*			<p className="body-text--small">DRMVRSE Membership 2023</p>*/}
					{/*		</div>*/}
					{/*	</div>*/}
					{/*</div>*/}

					<div className="dashboard-wrapper overflow-auto mt-4 card">
						<div className="grid grid-cols-12 mt-4">
							<div className="col-span-8">
								<h3 className="header-small">Technical Details</h3>
							</div>
							<div className="col-span-4 text-end">
								<p className="body-text--small">
									Related Space & Chain Information
								</p>
							</div>
						</div>
						<div className="grid grid-cols-12 mt-4">
							<div className="col-span-12">
								<table className="table w-full">
									<tbody>
										<tr>
											<th scope="grid grid-cols-12" className="body-text--small">
												Connected Space
											</th>
											<th
												scope="grid grid-cols-12"
												className="body-text--smallest gradient-color-txt"
											>
												<i className="fa-regular fa-solar-system"/>{' '}
												{spaceInfo.name}
											</th>
											<th scope="grid grid-cols-12" className="body-text--small">
												Created On
											</th>
											<th scope="grid grid-cols-12" className="body-text--smallest">
												{moment(itemData?.payment_transaction?.created_on).format('MMMM DD, YYYY hh:mm A')}
											</th>
										</tr>
										<tr>
											<th scope="grid grid-cols-12" className="body-text--small">
												Space URL
											</th>
											<th
												scope="grid grid-cols-12"
												className="body-text--smallest gradient-color-txt"
											>
												{API_DOMAIN + routes.viewUserSpace() + spaceInfo.username}
											</th>
											{/*<th scope="grid grid-cols-12" className="body-text--small">*/}
											{/*	Contract Address*/}
											{/*</th>*/}
											{/*<th*/}
											{/*	scope="grid grid-cols-12"*/}
											{/*	className="body-text--smallest gradient-color-txt"*/}
											{/*>*/}
											{/*	0xa063...f436*/}
											{/*</th>*/}
										</tr>
										<tr>
											<th scope="grid grid-cols-12" className="body-text--small">
												Created By
											</th>
											<th
												scope="grid grid-cols-12"
												className="body-text--smallest gradient-color-txt"
											>
												{itemData?.creator_username}
											</th>
											{(formattedMembers && formattedMembers.length > 0) &&
												<>
													<th scope="grid grid-cols-12" className="body-text--small">
														Owned by
													</th>
													<th
														scope="grid grid-cols-12"
														className="body-text--smallest gradient-color-txt"
													>
														{formattedMembers}
													</th>
												</>
											}

										</tr>
										{(itemData && itemData?.first_benefits) && (
											<tr>
												<th scope="grid grid-cols-12" className="body-text--small">
													Benefits
												</th>
												<th scope="grid grid-cols-12" className="body-text--smallest">
													{itemData?.benefits_count}
												</th>
												{/*<th scope="grid grid-cols-12" className="body-text--small">*/}
												{/*	Active*/}
												{/*</th>*/}
												{/*<th*/}
												{/*	scope="grid grid-cols-12"*/}
												{/*	className="body-text--smallest gradient-color-txt"*/}
												{/*>*/}
												{/*	20 of 26*/}
												{/*</th>*/}
											</tr>
										)}
										{/*<tr>*/}
										{/*	<th scope="grid grid-cols-12" className="body-text--small">*/}
										{/*		Membership*/}
										{/*	</th>*/}
										{/*	<th scope="grid grid-cols-12" className="body-text--smallest">*/}
										{/*		Yes*/}
										{/*	</th>*/}
										{/*	<th scope="grid grid-cols-12" className="body-text--small">*/}
										{/*		Chain & Standard*/}
										{/*	</th>*/}
										{/*	<th*/}
										{/*		scope="grid grid-cols-12"*/}
										{/*		className="body-text--smallest gradient-color-txt"*/}
										{/*	>*/}
										{/*		Ethereum ERC-721*/}
										{/*	</th>*/}
										{/*</tr>*/}
										{/*<tr>*/}
										{/*	<th scope="grid grid-cols-12" className="body-text--small">*/}
										{/*		Account Max*/}
										{/*	</th>*/}
										{/*	<th scope="grid grid-cols-12" className="body-text--smallest">*/}
										{/*		None*/}
										{/*	</th>*/}
										{/*	<th scope="grid grid-cols-12" className="body-text--small">*/}
										{/*		Max Count*/}
										{/*	</th>*/}
										{/*	<th scope="grid grid-cols-12" className="body-text--smallest">*/}
										{/*		None*/}
										{/*	</th>*/}
										{/*</tr>*/}
										{/*<tr>*/}
										{/*	<th scope="grid grid-cols-12" className="body-text--small">*/}
										{/*		Transferable*/}
										{/*	</th>*/}
										{/*	<th scope="grid grid-cols-12" className="body-text--smallest">*/}
										{/*		Yes*/}
										{/*	</th>*/}
										{/*	<th scope="grid grid-cols-12" className="body-text--small">*/}
										{/*		Royalty*/}
										{/*	</th>*/}
										{/*	<th scope="grid grid-cols-12" className="body-text--smallest">*/}
										{/*		5%*/}
										{/*	</th>*/}
										{/*</tr>*/}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default memo(ManageItem);
