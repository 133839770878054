import { createSlice} from "@reduxjs/toolkit";

export const spaceViewModeSlice = createSlice({
    name: "spaceViewMode",
    initialState: {
        isPublicUrl: false
    },
    reducers: {
        setIsPublicUrl: (state) => {
            state.isPublicUrl = true;
        },
        setIsNotPublicUrl: (state) => {
            state.isPublicUrl = false;
        }
    }
});

export const { setIsPublicUrl, setIsNotPublicUrl } = spaceViewModeSlice.actions;

export default spaceViewModeSlice.reducer;