import React, {memo, useEffect, useState, Fragment, forwardRef, useRef} from 'react';
import { Link } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import { getSitewideLink } from '../../../../utils/sitewideLinks';
import routes from '../../../../routes';
import './FooterProMobile.scss';
import { Dialog, Transition  } from '@headlessui/react';
import { AnimatePresence, motion } from 'framer-motion'
import { useNavigate } from 'react-router-dom';
import HeaderProMenu from '../HeaderPro/HeaderProMenu';
import HeaderProMenuSidebar from '../HeaderPro/HeaderProMenuSidebar/HeaderProMenuSidebar';
import DropdownWithItems from '../../../../components/commons/DropdownWithItems';
import { LANGUAGE, LOCATION } from '../../../../constants/profile';
import { Button } from 'antd';
import useIsInViewport from "../../../../utils/checkPageScrollPosition";
import {setIsFooterInViewPort} from "../../../../states/footerInViewSlice";
import {useTranslation} from "react-i18next";
import {setSelectedLanguage} from "../../../../states/language";
function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
  }


const FooterProMobile = (props) => {
	const [activeIndex, setActiveIndex] = useState(null);
	const { t, i18n } = useTranslation();
	const handleClick = (index) => {
		setActiveIndex(index === activeIndex ? null : index);
	};
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const [profileAssetType, setProfileAssetType] = useState(null);
	const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
	const [isBurgerClicked, setBurgerClicked] = useState(true);
	const [isProfileClicked, setProfileClicked] = useState(true);
	const [profileMenuOpen, setProfileMenuOpen] = useState(false);	
	const { spaceData, activeTab, onSelectedTabChange, ref1 } = props;
	const pro_styles = spaceData && spaceData.pro_style ? spaceData.pro_style : null;
	const tabs = ['home', 'updates', 'benefits', 'members'];
	const navigate = useNavigate();
	const [totalNavItems, setTotalNavItems] = useState(0);
	const { isPublicUrl } = useSelector((state) => state.spaceViewMode);
	const { languageList } = useSelector((state) => state.language);
	const { selectedLanguage } = useSelector((state) => state.language);
	const locationList = LOCATION;
	const [selectedLocation, setSelectedLocation] = useState('United States');

	const ref = useRef(null);
	const isInViewport = useIsInViewport(ref);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(setIsFooterInViewPort(isInViewport));
	}, [isInViewport]);

	const setActiveTabChange = (item) => {
		onSelectedTabChange(item);

		if (item === 'home' || item === 'members') {
			let url_username = spaceData.username;
			if (isPublicUrl) {
				url_username = spaceData.public_url_string;
			}

			// force page url navigate to reset location path when going back to home
			navigate(routes.viewUserSpace() + url_username, { replace: true });
		}
	};

	const header_navs = [];
	if (spaceData && spaceData.pro_navigations) {
		spaceData.pro_navigations.forEach((item) => {
			if (item.section === 'header') {
				header_navs.push(item);
			}
		});
	}

	useEffect(() => {
		setTotalNavItems(tabs.length + header_navs.length);
	}, [activeTab, totalNavItems]);

	useEffect(() => {

	}, [activeTab, totalNavItems, isPublicUrl]);

	useEffect(() => {}, [activeTab]);

	// const setActiveTabChange = (item) => {
	// 	onSelectedTabChange(item);
	// };
	
	useEffect(() => {
		if (authUserInfo && authUserInfo.profile) {
			setProfileAssetType(null);
			if (authUserInfo.profile.photo_large) {
				setProfileAssetType('image');
			}
			if (authUserInfo.profile.video) {
				setProfileAssetType('video');
			}
		}
	}, [authUserInfo, loading, profileAssetType]);
	useEffect(() => {
		setActiveIndex(0);
	  }, []);
	
	const closeDialog = async () => {
		setProfileMenuOpen(false);
	}
	
	const login = async () => {
		navigate(routes.login());
		setMobileMenuOpen(false);
		setProfileMenuOpen(false);
	}
	const signup = async () => {
		navigate(routes.signUp());
		setMobileMenuOpen(false);
		setProfileMenuOpen(false);
	}

	const footer_navs = [];
	if (spaceData && spaceData.pro_navigations) {
		spaceData.pro_navigations.forEach((item) => {
			if (item.section === 'footer') {
				footer_navs.push(item);
			}
		});
	}

	useEffect(() => {
		// console.log(isBurgerClicked);
	  }, [isBurgerClicked]);

	//CARL: added extra function to address HeadlessUI Dialog onClose issue
	//CARL: this will fix issue on onClick & onClose conflict
	//ISSUE: onClose sets value to false, onClick sets value to true
	const openMenu = (type) => {
		if(type === 'burger'){
			setBurgerClicked(true);	
			
			if(isBurgerClicked){
				setMobileMenuOpen(mobileMenuOpen => !mobileMenuOpen);
				setBurgerClicked(false);
			}	
			
		}else if(type === 'profile'){
			setProfileClicked(true);	
			if(isProfileClicked){
				setProfileMenuOpen(profileMenuOpen => !profileMenuOpen);
				setProfileClicked(false);
			}
		}
	}

	useEffect(() => {}, [selectedLanguage]);

	return (
		<footer ref={ref} className={classNames("flex justify-center relative fixed inset-x-0 bottom-0 z-10 h-[56px] footer",
		pro_styles.has_gradient ? 'has-gradient' : 'no-gradient')}>
			
			{!authUserInfo && (
				<div className='container px-[20px] md:px-[40px] flex items-center  mx-auto'>
					<div className="grid grid-cols-1 grid-flow-col gap-4 w-full text-xl text-slate-500 h-[48px] text-center flex items-center" onClick={() => openMenu('burger')}>
					<i className={classNames("text-[32px] fa fa-bars",
							mobileMenuOpen ? 'label' : '')} aria-hidden="true"></i>
						{/* <button
							type="button"
							className="inline-flex items-center justify-center  rounded-md p-2.5 text-gray-700"
							onClick={() => openMenu('burger')}
						>
							<span className="sr-only">Open main menu</span>
							<i class="" className={classNames("text-[32px] fa fa-bars",
							mobileMenuOpen ? 'gradient-color-txt' : '')} aria-hidden="true"></i>
						</button> */}
					</div>
				</div>
			)}
			
			
			{authUserInfo && (
				<div className='container px-[20px] md:px-[40px] flex items-center  mx-auto'>
					<div className='grid grid-cols-5 grid-flow-col gap-4 w-full text-xl text-slate-500 h-[48px]'>
						<div className='flex justify-center text-xl py-[8px]  m-auto'>
							<Link to="#">
								<i className="fa-solid fa-magnifying-glass text-[20px] "></i>
							</Link>
						</div>
						<div className='flex justify-center text-[20px]  m-auto'>
							<Link to="#">
								<i className="fa-solid fa-bell "></i>
							</Link>
						</div>
						<div className='flex h-10 items-center w-10 justify-center text-xl  m-auto' onClick={() => openMenu('profile')}>
						<div className={classNames("btn-secondary-dd rounded-full profile drop-shadow-[0_2px_3px_rgba(0,0,0,0.25)] overflow-hidden !h-[32px] !w-[32px]",
							profileMenuOpen ? 'icon-border-gradient' : '')} >
								<Link
									to="#"
									
								>
									{profileAssetType &&
									profileAssetType === 'video' ? (
										<video autoPlay muted loop disablePictureInPicture={true}>
											<source
												className='!h-full !w-full object-cover w-8'
												src={authUserInfo.profile.video}
												type="video/mp4"
											/>
										</video>
									) : (
										<>
											{profileAssetType === 'image' ? (	
												<img
												className='!h-full !w-full object-cover w-8'
													src={
														authUserInfo.profile.photo_thumb
													}
												/>
											) : (
												<div className="blank-avatar">
													<i className="fa-solid fa-user"></i>
												</div>
											)}
										</>
									)}
								</Link>
								<Dialog
									as="div"
									className="lg:hidden"
									open={profileMenuOpen}
									onClose={() => setProfileMenuOpen(false)}
								>
									{/* <div className="fixed !z-10 inset-0 bg-white top-[50%]" aria-hidden="true" /> */}
							<Dialog.Panel className={classNames(pro_styles.has_gradient ? 'has-gradient' : 'no-gradient',"block fixed bottom-[104px] md:hidden bg-white w-full z-40 h-[336px]")}>	
											
								
								
								<div className='grid grid-cols-12  mt-[24px] text-secondary items-center '>
									<div className='col-span-5 text-right pr-[24px] text-[20px] py-[4px]'>
										<i className="fa-regular fa-chart-tree-map text-right label"></i>{' '}
									</div>
									<div className=''>
										<Link
												to={routes.dashboard()}
												className="dropdown-item text-center py-[4px]"
												onClick={closeDialog}
										>
											<span className="text-xl font-[notoSansSemiBold] label">										
											{t('nav_dashboard')}
											</span>
											
										</Link>
									</div>
									
								</div>
								<div className='grid grid-cols-12 mt-[24px] text-secondary items-center'>
									<div className='col-span-5 text-right pr-[24px] py-[4px]'>
									<i className="fa-regular fa-solar-system text-right text-[20px] label"></i>{' '}
									</div>
									<div className=''>
										<Link
											to={routes.spaces()}
											className="dropdown-item text-center py-[4px]"
											onClick={closeDialog}
										>
											<span className="text-xl font-[notoSansSemiBold] label">										
											{t('nav_spaces')}
											</span>
										</Link>
									</div>								
								</div>
								<div className='grid grid-cols-12  mt-[24px] text-secondary  items-center'>
									<div className='col-span-5 text-right pr-[24px] py-[4px]'>
										<i className="fa-regular fa-id-badge text-right text-[20px] label"></i>{' '}
									</div>
									<div className=''>
										<Link
											to={routes.profile()}
											onClick={closeDialog}
											className="dropdown-item text-center py-[4px]"

										>										
											<span className="text-xl font-[notoSansSemiBold] label">										
											{t('nav_profile')}
											</span>
										</Link>
									</div>								
								</div>
								<div className='grid grid-cols-12  mt-[24px] text-secondary items-center'>
									<div className='col-span-5 text-right pr-[24px] py-[4px]'>
										<i className="fa-solid fa-gear text-right text-[20px] label"></i>{' '}
									</div>
									<div className=''>
										<Link
											to={routes.settings()}
											className="dropdown-item text-center py-[4px]"
											onClick={closeDialog}
										>										
											<span className="text-xl font-[notoSansSemiBold] label">										
												Settings
											</span>
										</Link>
									</div>								
								</div>
								<div className='grid grid-cols-12  mt-[24px] text-secondary items-center'>
									<div className='col-span-5 text-right pr-[24px] py-[4px]'>
										<i className="fa-regular fa-arrow-right-from-bracket text-righ text-[20px] label"></i>{' '}
									</div>
									<div className=''>
									<Link
											to={routes.logout()}
											onClick={closeDialog}
											className="dropdown-item text-xl text-center py-[4px]"
										>										
											<span className="text-xl font-[notoSansSemiBold] label">										
                                                {t('nav_logout')}
											</span>
										</Link>
									</div>								
								</div>
								
								{!authUserInfo && (
								<div className='sub grid grid-cols-2 grid-flow-col gap-4 px-[40px] pt-6'>
									<div className='h-[48px]'>
										<div onClick={login}  className='border-gradient w-full lg:right-[-109px] h-[48px] rounded-[6rem] cursor-pointer  z-50  text-white flex justify-center mx-auto'>
											<span className='block p-[13px]  font-semibold non-italic font-sans text-sm '>
											<p className='label' > {t('nav_login')} </p>
											</span>
										</div>
									</div>
									<div className='h-[48px]'>
										<div onClick={signup}   className='border-filled w-full  lg:right-[-109px] h-[48px] rounded-[6rem] cursor-pointer  z-50  text-white flex justify-center mx-auto'>
											<span className='block p-[13px]  font-semibold non-italic font-sans text-sm label'>{t('nav_signup')}</span>
										</div>
									</div>
								</div>
								)}
									</Dialog.Panel>
								</Dialog>
							</div>
						</div>
						<div className='flex justify-center j text-xl  m-auto items-center'>
							<Link to="#">
								<i className="fa-solid fa-wallet text-[20px] "></i>
							</Link>
						</div>
						<div className='flex justify-center text-xl  m-auto items-center z-60' onClick={() => openMenu('burger')}>
						<i className={classNames("text-[20px] fa fa-bars",
								mobileMenuOpen ? 'label' : '')} aria-hidden="true" ></i>						
						</div>				
					</div>
				</div>
			)}
				{ mobileMenuOpen && (
				<Dialog
					as="div"
					className="lg:hidden"
					open={mobileMenuOpen}
					onClose={() => setMobileMenuOpen(false)}				>
						{/* <div className={classNames("fixed inset-0 bg-white top-[50%]",
						)} aria-hidden="true" /> */}
					<Dialog.Panel className={classNames("h-[474px] fixed bottom-[104px] bg-white w-full !z-40 overflow-y-auto",
					pro_styles.has_gradient ? 'has-gradient' : 'no-gradient'
					)}>		
					<div className='max-[344px]:px-5 px-6 grid grid-cols-12 grid-rows-12 gap-6 py-6 '>
					<div className='h-10 col-span-12'>
								<DropdownWithItems
									title="Location"
									customButtonClass="btn-md btn-secondary-dd w-full"
									icon="fa-regular fa-globe"
									selectedValue={selectedLocation}
									listItems={locationList}
									onDropdownChange={setSelectedLocation}
								/>
							</div>
							<div className='h-10 col-span-12'>
								<DropdownWithItems
									title="EN"
									customButtonClass="icon-dropdown-gradient-alt"
									icon="fa-light fa-language"
									selectedValue={selectedLanguage}
									listItems={languageList}
									onDropdownChange={(value)=>{
										dispatch(setSelectedLanguage(value));
										i18n.changeLanguage(value);
									}
									}
								/>
							</div>
						{(spaceData.social_link || spaceData.website) && (
						<div className='h-10 col-span-12 grid grid-cols-12 social-links list-unstyled'>	
							{spaceData.social_link && (	
							<>
								<div className='col-span-2 flex justify-center items-center text-xl text-slate-400'>
									{getSitewideLink('footer_twitter_icon', "social-links list-unstyled", true)}
								</div>
								<div className='col-span-2 flex justify-center items-center text-xl text-slate-400'>
									{getSitewideLink('footer_instagram_icon', "social-links list-unstyled", true)}
								</div>
								<div className='col-span-2 flex justify-center items-center text-xl text-slate-400'>
									{getSitewideLink('footer_facebook_icon', "social-links list-unstyled", true)}
								</div>
								<div className='col-span-2 flex justify-center items-center text-xl text-slate-400'>
									{getSitewideLink('footer_youtube_icon', "social-links list-unstyled", true)}
								</div>
								<div className='col-span-2 flex justify-center items-center text-xl text-slate-400'>
									{getSitewideLink('footer_tiktok_icon', "social-links list-unstyled", true)}
								</div>
								<div className='col-span-2 flex justify-center items-center text-xl text-slate-400'>
									{getSitewideLink('footer_discord_icon', "social-links list-unstyled", true)}
								</div>
							</>	
							
							)}
							{spaceData.website && (
								<div className='col-span-2 flex justify-center items-center text-xl text-slate-400'>
									<a href={spaceData.website} target="_blank">
											<i className="fa-regular fa-globe label"></i>
										</a>
								</div>
							)}
						</div>
						)}
							<div className='h-10 col-span-12 col-span-12 grid grid-cols-12 gap-6 font-[notoSansSemiBold]'>
								<div className='col-span-4 flex justify-start md:justify-end items-center text-xl text-slate-400 label footer-links'>
									<Link
										to={routes.viewUserSpace() + spaceData?.username}
										onClick={(event) => setActiveTabChange('home')}
									>
										{t('nav_home')}
									</Link>
								</div>
								<div className='col-span-4 flex justify-center items-center text-xl text-slate-400 label footer-links'>
									<Link
										to={
											routes.viewUserSpace() + spaceData?.username + '/update'
										}
										onClick={(event) => setActiveTabChange('updates')}
									>
										{t('nav_updates')}
									</Link>
								</div>
								<div className='col-span-4 flex justify-end md:justify-start items-center text-xl text-slate-400  label footer-links'>
									<Link
										to={
											routes.viewUserSpace() +
											spaceData?.username +
											'/benefit'
										}
										onClick={(event) => setActiveTabChange('benefits')}
									>
										{t('nav_benefits')}
									</Link>
								</div>
							</div>
							<div className='h-10 col-span-12 col-span-12 grid grid-cols-12 gap-6 mb-6 font-[notoSansSemiBold]'>
								<div className='col-span-4 flex justify-start md:justify-end items-center text-xl text-slate-400 label footer-links'>
									<Link
										to={
											routes.viewUserSpace() + spaceData?.username + '/member'
										}
										onClick={(event) => setActiveTabChange('members')}
									>
										{t('nav_members')}
									</Link>
								</div>
								<div className='col-span-4 flex justify-center items-center text-xl text-slate-400 label footer-links'>
									<a
										href={
											routes.viewUserSpace() +
											spaceData?.username +
											'/privacy-policy'
										}
									>
										{t('nav_privacy')}
									</a>
								</div>
								<div className='col-span-4 flex justify-end md:justify-start items-center text-xl text-slate-400 label footer-links'>
									<a
										href={
											routes.viewUserSpace() +
											spaceData?.username +
											'/terms-services'
										}
									>
										{t('nav_terms')}
									</a>
								</div>
							</div>
							<div className='h-10 col-span-12 col-span-12 grid grid-cols-12 mb-6 gap-6 font-[notoSansSemiBold]'>
								{header_navs.map((data, index) => (
										<div className={classNames('sm:col-span-3 break-words h-10 flex text-center items-center text-xl text-slate-400 label footer-links', header_navs.length == 4 ? 'col-span-6' : 'col-span-4')}>
											{data.label}
										</div>
								))}
							</div>
							{spaceData && spaceData.pro_style && footer_navs.length > 0 && ( 
							<div className='h-10 col-span-12 col-span-12 grid grid-cols-12 gap-6 mt-6 font-[notoSansSemiBold]'>
								{footer_navs.map((data, index) => (
									<div className={classNames(' col-span-4 flex text-center items-center text-ellipisis text-xl text-slate-400 label footer-links')}>
										<a
													href={data.url}
													target={
														data.open_type === 'open_new_window'
															? '_blank'
															: ''
													}
												>
													{data.label}
												</a>
									</div>
								))}
							</div>
							
							
							)}
							<div className='h-10 col-span-12 grid grid-cols-12 mt-20 gap-x-6'>							
								<div className='col-span-6 flex justify-center items-center'>
									<Button className="btn btn-md btn-secondary mb-2" onClick={login}>
                                    {t('nav_login')}
									</Button>
									
								</div>
								<div className='col-span-6 flex justify-center items-center'>
									<Button className="btn btn-md btn-primary mb-2" onClick={signup}>
                                    {t('nav_signup')}
									</Button>
								</div>
							</div>
							
					</div>		
					
						
							
						{/* <div className='main grid grid-rows-2 grid-flow-col gap-4 px-[40px] pt-6'>
								<div className='grid grid-cols-4 gap-6 text-center items-center font-[notoSansSemiBold]'>
								{header_navs.map((data, index) => (
									<div className='text-xl text-slate-400  label'>
										{data.label}
									</div>
								))}
								</div>
						</div>
						<div className='grid grid-cols-3 gap-6 text-center items-center font-[notoSansSemiBold] px-[40px]'>
								{spaceData && spaceData.pro_style && footer_navs.length > 0 && (
										footer_navs.map((data, index) => (
											<div className='text-xl text-slate-400  label'>
												<a
													href={data.url}
													target={
														data.open_type === 'open_new_window'
															? '_blank'
															: ''
													}
												>
													{data.label}
												</a>
											</div>
										))
								)}
						
							</div>	
						{!authUserInfo && (
						<div className='sub grid grid-cols-2 grid-flow-col gap-4 px-[40px] pt-6'>
							<div className='h-[48px]'>
								<div onClick={login}  className='border-gradient w-full lg:right-[-109px] h-[48px] rounded-[6rem] cursor-pointer  z-50  text-white flex justify-center mx-auto'>
									<span className='block p-[13px]  font-semibold non-italic font-sans text-sm '>
									<p className='gradient-color-txt font-[notoSansSemiBold]' > Login </p>
									</span>
								</div>
							</div>
							<div className='h-[48px]'>
								<div onClick={signup}   className='border-filled w-full  lg:right-[-109px] h-[48px] rounded-[6rem] cursor-pointer  z-50  text-white flex justify-center mx-auto'>
									<span className='block p-[13px]  font-semibold non-italic font-sans text-sm font-[notoSansSemiBold]'>Sign Up</span>
								</div>
							</div>
						</div>
						
						)} */}
						{/* <div className=' grid grid-cols-1 text-center items-center '>
							Button
						</div> */}
					</Dialog.Panel>
				</Dialog>	
				)}
			
		</footer>
	);
};
export default memo(FooterProMobile);
