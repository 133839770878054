import {memo, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {ROOT_DOMAIN} from "../../../../../constants/common";
import {useSelector} from "react-redux";
import routes from "../../../../../routes";
import {useTranslation} from "react-i18next";

const HeaderProMenu = (props) => {
	const { spaceData, activeTab, onSelectedTabChange } = props;
	const { isPublicUrl } = useSelector((state) => state.spaceViewMode);
	const navigate = useNavigate();
	const { t, i18n } = useTranslation();
	// const tabs = [
	// 	// 'home',
	// 	'updates',
	// 	'benefits',
	// 	'members',
	// 	'support',
	// 	'about'
	// ];
	const tabs = [
		// { id: 'home', active: ['home'], localization_code: 'nav_home' },
		{ id: 'updates', active: ['updates'], localization_code: 'nav_updates' },
		{ id: 'benefits', active: ['benefits'], localization_code: 'nav_benefits' },
		{ id: 'members', active: ['members'], localization_code: 'nav_members'  },
		{ id: 'support', active: ['support'], localization_code: 'nav_support' },
		{ id: 'about', active: ['about', 'about#contact'], localization_code: 'nav_about' }
	];
	const [totalNavItems, setTotalNavItems] = useState(0);

	const setActiveTabChange = (item) => {
		onSelectedTabChange(item);
		if (item === 'home' || item === 'members') {
			if (ROOT_DOMAIN === window.location.hostname) {
				let url_username = spaceData.username;
				if (isPublicUrl) {
					url_username = spaceData.public_url_string;
				}

				// force page url navigate to reset location path when going back to home
				navigate(routes.viewUserSpace() + url_username, { replace: true });
			} else {
				if (item === 'members') {
					console.log(item);
					// navigate('/member', { replace: true });
				}
			}
		} else {
			if (ROOT_DOMAIN !== window.location.hostname) {
				if (item === 'updates') {
					navigate('/update', { replace: true });
				}
				if (item === 'benefits') {
					navigate('/benefit', { replace: true });
				}
			}
		}
	};

	const header_navs = [];
	if (spaceData && spaceData.pro_navigations) {
		spaceData.pro_navigations.forEach((item) => {
			if (item.section === 'header') {
				header_navs.push(item);
			}
		});
	}

	useEffect(() => {
		setTotalNavItems(tabs.length + header_navs.length);
	}, [activeTab, totalNavItems]);

	useEffect(() => {
	}, [activeTab, totalNavItems, isPublicUrl]);

	return (
		// md:grid-rows-3 grid-flow-col md:grid-rows-none lg:grid-rows-2 lg:grid-flow-col
		// <ul
		// 	// className={`nav nav-pills grid ${totalNavItems > 5 ? 'grid-cols-12 grid-flow-row justify-stretch place-content-center' : 'lg:grid-flow-col lg:justify-stretch'} `}
		// 	className='nav nav-pills grid grid-cols-12'
		// 	id="pills-tab"
		// 	role="tablist"
		// >
		// 	{tabs.map(tab => (
		// 		<li className={`nav-item col-span-12 flex ${totalNavItems > 6 ? 'lg:col-span-3' : 'lg:col-span-2'} `} role="presentation" key={tab}>
		// 			<a
		// 				// className={'w-full nav-link flex items-center justify-center text-center p-0 ' + (activeTab === tab ? 'active' : '')}
		// 				className={'w-full nav-link flex items-center justify-center text-center p-0 '}
		// 				onClick={() => setActiveTabChange(tab)}
		// 			>
		// 				{tab.charAt(0).toUpperCase() + tab.slice(1)}
		// 			</a>
		// 		</li>
		// 	))}
		// 	{header_navs.map((data, index) => (
		// 		// <li className={`nav-item col-span-12 ${totalNavItems > 6 ? 'lg:col-span-3' : 'lg:col-span-2'}  flex shrink w-[120px] xl:w-full justify-center items-center`} key={index}>
		// 		<li className={`nav-item col-span-12 flex ${totalNavItems > 6 ? 'lg:col-span-3' : 'lg:col-span-2'}  `} key={index}>
		// 			<a
		// 				className="w-full nav-link flex items-center justify-center text-center p-0 "
		// 				target={data.open_type === 'open_new_window' ? '_blank' : ''}
		// 				href={data.url}
		// 			>
		// 				{data.label}
		// 			</a>
		// 			{/* <Link
		// 				className="nav-link"
		// 				to={data.url}
		// 				target={data.open_type === 'open_new_window' ? '_blank' : ''}
		// 			>
		// 				{data.label}
		// 			</Link> */}
		// 		</li>
		// 	))}
		// 	{/*{tabs.map(tab => (*/}
		// 	{/*	<li className={`nav-item col-span-12 ${totalNavItems > 6 ? 'lg:col-span-3' : 'lg:col-span-2'} flex items-center justify-center`} role="presentation" key={tab}>*/}
		// 	{/*		<Button*/}
		// 	{/*			className={'w-full nav-link p-0 ' + (activeTab === tab ? 'active' : '')}*/}
		// 	{/*			onClick={() => setActiveTabChange(tab)}*/}
		// 	{/*		>*/}
		// 	{/*			{tab.charAt(0).toUpperCase() + tab.slice(1)}*/}
		// 	{/*		</Button>*/}
		// 	{/*	</li>*/}
		// 	{/*))}*/}
		// 	{/*{header_navs.map((data, index) => (*/}
		// 	{/*	<li className={`nav-item col-span-12 ${totalNavItems > 6 ? 'lg:col-span-3' : 'lg:col-span-2'}  flex justify-center items-center`} key={index}>*/}
		// 	{/*		<a*/}
		// 	{/*			className="w-full nav-link m-0 flex items-center justify-center text-center p-0 "*/}
		// 	{/*			target={data.open_type === 'open_new_window' ? '_blank' : ''}*/}
		// 	{/*			href={data.url}*/}
		// 	{/*		>*/}
		// 	{/*			{data.label}*/}
		// 	{/*		</a>*/}
		// 	{/*		/!* <Link*/}
		// 	{/*			className="nav-link"*/}
		// 	{/*			to={data.url}*/}
		// 	{/*			target={data.open_type === 'open_new_window' ? '_blank' : ''}*/}
		// 	{/*		>*/}
		// 	{/*			{data.label}*/}
		// 	{/*		</Link> *!/*/}
		// 	{/*	</li>*/}
		// 	{/*))}*/}
		// </ul>
		// #id applied hidden and lg:flex classes control viewport visibility
		<div id="cs-space-header-nav" className="hidden lg:flex gap-gutter">
			{/*Navigation Links x 7 max*/}
			{/*CSJS Nav Links Values based on Space Admin entered values {space_header_nav_link*_text}, {space_header_nav_link*_target} and {space_header_nav_link*_url}.*/}
			{tabs.map((tab, index) => (
				<p key={index}>
					<a
					   onClick={() => setActiveTabChange(tab.id)}
					>
						{/*{tab.charAt(0).toUpperCase() + tab.slice(1)}*/}
						{t(tab.localization_code)}
					</a>
				</p>
			))}
			{header_navs.map((data, index) => (
				<p key={index}>
					<a href={data.url}
					   target={data.open_type === 'open_new_window' ? '_blank' : '_self'}
					>
						{data.label}
					</a>
				</p>
			))}
		</div>
	);
};
export default memo(HeaderProMenu);
