import React, { forwardRef, memo, useEffect, useRef, useState } from 'react';
import co_logo from '../../../assets/images/cs-logo.svg';
import { getSitewideLink } from '../../../utils/sitewideLinks';
import DropdownWithItems from '../../commons/DropdownWithItems';
import { LANGUAGE, LOCATION } from '../../../constants/profile';
import useIsInViewport from '../../../utils/checkPageScrollPosition';
import { setIsFooterInViewPort } from '../../../states/footerInViewSlice';
import {useDispatch, useSelector} from 'react-redux';
import routes from '../../../routes';
import { checkIsLoggedIn } from '../../../utils/common';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getDataByUsername } from '../../../services/general';
import {getUserByUsername} from "../../../services/user";
import ReactPlayer from "react-player/lazy";
import default_user_profile from "../../../assets/images/default_user_profile.png";
import {useTranslation} from "react-i18next";
import {ROOT_DOMAIN} from "../../../constants/common";
import SocialLinks from "../../commons/SocialLinks";
import {setSelectedLanguage} from "../../../states/language";

const Footerv2 = (props) => {
	const { t, i18n } = useTranslation();
	const { spaceData } = props;
	const ref = useRef(null);
	const isInViewport = useIsInViewport(ref);
	const dispatch = useDispatch();
	let isLoggedIn = checkIsLoggedIn();
	const currentLocation = useLocation();
	const [path, setPath] = useState(window.location.pathname);
	const [spaceUsername, setSpaceUsername] = useState(null);
	const tabs = [t('nav_explore'), t('nav_create'), t('nav_learn')];
	const locationList = LOCATION;
	const [selectedLocation, setSelectedLocation] = useState('United States');
	const [openMobileFooterMenu, setOpenMobileFooterMenu] = useState(false);
	const navigate = useNavigate();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const { isPublicUrl } = useSelector((state) => state.spaceViewMode);
	const [isDashboardMenuOpen, setIsDashboardMenuOpen] = useState(false);
	const { languageList } = useSelector((state) => state.language);
	const { selectedLanguage } = useSelector((state) => state.language);

	const toggleDashboardMenu = () => {
		setIsDashboardMenuOpen(!isDashboardMenuOpen);
		// document.body.classList.toggle('lock-body');
	};

	const setActiveTabChange = (item) => {
		// onSelectedTabChange(item);
		if (item === 'home' || item === 'members') {
			if (ROOT_DOMAIN === window.location.hostname) {
				let url_username = spaceData.username;
				if (isPublicUrl) {
					url_username = spaceData.public_url_string;
				}

				// force page url navigate to reset location path when going back to home
				navigate(routes.viewUserSpace() + url_username, {replace: true});
			}
		}
	};

	useEffect(() => {
		dispatch(setIsFooterInViewPort(isInViewport));
	}, [isInViewport]);


	const handleTermsClick = (event) => {
		event.preventDefault();

		console.log('Terms Clicked');
		let updatedPath = path;
		console.log('pass: ', updatedPath);
		if (path && path.startsWith('/@') && path.length > 3) {
			let username = path.split('/')[1].replace('@', '');
			console.log('username: ', username);
			updatedPath = '/@' + username + '/terms-services';
			console.log('updatedPath: ', updatedPath);
		} else {
			updatedPath = '/terms-of-use';
		}

		console.log('Final Path: ', updatedPath);
		window.location.replace(updatedPath);
	};

	const handleSupportClick = (event) => {
		event.preventDefault();
		console.log('Support Clicked ');
		let updatedPath = path;
		console.log('pass: ', updatedPath);
		if (path && path.startsWith('/@') && path.length > 3) {
			let username = path.split('/')[1].replace('@', '');
			console.log('username: ', username);
			updatedPath = '/@' + username + '/support';
			console.log('updatedPath: ', updatedPath);
		} else {
			updatedPath = '/support';
		}

		console.log('Final Path: ', updatedPath);
		window.location.replace(updatedPath);
	};

	useEffect(() => {
		const newPath = window.location.pathname;
		setPath(newPath);
	}, [currentLocation.pathname]);

	useEffect(() => {}, [selectedLanguage, isPublicUrl]);

	return (
		// SECTION PLATFORM FOOTER
		<footer
			id="cs-platform-footer"
			className="cs-footer theme pt-0 pb-0 sm:pb-section-safeview px-0 sm:px-section-safeview"
		>
			{/*SECTION - Footer Frame*/}
			<div
				id="footer-frame"
				className={
					'centerframe-grid max-w-breakpoint py-gutter-2xl sm:pt-section sm:pb-0 px-section-safeview-base sm:px-0' +
					(openMobileFooterMenu ? '' : ' hide')
				}
			>
				{/*ANCHOR - Brand Info Block*/}
				<div className="cs-footer-brand block-base">
					<div className="stack-col">
						{/* //ANCHOR - Logo */}
						<div id="cs-platform-footer-logo" className="block-logo mobile-none-flex">
							<a href={routes.app()}>
								<img src={co_logo} alt="Commonspace Logo" />
							</a>
						</div>

						{/* //ANCHOR - Short Description */}
						<p className="cs-footer-brand-text mobile-none-block">
							Powering communities around the world for creators, brands, and
							businesses. {getSitewideLink('footer_learn_more', '')}
						</p>

						{/* //ANCHOR - Icon Links */}
						{/*<div className="cs-stack-link-icons stack-row mobile-2">*/}
						{/*	{getSitewideLink(*/}
						{/*		'footer_twitter_icon',*/}
						{/*		'button-icon-round-gradient',*/}
						{/*		true*/}
						{/*	)}*/}
						{/*	{getSitewideLink(*/}
						{/*		'footer_instagram_icon',*/}
						{/*		'button-icon-round-gradient',*/}
						{/*		true*/}
						{/*	)}*/}
						{/*	{getSitewideLink(*/}
						{/*		'footer_facebook_icon',*/}
						{/*		'button-icon-round-gradient',*/}
						{/*		true*/}
						{/*	)}*/}
						{/*	{getSitewideLink(*/}
						{/*		'footer_linkedin_icon',*/}
						{/*		'button-icon-round-gradient',*/}
						{/*		true*/}
						{/*	)}*/}
						{/*</div>*/}
						{(spaceData && spaceData?.social_links) && (
							<SocialLinks
								socialLinks={spaceData.social_links}
								parentClassName="cs-stack-link-icons stack-row mobile-2"
								childClassName="button-icon-round-gradient"
							/>
						)}

						{/* //ANCHOR - Localization Dropdowns */}
						<div className="cs-footer-localization stack-row-wrap mobile-1">
							<DropdownWithItems
								title="United States"
								customButtonClass="icon-dropdown-gradient-alt"
								icon="fa-light fa-globe"
								selectedValue={selectedLocation}
								listItems={locationList}
								onDropdownChange={setSelectedLocation}
							/>
							{/*<button*/}
							{/*	type="button"*/}
							{/*	className="icon-dropdown-gradient-alt"*/}
							{/*	id="menu-button-country"*/}
							{/*	aria-expanded="false"*/}
							{/*	aria-haspopup="true"*/}
							{/*>*/}
							{/*	<span>*/}
							{/*		<i className="fa-light fa-globe"></i>*/}
							{/*		<p>United States</p>*/}
							{/*		<i className="fa-solid fa-caret-down"></i>*/}
							{/*	</span>*/}
							{/*</button>*/}
							{/*<button*/}
							{/*	type="button"*/}
							{/*	className="icon-dropdown-gradient-alt"*/}
							{/*	id="menu-button-language"*/}
							{/*	aria-expanded="false"*/}
							{/*	aria-haspopup="true"*/}
							{/*>*/}
							{/*	<span>*/}
							{/*		<i className="fa-light fa-language"></i>*/}
							{/*		<p>English</p>*/}
							{/*		<i className="fa-solid fa-caret-down"></i>*/}
							{/*	</span>*/}
							{/*</button>*/}
							{/*<button*/}
							{/*	type="button"*/}
							{/*	className="icon-dropdown-gradient-alt"*/}
							{/*	id="menu-button-language"*/}
							{/*	aria-expanded="false"*/}
							{/*	aria-haspopup="true"*/}
							{/*>*/}
							{/*	<span>*/}
							{/*		<i className="fa-light fa-language"></i>*/}
							{/*		<p>English</p>*/}
							{/*		<i className="fa-solid fa-caret-down"></i>*/}
							{/*	</span>*/}
							{/*</button>*/}
							<DropdownWithItems
								title="EN"
								customButtonClass="icon-dropdown-gradient-alt"
								icon="fa-light fa-language"
								selectedValue={selectedLanguage}
								listItems={languageList}
								onDropdownChange={(value)=>{
									dispatch(setSelectedLanguage(value));
									i18n.changeLanguage(value);
								}
								}
							/>
						</div>
					</div>
				</div>
				{/* //ANCHOR END - Brand Info Block */}

				{/* //ANCHOR - Footer Nav Spacer */}
				<div className="cs-footer-spacer mobile-none-block"></div>

				{/* //ANCHOR - Footer Nav 1 */}
				<div id="cs-platform-footer-nav-1" className="block-base mobile-none-block">
					<nav className="cs-footer-nav-stack stack-col">
						<h3>{t('title_account')}</h3>
						<div className="cs-footer-nav-stack-links">
							<p>{getSitewideLink('footer_account_how_it_works', ' ')}</p>
							<p>{getSitewideLink('footer_account_communities', ' ')}</p>
							<p>{getSitewideLink('footer_account_memberships', ' ')}</p>
							<p>{getSitewideLink('footer_account_FAQ', ' ')}</p>
							<p>{getSitewideLink('footer_account_privacy_policy', ' ')}</p>
						</div>
					</nav>
				</div>

				{/* //ANCHOR - Footer Nav 2 */}
				<div id="cs-platform-footer-nav-2" className="block-base mobile-none-block">
					<nav className="cs-footer-nav-stack stack-col">
						<h3>References</h3>
						<div className="cs-footer-nav-stack-links">
							<p>{getSitewideLink('footer_resources_creators', ' ')}</p>
							<p>{getSitewideLink('footer_resources_brands', ' ')}</p>
							<p>{getSitewideLink('footer_resources_collabs', ' ')}</p>
							<p>{getSitewideLink('footer_resources_fans', ' ')}</p>
							<p>{getSitewideLink('footer_resources_commerce', ' ')}</p>
						</div>
					</nav>
				</div>

				{/* //ANCHOR - Footer Nav 3 */}
				<div id="cs-platform-footer-nav-3" className="block-base mobile-none-block">
					<nav className="cs-footer-nav-stack stack-col">
						<h3>{t('title_help')}</h3>
						<div className="cs-footer-nav-stack-links">
							<p>
								<a href="#">{t('nav_faq')}</a>
							</p>
							<p>
								<a href="#" onClick={handleSupportClick}>
									{t('nav_support')}
								</a>
							</p>

							<p>
								<a href="#">{t('nav_contact')}</a>
							</p>
							<p>
								<a href="#">{t('nav_privacy')}</a>
							</p>
							<p>
								<a href="#" onClick={handleTermsClick}>
								{t('nav_terms')}
								</a>
							</p>
						</div>
					</nav>
				</div>

				{/* //ANCHOR - Copyright */}
				<div className="cs-footer-copyright block-full mobile-none-block">
					<p>© 2024 Commonspace Platforms. All Rights Reserved.</p>
				</div>

				{/* //ANCHOR - Footer Mobile Menu Links */}
				<div
					id="cs-platform-footer-mobile-menu-links"
					className="block-full mobile-only-block"
				>
					<nav>
						<p>
							<a href={routes.app()}>{t('nav_home')}</a>
						</p>
						{tabs.map((tab, index) => (
							<p key={index}>
								<a
									// onClick={() => setActiveTabChange(tab.id)}
								>
									{tab}
								</a>
							</p>
						))}
						{/*{isLoggedIn ? (*/}
						{/*	<>*/}
						{/*		{tabs.map((tab, index) => (*/}
						{/*			<p key={index}>*/}
						{/*				<a*/}
						{/*					onClick={() => setActiveTabChange(tab)}*/}
						{/*				>*/}
						{/*					{tab.charAt(0).toUpperCase() + tab.slice(1)}*/}
						{/*				</a>*/}
						{/*			</p>*/}
						{/*		))}*/}
						{/*		{header_navs.map((data, index) => (*/}
						{/*			<p key={index}>*/}
						{/*				<a href={data.url}*/}
						{/*				   target={data.open_type === 'open_new_window' ? '_blank' : '_self'}*/}
						{/*				>*/}
						{/*					{data.label}*/}
						{/*				</a>*/}
						{/*			</p>*/}
						{/*		))}*/}
						{/*	</>*/}
						{/*) : (*/}
						{/*	<>*/}
						{/*		<p>*/}
						{/*			<a*/}
						{/*				// href="https://www.create.common.space/explore"*/}
						{/*			>*/}
						{/*				Explore*/}
						{/*			</a>*/}
						{/*		</p>*/}
						{/*		<p>*/}
						{/*			<a*/}
						{/*				// href="https://www.create.common.space/create"*/}
						{/*			>*/}
						{/*				Create*/}
						{/*			</a>*/}
						{/*		</p>*/}
						{/*		<p>*/}
						{/*			<a*/}
						{/*				// href="https://www.create.common.space/learn"*/}
						{/*				>*/}
						{/*				Learn*/}
						{/*			</a>*/}
						{/*		</p>*/}
						{/*	</>*/}
						{/*)}*/}
					</nav>
				</div>

				{/* //ANCHOR - PUBLIC User Auth Button Links */}
				<div id="cs-platform-footer-mobile-auth" className="block-full mobile-only-block">
					{!isLoggedIn && (
						<nav>
							<a
								href={routes.login()}
								target="_self"
								className="button-secondary-gradient"
							>
								<span>
									<p>{t('nav_login')}</p>
								</span>
							</a>
							<a
								href={routes.signUp()}
								target="_self"
								className="button-primary-gradient"
							>
								<span>
									<p>{t('nav_signup')}</p>
								</span>
							</a>
						</nav>
					)}
				</div>
			</div>
			{/* !SECTION END - Footer Frame */}

			<div id="cs-platform-user-menu" className={`block-base mr-section-safeview ${isDashboardMenuOpen ? '' : 'hide'}`}>
				<nav>
					<Link to={routes.dashboard()} target="_self">
						<div>
							<span><i className="fa-light fa-chart-tree-map"></i></span>
							<p>{t('nav_dashboard')}</p>
						</div>
					</Link>
					{/*<Link target="_self">*/}
					{/*	<div>*/}
					{/*		<span><i className="fa-light fa-list-ul"></i></span>*/}
					{/*		<p>Activity</p>*/}
					{/*	</div>*/}
					{/*</Link>*/}
					<Link to={routes.spaces()} target="_self">
						<div>
							<span><i className="fa-light fa-solar-system"></i></span>
							<p>{t('nav_spaces')}</p>
						</div>
					</Link>
					<Link to={routes.collections()} target="_self">
						<div>
							<span><i className="fa-light fa-grid"></i></span>
							<p>{t('nav_collections')}</p>
						</div>
					</Link>
					<Link to={routes.profile()} target="_self">
						<div>
							<span><i className="fa-light fa-id-badge"></i></span>
							<p>{t('nav_profile')}</p>
						</div>
					</Link>
					<Link to={routes.settings()} target="_self">
						<div>
							<span><i className="fa-light fa-gear"></i></span>
							<p>{t('nav_account')}</p>
						</div>
					</Link>
					<Link to={routes.logout()} target="_self">
						<div>
							<span><i className="fa-light fa-arrow-right-from-bracket"></i></span>
							<p>{t('nav_logout')}</p>
						</div>
					</Link>
				</nav>
			</div>

			{/* SECTION FOOTER MOBILE NAV & TOGGLES */}
			{/* CSJS MOBILE NAV is used for all User States (PUBLIC/MEMBER) with specific icon buttons only visible/exists if noted for the its User State. If present, the !hidden class (specifically with the "!") is used to demo visibility based on the demo state, it is not part of the normal usage. */}
			<div
				id="cs-platform-footer-mobile-nav"
				className="block-full mobile-only-block px-section-safeview"
			>
				<nav>
					<a href={routes.app()}>
						<i className="fa-regular fa-solar-system"></i>
					</a>

					{authUserInfo &&
						<a>
							<i className="fa-regular fa-bell"></i>
						</a>
					}

					{/*CSJS Visible/exists ONLY for MEMBER. Toggle "active" class on menu display. If present, className="!hidden" (specifically with the "!") is used to demo visibility based on the demo state, it is not part of the normal usage.*/}
					{authUserInfo && (
					    <a id="mobile-user-menu-toggle" className={`avatar-gradient`} onClick={toggleDashboardMenu}>
					        { authUserInfo?.profile?.video ? (
					            <ReactPlayer
					                playing={true}
					                loop={true}
					                muted={true}
					                controls={false}
					                width="100%"
					                height="100%"
					                className={`videoPreview`}
					                url={authUserInfo?.profile?.video ?? ''}
					            />
					        ) : (
					            <img
					                src={authUserInfo?.profile?.photo_thumb ? (authUserInfo?.profile?.photo_thumb ?? '') : default_user_profile}
					                alt="Image of cs_profile_username"
					            />
					        )}
					    </a>
					)}
					{authUserInfo &&
						<a>
							<i className="fa-regular fa-comments"></i>
						</a>
					}
					<a
						id="mobile-footer-menu-toggle"
						className="toggle-menu-gradient"
						onClick={() => {
							setOpenMobileFooterMenu(!openMobileFooterMenu);
						}}
					>
						{openMobileFooterMenu ? (
							<>
								<i
									id="mobile-footer-menu-close"
									className="fa-regular fa-angles-down active"
								></i>
							</>
						) : (
							<>
								<i id="mobile-footer-menu-open" className="fa-regular fa-bars"></i>
							</>
						)}
					</a>
				</nav>
			</div>
		</footer>
	);
};

export default memo(Footerv2);
