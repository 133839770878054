import React, { memo, useCallback, useEffect, useState } from 'react';
import { Button, Checkbox, Form, Input } from 'antd';
import { VALIDATE_MESSAGES } from '../../../../constants/common';
import ReactQuill from 'react-quill';
import UploadDragger from '../../../../components/commons/UploadDragger';
import {
	IMAGE_FILETYPES, IMAGE_LARGE_FILE_UPLOAD_SIZE, IMAGE_LARGE_MAX_HEIGHT, IMAGE_LARGE_MAX_WIDTH, IMAGE_LARGE_MIN_HEIGHT, IMAGE_LARGE_MIN_WIDTH,
	IMAGE_STANDARD_FILE_UPLOAD_SIZE,
	IMAGE_STANDARD_MAX_HEIGHT,
	IMAGE_STANDARD_MAX_WIDTH,
	IMAGE_STANDARD_MIN_HEIGHT,
	IMAGE_STANDARD_MIN_WIDTH,
	VIDEO_FILETYPES,
	VIDEO_STANDARD_FILE_UPLOAD_SIZE,
	VIDEO_STANDARD_MAX_HEIGHT,
	VIDEO_STANDARD_MAX_WIDTH,
	VIDEO_STANDARD_MIN_HEIGHT,
	VIDEO_STANDARD_MIN_WIDTH
} from '../../../../constants/file';
import {
	ACCESS_TYPES_LIST_WITH_DEFAULT,
	ADD_CREATE_BENEFIT,
	CONTENT_TYPES_LIST_WITH_DEFAULT,
	MEMBERSHIP_TYPES, PRICING_TYPES_LIST, SELECT_CURRENCIES,
	UPDATES_VISIBILITY
} from '../../../../constants/space';
import DropdownWithItems from '../../../../components/commons/DropdownWithItems';
import { getListIcon } from '../../../../utils/list';
import TextareaAutosize from 'react-textarea-autosize';
import {
	checkMembershipShortCodeAvailability, createSpaceAddMembership,
	updateSpaceMembership
} from '../../../../services/space';
import routes from '../../../../routes';
import { useSelector } from 'react-redux';
import ExplicitContent from '../../../../components/commons/Modals/ExplicitContent';
import Alert from 'react-bootstrap/Alert';
import { Link } from 'react-router-dom';
import { getSitewideLink } from '../../../../utils/sitewideLinks';
import { CustomValidators } from '../../../../utils/CustomValidators';
import UploadDraggerV2 from "../../../../components/commons/UploadDraggerV2";
import {RenewalOptions} from "../../../../utils/MembershipItemUtils";
import {getSpaceInfo} from "../../../../states/spaceGlobal/actions";

const EditMembershipCommunity = (props) => {

	const { membershipData, benefitData } = props;
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
	const [editMembershipForm] = Form.useForm();

	const [membershipID, setMembershipID] = useState(null);
	const [benefitID, setBenefitID] = useState(null);

	const [displayDropDownError, setDisplayDropDownError] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);
	const [alert, setAlert] = useState(null);
	const [submitted, setSubmitted] = useState(false);
	const [enableExpirationChecked, setExpirationChecked] = useState(false);
	const [enableGiftingChecked, setGiftingChecked] = useState(false);
	const [enableAddNumericChecked, setAddNumericChecked] = useState(false);
	const [membershipImage, setMembershipImage] = useState(null);
	const [membershipImageBlobPreview, setMembershipImageBlobPreview] = useState(null);
	const [membershipVideo, setMembershipVideo] = useState(null);
	const [membershipVideoBlobPreview, setMembershipVideoBlobPreview] = useState(null);
	const [selectedExplicitContent, setSelectedExplicitContent] = useState([]);
	const [selectedExplicitAccess, setSelectedExplicitAccess] = useState([]);
	const [contractAddressDetails, setContractAddressDetails] = useState(null);
	const [modalOpenExplicitContent, setModalOpenExplicitContent] = useState(false);
	const [modalOpenExplicitAccess, setModalOpenExplicitAccess] = useState(false);
	const [formIsOnFocusMembership, setFormIsOnFocusMembership] = useState(false);
	const [formHasErrorMembership, setFormHasErrorMembership] = useState(true);
	// const [addCreateBenefit, setAddCreateBenefit] = useState('default');
	const [photoIsRemoved, setPhotoIsRemoved] = useState(false);
	const [videoIsRemoved, setVideoIsRemoved] = useState(false);

	const [membershipImageError, setMembershipImageError] = useState(null);
	const [membershipVideoError, setMembershipVideoError] = useState(null);

	const [enableNumericCount, setEnableNumericCount] = useState(false);
	const [membershipName, setMembershipName] = useState(null);
	const [membershipSummary, setMembershipSummary] = useState(null);
	const [membershipListingText, setMembershipListingText] = useState(null);
	const [membershipDescription, setMembershipDescription] = useState(null);
	const [membershipSKUShortcode, setMembershipSKUShortcode] = useState(null);
	const [membershipIndividualTitling, setMembershipIndividualTitling] = useState(null);
	const [membershipPurchasePrice, setMembershipPurchasePrice] = useState(null);
	const [membershipTotalAvailable, setMembershipTotalAvailable] = useState(null);
	const [membershipAccountLimit, setMembershipAccountLimit] = useState(null);
	const [membershipIncludedMonths, setMembershipIncludedMonths] = useState(null);
	const [membershipOneMonth, setMembershipOneMonth] = useState(null);
	const [membershipThreeMonths, setMembershipThreeMonths] = useState(null);
	const [membershipSixMonths, setMembershipSixMonths] = useState(null);
	const [membershipTwelveMonths, setMembershipTwelveMonths] = useState(null);
	const [membershipPurchasePassword, setMembershipPurchasePassword] = useState(null);

	const [selectedAccessType, setSelectedAccessType] = useState('has_no_explicit');
	const [selectedPricingType, setSelectedPricingType] = useState('default');
	const [selectedCurrencyType, setSelectedCurrencyType] = useState('USD');
	const [selectedContentType, setSelectedContentType] = useState('has_no_explicit');
	const [selectedVisibilityType, setSelectedVisibilityType] = useState('public');

	const toolbarOptions = [
		['bold', 'italic', 'underline', 'strike'], // toggled buttons
		[{size: ['small', false, 'large', 'huge']}], // custom dropdown
		[{font: []}],
		[{header: 1}, {header: 2}], // custom button values
		[{header: [1, 2, 3, 4, 5, 6, false]}],
		['blockquote', 'code-block'],
		['link', 'image', 'video', 'formula'],
		[{list: 'ordered'}, {list: 'bullet'}],
		[{script: 'sub'}, {script: 'super'}], // superscript/subscript
		[{indent: '-1'}, {indent: '+1'}], // outdent/indent
		[{direction: 'rtl'}], // text direction
		[{color: []}, {background: []}], // dropdown with defaults from theme
		[{align: []}],
		['clean'] // remove formatting button
	];

	const setImageUploadErrors = (error) => {
		setMembershipImageError(error?.message)
	}
	const setVideoUploadErrors = (error) => {
		setMembershipVideoError(error?.message)
	}

	useEffect(() => {
		editMembershipForm.setFieldValue('currency', 'USD');
	});

	const onChangeEnableExpiration = (e) => {
		setExpirationChecked(e.target.checked);
	};


	const onChangeEnableGifting = (e) => {
		setGiftingChecked(e.target.checked);
	};

	const onChangeEnableAddNumeric = (e) => {
		setAddNumericChecked(e.target.checked);
	};

	const handleMembershipImageUploadFileChange = (file, blobPreview) => {
		if (!file) {
			setPhotoIsRemoved(true);
		} else {
			setPhotoIsRemoved(false);
		}

		setMembershipImage(file);
		setMembershipImageBlobPreview(blobPreview);
	};

	const handleMembershipVideoUploadFileChange = (file, blobPreview) => {
		if (!file) {
			setVideoIsRemoved(true);
		} else {
			setVideoIsRemoved(false);
		}

		setMembershipVideo(file);
		setMembershipVideoBlobPreview(blobPreview);
	};

	const openContentTypeModal = useCallback(
		async (value) => {
			setSelectedContentType(value);
			if (value === 'has_explicit') {
				setModalOpenExplicitContent(true);
			} else {
				setSelectedExplicitContent([]);
			}
		},
		[selectedExplicitContent]
	);

	const openAccessTypeModal = useCallback(
		async (value) => {
			setSelectedAccessType(value);
			if (value === 'has_explicit') {
				setModalOpenExplicitAccess(true);
			} else {
				setSelectedExplicitAccess([]);
			}
		},
		[selectedExplicitAccess]
	);

	// const handleSubmit = useCallback(
	// 	async (values) => {
	// 		setMembershipImageError(null);
	//
	// 		let hasError = false;
	//
	// 		if (selectedContentType === 'default' || selectedAccessType === 'default') {
	// 			hasError = true;
	// 		}
	//
	// 		if (!membershipImageBlobPreview) {
	// 			setMembershipImageError('Membership Image is required.');
	// 			hasError = true;
	// 		}
	//
	// 		if (hasError) {
	// 			window.scrollTo(0, 0);
	// 			return;
	// 		}
	//
	// 		setSubmitted(true);
	// 		const data = values;
	// 		data.membership_id = membershipData.id;
	// 		data.currency = 'USD';
	// 		data.type = MEMBERSHIP_TYPES.COMMUNITY;
	// 		data.expiration_subscription_enabled = enableExpirationChecked;
	// 		data.enable_add_numeric_count = enableAddNumericChecked;
	// 		data.enable_gifting = enableGiftingChecked;
	// 		data.content_type = selectedContentType;
	// 		data.content_access = selectedAccessType;
	// 		data.visibility = selectedVisibility;
	// 		data.explicit_content = JSON.stringify(selectedExplicitContent);
	// 		data.explicit_access = JSON.stringify(selectedExplicitAccess);
	//
	// 		if (membershipImage) {
	// 			data.membership_image = membershipImage;
	// 		} else {
	// 			if (photoIsRemoved) {
	// 				data.photo_is_empty = true;
	// 			}
	// 		}
	//
	// 		if (membershipVideo) {
	// 			data.membership_video = membershipVideo;
	// 		} else {
	// 			if (videoIsRemoved) {
	// 				data.video_is_empty = true;
	// 			}
	// 		}
	//
	// 		try {
	// 			if (authUserInfo) {
	// 				data.user_id = authUserInfo.id;
	// 				data.space_id = spaceInfo.id;
	//
	// 				const response = await updateSpaceMembership(data);
	// 				if (response && response.result) {
	// 					setAlert({
	// 						variant: 'success',
	// 						message: response.message
	// 					});
	// 					setDisplayDropDownError(false);
	// 					setSubmitted(false);
	//
	// 					// if (enableExpirationChecked) {
	// 					// 	const route = routes.addMemMonthBenefit();
	// 					// 	const url = route.replace(':space_username', '@' + spaceInfo.username);
	// 					// 	const fin_url = url.replace(':membership_id', response.membership.id);
	// 					// 	console.log('finurl_true: '+fin_url);
	// 					// 	window.location.replace(fin_url);
	// 					// } else {
	// 					// 	const route = routes.manageMembership();
	// 					// 	const url = route.replace(':space_username', '@' + spaceInfo.username);
	// 					// 	const fin_url = url.replace(':membership_id', response.membership.id);
	// 					// 	console.log('finurl_false: '+fin_url);
	// 					// 	window.location.replace(fin_url);
	// 					// }
	// 				} else {
	// 					setAlert({
	// 						variant: 'danger',
	// 						message: response.message
	// 					});
	// 					setSubmitted(false);
	// 				}
	//
	// 				setSubmitted(false);
	// 			}
	// 		} catch (error) {
	// 			console.log(error);
	// 			setSubmitted(false);
	// 		}
	// 	},
	// 	[
	// 		authUserInfo,
	// 		spaceInfo,
	// 		contractAddressDetails,
	// 		enableExpirationChecked,
	// 		enableAddNumericChecked,
	// 		enableGiftingChecked,
	// 		selectedContentType,
	// 		selectedAccessType,
	// 		// selectedVisibility,
	// 		membershipImage,
	// 		membershipVideo,
	// 		photoIsRemoved,
	// 		videoIsRemoved,
	// 		selectedExplicitAccess,
	// 		selectedExplicitContent,
	// 		membershipImageBlobPreview
	// 	]
	// );

	const handleSubmit = useCallback(async (values) => {
			setSubmitted(true);
			setAlert(null);
			setDisplayDropDownError(true);
			setMembershipImageError(null);

			let hasError = false;

			if (!selectedContentType || selectedContentType === 'default' ||
				!selectedAccessType || selectedAccessType === 'default' ||
				!selectedPricingType || selectedPricingType === 'default' ||
				!selectedCurrencyType || selectedCurrencyType === 'default') {
				hasError = true;
			}

			if (!membershipDescription || membershipDescription ==='default') {
				hasError = true;
			}

			if (!membershipImageBlobPreview) {
				setMembershipImageError('Membership Image is required.');
				hasError = true;
			}


			// if (!contractAddress) {
			// 	setCollectionAlert({
			// 		variant: 'danger',
			// 		message: 'Collection contract address not found.'
			// 	});
			// 	hasError = true;
			// }

			if (hasError) {
				setSubmitted(false);
				return;
			}

			const data = values;
			// data.type = MEMBERSHIP_TYPES.NFT_COLLECTION;
			data.type = MEMBERSHIP_TYPES.COMMUNITY;
			// data.collection_contract_address = contractAddress;
			data.name = membershipName;
			data.listing_text = membershipListingText;
			data.summary = membershipSummary;
			data.description = membershipDescription;
			data.sku_shortcode = membershipSKUShortcode;
			data.individual_title = membershipIndividualTitling;
			data.max_per_account = membershipAccountLimit;
			data.max_supply = membershipTotalAvailable;
			data.expiration_subscription_enabled = selectedPricingType === 'recurring';
			data.content_type = selectedContentType;
			data.content_access = selectedAccessType;
			data.explicit_content = JSON.stringify(selectedExplicitContent);
			data.explicit_access = JSON.stringify(selectedExplicitAccess);
			data.visibility = selectedVisibilityType;
			data.currency = selectedCurrencyType;
			data.is_include_benefit = selectedPricingType === 'recurring';
			data.enable_add_numeric_count = enableNumericCount;
			data.pricing_type = selectedPricingType;

			data.price = membershipPurchasePrice;
			data.monthly_price = !membershipOneMonth ? null : membershipOneMonth;
			data.three_month_renewal = !membershipThreeMonths ? null : membershipThreeMonths;
			data.six_month_renewal = !membershipSixMonths ? null : membershipSixMonths;
			data.twelve_month_renewal = !membershipTwelveMonths ? null : membershipTwelveMonths;
			data.included_months = membershipIncludedMonths;
			data.password = membershipPurchasePassword;

			if (membershipImage) {
				data.membership_image = membershipImage;
			} else {
				if (photoIsRemoved) {
					data.photo_is_empty = true;
				}
			}

			if (membershipVideo) {
				data.membership_video = membershipVideo;
			} else {
				if (videoIsRemoved) {
					data.video_is_empty = true;
				}
			}

			try {
				if (authUserInfo) {
					data.user_id = authUserInfo.id;
					data.space_id = spaceInfo.id;
					if (membershipID) {
						data.membership_id = membershipID;
					}
					if (benefitID) {
						data.benefit_id = benefitID;
					}
					const response = await updateSpaceMembership(data);
					if (response && response.result) {
						setAlert({variant: 'success', message: response.message});
						setDisplayDropDownError(false);
						setSubmitted(false);
						setTimeout(() => {
							setAlert(null);
						}, 2500);
					} else {
						setSubmitted(false);
						setAlert({
							variant: 'danger',
							message: response.message
						});
						setTimeout(() => {
							setAlert(null);
						}, 2500);
						setDisplayDropDownError(false);
					}

				}
			} catch (error) {
				setSubmitted(false);
				console.log(error);
			}
		},
		[
			authUserInfo,
			spaceInfo,
			enableExpirationChecked,
			membershipID,
			benefitID,
			membershipImage,
			membershipVideo,
			selectedContentType,
			selectedAccessType,
			selectedPricingType,
			selectedCurrencyType,
			enableNumericCount,
			selectedVisibilityType,
			photoIsRemoved,
			videoIsRemoved,
			selectedExplicitAccess,
			selectedExplicitContent,
			membershipName,
			membershipSummary,
			membershipListingText,
			membershipDescription,
			membershipSKUShortcode,
			membershipIndividualTitling,
			membershipTotalAvailable,
			membershipAccountLimit,
			membershipImageBlobPreview,
			membershipPurchasePrice,
			membershipDescription,
			membershipOneMonth,
			membershipThreeMonths,
			membershipSixMonths,
			membershipTwelveMonths,
			membershipIncludedMonths,
			membershipPurchasePassword,
			displayDropDownError
		]
	);

	const handleMembershipFormOnBlur = useCallback(async () => {
		await editMembershipForm
			.validateFields()
			.then(async () => {
				setFormHasErrorMembership(false);
			})
			.catch((errors) => {
				// console.error('Validation errors:', errors);
				setFormHasErrorMembership(true);
			});
	}, []);

	// const handleFormMembershipChanges = useCallback(async () => {
	// 	await editMembershipForm
	// 		.validateFields()
	// 		.then(async () => {
	// 			setFormHasErrorMembership(false);
	// 		})
	// 		.catch((errors) => {
	// 			setFormHasErrorMembership(true);
	// 		});
	// }, []);

	const redirectToMembershipUpdateBenefitLink = useCallback(
		(membership) => {
			if (spaceInfo) {
				if (membership.no_first_benefit) {
					const route = routes.addMemMonthBenefit();
					const url = route.replace(':space_username', '@' + spaceInfo.username);
					return url.replace(':membership_id', membership.id);
				} else {
					const route = routes.editMemMonthBenefit();
					let url = route.replace(':space_username', '@' + spaceInfo.username);
					url = url.replace(':benefit_id', membership.first_benefit.id);
					return url.replace(':membership_id', membership.id);
				}
			}
			return '';
		},
		[spaceInfo]
	);

	const onChangePricingType = useCallback(
		async (value) => {
			setSelectedPricingType(value);
			if (value === 'free') {
				setMembershipPurchasePrice('0.00');
				editMembershipForm.setFieldValue('price', '0.00');
			} else {
				if (membershipData) {
					let purchasePrice = '0.00';
					if (membershipData.pricing_type === 'one-time') {
						purchasePrice = membershipData.price && membershipData.price > 0 ? parseInt(membershipData.price, 10) : '';
					} else if (membershipData.pricing_type === 'recurring') {
						purchasePrice = membershipData.price && membershipData.price >= 0 ? parseInt(membershipData.price, 10) : '';
					}
					setMembershipPurchasePrice(!purchasePrice ? null : purchasePrice);
					editMembershipForm.setFieldValue('price', purchasePrice);
				}
			}
		},
		[membershipPurchasePrice, membershipData, selectedPricingType, displayDropDownError]
	);

	const onChangeCurrencyType = useCallback(
		async (value) => {
			setSelectedCurrencyType(value);
		},
		[]
	);

	const onChangeEnableNumericCount = (e) => {
		setEnableNumericCount(e.target.checked);
	};

	const handleTotalAvailableChange = (e) => {
		const value = e.target.value;
		if (!isNaN(value) && value >= 0) {
			setMembershipTotalAvailable(value);
		}
	};

	const preventNegativeInputRegex = (input) => { const pattern = /^(0|[1-9][0-9]*)$/; return pattern.test(input);}

	const preventNegativeInput = (e) => {
		const inputValue = e.target.value;
		const nextValue = inputValue + e.key;
		if (['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'].includes(e.key)) { return; }
		if (!preventNegativeInputRegex(nextValue)) { e.preventDefault(); }
	}

	const handleMonthlyPriceOnBlur = (e, monthlyType) => {
		const inputValue = e.target.value;
		// This regex pattern matches numbers that end with a period.
		// ^       : Asserts the start of the string
		// \d+     : Matches one or more digits (0-9)
		// \.      : Matches a literal period (dot)
		// $       : Asserts the end of the string
		const isValid = /^\d+\.$/.test(inputValue);
		if (isValid) {
			// Append '00' to make it a valid decimal number with two decimal places
			const correctedValue = inputValue + '00';

			if (monthlyType === RenewalOptions.monthly) {
				setMembershipOneMonth(correctedValue);
				editMembershipForm.setFieldValue('one_month_renewal', correctedValue);
			} else if (monthlyType === RenewalOptions.quarterly) {
				setMembershipThreeMonths(correctedValue);
				editMembershipForm.setFieldValue('three_month_renewal', correctedValue);
			} else if (monthlyType === RenewalOptions.biannually) {
				setMembershipSixMonths(correctedValue);
				editMembershipForm.setFieldValue('six_month_renewal', correctedValue);
			} else if (monthlyType === RenewalOptions.annually) {
				setMembershipTwelveMonths(correctedValue);
				editMembershipForm.setFieldValue('twelve_month_renewal', correctedValue);
			}

		}
	};

	const handlePurchasePriceOnBlur = (e) => {
		const inputValue = e.target.value;
		// This regex pattern matches numbers that end with a period.
		// ^       : Asserts the start of the string
		// \d+     : Matches one or more digits (0-9)
		// \.      : Matches a literal period (dot)
		// $       : Asserts the end of the string
		const isValid = /^\d+\.$/.test(inputValue);
		if (isValid) {
			// Append '00' to make it a valid decimal number with two decimal places
			const correctedValue = inputValue + '00';
			setMembershipPurchasePrice(correctedValue);
			editMembershipForm.setFieldValue('price', correctedValue);
		}
	};

	const preventPurchasePriceNegativeAndZeroInput = (e) => {
		// Get the current input value
		const inputValue = e.target.value + e.key;
		if (['Backspace', 'Delete'].includes(e.key)) {
			return;
		}
		// This regex validates input values to allow positive whole numbers and floating-point numbers
		// with up to two decimal places, but it does not allow zero.
		// Explanation:
		// ^                    : Start of the string
		// [1-9]\d*             : Matches positive whole numbers excluding leading zeros and zero itself
		// (\.\d{0,2})?         : Optionally matches a decimal point followed by zero, one, or two digits
		// $                    : End of the string
		const isValidNotAllowZeroNumber = /^[1-9]\d*(\.\d{0,2})?$/.test(inputValue);
		if ((!selectedPricingType || selectedPricingType === 'default' || selectedPricingType === 'one-time') && !isValidNotAllowZeroNumber) {
			e.preventDefault();
		}
		// This regex validates input values to allow zero, positive whole numbers, and floating-point numbers
		// with up to two decimal places.
		// Explanation:
		// ^                  : Start of the string
		// (0|[1-9]\d*)       : Matches zero or positive whole numbers excluding leading zeros
		// (\.\d{0,2})?       : Optionally matches a decimal point followed by zero, one, or two digits
		// $                  : End of the string
		const isValidAllowZeroNumber = /^(0|[1-9]\d*)(\.\d{0,2})?$/.test(inputValue);
		if (selectedPricingType === 'recurring' && !isValidAllowZeroNumber) {
			e.preventDefault();
		}
	}

	const handlePurchasePriceNegativeAndZeroInput = (e) => {
		// Get the current input value
		const inputValue = e.target.value;
		// This regex validates input values to allow positive whole numbers and floating-point numbers
		// with up to two decimal places, but it does not allow zero.
		// Explanation:
		// ^                    : Start of the string
		// [1-9]\d*             : Matches positive whole numbers excluding leading zeros and zero itself
		// (\.\d{0,2})?         : Optionally matches a decimal point followed by zero, one, or two digits
		// $                    : End of the string
		const isValidNotAllowZeroNumber = /^[1-9]\d*(\.\d{0,2})?$/.test(inputValue);
		if (selectedPricingType === 'one-time'){
			if (isValidNotAllowZeroNumber) {
				setMembershipPurchasePrice(inputValue);
				editMembershipForm.setFieldValue('price', inputValue);
			} else {
				setMembershipPurchasePrice('');
				editMembershipForm.setFieldValue('price', '');
			}
		} else {
			setMembershipPurchasePrice(inputValue);
			editMembershipForm.setFieldValue('price', inputValue);
		}
		handleMembershipFormOnBlur().then(() => {});
	}


	const preventMonthlyPriceNegativeInput = (e) => {
		// Get the current input value
		const inputValue = e.target.value + e.key;
		if (['Backspace', 'Delete'].includes(e.key)) {
			return;
		}
		// This regex validates input values to allow positive whole numbers and floating-point numbers
		// with up to two decimal places, but it does not allow zero.
		// Explanation:
		// ^                    : Start of the string
		// [1-9]\d*             : Matches positive whole numbers excluding leading zeros and zero itself
		// (\.\d{0,2})?         : Optionally matches a decimal point followed by zero, one, or two digits
		// $                    : End of the string
		const isValidNotAllowZeroNumber = /^[1-9]\d*(\.\d{0,2})?$/.test(inputValue);
		if (!isValidNotAllowZeroNumber) {
			e.preventDefault();
		}
	}

	const preventSpaceInput = (e) => {
		// Get the current input value
		// const inputValue = e.target.innerText;
		const inputValue = e.target.innerHTML;
		const maxWords = 2400;
		// Count the current number of words
		if (inputValue) {
			const wordCount = inputValue.split(/\s+/).filter(word => word.length > 0).length;
			// console.log(e.key)
			// console.log(wordCount)
			// Prevent specific keys and space if word count is 2400 or more
			if (wordCount > maxWords && e.key !== 'Backspace') {
				e.preventDefault();
			}
		}
	};

	const preventIncludedMonthsInvalidInput = (e) => {
		// Get the current input value
		const inputValue = e.target.value + e.key;
		if (['Backspace', 'Delete'].includes(e.key)) {
			return;
		}
		// This regex validates input values to allow numbers from 1 to 23 inclusive,
		// but it does not allow zero or numbers outside this range.
		// ^          : Asserts the start of the string
		// 1?[1-9]    : Matches numbers 1-9 and 10-19
		//    1?      : Matches an optional '1' (for numbers 1-19)
		//    [1-9]   : Matches digits 1 through 9 (for 1-9 and 11-19)
		// 2[0-3]     : Matches numbers 20-23
		//    2       : Matches the digit '2'
		//    [0-3]   : Matches digits 0 through 3
		// $          : Asserts the end of the string
		const isValidNotAllowZeroNumber = /^(1?[1-9]|2[0-3])$/.test(inputValue);
		if (!isValidNotAllowZeroNumber) {
			e.preventDefault();
		}
	}

	const handleDescriptionChange = (content, delta, source, editor) => {
		const maxWords = 2400;
		const innerText = editor.getText(); // Get plain text from the editor
		// const words = innerText.split(/\s+/).filter(word => word.length > 0);
		const words = content.split(/\s+/).filter(word => word.length > 0);
		const wordCount = words.length;
		// console.log(content);
		// console.log(innerText);
		// console.log(wordCount);
		if ('<p><br></p>' === content) {
			// Allow clearing the editor
			editMembershipForm.setFieldValue('description', '');
			setMembershipDescription(null);
		} else {
			if (wordCount <= maxWords) {
				setMembershipDescription(content);
				editMembershipForm.setFieldValue('description', content);
			}
		}
		handleFormMembershipChanges().then(r => {});
	};

	const handleAccountLimitChange = (e) => {
		const value = e.target.value;
		if (!isNaN(value) && value >= 0) {
			setMembershipAccountLimit(value);
		}
	};

	let timeoutId;
	const shortcodeTimeout = (value) => {
		if (timeoutId) {
			clearTimeout(timeoutId); // Clear the previous timeout if it exists
		}

		timeoutId = setTimeout(async () => {
			const data = {
				shortcode: value
			};
			let available = false;
			if (spaceInfo && membershipID) {
				data['space_id'] = spaceInfo.id;
				data['membership_id'] = membershipID;
				const response = await checkMembershipShortCodeAvailability(data);
				if (response && response.result) {
					available = response.availability;
				}
			}
			if (!available) {
				editMembershipForm.setFields([ { name: 'sku_shortcode', errors: ['Shortcode not available.'], }, ]);
			}
		}, 2000);
	};



	// const redirectToMembershipAddBenefitLink = useCallback(
	// 	() => {
	// 		const route = routes.addMemMonthBenefit();
	// 		if (spaceInfo && addCreateBenefit === 'add_benefit' || addCreateBenefit === 'create_benefit') {
	// 			let url = route.replace(':space_username', '@' + spaceInfo.username);
	// 			return url.replace(':membership_id', membershipData.id);
	// 		}
	// 		return '';
	// 	},
	// 	[spaceInfo, membershipData, addCreateBenefit]
	// );

	useEffect(() => {
		// console.log(membershipIncludedMonths);
	}, [membershipImageError, formHasErrorMembership, membershipIncludedMonths]);

	useEffect(() => {
		if (membershipData) {
			setFormData(membershipData).then((r) => {});
		}
	}, [authUserInfo, spaceInfo, spaceInfoLoading, membershipData, benefitData]);

	const setFormData = useCallback(async (membershipData) => {
		if (membershipData) {
			const firstBenefit = membershipData.first_benefit;

			let description = membershipData.description;
			if ('<p><br></p>' === description) {
				setMembershipDescription(null);
				description = null;
			} else {
				setMembershipDescription(description);
			}
			let purchasePrice = '0.00';
			if (membershipData?.pricing_type === 'one-time') {
				purchasePrice = membershipData.price && membershipData.price > 0 ? parseInt(membershipData.price, 10) : '';
			} else if (membershipData?.pricing_type === 'recurring') {
				purchasePrice = membershipData.price && membershipData.price >= 0 ? parseInt(membershipData.price, 10) : '';
			}
			setMembershipID(membershipData.id);
			editMembershipForm.setFieldsValue({
				name: membershipData.name,
				listing_text: membershipData.listing_text,
				summary: membershipData.summary,
				sku_shortcode: membershipData.sku_shortcode,
				individual_title: membershipData.individual_title,
				description: description,
				// price: firstMembership.benefits.length > 0 ? firstMembership.benefits[0].price : null,
				price: purchasePrice,
				max_supply: membershipData.max_supply,
				max_per_account: membershipData.max_per_account,
				royalty_percentage: membershipData.royalty_percentage,
				included_months: firstBenefit?.included_months ?? 1
			});
			setMembershipName(membershipData.name);
			setMembershipListingText(
				membershipData.listing_text ? membershipData.listing_text : ''
			);
			setMembershipSummary(membershipData.summary ? membershipData.summary : '');
			setMembershipSKUShortcode(membershipData.sku_shortcode);
			setMembershipTotalAvailable(membershipData.max_supply);
			setExpirationChecked(membershipData.enable_expiration_and_subscription);
			setEnableNumericCount(membershipData.enable_add_numeric_count);
			setMembershipIndividualTitling(membershipData.individual_title);
			setMembershipAccountLimit(membershipData.max_per_account);

			setSelectedContentType(membershipData.content_type);
			setSelectedAccessType(membershipData.content_access);
			setSelectedExplicitContent(JSON.parse(membershipData.explicit_content));
			setSelectedExplicitAccess(JSON.parse(membershipData.explicit_access));
			setSelectedVisibilityType(membershipData.visibility);
			setSelectedPricingType(membershipData?.pricing_type ? membershipData?.pricing_type : 'default');
			setSelectedCurrencyType(membershipData.currency ?  membershipData.currency : 'USD');
			setMembershipPurchasePrice(purchasePrice);


			if (firstBenefit) {
				setMembershipIncludedMonths(firstBenefit.included_months ? parseInt(firstBenefit.included_months) : null);
				setMembershipOneMonth(firstBenefit.monthly_price ? parseInt(firstBenefit.monthly_price, 10) : null);
				setMembershipThreeMonths(firstBenefit.three_month_renewal ? parseInt(firstBenefit.three_month_renewal, 10) : null);
				setMembershipSixMonths(firstBenefit.six_month_renewal ? parseInt(firstBenefit.six_month_renewal, 10) : null);
				setMembershipTwelveMonths(firstBenefit.twelve_month_renewal ? parseInt(firstBenefit.twelve_month_renewal, 10): null);
				setBenefitID(firstBenefit.id);
			}

			// setMembershipPurchasePassword(firstMembership.password);

			if (membershipData.photo) {
				if (!photoIsRemoved) {
					setMembershipImageBlobPreview(membershipData.photo);
				}
			}
			if (membershipData.video) {
				if (!videoIsRemoved) {
					setMembershipVideoBlobPreview(membershipData.video);
				}
			}

			await editMembershipForm
				.validateFields()
				.then(async () => {
					setFormHasErrorMembership(false);
				})
				.catch((errors) => {
					console.error('Validation errors:', errors);
					setFormHasErrorMembership(true);
				});
		}
		// editMembershipForm.setFieldsValue({
		// 	name: data.name,
		// 	sku_shortcode: data.sku_shortcode,
		// 	max_per_account: data.max_per_account,
		// 	max_supply: data.max_supply,
		// 	summary: data.summary,
		// 	listing_text: data.listing_text,
		// 	price: data.price,
		// 	individual_title: data.individual_title,
		// 	description: data.description,
		// 	royalty_percentage: data.royalty_percentage
		// });
		//
		// if (data.visibility) {
		// 	setSelectedVisibility(data.visibility);
		// } else {
		// 	setSelectedVisibility('custom');
		// }
		//
		// let content_type = data.content_type;
		// if (!data.content_type) {
		// 	content_type = 'default';
		// }
		// setSelectedContentType(content_type);
		//
		// let content_access = data.content_access;
		// if (!data.content_access) {
		// 	content_access = 'default';
		// }
		// setSelectedAccessType(content_access);
		//
		// if (data.explicit_content !== null) {
		// 	setSelectedExplicitContent(JSON.parse(data.explicit_content));
		// }
		// if (data.explicit_access !== null) {
		// 	setSelectedExplicitAccess(JSON.parse(data.explicit_access));
		// }
		//
		// setExpirationChecked(data.enable_expiration_and_subscription);
		// setAddNumericChecked(data.enable_add_numeric_count);
		// setGiftingChecked(data.enable_gifting);
		// if (data.photo) {
		// 	setMembershipImageBlobPreview(data.photo);
		// }
		// if (data.video) {
		// 	setMembershipVideoBlobPreview(data.video);
		// }
		// handleFormMembershipChanges();
		},
		[
			photoIsRemoved,
			videoIsRemoved,
			membershipName,
			membershipSummary,
			membershipListingText,
			membershipDescription,
			membershipSKUShortcode,
			membershipIndividualTitling,
			membershipPurchasePrice,
			membershipTotalAvailable,
			membershipAccountLimit,
			membershipOneMonth,
			membershipThreeMonths,
			membershipSixMonths,
			membershipTwelveMonths,
			membershipPurchasePassword,
			membershipIncludedMonths
		]
	);

	// Regex to allow numbers with up to 2 decimal places if (valid) { setValue(value); } };
	const handleMonthlyChange = (value, monthlyOptions) => {
		const valid = /^\d*\.?\d{0,2}$/.test(value);
		if (valid) {
			if (RenewalOptions.monthly === monthlyOptions) {
				setMembershipOneMonth(value);
			} else if (RenewalOptions.quarterly === monthlyOptions) {
				setMembershipThreeMonths(value);
			} else if (RenewalOptions.biannually === monthlyOptions) {
				setMembershipSixMonths(value);
			} else if (RenewalOptions.annually === monthlyOptions) {
				setMembershipTwelveMonths(value);
			}
		}
	};

	const handleFormMembershipChanges = useCallback(async () => {
		setMembershipName(editMembershipForm.getFieldValue('name'));
		setMembershipSummary(editMembershipForm.getFieldValue('summary'));
		setMembershipListingText(editMembershipForm.getFieldValue('listing_text'));
		// setMembershipSKUShortcode(editMembershipForm.getFieldValue('sku_shortcode'));
		setMembershipIndividualTitling(editMembershipForm.getFieldValue('individual_title'));
		// setMembershipPurchasePrice(editMembershipForm.getFieldValue('price'));
		// setMembershipTotalAvailable(editMembershipForm.getFieldValue('max_supply'));
		// setMembershipAccountLimit(editMembershipForm.getFieldValue('max_per_account'));
		setMembershipPurchasePassword(editMembershipForm.getFieldValue('password'));
		setMembershipIncludedMonths(editMembershipForm.getFieldValue('included_months'));
	}, [
		membershipName,
		membershipSummary,
		membershipListingText,
		membershipDescription,
		membershipSKUShortcode,
		membershipIndividualTitling,
		membershipPurchasePrice,
		membershipTotalAvailable,
		membershipAccountLimit,
		membershipOneMonth,
		membershipThreeMonths,
		membershipSixMonths,
		membershipTwelveMonths,
		membershipPurchasePassword,
		membershipIncludedMonths
	]);

	return (
		<>
			<div className="block-full card">
				<div className="memberships">
					<div className="dashboard-wrapper">
						<div className="grid grid-cols-12 gap-8">
							<div className="col-span-12">
								<h2 className="header-medium">Editing {membershipData.name}</h2>
							</div>
						</div>
						<div className="grid grid-cols-12 gap-8 mt-5">
							<div className="col-span-12">
								<p className="body-text--small">
									You can edit your Membership Details here, however most changes may
									only apply to new sales and certain details may not be modifiable
									depending on your Membership Type and status.{' '}
									<a className="body-txtitalic--small gradient-color-txt">
										{getSitewideLink('learn_more_on_membership_and_editing_limits')}
										{/*Learn more about Membership Types and editing limits.*/}
									</a>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<main id="cs-platform-main" className="cs-main main-theme">
				<section id="cs-platform-create-basics-c1" className="p-section-safeview theme-transparent">
					<div className="centerframe-grid gap-2gutter">
						<div className="block-full">
							<div className="cs-stack-form-guided">
								<div className="formrow title">
									<div>
										<h3>Membership Product Details</h3>
									</div>
									<div>
										<p>Membership Details can be changed but some changes may only affect to new sales.</p>
									</div>
								</div>

								<Form
									form={editMembershipForm}
									name="editMembershipForm"
									id="editMembershipForm"
									validateMessages={VALIDATE_MESSAGES}
									onFocus={() => {
										setFormIsOnFocusMembership(true);
									}}
									onBlur={() => {
										setTimeout(() => {
											setFormIsOnFocusMembership(false);
											handleMembershipFormOnBlur().then(r => {});
										}, 200);
									}}
									onChange={handleFormMembershipChanges}
									onFinish={handleSubmit}
									className='cs-stack-form-guided'
								>

									<div className="formrow media">
										<div className="cs-stack-form-media stack-row">
											<div className="cs-stack-form-media-item">
												<UploadDraggerV2
													onError={setMembershipImageError}
													cropperAspectRatio={4 / 3}
													shape="square"
													hideDragIcon="true"
													hideText="true"
													uploadFileTypes={IMAGE_FILETYPES.toString()}
													uploadedFilePreview={membershipImageBlobPreview}
													onUploadFileChange={
														handleMembershipImageUploadFileChange
													}
													minHeight={IMAGE_LARGE_MIN_HEIGHT}
													minWidth={IMAGE_LARGE_MIN_WIDTH}
													maxHeight={IMAGE_LARGE_MAX_HEIGHT}
													maxWidth={IMAGE_LARGE_MAX_WIDTH}
													uploadSizeLimit={IMAGE_LARGE_FILE_UPLOAD_SIZE}
													disableCropper={true}
												/>
												<p className="cs-label">Membership Image</p>
												<p className="cs-helper">Up to 3840px JPEG, PNG, or GIF</p>
												{membershipImageError && (
													<div className="alert callout warning !w-full items-center">
														<div className="action">
															<i className="fa-solid fa-triangle-exclamation"></i>
														</div>
														<div className="content">
															<div>
																<h6>{membershipImageError}</h6>
															</div>
														</div>
													</div>
												)}
											</div>
											<div className="cs-stack-form-media-item">
												<UploadDraggerV2
													onError={setMembershipVideoError}
													hideDragIcon="true"
													hideText="true"
													shape="square"
													uploadFileTypes={VIDEO_FILETYPES.toString()}
													uploadedFilePreview={membershipVideoBlobPreview}
													onUploadFileChange={
														handleMembershipVideoUploadFileChange
													}
													minHeight={VIDEO_STANDARD_MIN_HEIGHT}
													minWidth={VIDEO_STANDARD_MIN_WIDTH}
													maxHeight={VIDEO_STANDARD_MAX_HEIGHT}
													maxWidth={VIDEO_STANDARD_MAX_WIDTH}
													uploadSizeLimit={VIDEO_STANDARD_FILE_UPLOAD_SIZE}
												/>
												<p className="cs-label">Membership Video</p>
												<p className="cs-helper">Optional up to 1920px/1080p MOV or MP4</p>
												{membershipVideoError && (
													<div className="alert callout warning !w-full items-center">
														<div className="action">
															<i className="fa-solid fa-triangle-exclamation"></i>
														</div>
														<div className="content">
															<div>
																<h6>{membershipVideoError}</h6>
															</div>
														</div>

													</div>
												)}
											</div>
										</div>
										<div className="cs-formrow-helper-split">
											<p>Your Membership Image (up to 4MB) is used on Product Pages and previews. A square format is preferred.</p>
											<p>You can add an optional Membership Video (up to 12MB) which appears in limited pages. Optional.</p>
											{/*<p>Your Membership Image (up to 8MB) should be as high*/}
											{/*	resolution as possible, up to 3840px height and width. This*/}
											{/*	will also be used for any thumbnails and previews.</p>*/}
											{/*<p>You can add a Membership Video (up to 12MB). This will be*/}
											{/*	viewable by Members when viewing their purchased Membership.*/}
											{/*	Optional.</p>*/}
										</div>
									</div>

									<Form.Item
										name="name"
										label="Membership Product Name"
										validateTrigger="onBlur"
										rules={[
											{required: true},
											{
												max: 32,
												message: 'Must not exceed 32 characters.'
											}
										]}
									>
										<div className="formrow">
											<div className="form-input air">
												<label htmlFor="text">Membership Product Name</label>
												<input id="name"
													   name="name"
													   type="text"
													   value={membershipName}
													   maxLength={32}
													   placeholder="Up to 32 characters, including spaces."
												/>
												{editMembershipForm.getFieldsError()[0]?.errors.length > 0 && (
													<div className="alert callout warning !w-full items-center">
														<div className="action">
															<i className="fa-solid fa-triangle-exclamation"></i>
														</div>
														<div className="content">
															<div>
																<h6>{editMembershipForm.getFieldsError()[0]?.errors}</h6>
															</div>
														</div>

													</div>
												)}
											</div>
											<div className="cs-formrow-helper-end">
												<p>This shows on the Membership Product Page and listing which people can purchase.</p>
											</div>
										</div>
									</Form.Item>

									<Form.Item
										name="summary"
										label="Summary"
										validateTrigger="onBlur"
										rules={[
											{required: true},
											{
												max: 128,
												message: 'Must not exceed 128 characters.'
											}
										]}
									>
										<div className="formrow">
											<div className="form-textarea air">
												<label htmlFor="text">Summary</label>
												<textarea id="summary" name="summary" maxLength={128} value={membershipSummary} placeholder="Up to 128 characters, including spaces."></textarea>
												{editMembershipForm.getFieldsError()[1]?.errors.length > 0 && (
													<div className="alert callout warning !w-full items-center">
														<div className="action">
															<i className="fa-solid fa-triangle-exclamation"></i>
														</div>
														<div className="content">
															<div>
																<h6>{editMembershipForm.getFieldsError()[1]?.errors}</h6>
															</div>
														</div>

													</div>
												)}
											</div>
											<div className="cs-formrow-textarea-helper">
												<p>This should be 1 to 2 sentences long and is shown on the Product page and purchased Memberships.</p>
											</div>
										</div>
									</Form.Item>

									<Form.Item
										name="listing_text"
										label="Listing Text"
										validateTrigger="onBlur"
										rules={[
											{required: true},
											{
												max: 128,
												message: 'Must not exceed 128 characters.'
											}
										]}
									>
										<div className="formrow">
											<div className="form-textarea air">
												<label htmlFor="text">Listing Text</label>
												<textarea id="listing_text" name="listing_text" value={membershipListingText} maxLength={128} placeholder="Up to 128 characters, including spaces."></textarea>
												{editMembershipForm.getFieldsError()[2]?.errors.length > 0 && (
													<div className="alert callout warning !w-full items-center">
														<div className="action">
															<i className="fa-solid fa-triangle-exclamation"></i>
														</div>
														<div className="content">
															<div>
																<h6>{editMembershipForm.getFieldsError()[2]?.errors}</h6>
															</div>
														</div>
													</div>
												)}
											</div>
											<div className="cs-formrow-textarea-helper">
												<p>This is marketing text for your Membership, it should be 1 to 2 sentences summarizing and promoting the Membership.</p>
											</div>
										</div>
									</Form.Item>

									<Form.Item
										name="description"
										label="Full Description"
										validateTrigger="onBlur"
										rules={[
											{ required: true },
											{ validator: (_, value) => {
													const tempDiv = document.createElement('div');
													tempDiv.innerHTML = value;
													const innerText = tempDiv.innerText ;
													// console.log(innerText)
													// Count the words
													const wordCount = innerText ? innerText.split(/\s+/).filter(word => word.length > 0).length : 0;
													// console.log(wordCount);
													if (wordCount < 2) {
														return Promise.reject(new Error('Must be minimum 2 words.'));
													} else if (wordCount > 2400) {
														return Promise.reject(new Error('Must not exceed 2400 words.'));
													} return Promise.resolve();
												},
											},
										]}
									>
										<div className="formrow">
											<div className="form-textarea air">
												<label htmlFor="description">Full Description</label>
												{/*<textarea id="description" name="description" rows="7"*/}
												{/*		  value={membershipDescription}*/}
												{/*		  required/>*/}
												<ReactQuill
													theme="snow"
													modules={{
														toolbar: toolbarOptions
													}}
													value={membershipDescription}
													onKeyDown={preventSpaceInput}
													onChange={handleDescriptionChange}
												/>
												{editMembershipForm.getFieldsError()[3]?.errors.length > 0 && (
													<div className="alert callout warning !w-full items-center">
														<div className="action">
															<i className="fa-solid fa-triangle-exclamation"></i>
														</div>
														<div className="content">
															<div>
																<h6>{editMembershipForm.getFieldsError()[3]?.errors[0]}</h6>
															</div>
														</div>

													</div>
												)}
											</div>
											<div className="cs-formrow-textarea-helper">
												<p>This displays on your Product page and on the details page of a purchased Membership. It should provides the full details about the Membership. You can format and add up to approximately 2,400 words.</p>
											</div>
										</div>
									</Form.Item>

									<Form.Item
										name="sku_shortcode"
										label="Shortcode"
										validateTrigger="onBlur"
										rules={[
											{required: true},
											{
												min: 2,
												message: 'Must be minimum 2 characters.'
											},
											{
												max: 8,
												message: 'Must not exceed 8 characters.'
											},
											({getFieldValue}) => ({
												async validator(_, value) {
													if (value) {
														shortcodeTimeout(value);

														if (value.length > 8 || value.length < 2) {
															return Promise.reject(
																new Error(
																	'Shortcode not available.'
																)
															);
														}
													}

													if (
														(value && value.match(/\ /g)) ||
														/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(
															value
														)
													) {
														return Promise.reject(
															new Error('Invalid Input')
														);
													}
													return Promise.resolve();
												}
											})
										]}
									>
										<div className="formrow">
											<div className="form-input air">
												<label htmlFor="text">Shortcode</label>
												<input id="sku_shortcode"
													   name="sku_shortcode"
													   value={membershipSKUShortcode}
													   onChange={(e)=>setMembershipSKUShortcode(e.target.value)}
													   type="text" placeholder="2 to 8 characters. Letters and numbers only, no spaces."
												/>
													{editMembershipForm.getFieldsError()[4]?.errors.length > 0 && (
														<div className="alert callout warning !w-full items-center">
															<div className="action">
																<i className="fa-solid fa-triangle-exclamation"></i>
															</div>
															<div className="content">
																<div>
																	<h6>{editMembershipForm.getFieldsError()[4]?.errors[0]}</h6>
																</div>
															</div>

														</div>
													)}
											</div>
											<div className="cs-formrow-helper-end">
												<p>Choose a unique ID based the Membership Name. This is used in transactions, support, and other areas.</p>
											</div>
										</div>
									</Form.Item>


									<div className="formrow title">
										<div>
											<h3>Individual Membership Details</h3>
										</div>
										<div>
											<p>Set details for individual Memberships when purchased.</p>
										</div>
									</div>

									<Form.Item
										name="individual_title"
										label="Individual Titling"
										validateTrigger="onBlur"
										rules={[
											{required: true},
											{
												max: 32,
												message: 'Must not exceed 32 characters.'
											}
										]}
									>
										<div className="formrow">
											<div className="form-input air">
												<label htmlFor="text">Individual Titling</label>
												<input
													id="individual_title"
													name="individual_title"
													type="text"
													maxLength={32}
													placeholder="Up to 32 characters, including spaces."
													value={membershipIndividualTitling}
												/>
												{editMembershipForm.getFieldsError()[5]?.errors.length > 0 && (
													<div className="alert callout warning !w-full items-center">
														<div className="action">
															<i className="fa-solid fa-triangle-exclamation"></i>
														</div>
														<div className="content">
															<div>
																<h6>{editMembershipForm.getFieldsError()[5]?.errors[0]}</h6>
															</div>
														</div>

													</div>
												)}
											</div>
											<div className="cs-formrow-helper-end">
												<p>This is applied to individual Memberships when purchased. It can be the same or a variation of your Membership Product Name.</p>
											</div>
										</div>
									</Form.Item>

									<div className="formrow">
										<div className="form-input-checkbox gradient md rounded row w-full">
											<div className="checkbox">
												<input
													id="checkbox-cs-agreement"
													aria-describedby="checkbox-cs-agreement-description"
													name="checkbox-cs-agreement"
													type="checkbox"
													// required
													checked={enableNumericCount}
													onChange={onChangeEnableNumericCount}
												/>
											</div>
											<div>
												<label htmlFor="checkbox-cs-agreement" className="sr-only">Add Numeric Count</label>
												<p>Add Numeric Count to the Individual Title of each purchased Membership</p>
											</div>
										</div>
										<div>
											<p>Add a numeric count to each purchased Membership. For example, the first "Club Membership" sold would be “Club Membership #1”, the second “Club Membership #2”, and so on.</p>
										</div>
									</div>

									<div className="formrow title">
										<div>
											<h3>Pricing & Availability</h3>
										</div>
										<div>
											<p>Specify the type, pricing, and supply for this Membership.</p>
										</div>
									</div>

									<div className="formrow">
										{/*DROPDOWN MENU GRADIENT SET - START*/}
										<div className="dropdown-menu-set">
											{/*DROPDOWN MENU GRADIENT BUTTON - START*/}
											<DropdownWithItems
												title="Select Pricing Type"
												customButtonclassName="icon-dropdown-gradient-alt secondary"
												displayError={!selectedPricingType || selectedPricingType === 'default'}
												icon="fa-light fa-dollar"
												selectedValue={selectedPricingType}
												listItems={PRICING_TYPES_LIST}
												onDropdownChange={(value) => onChangePricingType(value)}
											/>
											{/*DROPDOWN MENU GRADIENT UL LI - MENU COMPONENT END*/}

										</div>
										{/*DROPDOWN MENU GRADIENT SET - END*/}

										<div>
											<p>Memberships can be Free, One-time Purchase, or Recurring Subscription. Any Memberships pricing changes will only apply to future purchases.</p>
										</div>
									</div>

									{/*CSJS Only display Currency Dropdown Row if One-time Purchase or Recurring Subscription option is chosen in previous dropdown.*/}
									<div className="formrow">
										{/*DROPDOWN MENU GRADIENT SET - START*/}
										<div className="dropdown-menu-set">

											{/*DROPDOWN MENU GRADIENT BUTTON - START*/}
											<DropdownWithItems
												title="Select Currency"
												customButtonclassName="icon-dropdown-gradient-alt secondary"
												displayError={displayDropDownError && selectedCurrencyType === 'default'}
												icon="fa-light fa-dollar"
												selectedValue={selectedCurrencyType}
												listItems={SELECT_CURRENCIES}
												onDropdownChange={(value) => onChangeCurrencyType(value)}
											/>
											{/*DROPDOWN MENU GRADIENT UL LI - MENU COMPONENT END*/}
										</div>
										{/*DROPDOWN MENU GRADIENT SET - END*/}

										<div>
											<p>Select the Currency for your Membership Price. Any purchases in a different currency will be converted based on market pricing at the time of purchase. <a href="link-via-admin" target="_blank">See officially supported major currencies and cryptocurrencies.</a></p>
										</div>
									</div>


									{/*CSJS Only display Purchase Price Form Row if One-time Purchase or Recurring Subscription option is chosen in previous dropdown.*/}
									{/*{selectedPricingType !== 'free' &&*/}
										<Form.Item
											name="price"
											label="Purchase Price"
											validateTrigger="onBlur"
											rules={[
												{required: true},
											]}
										>
											<div className="formrow">
												<div className="form-input air">
													<label htmlFor="text">Purchase Price</label>
													<input
														id="price"
														name="price"
														type="text"
														// required
														disabled={selectedPricingType === 'free'}
														placeholder="Enter numbers and a decimal only, up to 2 decimal places."
														value={membershipPurchasePrice}
														onChange={handlePurchasePriceNegativeAndZeroInput}
														onKeyDown={preventPurchasePriceNegativeAndZeroInput}
														onBlur={(e) => handlePurchasePriceOnBlur(e)}
														className={selectedPricingType === 'free' ? 'disabled-input' : ''}

													/>
													{editMembershipForm.getFieldsError()[6]?.errors.length > 0 && (
														<div className="alert callout warning !w-full items-center">
															<div className="action">
																<i className="fa-solid fa-triangle-exclamation"></i>
															</div>
															<div className="content">
																<div>
																	<h6>{editMembershipForm.getFieldsError()[6]?.errors[0]}</h6>
																</div>
															</div>
														</div>
													)}
												</div>
												<div className="cs-formrow-helper-end">
													<p>Set the upfront purchase price for your Membership. Paid Memberships have a $1 USD/¥100 JPY Minimum.</p>
												</div>
											</div>
										</Form.Item>
									{/*}*/}


									<div className="formrow">
										<div className="cs-stack-form-inputs stack-row">
											<Form.Item
												name="max_supply"
												label="Total Available"
												validateTrigger="onBlur"
												rules={[
													{ required: true },
												]}
											>
												<div className="form-input air">
													<label htmlFor="text">Total Available</label>
													<input
														id="max_supply"
														name="max_supply"
														type="text"
														// required
														onChange={handleTotalAvailableChange}
														placeholder="Enter 0 for unlimited."
														value={membershipTotalAvailable}
														onKeyDown={preventNegativeInput}
													/>
													{editMembershipForm.getFieldsError()[7]?.errors.length > 0 && (
														<div className="alert callout warning !w-full items-center">
															<div className="action">
																<i className="fa-solid fa-triangle-exclamation"></i>
															</div>
															<div className="content">
																<div>
																	<h6>{editMembershipForm.getFieldsError()[7]?.errors[0]}</h6>
																</div>
															</div>

														</div>
													)}
												</div>
											</Form.Item>

											<Form.Item
												name="max_per_account"
												label="Account Limit"
												validateTrigger="onBlur"
												rules={[
													{ required: true },
												]}
											>
												<div className="form-input air">
													<label htmlFor="text">Account Limit</label>
													<input
														id="max_per_account"
														name="max_per_account"
														type="text"
														// required
														placeholder="1 is recommended."
														value={membershipAccountLimit}
														onChange={handleAccountLimitChange}
														onKeyDown={preventNegativeInput}
													/>
													{editMembershipForm.getFieldsError()[8]?.errors.length > 0 && (
														<div className="alert callout warning !w-full items-center">
															<div className="action">
																<i className="fa-solid fa-triangle-exclamation"></i>
															</div>
															<div className="content">
																<div>
																	<h6>{editMembershipForm.getFieldsError()[8]?.errors[0]}</h6>
																</div>
															</div>

														</div>
													)}
												</div>
											</Form.Item>
										</div>
										<div className="cs-formrow-helper-split">
											<p>Enter “0” to offer unlimited availability for this Membership or any number to limit availability.</p>
											<p>Enter any number to set a limit on how many of this Membership can be purchased by a single account. Enter “0” for unlimited.</p>
										</div>
									</div>

									{/*CSJS Only display Subscription Details Title and Renewal Form Row if Recurring Subscription option is chosen in earlier dropdown.*/}

									{(selectedPricingType === 'recurring') &&
										<>
											<div className="formrow title">
												<div>
													<h3>Subscription Details</h3>
												</div>
												<div>
													<p>Configure your Subscription Pricing and Included Months here.</p>
												</div>
											</div>


											<Form.Item
												name="included_months"
												label="Months included with Purchase Price"
												validateTrigger="onBlur"
												rules={
												[
													{ required: true, message: 'Months included with Purchase Price is required.' },
													// {
													// 	type: 'number',
													// 	min: 1, max: 23,
													// 	message: 'Please enter a number between 1 and 23.'
													// },
												]}
											>
												<div className="formrow">
													<div className="form-input air">
														<label htmlFor="text">Months included with Purchase Price</label>
														<input
															id="included_months"
															name="included_months"
															type="text"
															placeholder="Enter 1 or more."
															value={membershipIncludedMonths}
															onKeyDown={preventIncludedMonthsInvalidInput}
														/>
														{editMembershipForm.getFieldsError()[9]?.errors.length > 0 && (
															<div className="alert callout warning !w-full items-center">
																<div className="action">
																	<i className="fa-solid fa-triangle-exclamation"></i>
																</div>
																<div className="content">
																	<div>
																		<h6>{editMembershipForm.getFieldsError()[9]?.errors[0]}</h6>
																	</div>
																</div>

															</div>
														)}
													</div>
													<div className="cs-formrow-helper-end">
														<p>Membership with Subscriptions must include at least 1 month included, after which it must be renewed.</p>
													</div>
												</div>
											</Form.Item>


											<div className="formrow">
												<div className="cs-stack-form-inputs stack-row cs-stack-form-input-quarters">
													<div className="stack-row">
														<Form.Item
															name="one_month_renewal"
															label="1 Month Renewal Price"
															validateTrigger="onBlur"
															
														>
															<div className="form-input air">
																<label htmlFor="text">1 Month Renewal Price</label>
																<input
																	id="one_month_renewal"
																	name="one_month_renewal"
																	type="text"
																	onChange={(e)=>handleMonthlyChange(e.target.value, RenewalOptions.monthly)}
																	placeholder="Enter numbers and a decimal only, up to 2 decimal places."
																	value={membershipOneMonth}
																	onKeyDown={preventMonthlyPriceNegativeInput}
																	onBlur={(e) => handleMonthlyPriceOnBlur(e, RenewalOptions.monthly)}
																/>
																{/*{editMembershipForm.getFieldsError()[3]?.errors.length > 0 && (*/}
																{/*	<div className="alert callout warning !w-full items-center">*/}
																{/*		<div className="action">*/}
																{/*			<i className="fa-solid fa-triangle-exclamation"></i>*/}
																{/*		</div>*/}
																{/*		<div className="content">*/}
																{/*			<div>*/}
																{/*				<h6>{editMembershipForm.getFieldsError()[3]?.errors[0]}</h6>*/}
																{/*			</div>*/}
																{/*		</div>*/}

																{/*	</div>*/}
																{/*)}*/}
															</div>
														</Form.Item>
														<Form.Item
															name="three_month_renewal"
															label="3 Month Renewal Price"
															validateTrigger="onBlur"
														>
															<div className="form-input air">
																<label htmlFor="text">3 Month Renewal Price</label>
																<input
																	id="three_month_renewal"
																	name="three_month_renewal"
																	type="text"
																	value={membershipThreeMonths}
																	onChange={(e)=>handleMonthlyChange(e.target.value, RenewalOptions.quarterly)}
																	placeholder="Enter numbers and a decimal only, up to 2 decimal places."
																	onKeyDown={preventMonthlyPriceNegativeInput}
																	onBlur={(e) => handleMonthlyPriceOnBlur(e, RenewalOptions.quarterly)}
																/>
																{/*{editMembershipForm.getFieldsError()[3]?.errors.length > 0 && (*/}
																{/*	<div className="alert callout warning !w-full items-center">*/}
																{/*		<div className="action">*/}
																{/*			<i className="fa-solid fa-triangle-exclamation"></i>*/}
																{/*		</div>*/}
																{/*		<div className="content">*/}
																{/*			<div>*/}
																{/*				<h6>{editMembershipForm.getFieldsError()[3]?.errors[0]}</h6>*/}
																{/*			</div>*/}
																{/*		</div>*/}

																{/*	</div>*/}
																{/*)}*/}
															</div>
														</Form.Item>
													</div>
													<div className="stack-row">
														<Form.Item
															name="six_month_renewal"
															label="6 Month Renewal Price"
															validateTrigger="onBlur"
														>
															<div className="form-input air">
																<label htmlFor="text">6 Month Renewal Price</label>
																<input
																	id="six_month_renewal"
																	name="six_month_renewal"
																	type="text"
																	value={membershipSixMonths}
																	onChange={(e)=>handleMonthlyChange(e.target.value, RenewalOptions.biannually)}
																	placeholder="Enter numbers and a decimal only, up to 2 decimal places."
																	onKeyDown={preventMonthlyPriceNegativeInput}
																	onBlur={(e) => handleMonthlyPriceOnBlur(e, RenewalOptions.biannually)}
																/>
																{/*{editMembershipForm.getFieldsError()[3]?.errors.length > 0 && (*/}
																{/*	<div className="alert callout warning !w-full items-center">*/}
																{/*		<div className="action">*/}
																{/*			<i className="fa-solid fa-triangle-exclamation"></i>*/}
																{/*		</div>*/}
																{/*		<div className="content">*/}
																{/*			<div>*/}
																{/*				<h6>{editMembershipForm.getFieldsError()[3]?.errors[0]}</h6>*/}
																{/*			</div>*/}
																{/*		</div>*/}

																{/*	</div>*/}
																{/*)}*/}
															</div>
														</Form.Item>
														<Form.Item
															name="twelve_month_renewal"
															label="12 Month Renewal Price"
															validateTrigger="onBlur"
														>
															<div className="form-input air">
																<label htmlFor="text">12 Month Renewal Price</label>
																<input
																	id="twelve_month_renewal"
																	name="twelve_month_renewal"
																	type="text"
																	onChange={(e)=>handleMonthlyChange(e.target.value, RenewalOptions.annually)}
																	placeholder="Enter numbers and a decimal only, up to 2 decimal places."
																	value={membershipTwelveMonths}
																	onKeyDown={preventMonthlyPriceNegativeInput}
																	onBlur={(e) => handleMonthlyPriceOnBlur(e, RenewalOptions.annually)}
																/>
																{/*{editMembershipForm.getFieldsError()[3]?.errors.length > 0 && (*/}
																{/*	<div className="alert callout warning !w-full items-center">*/}
																{/*		<div className="action">*/}
																{/*			<i className="fa-solid fa-triangle-exclamation"></i>*/}
																{/*		</div>*/}
																{/*		<div className="content">*/}
																{/*			<div>*/}
																{/*				<h6>{editMembershipForm.getFieldsError()[3]?.errors[0]}</h6>*/}
																{/*			</div>*/}
																{/*		</div>*/}

																{/*	</div>*/}
																{/*)}*/}
															</div>
														</Form.Item>
													</div>
												</div>
												<div className="cs-formrow-helper-end">
													<p>Set your Renewal Pricing for one or more time options. To hide an option, leave it blank.</p>
												</div>
											</div>
										</>
									}


									<div className="formrow title">
										<div>
											<h3>Visibility & Content</h3>
										</div>
										<div>
											<p>Set Visibility. Content, and Linked Access. </p>
										</div>
									</div>

									<div className="formrow">
										{/*DROPDOWN MENU GRADIENT SET - START*/}
										<div className="dropdown-menu-set">

											<DropdownWithItems
												title="Update Visibility"
												displayError={!selectedVisibilityType || selectedVisibilityType === 'default'}
												dropDownClass="dropdown-bg"
												customButtonClass="icon-dropdown-gradient-alt secondary"
												selectedValue={selectedVisibilityType}
												listItems={UPDATES_VISIBILITY}
												icon="fa-light fa-eye"
												// icon={getListIcon(
												// 	UPDATES_VISIBILITY,
												// 	selectedVisibilityType
												// )}
												onDropdownChange={(value) => setSelectedVisibilityType(value)}
											/>
											

										</div>
										{/*DROPDOWN MENU GRADIENT SET - END*/}

										<div>
											<p>Memberships can be visible to Public, Members Only (any members), Password Required, Hidden, or Custom Access (based on owning a specific Membership/Item).</p>
										</div>
									</div>

									{/*CSJS Only display Password Form Row if Password Required option is chosen in previous dropdown.*/}
									{selectedVisibilityType === 'password' &&
										<Form.Item
											name="password"
											label="Choose Password"
											validateTrigger="onBlur"
										>
											<div className="formrow">
												<div className="form-input air">
													<label htmlFor="text">Choose Password</label>
													<input
														id="password"
														name="password"
														type="password"
														placeholder="1+ letters, numbers, and symbols."
														value={membershipPurchasePassword}
													/>
													{/*{createSpaceAddMembershipForm.getFieldsError()[3]?.errors.length > 0 && (*/}
													{/*	<div className="alert callout warning !w-full items-center">*/}
													{/*		<div className="action">*/}
													{/*			<i className="fa-solid fa-triangle-exclamation"></i>*/}
													{/*		</div>*/}
													{/*		<div className="content">*/}
													{/*			<div>*/}
													{/*				<h6>{createSpaceAddMembershipForm.getFieldsError()[3]?.errors[0]}</h6>*/}
													{/*			</div>*/}
													{/*		</div>*/}

													{/*	</div>*/}
													{/*)}*/}
												</div>
												<div className="cs-formrow-helper-end">
													<p>Choose a case-sensitive password required to purchase this Membership.</p>
												</div>
											</div>
										</Form.Item>
									}

									<div className="formrow">
										<div className="cs-stack-form-inputs stack-row">
											<div className="cs-stack-form-buttons">
												<DropdownWithItems
													title="No Sensitive Content"
													customButtonclassName="button-dropdown-gradient-alt secondary whitespace-pre-wrap"
													displayError={!selectedContentType || selectedContentType === 'default'}
													icon={getListIcon(
														CONTENT_TYPES_LIST_WITH_DEFAULT,
														selectedContentType
													)}
													selectedValue={selectedContentType}
													listItems={CONTENT_TYPES_LIST_WITH_DEFAULT}
													onDropdownChange={(value) => openContentTypeModal(value)}
												/>
												<ExplicitContent
													modalOpen={modalOpenExplicitContent}
													setModalOpen={setModalOpenExplicitContent}
													selectedItems={selectedExplicitContent}
													isExplicitContent={true}
													onSelectedItems={(value) => setSelectedExplicitContent(value)}
												/>
											</div>

											{/*/!*DROPDOWN MENU GRADIENT SET - START*!/*/}
											{/*<div className="dropdown-menu-set">*/}
											{/*	/!*DROPDOWN MENU GRADIENT BUTTON - START*!/*/}
											{/*	<button type="button" className="icon-dropdown-gradient-alt secondary" aria-expanded="false" aria-haspopup="true" id="dropdown-button-3"><span><i className="fa-light fa-photo-film-music"></i><p>No Sensitive Content</p><i className="fa-solid fa-caret-down"></i></span></button>*/}
											{/*	/!*CSJS ID is for DEMO Purposes only.*!/*/}
											{/*	/!*DROPDOWN MENU GRADIENT BUTTON - END*!/*/}
											{/*	/!*DROPDOWN MENU GRADIENT UL LI - MENU COMPONENT START*!/*/}
											{/*	<ul className="dropdown-menu-gradient hide" aria-orientation="vertical" role="listbox" id="dropdown-menu-3">*/}
											{/*		/!*CSJS ID is for DEMO Purposes only.*!/*/}
											{/*		<li role="option" tabindex="-1">Value</li>*/}
											{/*		<li role="option" tabindex="-1">Value</li>*/}
											{/*		<li role="option" tabindex="-1">Value</li>*/}
											{/*		<li role="option" tabindex="-1">Value</li>*/}
											{/*		<li role="option" tabindex="-1">Value</li>*/}
											{/*	</ul>*/}
											{/*	/!*DROPDOWN MENU GRADIENT UL LI - MENU COMPONENT END*!/*/}
											{/*</div>*/}
											{/*DROPDOWN MENU GRADIENT SET - END*/}

											{/*DROPDOWN MENU GRADIENT SET - START*/}
											<div className="dropdown-menu-set">
												{/*DROPDOWN MENU GRADIENT BUTTON - START*/}
												<DropdownWithItems
													title="No Sensitive Access"
													customButtonclassName="icon-dropdown-gradient-alt secondary active"
													displayError={!selectedAccessType || selectedAccessType === 'default'}
													icon={getListIcon(
														ACCESS_TYPES_LIST_WITH_DEFAULT,
														selectedAccessType
													)}
													selectedValue={selectedAccessType}
													listItems={ACCESS_TYPES_LIST_WITH_DEFAULT}
													onDropdownChange={(value) => openAccessTypeModal(value)}
												/>
												{/*<button type="button" className="icon-dropdown-gradient-alt secondary active" aria-expanded="false" aria-haspopup="true" id="dropdown-button-4"><span><i className="fa-light fa-globe-pointer"></i><p>No Sensitive Access</p><i className="fa-solid fa-caret-down"></i></span></button>*/}
												{/*CSJS ID is for DEMO Purposes only.*/}
												{/*DROPDOWN MENU GRADIENT BUTTON - END*/}
												{/*DROPDOWN MENU GRADIENT UL LI - MENU COMPONENT START*/}
												<ExplicitContent
													modalOpen={modalOpenExplicitAccess}
													setModalOpen={setModalOpenExplicitAccess}
													selectedItems={selectedExplicitAccess}
													isExplicitContent={false}
													onSelectedItems={(value) => setSelectedExplicitAccess(value)}
												/>
												{/*DROPDOWN MENU GRADIENT UL LI - MENU COMPONENT END*/}
											</div>
											{/*DROPDOWN MENU GRADIENT SET - END*/}

										</div>
										<div className="cs-formrow-helper-end">
											<p>Specify if your Membership contains or provides access to sensitive content. {getSitewideLink('view_hosted_content_guideline_memberships', 'gradient-color-txt body-txtitalic--reg')}</p>
										</div>
									</div>
									<Form.Item>
										<div className="formrow">
											<button
												disabled={submitted || formHasErrorMembership}
												type="submit" className="secondary-gradient">
												<span><p>
													{submitted && (
														<i className="fa-solid fa-spinner fa-spin"></i>
													)}
													Save
													{/*Save Membership & Continue*/}
												</p></span>
											</button>
											<div className="cs-formrow-helper-none">
												<p></p>
											</div>
										</div>
									</Form.Item>
								</Form>

								{alert && (
									<section className={"alert banner px-section-safeview" + (alert.variant === 'success' ? ' success' : ' error')}>
										<div className="action">
											<i className="fa-solid fa-circle-check"></i>
										</div>
										<div className="content">
											<hgroup>
												<h6></h6>
												<p>{alert.message}</p>
											</hgroup>
											{/*<a href="#alertlink" class="button">Link Button</a>*/}
										</div>
										<div className="action">
											<a onClick={() => {setAlert(null)}}>
												<i className="fa-solid fa-xmark"></i>
											</a>
										</div>
									</section>
								)}

							</div>
						</div>
					</div>
				</section>
			</main>








			{/*<div className="block-full card">*/}
			{/*	<div className="memberships">*/}
			{/*		<div className="dashboard-wrapper">*/}
			{/*			<div className="grid grid-cols-12 gap-8">*/}
			{/*				<div className="col-span-12">*/}
			{/*					<h2 className="header-medium">Editing {membershipData.name}</h2>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*			<div className="grid grid-cols-12 gap-8 mt-5">*/}
			{/*				<div className="col-span-12">*/}
			{/*					<p className="body-text--small">*/}
			{/*						You can edit your Membership Details here, however most changes may*/}
			{/*						only apply to new sales and certain details may not be modifiable*/}
			{/*						depending on your Membership Type and status.{' '}*/}
			{/*						<a className="body-txtitalic--small gradient-color-txt">*/}
			{/*							{getSitewideLink('learn_more_on_membership_and_editing_limits')}*/}
			{/*							/!*Learn more about Membership Types and editing limits.*!/*/}
			{/*						</a>*/}
			{/*					</p>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*		</div>*/}
			{/*	</div>*/}
			{/*</div>*/}
			{/*<div className="block-full card">*/}
			{/*	<div className="memberships">*/}
			{/*		<div className="dashboard-wrapper">*/}
			{/*			<div className="grid grid-cols-12">*/}
			{/*				<div className="col-span-6">*/}
			{/*					<h3 className="header-small">Membership Details</h3>*/}
			{/*				</div>*/}
			{/*				<div className="col-span-6 hidden">*/}
			{/*					/!*<p className="body-text--small gradient-color-txt text-end">*!/*/}
			{/*					/!*	Remove Membership*!/*/}
			{/*					/!*</p>*!/*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*			<Form*/}
			{/*				form={editMembershipForm}*/}
			{/*				name="editMembershipForm"*/}
			{/*				id="editMembershipForm"*/}
			{/*				validateMessages={VALIDATE_MESSAGES}*/}
			{/*				onFocus={() => {*/}
			{/*					setFormIsOnFocusMembership(true);*/}
			{/*				}}*/}
			{/*				onBlur={() => {*/}
			{/*					// setTimeout(() => {*/}
			{/*					setFormIsOnFocusMembership(false);*/}
			{/*					handleMembershipFormOnBlur().then(r => {});*/}
			{/*					// }, 200);*/}
			{/*				}}*/}
			{/*				onChange={handleFormMembershipChanges}*/}
			{/*				onFinish={handleSubmit}*/}
			{/*				className={formIsOnFocusMembership ? 'hide-antd-error-messages' : ''}*/}
			{/*			>*/}
			{/*				<div className="grid grid-cols-12 gap-8 mt-5">*/}
			{/*					<div className="col-span-4">*/}
			{/*						<div className="cs-stack-form-media-item">*/}
			{/*							<UploadDraggerV2*/}
			{/*								onError={setImageUploadErrors}*/}
			{/*								cropperAspectRatio={4 / 3}*/}
			{/*								hideDragIcon="true"*/}
			{/*								hideText="true"*/}
			{/*								uploadFileTypes={IMAGE_FILETYPES.toString()}*/}
			{/*								uploadedFilePreview={membershipImageBlobPreview}*/}
			{/*								onUploadFileChange={handleMembershipImageUploadFileChange}*/}
			{/*								minHeight={IMAGE_STANDARD_MIN_HEIGHT}*/}
			{/*								minWidth={IMAGE_STANDARD_MIN_WIDTH}*/}
			{/*								maxHeight={IMAGE_STANDARD_MAX_HEIGHT}*/}
			{/*								maxWidth={IMAGE_STANDARD_MAX_WIDTH}*/}
			{/*								uploadSizeLimit={IMAGE_STANDARD_FILE_UPLOAD_SIZE}*/}
			{/*								disableCropper={true}*/}
			{/*								shape="aspect-cs-preview"*/}
			{/*							/>*/}
			{/*						</div>*/}
			{/*						<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">*/}
			{/*							Membership Image**/}
			{/*						</p>*/}
			{/*						<p className="text-center body-txtitalic--smallest">*/}
			{/*							Up to 3840px square or wide. <br />*/}
			{/*							JPEG, PNG, or GIF*/}
			{/*						</p>*/}
			{/*						{membershipImageError && (*/}
			{/*							<div className="alert callout warning items-center !w-full">*/}
			{/*								<div className="action">*/}
			{/*									<i className="fa-solid fa-triangle-exclamation"></i>*/}
			{/*								</div>*/}
			{/*								<div className="content">*/}
			{/*									<div>*/}
			{/*										<h6>{membershipImageError}</h6>*/}
			{/*									</div>*/}
			{/*								</div>*/}

			{/*							</div>*/}
			{/*						)}*/}
			{/*					</div>*/}
			{/*					<div className="col-span-4">*/}
			{/*						<div className="cs-stack-form-media-item">*/}
			{/*							<UploadDraggerV2*/}
			{/*								hideDragIcon="true"*/}
			{/*								hideText="true"*/}
			{/*								onError={setVideoUploadErrors}*/}
			{/*								uploadFileTypes={VIDEO_FILETYPES.toString()}*/}
			{/*								uploadedFilePreview={membershipVideoBlobPreview}*/}
			{/*								onUploadFileChange={handleMembershipVideoUploadFileChange}*/}
			{/*								minHeight={VIDEO_STANDARD_MIN_HEIGHT}*/}
			{/*								minWidth={VIDEO_STANDARD_MIN_WIDTH}*/}
			{/*								maxHeight={VIDEO_STANDARD_MAX_HEIGHT}*/}
			{/*								maxWidth={VIDEO_STANDARD_MAX_WIDTH}*/}
			{/*								uploadSizeLimit={VIDEO_STANDARD_FILE_UPLOAD_SIZE}*/}
			{/*								shape="aspect-cs-preview"*/}
			{/*							/>*/}
			{/*						</div>*/}
			{/*						<p className="body-text--small gradient-color-txt text-center mt-3 mb-0">*/}
			{/*							Membership Video*/}
			{/*						</p>*/}
			{/*						<p className="text-center body-txtitalic--smallest">*/}
			{/*							Up to 1920px/1080px, square or wide. <br />*/}
			{/*							MOV or MP4*/}
			{/*						</p>*/}
			{/*						{membershipVideoError && (*/}
			{/*							<div className="alert callout warning items-center !w-full">*/}
			{/*								<div className="action">*/}
			{/*									<i className="fa-solid fa-triangle-exclamation"></i>*/}
			{/*								</div>*/}
			{/*								<div className="content">*/}
			{/*									<div>*/}
			{/*										<h6>{membershipVideoError}</h6>*/}
			{/*									</div>*/}
			{/*								</div>*/}
			{/*							</div>*/}
			{/*						)}*/}
			{/*					</div>*/}
			{/*					<div className="col-span-4">*/}
			{/*						<p className="body-txtitalic--smallest">*/}
			{/*							Your Membership Image (up to 8MB) should be as high resolution*/}
			{/*							as possible, up to 3840px height and width. This will also be*/}
			{/*							used for any thumbnails and previews.*/}
			{/*						</p>*/}
			{/*						<p className="body-txtitalic--smallest">*/}
			{/*							Your Membership Image (up to 8MB) should be as high resolution*/}
			{/*							as possible, up to 3840px height and width. This will also be*/}
			{/*							used for any thumbnails and previews.*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*				</div>*/}

			{/*				<div className="grid grid-cols-12 gap-8 mt-4 flex items-center">*/}
			{/*					<div className="col-span-8">*/}
			{/*						<div className="form-input w-full mt-2">*/}
			{/*							<label htmlFor="text">Name</label>*/}
			{/*							<Form.Item*/}
			{/*								label="Name"*/}
			{/*								name="name"*/}
			{/*								className="mb-0 hidden-label w-full"*/}
			{/*								validateTrigger="onBlur"*/}
			{/*								rules={[*/}
			{/*									{ required: true },*/}
			{/*									{*/}
			{/*										max: 32,*/}
			{/*										message: 'Must not exceed 32 characters.'*/}
			{/*									}*/}
			{/*								]}*/}
			{/*							>*/}
			{/*								<Input*/}
			{/*									placeholder="Up to 32 characters, including spaces."*/}
			{/*									className="input-md body-text--small color-reg"*/}
			{/*									maxLength={32}*/}
			{/*								/>*/}
			{/*							</Form.Item>*/}
			{/*						</div>*/}
			{/*						{editMembershipForm.getFieldsError()[0]?.errors.length > 0 && (*/}
			{/*							<div className="alert callout warning items-center !w-full">*/}
			{/*								<div className="action">*/}
			{/*									<i className="fa-solid fa-triangle-exclamation"></i>*/}
			{/*								</div>*/}
			{/*								<div className="content">*/}
			{/*									<div>*/}
			{/*										<h6>{editMembershipForm.getFieldsError()[0]?.errors}</h6>*/}
			{/*									</div>*/}
			{/*								</div>*/}
			{/*							</div>*/}
			{/*						)}*/}
			{/*					</div>*/}
			{/*					<div className="col-span-4">*/}
			{/*						<p className="body-txtitalic--smallest">*/}
			{/*							This is the displayed Name of the Membership and is what people*/}
			{/*							will see when browsing your Space and viewing their purchased*/}
			{/*							Membership, as well as the Collection Name when viewing the*/}
			{/*							artwork.*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*				</div>*/}

			{/*				<div className="grid grid-cols-12 gap-8 mt-4 flex items-center">*/}
			{/*					<div className="col-span-8">*/}
			{/*						<div className="form-input w-full mt-2">*/}
			{/*							<label htmlFor="text">Sku Short Code</label>*/}
			{/*							<Form.Item*/}
			{/*								label="Sku Short Code"*/}
			{/*								name="sku_shortcode"*/}
			{/*								className="mb-0 hidden-label w-full"*/}
			{/*								validateTrigger="onBlur"*/}
			{/*								rules={[*/}
			{/*									{ required: true },*/}
			{/*									{*/}
			{/*										min: 2,*/}
			{/*										message: 'Must be minimum 2 characters.'*/}
			{/*									},*/}
			{/*									{*/}
			{/*										max: 8,*/}
			{/*										message: 'Must not exceed 8 characters.'*/}
			{/*									},*/}
			{/*									({ getFieldValue }) => ({*/}
			{/*										async validator(_, value) {*/}
			{/*											if (value) {*/}
			{/*												const data = {*/}
			{/*													space_id: spaceInfo.id,*/}
			{/*													shortcode: value*/}
			{/*												};*/}

			{/*												if (membershipData) {*/}
			{/*													data['membership_id'] =*/}
			{/*														membershipData.id;*/}
			{/*												}*/}

			{/*												let available = false;*/}
			{/*												const response =*/}
			{/*													await checkMembershipShortCodeAvailability(*/}
			{/*														data*/}
			{/*													);*/}
			{/*												if (response && response.result) {*/}
			{/*													available = response.availability;*/}
			{/*												}*/}

			{/*												if (!available && value.length <= 8) {*/}
			{/*													return Promise.reject(*/}
			{/*														new Error(*/}
			{/*															'Shortcode not available.'*/}
			{/*														)*/}
			{/*													);*/}
			{/*												}*/}
			{/*											}*/}

			{/*											if (typeof value === 'number') {*/}
			{/*												value = value.toString();*/}
			{/*											}*/}
			{/*											if (*/}
			{/*												(value && value.match(/\ /g)) ||*/}
			{/*												/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(*/}
			{/*													value*/}
			{/*												)*/}
			{/*											) {*/}
			{/*												return Promise.reject(*/}
			{/*													new Error('Invalid Input')*/}
			{/*												);*/}
			{/*											}*/}

			{/*											return Promise.resolve();*/}
			{/*										}*/}
			{/*									})*/}
			{/*								]}*/}
			{/*							>*/}
			{/*								<Input*/}
			{/*									placeholder="2 to 8 characters. Letters and numbers only, no spaces."*/}
			{/*									className="input-md body-text--small color-reg"*/}
			{/*									maxLength={8}*/}
			{/*								/>*/}
			{/*							</Form.Item>*/}
			{/*						</div>*/}
			{/*						{editMembershipForm.getFieldsError()[1]?.errors.length > 0 && (*/}
			{/*							<div className="alert callout warning items-center !w-full">*/}
			{/*								<div className="action">*/}
			{/*									<i className="fa-solid fa-triangle-exclamation"></i>*/}
			{/*								</div>*/}
			{/*								<div className="content">*/}
			{/*									<div>*/}
			{/*										<h6>{editMembershipForm.getFieldsError()[1]?.errors}</h6>*/}
			{/*									</div>*/}
			{/*								</div>*/}

			{/*							</div>*/}
			{/*						)}*/}
			{/*					</div>*/}
			{/*					<div className="col-span-4">*/}
			{/*						<p className="body-txtitalic--smallest">*/}
			{/*							Choose a unique SKU Shortcode based the Membership Name. This is*/}
			{/*							must be unique within your Space and is used in transactions,*/}
			{/*							support, and other areas.*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*				</div>*/}

			{/*				<div className="grid grid-cols-12 gap-8 mt-4 flex items-center">*/}
			{/*					<div className="col-span-8">*/}
			{/*						<div className="form-input w-full mt-2">*/}
			{/*							<label htmlFor="text">Max Supply</label>*/}
			{/*							<Form.Item*/}
			{/*								label="Max Supply"*/}
			{/*								name="max_supply"*/}
			{/*								className="mb-0 hidden-label w-full"*/}
			{/*								validateTrigger="onBlur"*/}
			{/*								rules={[*/}
			{/*									{ required: true },*/}
			{/*									({ getFieldValue }) => ({*/}
			{/*										validator(_, value) {*/}
			{/*											if (typeof value === 'number') {*/}
			{/*												value = value.toString();*/}
			{/*											}*/}
			{/*											if (*/}
			{/*												isNaN(value) ||*/}
			{/*												value < 0 ||*/}
			{/*												value.match(/\./g)*/}
			{/*											) {*/}
			{/*												return Promise.reject(*/}
			{/*													new Error('Invalid value.')*/}
			{/*												);*/}
			{/*											}*/}

			{/*											return Promise.resolve();*/}
			{/*										}*/}
			{/*									})*/}
			{/*								]}*/}
			{/*							>*/}
			{/*								<Input*/}
			{/*									placeholder="Enter “0” for unlimited."*/}
			{/*									className="input-md body-text--small color-reg"*/}
			{/*								/>*/}
			{/*							</Form.Item>*/}
			{/*						</div>*/}
			{/*						{editMembershipForm.getFieldsError()[2]?.errors.length > 0 && (*/}
			{/*							<div className="alert callout warning items-center !w-full">*/}
			{/*								<div className="action">*/}
			{/*									<i className="fa-solid fa-triangle-exclamation"></i>*/}
			{/*								</div>*/}
			{/*								<div className="content">*/}
			{/*									<div>*/}
			{/*										<h6>{editMembershipForm.getFieldsError()[2]?.errors}</h6>*/}
			{/*									</div>*/}
			{/*								</div>*/}
			{/*							</div>*/}
			{/*						)}*/}
			{/*					</div>*/}
			{/*					<div className="col-span-4">*/}
			{/*						<p className="body-txtitalic--smallest">*/}
			{/*							Your Membership can have a limited or unlimited Supply. Enter*/}
			{/*							“0” to set the Supply to unlimited, “1” to make it a unique*/}
			{/*							1-of-1, or any larger number to set a higher limit.*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*				</div>*/}

			{/*				<div className="grid grid-cols-12 gap-8 mt-4 flex items-center">*/}
			{/*					<div className="col-span-8">*/}
			{/*						<div className="form-input w-full mt-2">*/}
			{/*							<label htmlFor="text">Max Per Account</label>*/}
			{/*							<Form.Item*/}
			{/*								label="Max Per Account"*/}
			{/*								name="max_per_account"*/}
			{/*								className="mb-0 hidden-label w-full"*/}
			{/*								validateTrigger="onBlur"*/}
			{/*								rules={[*/}
			{/*									{ required: true },*/}
			{/*									{ validator: CustomValidators.maxPerCount }*/}
			{/*								]}*/}
			{/*							>*/}
			{/*								<Input*/}
			{/*									placeholder="Enter “0” for unlimited."*/}
			{/*									className="input-md body-text--small color-reg"*/}
			{/*								/>*/}
			{/*							</Form.Item>*/}
			{/*						</div>*/}
			{/*						{editMembershipForm.getFieldsError()[3]?.errors.length > 0 && (*/}
			{/*							<div className="alert callout warning items-center !w-full">*/}
			{/*								<div className="action">*/}
			{/*									<i className="fa-solid fa-triangle-exclamation"></i>*/}
			{/*								</div>*/}
			{/*								<div className="content">*/}
			{/*									<div>*/}
			{/*										<h6>{editMembershipForm.getFieldsError()[3]?.errors}</h6>*/}
			{/*									</div>*/}
			{/*								</div>*/}
			{/*							</div>*/}
			{/*						)}*/}
			{/*					</div>*/}
			{/*					<div className="col-span-4">*/}
			{/*						<p className="body-txtitalic--smallest">*/}
			{/*							You can put a limit how many Memberships can be purchased by a*/}
			{/*							single account. Enter “0” to set this to unlimited.*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*				</div>*/}

			{/*				<div className="grid grid-cols-12 gap-8 mt-4 flex items-center">*/}
			{/*					<div className="col-span-8">*/}
			{/*						<div className="form-input w-full mt-2">*/}
			{/*							<label htmlFor="text">Currency</label>*/}
			{/*							<Form.Item*/}
			{/*								label="Currency"*/}
			{/*								name="currency"*/}
			{/*								className="mb-0 hidden-label w-full"*/}
			{/*								validateTrigger="onBlur"*/}
			{/*								rules={[{ required: true }]}*/}
			{/*							>*/}
			{/*								<Input*/}
			{/*									placeholder="Start typing your currency and select from the list."*/}
			{/*									className="input-md body-text--small color-reg"*/}
			{/*									disabled*/}
			{/*								/>*/}
			{/*							</Form.Item>*/}
			{/*						</div>*/}
			{/*						{editMembershipForm.getFieldsError()[4]?.errors.length > 0 && (*/}
			{/*							<div className="alert callout warning items-center !w-full">*/}
			{/*								<div className="action">*/}
			{/*									<i className="fa-solid fa-triangle-exclamation"></i>*/}
			{/*								</div>*/}
			{/*								<div className="content">*/}
			{/*									<div>*/}
			{/*										<h6>{editMembershipForm.getFieldsError()[4]?.errors}</h6>*/}
			{/*									</div>*/}
			{/*								</div>*/}
			{/*							</div>*/}
			{/*						)}*/}
			{/*					</div>*/}
			{/*					<div className="col-span-4">*/}
			{/*						<p className="body-txtitalic--smallest">*/}
			{/*							You can offer your Membership for free or sell it at any price*/}
			{/*							you choose. Denominated in your selected Currency. Enter “0” to*/}
			{/*							set the Price as free.*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*				</div>*/}

			{/*				<div className="grid grid-cols-12 gap-8 mt-4 flex items-center">*/}
			{/*					<div className="col-span-8">*/}
			{/*						<div className="form-input w-full mt-2">*/}
			{/*							<label htmlFor="text">Price</label>*/}
			{/*							<Form.Item*/}
			{/*								label="Price"*/}
			{/*								name="price"*/}
			{/*								className="mb-0 hidden-label"*/}
			{/*								validateTrigger="onBlur"*/}
			{/*								rules={[*/}
			{/*									{ required: true },*/}
			{/*									{*/}
			{/*										validator: CustomValidators.priceMinmax(*/}
			{/*											membershipData.currency*/}
			{/*										)*/}
			{/*									}*/}
			{/*								]}*/}
			{/*							>*/}
			{/*								<Input*/}
			{/*									placeholder="Enter “0” if free. "*/}
			{/*									className="input-md body-text--small color-reg"*/}
			{/*								/>*/}
			{/*							</Form.Item>*/}
			{/*						</div>*/}
			{/*						{editMembershipForm.getFieldsError()[5]?.errors.length > 0 && (*/}
			{/*							<div className="alert callout warning items-center !w-full">*/}
			{/*								<div className="action">*/}
			{/*									<i className="fa-solid fa-triangle-exclamation"></i>*/}
			{/*								</div>*/}
			{/*								<div className="content">*/}
			{/*									<div>*/}
			{/*										<h6>{editMembershipForm.getFieldsError()[5]?.errors}</h6>*/}
			{/*									</div>*/}
			{/*								</div>*/}
			{/*							</div>*/}
			{/*						)}*/}
			{/*					</div>*/}
			{/*					<div className="col-span-4">*/}
			{/*						<p className="body-txtitalic--smallest">*/}
			{/*							Set the Currency for your Membership Price. Any purchases in a*/}
			{/*							different currency will be converted based on market pricing at*/}
			{/*							the time of purchase.{' '}*/}
			{/*							<a className="gradient-color-txt">*/}
			{/*								{' '}{getSitewideLink('see_supported_currencies_and_cryptocurrencies')}*/}
			{/*								/!*See officially supported major currencies and cryptocurrencies.*!/*/}
			{/*							</a>*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*				</div>*/}

			{/*				<div className="grid grid-cols-12 gap-8 mt-4  flex items-center">*/}
			{/*					<div className="col-span-8">*/}
			{/*						<div className="form-check ms-5">*/}
			{/*							<label className="form-check-label body-text--small gradient-color-txt">*/}
			{/*								<Checkbox*/}
			{/*									className="inline-flex  items-center mr-8 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"*/}
			{/*									checked={enableExpirationChecked}*/}
			{/*									onChange={onChangeEnableExpiration}*/}
			{/*								/>*/}
			{/*								Enable Expiration Date and add Monthly Subscription for*/}
			{/*								continued access*/}
			{/*							</label>*/}
			{/*						</div>*/}
			{/*					</div>*/}
			{/*					<div className="col-span-4">*/}
			{/*						<p className="body-txtitalic--smallest">*/}
			{/*							Memberships have a one-time upfront Price. This option adds an*/}
			{/*							Expiration after a set number of months. After that, Members*/}
			{/*							must pay a Monthly Subscription to continue access. Manage*/}
			{/*							details in the Benefits Section.*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*				</div>*/}

			{/*				<div className="grid grid-cols-12 gap-8 mt-4 flex items-center">*/}
			{/*					<div className="col-span-8">*/}
			{/*						<div className="form-input w-full mt-2">*/}
			{/*							<label htmlFor="text">Summary</label>*/}
			{/*							<Form.Item*/}
			{/*								label="Summary"*/}
			{/*								name="summary"*/}
			{/*								className="mb-0 hidden-label w-full"*/}
			{/*								validateTrigger="onBlur"*/}
			{/*								rules={[*/}
			{/*									{ required: true },*/}
			{/*									{*/}
			{/*										max: 128,*/}
			{/*										message: 'Must not exceed 128 characters.'*/}
			{/*									}*/}
			{/*								]}*/}
			{/*							>*/}
			{/*								<TextareaAutosize*/}
			{/*									minRows={3}*/}
			{/*									placeholder="Up to 128 characters, including spaces."*/}
			{/*									className={"w-full bg-transparent border-none no-scrollbar"}*/}
			{/*									maxLength={128}*/}
			{/*								/>*/}
			{/*							</Form.Item>*/}
			{/*						</div>*/}
			{/*						{editMembershipForm.getFieldsError()[6]?.errors.length > 0 && (*/}
			{/*							<div className="alert callout warning items-center !w-full">*/}
			{/*								<div className="action">*/}
			{/*									<i className="fa-solid fa-triangle-exclamation"></i>*/}
			{/*								</div>*/}
			{/*								<div className="content">*/}
			{/*									<div>*/}
			{/*										<h6>{editMembershipForm.getFieldsError()[6]?.errors}</h6>*/}
			{/*									</div>*/}
			{/*								</div>*/}
			{/*							</div>*/}
			{/*						)}*/}
			{/*					</div>*/}
			{/*					<div className="col-span-4">*/}
			{/*						<p className="body-txtitalic--smallest">*/}
			{/*							This Summary shows when Members view their Membership. It should*/}
			{/*							be 1 to 2 sentences summarizing the Membership and its details.*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*				</div>*/}

			{/*				<div className="grid grid-cols-12 gap-8 mt-5 flex items-center">*/}
			{/*					<div className="col-span-8">*/}
			{/*						<div className="form-input w-full mt-2">*/}
			{/*							<label htmlFor="text">Listing Text</label>*/}
			{/*							<Form.Item*/}
			{/*								label="Listing Text"*/}
			{/*								name="listing_text"*/}
			{/*								className="mb-0 hidden-label w-full"*/}
			{/*								validateTrigger="onBlur"*/}
			{/*								rules={[*/}
			{/*									{ required: true },*/}
			{/*									{*/}
			{/*										max: 128,*/}
			{/*										message: 'Must not exceed 128 characters.'*/}
			{/*									}*/}
			{/*								]}*/}
			{/*							>*/}
			{/*								<TextareaAutosize*/}
			{/*									minRows={3}*/}
			{/*									placeholder="Up to 128 characters, including spaces."*/}
			{/*									className={"w-full bg-transparent border-none no-scrollbar"}*/}
			{/*									maxLength={128}*/}
			{/*								/>*/}
			{/*							</Form.Item>*/}
			{/*						</div>*/}
			{/*						{editMembershipForm.getFieldsError()[7]?.errors.length > 0 && (*/}
			{/*							<div className="alert callout warning items-center !w-full">*/}
			{/*								<div className="action">*/}
			{/*									<i className="fa-solid fa-triangle-exclamation"></i>*/}
			{/*								</div>*/}
			{/*								<div className="content">*/}
			{/*									<div>*/}
			{/*										<h6>{editMembershipForm.getFieldsError()[7]?.errors}</h6>*/}
			{/*									</div>*/}
			{/*								</div>*/}
			{/*							</div>*/}
			{/*						)}*/}
			{/*					</div>*/}
			{/*					<div className="col-span-4">*/}
			{/*						<p className="body-txtitalic--smallest">*/}
			{/*							The Listing Text is used when listing your Membership for*/}
			{/*							purchase on your Space. It should be 1 to 2 sentences*/}
			{/*							summarizing the Membership and why people should join.*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*				</div>*/}

			{/*				<div className="grid grid-cols-12 gap-8 mt-4 flex items-center">*/}
			{/*					<div className="col-span-8">*/}
			{/*						<div className="form-input w-full mt-2">*/}
			{/*							<label htmlFor="text">Individual Title</label>*/}
			{/*							<Form.Item*/}
			{/*								label="Individual Title"*/}
			{/*								name="individual_title"*/}
			{/*								className="mb-0 hidden-label w-full"*/}
			{/*								validateTrigger="onBlur"*/}
			{/*								rules={[*/}
			{/*									{ required: true },*/}
			{/*									{*/}
			{/*										max: 32,*/}
			{/*										message: 'Must not exceed 32 characters.'*/}
			{/*									}*/}
			{/*								]}*/}
			{/*							>*/}
			{/*								<Input*/}
			{/*									maxLength={32}*/}
			{/*									placeholder="Up to 32 characters, including spaces. Usually this is the same or similar to the Name."*/}
			{/*									className="input-md body-text--small color-reg"*/}
			{/*								/>*/}
			{/*							</Form.Item>*/}
			{/*						</div>*/}
			{/*						{editMembershipForm.getFieldsError()[8]?.errors.length > 0 && (*/}
			{/*							<div className="alert callout warning items-center !w-full">*/}
			{/*								<div className="action">*/}
			{/*									<i className="fa-solid fa-triangle-exclamation"></i>*/}
			{/*								</div>*/}
			{/*								<div className="content">*/}
			{/*									<div>*/}
			{/*										<h6>{editMembershipForm.getFieldsError()[8]?.errors}</h6>*/}
			{/*									</div>*/}
			{/*								</div>*/}
			{/*							</div>*/}
			{/*						)}*/}
			{/*					</div>*/}
			{/*					<div className="col-span-4">*/}
			{/*						<p className="body-txtitalic--smallest">*/}
			{/*							This is the Title given to an individual Membership when*/}
			{/*							purchased by someone. For example, if your Membership Name is*/}
			{/*							“Club All Access Membership” then you could make this “Club*/}
			{/*							Membership”.*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*				</div>*/}

			{/*				<div className="grid grid-cols-12 gap-8 mt-4  flex items-center">*/}
			{/*					<div className="col-span-8">*/}
			{/*						<div className="form-check ms-5">*/}
			{/*							<label className="form-check-label body-text--small gradient-color-txt">*/}
			{/*								<Checkbox*/}
			{/*									className="inline-flex  items-center mr-8 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"*/}
			{/*									checked={enableAddNumericChecked}*/}
			{/*									onChange={onChangeEnableAddNumeric}*/}
			{/*								/>*/}
			{/*								Add Numeric Count to the Individual Title of each purchased*/}
			{/*								Membership*/}
			{/*							</label>*/}
			{/*						</div>*/}
			{/*					</div>*/}
			{/*					<div className="col-span-4">*/}
			{/*						<p className="body-txtitalic--smallest">*/}
			{/*							This adds a numeric count to the Individual Title of each*/}
			{/*							Membership sold. For example, the first Membership sold would*/}
			{/*							have the Title of “Club Membership #1”, and the second would be*/}
			{/*							“Club Membership #2”, and so on.*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*				</div>*/}

			{/*				<div className="grid grid-cols-12 gap-8 mt-4">*/}
			{/*					<div className="col-span-8 items-center">*/}
			{/*						<div className="form-input w-full mt-2">*/}
			{/*							<label htmlFor="text">Description</label>*/}
			{/*							<Form.Item*/}
			{/*								label="Description"*/}
			{/*								name="description"*/}
			{/*								className="mb-0 hidden-label w-full"*/}
			{/*								validateTrigger="onBlur"*/}
			{/*								rules={[*/}
			{/*									{ required: true },*/}
			{/*									{*/}
			{/*										max: 512,*/}
			{/*										message: 'Must not exceed 512 characters.'*/}
			{/*									}*/}
			{/*								]}*/}
			{/*							>*/}
			{/*								<TextareaAutosize*/}
			{/*									minRows={6}*/}
			{/*									placeholder="Up to 512 characters, including spaces."*/}
			{/*									className={"w-full bg-transparent border-none no-scrollbar"}*/}
			{/*									maxLength={512}*/}
			{/*								/>*/}
			{/*							</Form.Item>*/}
			{/*						</div>*/}
			{/*						{editMembershipForm.getFieldsError()[9]?.errors.length > 0 && (*/}
			{/*							<div className="alert callout warning items-center !w-full">*/}
			{/*								<div className="action">*/}
			{/*									<i className="fa-solid fa-triangle-exclamation"></i>*/}
			{/*								</div>*/}
			{/*								<div className="content">*/}
			{/*									<div>*/}
			{/*										<h6>{editMembershipForm.getFieldsError()[9]?.errors}</h6>*/}
			{/*									</div>*/}
			{/*								</div>*/}
			{/*							</div>*/}
			{/*						)}*/}
			{/*					</div>*/}
			{/*					<div className="col-span-4 flex align-top">*/}
			{/*						<p className="body-txtitalic--smallest">*/}
			{/*							This is a full Description of your Membership. You can use{' '}*/}
			{/*							{getSitewideLink('markdown_syntax')} to add basic formatting.*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*				</div>*/}

			{/*				<div className="grid grid-cols-12 gap-8 mt-4 items-center">*/}
			{/*					<div className="col-span-8">*/}
			{/*						<label className="form-check ms-5">*/}
			{/*							<label className="form-check-label body-text--small gradient-color-txt">*/}
			{/*								<Checkbox*/}
			{/*									className="inline-flex  items-center mr-8 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"*/}
			{/*									checked={enableGiftingChecked}*/}
			{/*									onChange={onChangeEnableGifting}*/}
			{/*								/>*/}
			{/*								Enable gifting to accounts and external transfers on*/}
			{/*								marketplaces*/}
			{/*							</label>*/}
			{/*						</label>*/}
			{/*					</div>*/}
			{/*					<div className="col-span-4">*/}
			{/*						<p className="body-txtitalic--smallest">*/}
			{/*							Enabling gifting and transfers allows Members to gift a*/}
			{/*							purchased Membership to another account. Free gifting is*/}
			{/*							supported within your Space. External transfers and sales can be*/}
			{/*							done on marketplaces like OpenSea.{' '}*/}
			{/*							<a className="gradient-color-txt">{getSitewideLink('learn_more_enable_gifting')}</a>*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*				</div>*/}

			{/*				{enableGiftingChecked && (*/}
			{/*					<div className="grid grid-cols-12 gap-8 mt-4 flex items-center">*/}
			{/*						<div className="col-span-8">*/}
			{/*							<div className="form-input w-full mt-2">*/}
			{/*								<label htmlFor="text">Royalty Percentage</label>*/}
			{/*								<Form.Item*/}
			{/*									label="Royalty Percentage"*/}
			{/*									name="royalty_percentage"*/}
			{/*									className="mb-0 hidden-label w-full"*/}
			{/*									validateTrigger="onBlur"*/}
			{/*									rules={[*/}
			{/*										{ required: true },*/}
			{/*										{ validator: CustomValidators.royaltyPercentage }*/}
			{/*									]}*/}
			{/*								>*/}
			{/*									<Input*/}
			{/*										placeholder="Whole numbers only, 0% to 20%."*/}
			{/*										className="input-md body-text--small color-reg"*/}
			{/*									/>*/}
			{/*								</Form.Item>*/}
			{/*							</div>*/}
			{/*							{editMembershipForm.getFieldsError()[10]?.errors.length > 0 && (*/}
			{/*								<div className="alert callout warning items-center !w-full">*/}
			{/*									<div className="action">*/}
			{/*										<i className="fa-solid fa-triangle-exclamation"></i>*/}
			{/*									</div>*/}
			{/*									<div className="content">*/}
			{/*										<div>*/}
			{/*											<h6>{editMembershipForm.getFieldsError()[10]?.errors}</h6>*/}
			{/*										</div>*/}
			{/*									</div>*/}
			{/*								</div>*/}
			{/*							)}*/}
			{/*						</div>*/}
			{/*						<div className="col-span-4">*/}
			{/*							<p className="body-txtitalic--smallest">*/}
			{/*								With Gifting and Transfers enabled, when a Membership is sold on*/}
			{/*								an external marketplace, you can specify that a percentage of*/}
			{/*								the sale price be paid to you as a royalty.*/}
			{/*								<a className="gradient-color-txt"> Learn more.</a>*/}
			{/*							</p>*/}
			{/*						</div>*/}
			{/*					</div>*/}
			{/*				)}*/}

			{/*				<div className="grid grid-cols-12 gap-8 mt-4 flex items-center">*/}
			{/*					<div className="col-span-8">*/}
			{/*						<DropdownWithItems*/}
			{/*							title="Select Content Type"*/}
			{/*							dropDownClass="dropdown-bg"*/}
			{/*							customButtonClass="button gradient secondary distribute w-full"*/}
			{/*							displayError={*/}
			{/*								displayDropDownError && selectedContentType === 'default'*/}
			{/*							}*/}
			{/*							icon={getListIcon(*/}
			{/*								CONTENT_TYPES_LIST_WITH_DEFAULT,*/}
			{/*								selectedContentType*/}
			{/*							)}*/}
			{/*							selectedValue={selectedContentType}*/}
			{/*							listItems={CONTENT_TYPES_LIST_WITH_DEFAULT}*/}
			{/*							onDropdownChange={(value) => openContentTypeModal(value)}*/}
			{/*						/>*/}
			{/*						<ExplicitContent*/}
			{/*							modalOpen={modalOpenExplicitContent}*/}
			{/*							setModalOpen={setModalOpenExplicitContent}*/}
			{/*							selectedItems={selectedExplicitContent}*/}
			{/*							isExplicitContent={true}*/}
			{/*							onSelectedItems={(value) => setSelectedExplicitContent(value)}*/}
			{/*						/>*/}
			{/*					</div>*/}
			{/*					<div className="col-span-4">*/}
			{/*						<p className="body-txtitalic--smallest">*/}
			{/*							Change this setting if your Membership Details, Image, or Video*/}
			{/*							contains explicit or sensitive content. If it does, viewers may*/}
			{/*							need to confirm their age before accessing content. View our*/}
			{/*							Content Guidelines.*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*				</div>*/}
			{/*				<div className="grid grid-cols-12 gap-8 mt-4 flex items-center">*/}
			{/*					<div className="col-span-8">*/}
			{/*						<DropdownWithItems*/}
			{/*							title="Select Access Type"*/}
			{/*							dropDownClass="dropdown-bg"*/}
			{/*							customButtonClass="button gradient secondary distribute w-full"*/}
			{/*							displayError={*/}
			{/*								displayDropDownError && selectedAccessType === 'default'*/}
			{/*							}*/}
			{/*							icon={getListIcon(*/}
			{/*								ACCESS_TYPES_LIST_WITH_DEFAULT,*/}
			{/*								selectedAccessType*/}
			{/*							)}*/}
			{/*							selectedValue={selectedAccessType}*/}
			{/*							listItems={ACCESS_TYPES_LIST_WITH_DEFAULT}*/}
			{/*							onDropdownChange={(value) => openAccessTypeModal(value)}*/}
			{/*						/>*/}
			{/*						<ExplicitContent*/}
			{/*							modalOpen={modalOpenExplicitAccess}*/}
			{/*							setModalOpen={setModalOpenExplicitAccess}*/}
			{/*							selectedItems={selectedExplicitAccess}*/}
			{/*							isExplicitContent={false}*/}
			{/*							onSelectedItems={(value) => setSelectedExplicitAccess(value)}*/}
			{/*						/>*/}
			{/*					</div>*/}
			{/*					<div className="col-span-4">*/}
			{/*						<p className="body-txtitalic--smallest">*/}
			{/*							Change this setting if this Membership will provide Members with*/}
			{/*							access to explicit or sensitive content, either directly on your*/}
			{/*							Space or via an external site or other source. View our Content*/}
			{/*							Guidelines.*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*				</div>*/}
			{/*				<div className="grid grid-cols-12 gap-8 mt-4 flex items-center">*/}
			{/*					<div className="col-span-8">*/}
			{/*						<DropdownWithItems*/}
			{/*							title="Update Visibility"*/}
			{/*							dropDownClass="dropdown-bg"*/}
			{/*							customButtonClass="button gradient secondary distribute w-full"*/}
			{/*							selectedValue={selectedVisibility}*/}
			{/*							listItems={UPDATES_VISIBILITY}*/}
			{/*							onDropdownChange={(value) => setSelectedVisibility(value)}*/}
			{/*						/>*/}
			{/*					</div>*/}
			{/*					<div className="col-span-4">*/}
			{/*						<p className="body-txtitalic--smallest">*/}
			{/*							You can set the Visibility of this Membership to viewable to the*/}
			{/*							Public, Members Only (any membership), or Custom Access (based*/}
			{/*							on owning a specific Membership or Item).*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*				</div>*/}
			{/*				<div className="grid grid-cols-12 gap-8 mt-4 flex items-center">*/}
			{/*					<div className="col-span-12">*/}
			{/*						<div className="dialog-box-secondary">*/}
			{/*							<div className="content">*/}
			{/*								<div className="grid grid-cols-12 gap-8">*/}
			{/*									<div className="col-span-2">*/}
			{/*										<p className="header-small-title color-lightest text-white">*/}
			{/*											IMPORTANT NOTICE BASED ON YOUR CHANGES.*/}
			{/*											<br />*/}
			{/*											<br />*/}
			{/*											PLEASE REVIEW BEFORE SAVING.*/}
			{/*										</p>*/}
			{/*									</div>*/}
			{/*									<div className="col-span-10">*/}
			{/*										<ul className="list-none">*/}
			{/*											<li className="body-text--small color-lightest text-white">*/}
			{/*												Your Image and Video change will apply to*/}
			{/*												ALL Existing AND New Membership sales.*/}
			{/*											</li>*/}
			{/*											<li className="body-text--small color-lightest text-white">*/}
			{/*												Your Name and Individual Title change will*/}
			{/*												only apply to New Membership sales and may*/}
			{/*												cause new Memberships to display as a new*/}
			{/*												collection on external sites and*/}
			{/*												marketplaces.*/}
			{/*											</li>*/}
			{/*											<li className="body-text--small color-lightest text-white">*/}
			{/*												Your Description, Price, and Royalty*/}
			{/*												Percentage change will only apply to new*/}
			{/*												Membership sales.*/}
			{/*											</li>*/}
			{/*											<li className="body-text--small color-lightest text-white">*/}
			{/*												Your Monthly Subscription change will apply*/}
			{/*												to ALL Existing AND New Memberships sales.*/}
			{/*											</li>*/}
			{/*										</ul>*/}
			{/*									</div>*/}
			{/*								</div>*/}
			{/*							</div>*/}
			{/*						</div>*/}
			{/*					</div>*/}
			{/*				</div>*/}
			{/*				<div className="grid grid-cols-12 gap-8 mt-4 flex items-center">*/}
			{/*					<>*/}
			{/*						{alert && (*/}
			{/*							<div className="col-span-12">*/}
			{/*								<Alert*/}
			{/*									key={alert.variant}*/}
			{/*									variant={alert.variant}*/}
			{/*									className={*/}
			{/*										'custom-alert ' +*/}
			{/*										(alert.variant === 'danger'*/}
			{/*											? 'custom-alert-danger'*/}
			{/*											: 'custom-alert-success')*/}
			{/*									}*/}
			{/*								>*/}
			{/*									{alert.message}*/}
			{/*								</Alert>*/}
			{/*							</div>*/}
			{/*						)}*/}
			{/*					</>*/}
			{/*				</div>*/}
			{/*				<div className="grid grid-cols-12 gap-8 mt-4 flex items-center">*/}
			{/*					<div className="col-span-12">*/}
			{/*						<Form.Item className="mb-0">*/}
			{/*							<button*/}
			{/*								className="primary-gradient w-full"*/}
			{/*								type="submit"*/}
			{/*								disabled={submitted || formHasErrorMembership}*/}
			{/*							>*/}
			{/*							<span><p>{submitted ? (*/}
			{/*								<>*/}
			{/*									<i className="fa-solid fa-spinner fa-spin me-2"></i>*/}
			{/*									Saving*/}
			{/*								</>*/}
			{/*							) : (*/}
			{/*								<>Save</>*/}
			{/*							)}</p></span>*/}
			{/*							</button>*/}
			{/*						</Form.Item>*/}
			{/*					</div>*/}
			{/*				</div>*/}
			{/*			</Form>*/}
			{/*		</div>*/}
			{/*		<div className="dashboard-wrapper mt-5">*/}
			{/*			<div className="grid grid-cols-12 gap-8">*/}
			{/*				<div className="col-span-12">*/}
			{/*					<h3 className="header-small">Membership Benefits</h3>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*			<div className="grid grid-cols-12 gap-8 mt-5 flex items-center">*/}
			{/*				<div className="col-span-4">*/}
			{/*					<p className="body-text--small gradient-color-txt">Member Access</p>*/}
			{/*				</div>*/}
			{/*				<div className="col-span-4">*/}
			{/*					<p className="body-txtitalic--smallest">*/}
			{/*						The Member Access Benefit provides basic “Members Only” access to*/}
			{/*						Updates and Members sections in your Space. This is included in*/}
			{/*						every Membership you create and cannot be edited.*/}
			{/*					</p>*/}
			{/*				</div>*/}
			{/*				<div className="col-span-4">*/}
			{/*					<p className="body-text--small">Included Benefit</p>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*			{enableExpirationChecked && (*/}
			{/*				<div className="grid grid-cols-12 gap-8 mt-5 flex items-center">*/}
			{/*					<div className="col-span-4">*/}
			{/*						<p className="body-text--small gradient-color-txt">*/}
			{/*							Monthly Subscription*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*					<div className="col-span-4">*/}
			{/*						<p className="body-txtitalic--smallest">*/}
			{/*							The Monthly Subscription Benefit is required when the Expiration*/}
			{/*							Date option is enabled.*/}
			{/*						</p>*/}
			{/*					</div>*/}
			{/*					<div className="col-span-4 flexjustify-between">*/}
			{/*						<Link className="button-primary-gradient w-full">*/}
			{/*							<span><p><i className="fa-light fa-arrow-up-right-from-square"/></p></span>*/}
			{/*						</Link>*/}
			{/*						<Link*/}
			{/*							className="button-primary-gradient w-full"*/}
			{/*							to={redirectToMembershipUpdateBenefitLink(membershipData)}*/}
			{/*						>*/}
			{/*							<span><p><i className="fa-light fa-pen-to-square"/></p></span>*/}
			{/*						</Link>*/}
			{/*					</div>*/}
			{/*				</div>*/}
			{/*			)}*/}
			{/*			<div className="grid grid-cols-12 gap-8 mt-5 flex items-center">*/}
			{/*				<div className="col-span-12">*/}
			{/*					<DropdownWithItems*/}
			{/*						title="Add or Create Benefit"*/}
			{/*						dropDownClass="dropdown-bg"*/}
			{/*						customButtonClass="button gradient secondary distribute w-full"*/}
			{/*						icon="fa-solid fa-gifts"*/}
			{/*						selectedValue={addCreateBenefit}*/}
			{/*						listItems={ADD_CREATE_BENEFIT}*/}
			{/*						onDropdownChange={(value) => setAddCreateBenefit(value)}*/}
			{/*					/>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*			<div className="grid grid-cols-12 gap-8 mt-5 flex items-center">*/}
			{/*				<div className='col-span-12'>*/}
			{/*					<Link*/}
			{/*						className="button-primary-gradient w-full"*/}
			{/*						to={redirectToMembershipAddBenefitLink()}*/}
			{/*					>*/}
			{/*						<span><p>Save</p></span>*/}
			{/*					</Link>*/}
			{/*				</div>*/}
			{/*			</div>*/}
			{/*		</div>*/}
			{/*	</div>*/}
			{/*</div>*/}
		</>
	);
};

export default memo(EditMembershipCommunity);
