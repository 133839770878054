import React, {memo, useEffect, useState, Fragment, forwardRef, useRef} from 'react';
import { Link, useLocation } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from "react-i18next";
import { getSitewideLink } from '../../../utils/sitewideLinks';
import routes from '../../../routes';
import './FooterMobile.scss';
import { Dialog, Transition  } from '@headlessui/react';
import { AnimatePresence, motion } from 'framer-motion'
import { useNavigate } from 'react-router-dom';
import DropdownWithItems from '../../commons/DropdownWithItems';
import { LANGUAGE, LOCATION } from '../../../constants/profile';
import { Button } from 'antd';
import useIsInViewport from "../../../utils/checkPageScrollPosition";
import {setIsFooterInViewPort} from "../../../states/footerInViewSlice";
import {setSelectedLanguage} from "../../../states/language";
function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
  }

const FooterMobile = (props) => {
	const { t, i18n } = useTranslation();
	const [activeIndex, setActiveIndex] = useState(null);
	const handleClick = (index) => {
		setActiveIndex(index === activeIndex ? null : index);
	};
	const currentLocation = useLocation();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const [profileAssetType, setProfileAssetType] = useState(null);
	const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
	const [isBurgerClicked, setBurgerClicked] = useState(true);
	const [isProfileClicked, setProfileClicked] = useState(true);
	const [profileMenuOpen, setProfileMenuOpen] = useState(false);
	const { spaceData, activeTab, onSelectedTabChange, ref1 } = props;
	const { languageList } = useSelector((state) => state.language);
	const { selectedLanguage } = useSelector((state) => state.language);
	const locationList = LOCATION;
	const [selectedLocation, setSelectedLocation] = useState('United States');
	const navigate = useNavigate();
	const path = currentLocation.pathname;
	// const isDashboardView = path.startsWith('/@');
	const [isDashboardView, setIsDashboardView] = useState(false);
	const [spaceUsername, setSpaceUsername] = useState(null);

	const ref = useRef(null);
	const isInViewport = useIsInViewport(ref);
	const dispatch = useDispatch();


	useEffect(() => {
		dispatch(setIsFooterInViewPort(isInViewport));
	}, [isInViewport]);
	
	useEffect(() => {
		if (authUserInfo && authUserInfo.profile) {
			setProfileAssetType(null);
			if (authUserInfo.profile.photo_large) {
				setProfileAssetType('image');
			}
			if (authUserInfo.profile.video) {
				setProfileAssetType('video');
			}
		}
	}, [authUserInfo, loading, profileAssetType]);
	useEffect(() => {
		setActiveIndex(0);
	}, []);
	useEffect(() => {
		setSelectedLocation('United States');
		setActiveIndex(0);
	}, []);

	  useEffect(() => {
		const path = currentLocation.pathname;
		if (path && path.startsWith('/@') && path.length > 3) {
			let username = path.replace('/@', '');
			
			if (username.includes('/')) {
				const usernameExp = username.split('/');
				username = usernameExp[0] ? usernameExp[0] : '';
				setIsDashboardView(path.startsWith('/@') && usernameExp[1] !== 'about');
				setSpaceUsername(username);
			}
		}})
	
	const closeDialog = async () => {
		setProfileMenuOpen(false);
	}

	const redirectToSpaceLink = (route, spaceUsername) => {
		if (spaceUsername) {
			return route.replace(':space_username', '@' + spaceUsername);
		}
		return '';
	};

	const redirectToUserLink = (route) => {
		return route.replace(':username', '@' + authUserInfo.profile.username);
		
	};
	
	const login = async () => {
		navigate(routes.login());
		setMobileMenuOpen(false);
		setProfileMenuOpen(false);
	}
	const signup = async () => {
		navigate(routes.signUp());
		setMobileMenuOpen(false);
		setProfileMenuOpen(false);
	}

	useEffect(() => {
		// console.log(isBurgerClicked);
	  }, [isBurgerClicked]);

	//CARL: added extra function to address HeadlessUI Dialog onClose issue
	//CARL: this will fix issue on onClick & onClose conflict
	//ISSUE: onClose sets value to false, onClick sets value to true
	const openMenu = (type) => {
		if(type === 'burger'){
			setBurgerClicked(true);	
			
			if(isBurgerClicked){
				setMobileMenuOpen(mobileMenuOpen => !mobileMenuOpen);
				setBurgerClicked(false);
			}	
			
		}else if(type === 'profile'){
			setProfileClicked(true);	
			if(isProfileClicked){
				setProfileMenuOpen(profileMenuOpen => !profileMenuOpen);
				setProfileClicked(false);
			}
		}
	}

	useEffect(() => {}, [selectedLanguage]);
	

	return (
		<footer ref={ref} className="flex justify-center fixed inset-x-0 bottom-0 z-10 h-[48px] footer">
			
			{!authUserInfo && (
				<div className='container px-[20px] md:px-[40px] flex items-center  mx-auto'>
					<div className="grid grid-cols-1 flex justify-center items-center grid-flow-col gap-4 w-full text-xl text-slate-500 text-center" onClick={() => openMenu('burger')}>
					<i className={classNames("text-xl fa fa-bars justify-center",
							mobileMenuOpen ? 'gradient-color-txt' : '')} aria-hidden="true"></i>
						{/* <button
							type="button"
							className="inline-flex items-center justify-center  rounded-none p-2.5 text-gray-700"
							onClick={() => openMenu('burger')}
						>
							<span className="sr-only">Open main menu</span>
							<i class="" className={classNames("text-[32px] fa fa-bars",
							mobileMenuOpen ? 'gradient-color-txt' : '')} aria-hidden="true"></i>
						</button> */}
					</div>
				</div>
			)}
			
			
			{authUserInfo && (
				<div className='container px-[20px] md:px-[40px] flex items-center  mx-auto'>
				<div className='grid grid-cols-5 grid-flow-col gap-4 w-full text-xl text-slate-500 h-[48px]'>
					<div className='flex justify-center justify-items-center text-xl py-[8px]  m-auto'>
						<Link to="#">
							<i className="fa-solid fa-magnifying-glass text-[20px]"></i>
						</Link>
					</div>
					<div className='flex justify-center justify-items-center text-[20px]  m-auto'>
						<Link to="#">
							<i className="fa-solid fa-bell"></i>
						</Link>
					</div>
					<div className='flex h-10 items-center w-10 justify-center justify-items-center text-xl  m-auto' onClick={() => openMenu('profile')}>
					<div className={classNames("dropdown rounded-full profile drop-shadow-[0_2px_3px_rgba(0,0,0,0.25)] overflow-hidden !h-[32px] !w-[32px]",
						profileMenuOpen ? 'icon-border-gradient' : '')} >
							<Link
								to="#"
								
							>
								{profileAssetType &&
								profileAssetType === 'video' ? (
									<video autoPlay muted loop disablePictureInPicture={true}>
										<source
											className='!h-[100%] !w-full object-cover'
											src={authUserInfo.profile.video}
											type="video/mp4"
										/>
									</video>
								) : (
									<>
										{profileAssetType === 'image' ? (	
											<img
											className='!h-full !w-full object-cover'
												src={
													authUserInfo.profile.photo_thumb
												}
											/>
										) : (
											<div className="blank-avatar">
												<i className="fa-solid fa-user"></i>
											</div>
										)}
									</>
								)}
							</Link>
							<Dialog
								as="div"
								className="lg:hidden"
								open={profileMenuOpen}
								onClose={() => setProfileMenuOpen(false)}
							>
								{/* <div className="fixed !z-10 inset-0 bg-white top-[50%]" aria-hidden="true" /> */}
								<Dialog.Panel className="block fixed bottom-[104px]  md:hidden bg-white w-full z-[999999] h-[370px] overflow-auto">					
									
									<div className='grid grid-cols-12  mt-[24px] text-secondary items-center gradient-color-txt'>
										<div className='col-span-5 text-right pr-[24px] text-[20px] py-[4px]'>
											<i className="fa-regular fa-chart-tree-map text-right "></i>{' '}
										</div>
										<div className=''>
											<Link
													to={!isDashboardView ? routes.dashboard() : redirectToSpaceLink(
														routes.spaceDashboard(),
														spaceUsername
													)}
													className="dropdown-item text-center py-[4px]"
													onClick={closeDialog}
											>
												<span className="text-xl font-[notoSansSemiBold] ">										
												{t('nav_dashboard')}
												</span>
												
											</Link>
										</div>
										
									</div>
									{ !isDashboardView && (
									<div className='grid grid-cols-12  mt-[24px] text-secondary items-center gradient-color-txt'>
										<div className='col-span-5 text-right pr-[24px] text-[20px] py-[4px]'>
										<i className="fa-regular fa-list-ul"></i>
										</div>
										<div className=''>
											<Link
													to={redirectToUserLink(
														routes.activities())}
													className="dropdown-item text-center py-[4px]"
													onClick={closeDialog}
											>
												<span className="text-xl font-[notoSansSemiBold]">										
													Activity
												</span>
												
											</Link>
										</div>
										
									</div>
									)}
									
									
									{ isDashboardView && (
									<div className='grid grid-cols-12  mt-[24px] text-secondary items-center gradient-color-txt'>
										<div className='col-span-5 text-right pr-[24px] text-[20px] py-[4px]'>
										<i className="fa-regular fa-list-ul"></i>
										</div>
										<div className=''>
											<Link
													to={redirectToSpaceLink(
														routes.spaceActivity(),
														spaceUsername)}
													className="dropdown-item text-center py-[4px]"
													onClick={closeDialog}
											>
												<span className="text-xl font-[notoSansSemiBold]">										
													Activity
												</span>
												
											</Link>
										</div>
										
									</div>
									)}
									{ isDashboardView && (
									<div className='grid grid-cols-12  mt-[24px] text-secondary items-center gradient-color-txt'>
										<div className='col-span-5 text-right pr-[24px] text-[20px] py-[4px]'>
											<i className="fa-regular fa-users"></i>
										</div>
										<div className=''>
											<Link
													to={redirectToSpaceLink(
														routes.spaceMembers(),
														spaceUsername
													)}
													className="dropdown-item text-center py-[4px]"
													onClick={closeDialog}
											>
												<span className="text-xl font-[notoSansSemiBold]">										
                                                {t('nav_members')}
												</span>
												
											</Link>
										</div>
										
									</div>
									)}
									{ isDashboardView && (
									<div className='grid grid-cols-12  mt-[24px] text-secondary items-center gradient-color-txt'>
										<div className='col-span-5 text-right pr-[24px] text-[20px] py-[4px]'>
											<i className="fa-regular fa-memo"></i>
										</div>
										<div className=''>
											<Link
													to={redirectToSpaceLink(
														routes.spaceUpdates(),
														spaceUsername
													)}
													className="dropdown-item text-center py-[4px]"
													onClick={closeDialog}
											>
												<span className="text-xl font-[notoSansSemiBold]">										
													{t('nav_updates')}
												</span>
												
											</Link>
										</div>
										
									</div>
									)}
									{ isDashboardView && (
									<div className='grid grid-cols-12  mt-[24px] text-secondary items-center gradient-color-txt'>
										<div className='col-span-5 text-right pr-[24px] text-[20px] py-[4px]'>
											<i className="fa-regular fa-gift"></i>
										</div>
										<div className=''>
											<Link
													to={redirectToSpaceLink(
														routes.spaceBenefits(),
														spaceUsername
													)}
													className="dropdown-item text-center py-[4px]"
													onClick={closeDialog}
											>
												<span className="text-xl font-[notoSansSemiBold]">										
													{t('nav_benefits')}
												</span>
												
											</Link>
										</div>
										
									</div>
									)}
									{ isDashboardView && (
									<div className='grid grid-cols-12  mt-[24px] text-secondary items-center gradient-color-txt'>
										<div className='col-span-5 text-right pr-[24px] text-[20px] py-[4px]'>
											<i className="fa-regular fa-hexagon-image"></i>
										</div>
										<div className=''>
											<Link
													to={redirectToSpaceLink(routes.spaceItems(), spaceUsername)}
													className="dropdown-item text-center py-[4px]"
													onClick={closeDialog}
											>
												<span className="text-xl font-[notoSansSemiBold]">										
												Items
												</span>
												
											</Link>
										</div>
										
									</div>
									)}
									{ isDashboardView && (
									<div className='grid grid-cols-12  mt-[24px] text-secondary items-center gradient-color-txt'>
										<div className='col-span-5 text-right pr-[24px] text-[20px] py-[4px]'>
											<i className="fa-regular fa-id-card"></i>
										</div>
										<div className=''>
											<Link
													to={redirectToSpaceLink(
														routes.spaceMemberships(),
														spaceUsername
													)}
													className="dropdown-item text-center py-[4px]"
													onClick={closeDialog}
											>
												<span className="text-xl font-[notoSansSemiBold]">										
												Memberships
												</span>
												
											</Link>
										</div>
										
									</div>
									)}
									{ isDashboardView && (
									<div className='grid grid-cols-12  mt-[24px] text-secondary items-center gradient-color-txt gradient-color-txt'>
										<div className='col-span-5 text-right pr-[24px] text-[20px] py-[4px]'>
											<i className="fa-regular fa-cash-register"></i>
										</div>
										<div className=''>
											<Link
													to={redirectToSpaceLink(
														routes.spaceTransactions(),
														spaceUsername
													)}
													className="dropdown-item text-center py-[4px]"
													onClick={closeDialog}
											>
												<span className="text-xl font-[notoSansSemiBold]">										
												Transactions
												</span>
												
											</Link>
										</div>
										
									</div>
									)}
									<div className='grid grid-cols-12 mt-[24px] text-secondary items-center gradient-color-txt'>
										<div className='col-span-5 text-right pr-[24px] py-[4px]'>
										<i className="fa-regular fa-solar-system text-right text-[20px]"></i>{' '}
										</div>
										<div className=''>
											<Link
												to={ !isDashboardView ? routes.spaces() : redirectToSpaceLink(
													routes.spaceManageSpace(),
													spaceUsername
												)}
												className="dropdown-item text-center py-[4px]"
												onClick={closeDialog}
											>
												<span className="text-xl font-[notoSansSemiBold] ">										
												{t('nav_spaces')}
												</span>
											</Link>
										</div>								
									</div>
									{ !isDashboardView && (
									<div className='grid grid-cols-12  mt-[24px] text-secondary items-center gradient-color-txt'>
										<div className='col-span-5 text-right pr-[24px] text-[20px] py-[4px]'>
											<i className="fa-regular fa-grid"/>
										</div>
										<div className=''>
											<Link
													to={routes.collections()}
													className="dropdown-item text-center py-[4px]"
													onClick={closeDialog}
											>
												<span className="text-xl font-[notoSansSemiBold]">										
                                                    {t('nav_collections')}
												</span>
												
											</Link>
										</div>
										
									</div>
									)}
									<div className='grid grid-cols-12  mt-[24px] text-secondary  items-center gradient-color-txt'>
										<div className='col-span-5 text-right pr-[24px] py-[4px]'>
											<i className="fa-regular fa-id-badge text-right text-[20px]"></i>{' '}
										</div>
										<div className=''>
											<Link
												to={routes.profile()}
												onClick={closeDialog}
												className="dropdown-item text-center py-[4px]"

											>										
												<span className="text-xl font-[notoSansSemiBold] ">										
												{t('nav_profile')}
												</span>
											</Link>
										</div>								
									</div>
									<div className='grid grid-cols-12  mt-[24px] text-secondary items-center gradient-color-txt'>
										<div className='col-span-5 text-right pr-[24px] py-[4px]'>
											<i className="fa-solid fa-gear text-right text-[20px]"></i>{' '}
										</div>
										<div className=''>
											<Link
												to={!isDashboardView ? routes.settings() : redirectToSpaceLink(
																routes.spaceSettings(),
																spaceUsername
															)}
												className="dropdown-item text-center py-[4px]"
												onClick={closeDialog}
											>										
												<span className="text-xl font-[notoSansSemiBold] ">										
													Settings
												</span>
											</Link>
										</div>								
									</div>
									<div className='grid grid-cols-12  mt-[24px] text-secondary items-center gradient-color-txt'>
										<div className='col-span-5 text-right pr-[24px] py-[4px]'>
											<i className="fa-regular fa-arrow-right-from-bracket text-righ text-[20px]"></i>{' '}
										</div>
										<div className=''>
										<Link
												to={routes.logout()}
												onClick={closeDialog}
												className="dropdown-item text-xl text-center py-[4px]"
											>										
												<span className="text-xl font-[notoSansSemiBold] ">										
                                                {t('nav_logout')}
												</span>
											</Link>
										</div>								
									</div>
									{!authUserInfo && (
									<div className='sub grid grid-cols-2 grid-flow-col gap-4 px-[40px] pt-6'>
										<div className='h-[48px]'>
											<div onClick={login}  className='border-gradient w-full lg:right-[-109px] h-[48px] rounded-[6rem] cursor-pointer  z-50  text-white flex justify-center mx-auto'>
												<span className='block p-[13px]  font-semibold non-italic font-sans text-sm '>
												<p className='gradient-color-txt' > Login </p>
												</span>
											</div>
										</div>
										<div className='h-[48px]'>
											<div onClick={signup}   className='border-filled w-full  lg:right-[-109px] h-[48px] rounded-[6rem] cursor-pointer  z-50  text-white flex justify-center mx-auto'>
												<span className='block p-[13px]  font-semibold non-italic font-sans text-sm '>{t('nav_signup')}</span>
											</div>
										</div>
									</div>
									)}
								</Dialog.Panel>
							</Dialog>
						</div>
					</div>
					<div className='flex justify-center justify-items-center text-xl  m-auto items-center'>
						<Link to="#">
							<i className="fa-solid fa-wallet text-[20px]"></i>
						</Link>
					</div>
					<div className='flex justify-center justify-items-center text-xl  m-auto items-center z-60' onClick={() => openMenu('burger')}>
					<i className={classNames("text-[20px] fa fa-bars",
							mobileMenuOpen ? 'gradient-color-txt' : '')} aria-hidden="true" ></i>
						{/* <button
							type="button"
							className="-m-2.5 inline-flex items-center justify-center rounded-none p-2.5 text-gray-700"
							onClick={() => openMenu('burger')}
							>
							<span className="sr-only">Open main menu</span>
							<i class="" className={classNames("text-[20px] fa fa-bars",
							mobileMenuOpen ? 'gradient-color-txt' : '')} aria-hidden="true"></i>
						</button> */}
					</div>
					

				</div>
				</div>
			)}
				{ mobileMenuOpen && (
				<Dialog
					as="div"
					className="lg:hidden"
					open={mobileMenuOpen}
					onClose={() => setMobileMenuOpen(false)}				>
						{/* <div className="fixed inset-0 bg-white top-[50%]" aria-hidden="true" /> */}
					<Dialog.Panel className="h-[474px] fixed bottom-[104px] px-6 bg-white w-full z-[99999] overflow-y-auto flex justify-center md:hidden">	
						<div className='container grid grid-cols-12 grid-rows-12 gap-6 py-6 overflow-auto'>
							<div className='h-10 col-span-12'>
								<DropdownWithItems
									title="Location"
									customButtonClass="btn-md btn-secondary w-full"
									icon="fa-regular fa-globe"
									selectedValue={selectedLocation}
									listItems={locationList}
									onDropdownChange={setSelectedLocation}
								/>
							</div>
							<div className='h-10 col-span-12'>
								<DropdownWithItems
									title="EN"
									customButtonClass="icon-dropdown-gradient-alt"
									icon="fa-light fa-language"
									selectedValue={selectedLanguage}
									listItems={languageList}
									onDropdownChange={(value)=>{
										dispatch(setSelectedLanguage(value));
										i18n.changeLanguage(value);
									}
									}
								/>
							</div>
							<div className='h-10 col-span-12 grid grid-cols-12 social-links list-unstyled list-none'>
								<div className='col-span-2 flex justify-center items-center text-xl text-slate-400'>
									{getSitewideLink('footer_twitter_icon', "social-links list-unstyled", true)}
								</div>
								<div className='col-span-2 flex justify-center items-center text-xl text-slate-400'>
									{getSitewideLink('footer_instagram_icon', "social-links list-unstyled", true)}
								</div>
								<div className='col-span-2 flex justify-center items-center text-xl text-slate-400'>
									{getSitewideLink('footer_facebook_icon', "social-links list-unstyled", true)}
								</div>
								<div className='col-span-2 flex justify-center items-center text-xl text-slate-400'>
									{getSitewideLink('footer_youtube_icon', "social-links list-unstyled", true)}
								</div>
								<div className='col-span-2 flex justify-center items-center text-xl text-slate-400'>
									{getSitewideLink('footer_tiktok_icon', "social-links list-unstyled", true)}
								</div>
								<div className='col-span-2 flex justify-center items-center text-xl text-slate-400'>
									{getSitewideLink('footer_discord_icon', "social-links list-unstyled", true)}
								</div>
							</div>
							<div className='h-10 col-span-12 col-span-12 grid grid-cols-12  font-[notoSansSemiBold]'>
								<div className='col-span-4 flex justify-start md:justify-end items-center text-xl text-slate-400  footer-links'>
									<a href='#'>{t('nav_explore')}</a>
								</div>
								<div className='col-span-4 flex justify-center items-center text-xl text-slate-400  footer-links'>
									<a href='#'>{t('nav_create')}</a>
								</div>
								<div className='col-span-4 flex justify-end md:justify-start items-center text-xl text-slate-400  footer-links'>
									<a href='#'>Collab</a>
								</div>
							</div>
							<div className='h-10 col-span-12 col-span-12 grid grid-cols-12  font-[notoSansSemiBold]'>
								<div className='col-span-4 flex justfiy-start md:justify-end items-center text-xl text-slate-400  footer-links'>
									<a href='#'>{t('nav_learn')}</a>
								</div>
								<div className='col-span-4 flex justify-center items-center text-xl text-slate-400  footer-links'>
									<a href='#'>{t('nav_about')}</a>
								</div>
								<div className='col-span-4 flex justify-end md:justify-start items-center text-xl text-slate-400  footer-links'>
									<a href='#'>{t('nav_terms')}</a>
								</div>
							</div>
							<div className='h-10 col-span-12 col-span-12 grid grid-cols-12 font-[notoSansSemiBold] '>
								<div className='col-span-4 flex justify-start md:justify-end items-center text-xl text-slate-400  footer-links'>
									<a href='#'>{t('nav_account')}</a>
								</div>
								<div className='col-span-4 flex justify-center items-center text-xl text-slate-400  footer-links'>
									<a href='#'>{t('nav_resources')}</a>
								</div>
								<div className='col-span-4 flex justify-end md:justify-start items-center text-xl text-slate-400  footer-links'>
									<a href='#'>{t('nav_support')}</a>
								</div>
							</div>
							{!authUserInfo && (
							<div className='h-10 col-span-12 grid grid-cols-12 gap-x-6'>							
								<div className='col-span-6 flex justify-center items-center'>
									<Button className="btn btn-md btn-secondary mb-2" onClick={login}>
                                        {t('nav_login')}
									</Button>
								</div>
								<div className='col-span-6 flex justify-center items-center'>
									<Button className="btn btn-md btn-primary mb-2" onClick={signup}>
                                        {t('nav_signup')}
									</Button>
								</div>
							</div>
							)}
						</div>
												
					</Dialog.Panel>
				</Dialog>	
				)}
			
		</footer>
	);
};
export default memo(FooterMobile);
