import React, { memo, useCallback, useEffect, useState } from 'react';
import AccessGroupModal from "../../../../components/commons/Modals/AccessGroupModal/AccessGroupModal";
import CreateAccessGroupModal
	from "../../../../components/commons/Modals/CreateAccessGroupModal/CreateAccessGroupModal";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getSpaceAccessGroups} from "../../../../services/space";
import {Form} from "antd";
import {VALIDATE_MESSAGES} from "../../../../constants/common";
import {CUSTOM_REGEX} from "../../../../constants/custom_regex";
import FormFieldStacked from "../../../../components/commons/FormFieldStacked";
import DropdownWithItems from "../../../../components/commons/DropdownWithItems";
import {
	SPACE_CHANNEL_ACCESS,
	SPACE_CHANNEL_COMMENTING,
	SPACE_CHANNEL_POSTING,
	SPACE_CHANNEL_VISIBILITY
} from "../../../../constants/space";
import UpdateAccessGroupModal
	from "../../../../components/commons/Modals/UpdateAccessGroupModal/UpdateAccessGroupModal";

const ManageAccessGroups = () => {
	const { space_username } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
	const [accessGroupList, setAccessGroupList] = useState([]);
	const [createAccessGroupModalOpen, setCreateAccessGroupModalOpen] = useState(false);
	const [updateAccessGroupModalOpen, setUpdateAccessGroupModalOpen] = useState(false);
	const [selectedGroup, setSelectedGroup] = useState();

	const getSpaceAccessGroupsRequest = useCallback(
		async () => {
			try {
				if (spaceInfo) {
					const space_param = {}
					space_param.space_id = spaceInfo.id;

					const responseGetSpaceAccessGroups = await getSpaceAccessGroups(space_param);
					if (responseGetSpaceAccessGroups && responseGetSpaceAccessGroups.result) {
						let groupArray = [];
						const groupList = responseGetSpaceAccessGroups.access_groups;
						if (groupList && groupList.length > 0) {
							groupList.map((group) => {
								groupArray.push({
									id: group.id,
									label: group.name,
									value: group.id,
									data: group
								})
							})
						}
						setAccessGroupList(groupArray);
					}
				}
			} catch (error) {
			}
		},
		[spaceInfo]
	);

	const handleUpdateAccessGroup = useCallback(
		(group) => {
			setSelectedGroup(group);
		},
		[spaceInfo, selectedGroup]
	);

	const updateAccessGroup = (group) => {
		getSpaceAccessGroupsRequest().then(r => {});
	}

	useEffect(() => {
		if (spaceInfo)
			getSpaceAccessGroupsRequest().then(r => {});
	}, [spaceInfo]);

	useEffect(() => {
		if (selectedGroup) {
			setUpdateAccessGroupModalOpen(true);
		}
	}, [accessGroupList, selectedGroup]);

	useEffect(() => {
		if (!updateAccessGroupModalOpen) {
			setSelectedGroup(null);
		}
	}, [updateAccessGroupModalOpen]);

	return (
		<div className="block-full">
			{createAccessGroupModalOpen && (
				<CreateAccessGroupModal
					modalOpen={createAccessGroupModalOpen}
					setModalOpen={setCreateAccessGroupModalOpen}
					spaceMembership={spaceInfo.memberships}
					spaceItems={spaceInfo.items}
					updateAccessGroupList={updateAccessGroup}
					setAccessGroupModalOpen={setCreateAccessGroupModalOpen}
					spaceID={spaceInfo.id}
					userID={authUserInfo.id}
				/>
			)}

			{updateAccessGroupModalOpen && (
				<UpdateAccessGroupModal
					modalOpen={updateAccessGroupModalOpen}
					setModalOpen={setUpdateAccessGroupModalOpen}
					spaceMemberships={spaceInfo.memberships}
					spaceItems={spaceInfo.items}
					updateAccessGroupList={updateAccessGroup}
					setAccessGroupModalOpen={setUpdateAccessGroupModalOpen}
					spaceID={spaceInfo.id}
					userID={authUserInfo.id}
					selectedAccessGroup={selectedGroup}
				/>
			)}

			<div id="cs-platform-dash" className="cs-dash">
				<div className="cs-dashframe section-flex">
					<div className="cs-dash-card block-full card">
						<hgroup className="heading">
							<h2>Access Groups</h2>
							<p>Create and manage access to your Access Groups here. You can control access to content, posts, and channels by specifying which Memberships and Items are included in each Access Group. <a href="link-via-admin" target="_blank">Learn more about Access Groups.</a></p>
						</hgroup>
						<a onClick={() => {
							   setCreateAccessGroupModalOpen(true);
						   }}
						   id="modal-open" className="button gradient secondary"><span><p>Create New Access Group</p></span></a>
					</div>

					{(accessGroupList && accessGroupList.length > 0) && (
						<div id="draggable-cards" className={"cs-stack-form-drag-cards gap-gutter"}>
							{accessGroupList.map((group, index) => (
								<div draggable="true" className="cs-dash-card block-full card cs-form-drag-card" key={index}>
									<div className="cs-stack-controlrows stack-form-col">
										<div className="cs-form-drag-card-title">
											<div className="cs-controls-draggable">
												<p><i className="fa-regular fa-grip-lines"></i></p>
												<div className={"cs-controls-draggable-buttons"}>
													<button><i className="fa-regular fa-angle-up"></i></button>
													<button><i className="fa-regular fa-angle-down"></i></button>
												</div>
											</div>
											<div className="cs-channel-card-title stack-row">
												<h4>{group.label}</h4>
											</div>
										</div>
										<div className="cs-stack-icons-attributes">
											<p><i className="fa-regular fa-id-card"></i></p>
											<p>{JSON.parse(group.data.included_memberships).length}</p>
											<p><i className="fa-regular fa-hexagon-image"></i></p>
											<p>{JSON.parse(group.data.included_items).length}</p>
										</div>
										<div className="cs-channel-card-controls stack-row">
											<a className="button gradient secondary"
											   onClick={() => { handleUpdateAccessGroup(group.data) }}>
												<span><i className="fa-light fa-money-check-pen"></i></span>
											</a>
										</div>
									</div>
								</div>
							))}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default memo(ManageAccessGroups);
