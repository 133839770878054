import { memo, useCallback, useEffect, useState } from 'react';
import { Layout } from 'antd';

import './Home.scss';
import { getAuthUserInfo } from '../../states/general/actions';
import { useDispatch, useSelector } from 'react-redux';
import { checkIsLoggedIn, removeAccessToken } from '../../utils/common';
import { getLocalStorage, setLocalStorage } from '../../utils/storage';
import { CREATE_SPACE_UID, ROOT_DOMAIN, STORAGE_KEYS } from '../../constants/common';
import { refresh_token } from '../../services/auth';
import {Route, useLocation, useSearchParams} from 'react-router-dom';
import routes from '../../routes';
import UserView from "../Profile/UserView";
import {USER_VIEW_TYPES} from "../../constants/user";
import HeaderPro from "../SpaceView/SpaceViewPro/HeaderPro";
import Header from "../../components/layouts/Header";
import FooterProV2 from "../SpaceView/SpaceViewPro/FooterProV2/FooterProV2";
import FooterV2 from "../../components/layouts/FooterV2/FooterV2";
import {getSpaceRoute} from "../../utils/redirects";
// import './AppLayout.scss';

const Home = (props) => {
	const {
		onSelectedTabChange,
		spaceViewDataByDomain
	} = props;
	const [searchParams] = useSearchParams();
	const currentLocation = useLocation();
	const dispatch = useDispatch();
	let isLoggedIn = checkIsLoggedIn();
	const { displayPageContentLoaderStatus } = useSelector(
		(state) => state.displayPageContentLoader
	);
	const [isInitialLoad, setIsInitialLoad] = useState(true);
	const [tokenRequestSuccess, setTokenRequestSuccess] = useState(false);
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
	const [displaySpaceView, setDisplaySpaceView] = useState(false);
	const [proceedDisplaySpaceView, setProceedDisplaySpaceView] = useState(false);
	const [forCustomComponent, setForCustomComponent] = useState(false);
	const [activeTab, setActiveTab] = useState('home');
	const [spaceData, setSpaceData] = useState(null);

	const requestRefreshToken = async () => {
		const refreshToken = JSON.stringify(getLocalStorage(STORAGE_KEYS.REFRESH_TOKEN));

		try {
			const result = await refresh_token({ refresh: JSON.parse(refreshToken) });
			setLocalStorage(STORAGE_KEYS.ACCESS_TOKEN, result.access);
			setTokenRequestSuccess(true);
		} catch (e) {
			removeAccessToken();
			location.reload();
		}
	};

	const handleUserLoginRedirects = useCallback(async () => {
		const path = window.location.pathname;

		if (isLoggedIn === true) {
			if (isInitialLoad) {
				setIsInitialLoad(false);
				dispatch(getAuthUserInfo());
			}
		} else if (isLoggedIn === 'request-refresh') {
			if (!tokenRequestSuccess) {
				requestRefreshToken().then((res) => {});
			}
		} else {
			// if (window.location.hostname === 'www.common.space') {
			// 	const urlUIDparam = searchParams.get('uid');
			// 	if (CREATE_SPACE_UID) {
			// 		if (urlUIDparam === CREATE_SPACE_UID) {
			// 			window.location.replace(routes.login() + '?uid=' + urlUIDparam);
			// 		} else {
			// 			window.location.href = 'https://create.common.space';
			// 		}
			// 	}
			// } else {
			// 		window.location.replace(routes.login());
			// }

			if (ROOT_DOMAIN !== window.location.hostname) {
				if (path === '/') {
					// console.log('display space home');
				}
			} else {
				window.location.replace(routes.login());
			}
		}
	}, [tokenRequestSuccess, isLoggedIn, isInitialLoad]);

	const handleSpaceViewData = useCallback(
		async (data) => {
			// console.log(data);
		},
		[]
	);

	const handleGlobalActiveChange = useCallback(async (tab) => {
			setActiveTab(tab);
		}, [activeTab]
	);

	useEffect(() => {
		handleUserLoginRedirects().then((r) => {});

		if (loading && loading === 'done' && authUserInfo) {
			if (ROOT_DOMAIN !== window.location.hostname) {
				setDisplaySpaceView(true);
			} else {
				// window.location.replace(routes.dashboard());
			}
		}

		document.title = 'Commonspace';
	}, [tokenRequestSuccess, isLoggedIn, isInitialLoad, authUserInfo]);

	useEffect(() => {
		if (spaceInfoLoading && spaceInfoLoading === 'done') {
			if (spaceInfo && spaceInfo.id) {
				if (!spaceViewDataByDomain) {
					setSpaceData(spaceInfo);
				}
				setProceedDisplaySpaceView(true);
			}
		}
	}, [displaySpaceView, spaceInfo, spaceInfoLoading, spaceViewDataByDomain]);

	useEffect(() => {
		if (spaceViewDataByDomain) {
			setSpaceData(spaceViewDataByDomain);
		}
	}, [spaceViewDataByDomain]);

	useEffect(() => {}, [spaceData]);

	useEffect(() => {
		if (ROOT_DOMAIN !== window.location.hostname) {
			if ((currentLocation.pathname === '/update') || (currentLocation.pathname === '/benefit') ||
				(currentLocation.pathname === '/member') || (currentLocation.pathname === '/support') ||
				(currentLocation.pathname === '/about') ||

				(currentLocation.pathname === (getSpaceRoute(routes.channel().replace('/:space_username', '')))) ||
				(currentLocation.pathname === (getSpaceRoute(routes.channelGuid().replace('/:space_username', '')))) ||
				(currentLocation.pathname === (getSpaceRoute(routes.registerSpaceMemberProfile().replace('/:space_username', '')))) ||

				(currentLocation.pathname === (getSpaceRoute(routes.spaceMembershipBuy().replace('/:space_username', '')))) ||
				(currentLocation.pathname === (getSpaceRoute(routes.spaceMembershipPaymentMethod().replace('/:space_username', '')))) ||
				(currentLocation.pathname === (getSpaceRoute(routes.spaceMembershipPaymentCompleted().replace('/:space_username', '')))) ||
				(currentLocation.pathname === (getSpaceRoute(routes.spaceMembershipRegisterMemberProfile().replace('/:space_username', '')))) ||
				(currentLocation.pathname === (getSpaceRoute(routes.spaceMembershipActivate().replace('/:space_username', '')))) ||
				(currentLocation.pathname === (getSpaceRoute(routes.spaceMembershipWalletSetAccountEmailSent().replace('/:space_username', '')))) ||
				(currentLocation.pathname === (getSpaceRoute(routes.spaceMembershipEmailVerificationWalletSetPassword().replace('/:space_username', '')))) ||
				(currentLocation.pathname === (getSpaceRoute(routes.spaceMembersProductsMembership().replace('/:space_username', '')))) ||
				(currentLocation.pathname === (getSpaceRoute(routes.spaceMembershipWalletConnect().replace('/:space_username', '')))) ||
				(currentLocation.pathname === (getSpaceRoute(routes.spaceMembershipErrorConnect().replace('/:space_username', '')))) ||

				(currentLocation.pathname === (getSpaceRoute(routes.spaceItemBuy().replace('/:space_username', '')))) ||
				(currentLocation.pathname === (getSpaceRoute(routes.spaceItemPaymentMethod().replace('/:space_username', '')))) ||
				(currentLocation.pathname === (getSpaceRoute(routes.spaceItemPaymentCompleted().replace('/:space_username', '')))) ||
				(currentLocation.pathname === (getSpaceRoute(routes.spaceItemActivate().replace('/:space_username', '')))) ||
				(currentLocation.pathname === (getSpaceRoute(routes.spaceItemActivated().replace('/:space_username', '')))) ||
				(currentLocation.pathname === (getSpaceRoute(routes.spaceItemWalletConnect().replace('/:space_username', '')))) ||
				(currentLocation.pathname === (getSpaceRoute(routes.spaceItemErrorConnect().replace('/:space_username', '')))) ||
				(currentLocation.pathname === (getSpaceRoute(routes.spaceItemWalletSetAccountEmailSent().replace('/:space_username', '')))) ||
				(currentLocation.pathname === (getSpaceRoute(routes.spaceItemEmailVerificationWalletSetPassword().replace('/:space_username', '')))) ||
				(currentLocation.pathname === (getSpaceRoute(routes.spaceItemRegisterMemberProfile().replace('/:space_username', '')))) ||
				(currentLocation.pathname === (getSpaceRoute(routes.spaceMembersProductsItem().replace('/:space_username', '')))) ||

				(currentLocation.pathname === (getSpaceRoute(routes.spaceFAQ().replace('/:space_username', '')))) ||
				(currentLocation.pathname === (getSpaceRoute(routes.spaceTOS().replace('/:space_username', '')))) ||
				(currentLocation.pathname === (getSpaceRoute(routes.spacePrivacyPolicy().replace('/:space_username', '')))) ||
				(currentLocation.pathname === (getSpaceRoute(routes.spaceContact().replace('/:space_username', '')))) ||
				(currentLocation.pathname === (getSpaceRoute(routes.spaceCommunityGuidelines().replace('/:space_username', '')))) ||
				(currentLocation.pathname === (getSpaceRoute(routes.spaceViewSpaceUpdate().replace('/:space_username', '')))) ||
				(currentLocation.pathname === (getSpaceRoute(routes.spaceViewSpaceUpdateWithTitle().replace('/:space_username', ''))))
			) {
				setForCustomComponent(true);
			}
		}
	}, [currentLocation.pathname]);

	useEffect(() => {}, [proceedDisplaySpaceView, forCustomComponent]);

	return (
		<>
			{proceedDisplaySpaceView ? (
				<Layout>
					{spaceData && spaceData.plan === 'pro' ? (
						<HeaderPro
							onSelectedTabChange={handleGlobalActiveChange}
							spaceData={spaceData}
							activeTab={activeTab}
						/>
					) : (
						<Header
							spaceData={spaceData}
							globalActiveTab={activeTab}
						/>
					)}

					<UserView
						globalActiveTab={activeTab}
						spaceViewData={handleSpaceViewData}
						spaceViewDataByDomain={spaceData}
						updateGlobalActiveTab={
							handleGlobalActiveChange
						}
					/>

					{spaceData && spaceData.plan === 'pro' ?  (
						<FooterProV2
							spaceData={spaceData}
							isSpaceLogin={
								currentLocation.pathname === '/login'
							}
							activeTab={activeTab}
						/>
					) : (
						!displayPageContentLoaderStatus && (
							<FooterV2
								spaceData={spaceData}
							/>
						))}
				</Layout>
			) : (
				<>
					<main id="home" className="flex">
						<div className="container"></div>
					</main>
				</>
			)}
		</>
	);
};

export default memo(Home);
