const routes = {
	signUp: () => '/sign-up',
	login: () => '/login',
	forgotPassword: () => '/forgot-password',
	logout: () => '/logout',
	walletSetAccount: () => '/wallet/set-account',
	walletSetAccountEmailSent: () => '/wallet/set-account/email-sent',
	emailVerification: () => '/verify-email',
	registerMemberProfile: () => '/member-profile',
	connectWallet: () => '/connect-wallet',
	emailVerificationWalletSetPassword: () => '/wallet-set-password',
	app: () => '/',
	dashboard: () => '/dashboard',
	activities: () => '/:username/activities',
	termsOfUse: () => '/terms-of-use',
	support: () => '/support',
	privacyPolicy: () => '/privacy-policy',
	communityGuidelines: () => '/community-guidelines',

	//channel
	channel: () => '/:space_username/channel',
	channelGuid: () => '/:space_username/channel/:guid',
	// create space routes
	createSpaceGettingStarted: () => '/space/getting-started',
	createSpace: () => '/space/create',
	updateSpace: () => '/space/:space_username/update',
	createSpaceAddDetailsPage: () => '/space/create/:space_username/details',
	createSpaceAddMembershipPage: () => '/space/create/:space_username/membership',
	createSpaceAddCommunityPage: () => '/space/create/:space_username/community',
	createSpaceAddMembershipTraitsPage: () => '/space/create/:space_username/membership-traits',
	createSpaceAddMembershipBenefitPage: () => '/space/create/:space_username/benefit',
	createSpaceAddMembershipBenefitTraitsPage: () => '/space/create/:space_username/benefit-traits',
	createSpaceBasicCompletePage: () => '/space/create/:space_username/basic-complete',
	createSpaceAddFirstItemPage: () => '/space/create/:space_username/first-item',
	createSpacePostUpdate: () => '/space/create/:space_username/post-update',
	createCommunityFeatures: () => '/space/create/:space_username/community-features',
	createSpaceInviteTeam: () => '/space/create/:space_username/invite-team',
	createSpaceUpgradePlan: () => '/space/create/:space_username/upgrade-plan',
	createCustomizeProSpace: () => '/space/create/:space_username/customize-space',
	createCustomizeProNav: () => '/space/create/:space_username/customize-navigation',
	createSpaceLaunch: () => '/space/create/:space_username/launch',
	createSpaceLaunchCheckList: () => '/space/create/:space_username/launch-checklist',
	createSpaceAddBusiness: () => '/space/create/:space_username/business-info',
	createSpaceAddPages: () => '/space/create/:space_username/pages',
	createSpacePayment: () => '/space/create/:space_username/payment-method',
	createSpaceBilling: () => '/space/create/:space_username/billing-info',
	createSpacePrevLaunch: () => '/space/create/:space_username/preview-launch',

	spaces: () => '/spaces',
	manageSpaceMembership: () => '/spaces/:space_username/manage/active/membership/:membership_id',
	manageSpaceMembershipExpired: () =>
		'/spaces/:space_username/manage/inactive/membership/:membership_id',
	collections: () => '/collections',
	viewCollection: () => '/collections/:space_username/view',
	viewCollectionItem: () => '/collections/:space_username/view/item',
	viewCollectionManageMembership: () => '/collections/:space_username/membership/:membership_id',

	profile: () => '/profile',
	profileEdit: () => '/profile/edit',
	profileView: () => '/profile/view',
	profileCommunityBios: () => '/profile/community-bios',
	profileEditAboutSection: () => '/profile/about-section',
	profileManageFollowers: () => '/profile/manage-followers',
	profileManageContact: () => '/profile/manage-contact',
	profileManageVisibility: () => '/profile/visibility',
	settings: () => '/profile/settings',
	settings2FA: () => '/profile/settings/2fa',
	settingsNotificationPreferences: () => '/profile/settings/notification/preferences',
	settingsPaymentMethods: () => '/profile/settings/payment-methods',
	settingsMagicWallet: () => '/profile/settings/magic-wallet',
	settingsTransactions: () => '/profile/settings/transactions',
	alchemyLabolatory: () => '/alchemy/lab',
	viewUserSpace: () => '/@',
	// viewUserSpaceLogin: () => '/:space_username/login',
	registerSpaceMemberProfile: () => '/:space_username/member-profile',

	//Membership buy
	spaceMembershipBuy: () => '/:space_username/membership/buy',
	spaceMembershipPaymentMethod: () => '/:space_username/membership/buy/payment-method',
	spaceMembershipPaymentCompleted: () => '/:space_username/membership/buy/payment-completed',

	// CUSTOM DOMAIN Membership buy
	customDomainSpaceMembershipBuy: () => '/membership/buy',
	customDomainSpaceMembershipPaymentMethod: () => '/membership/buy/payment-method',
	customDomainSpaceMembershipPaymentCompleted: () => '/membership/buy/payment-completed',

	//Item buy
	spaceItemBuy: () => '/:space_username/item/buy',
	spaceItemPaymentMethod: () => '/:space_username/item/buy/payment-method',
	spaceItemPaymentCompleted: () => '/:space_username/item/buy/payment-completed',

	// CUSTOM DOMAIN Item buy
	customDomainSpaceItemBuy: () => '/item/buy',
	customDomainSpaceItemPaymentMethod: () => '/item/buy/payment-method',
	customDomainSpaceItemPaymentCompleted: () => '/item/buy/payment-completed',

	spaceMembersProductsMembership: () => '/:space_username/membership/claim',
	spaceMembershipWalletConnect: () => '/:space_username/membership/wallet-connected',
	spaceMembershipErrorConnect: () => '/:space_username/membership/error-connect',
	spaceMembershipWalletSetAccountEmailSent: () =>
		'/:space_username/membership/wallet/set-account/email-sent',
	spaceMembershipEmailVerificationWalletSetPassword: () =>
		'/:space_username/membership/wallet-set-password',
	spaceMembershipRegisterMemberProfile: () => '/:space_username/membership/member-profile',
	spaceMembershipActivate: () => '/:space_username/membership/activate',

	spaceMembersProductsItem: () => '/:space_username/item/claim',
	spaceItemWalletConnect: () => '/:space_username/item/wallet-connected',
	spaceItemErrorConnect: () => '/:space_username/item/error-connect',
	spaceItemWalletSetAccountEmailSent: () => '/:space_username/item/wallet/set-account/email-sent',
	spaceItemEmailVerificationWalletSetPassword: () => '/:space_username/item/wallet-set-password',
	spaceItemRegisterMemberProfile: () => '/:space_username/item/member-profile',
	spaceItemActivate: () => '/:space_username/item/activate',
	spaceItemActivated: () => '/:space_username/item/activated',

	spaceFAQ: () => '/:space_username/faq',
	spaceTOS: () => '/:space_username/terms-services',
	spacePrivacyPolicy: () => '/:space_username/privacy-policy',
	spaceContact: () => '/:space_username/contact',
	spaceCommunityGuidelines: () => '/:space_username/community-guidelines',

	// space dashboard routes
	spaceDashboard: () => '/:space_username/dashboard',
	spaceActivity: () => '/:space_username/activity',
	spaceMembers: () => '/:space_username/members',
	manageMember: () => '/:space_username/members/:member_id/manage',
	spaceUpdates: () => '/:space_username/updates',
	spaceBenefits: () => '/:space_username/benefits',
	spaceSupport: () => '/:space_username/support',
	spaceAbout: () => '/:space_username/about',

	// manage space update routes
	createUpdate: () => '/:space_username/updates/create',
	editUpdate: () => '/:space_username/updates/:update_id/edit',

	// manage space item routes
	spaceItems: () => '/:space_username/items',
	manageItem: () => '/:space_username/items/:item_id/manage',
	itemType: () => '/:space_username/item/type',
	createItemCommunity: () => '/:space_username/item/create/community',
	createItemNFT: () => '/:space_username/item/create/nft',
	editItem: () => '/:space_username/items/:item_id/edit',
	addItemMonthBenefit: () => '/:space_username/items/:item_id/benefit/add',
	editItemMonthBenefit: () => '/:space_username/items/:item_id/benefit/:benefit_id/edit',

	// manage space membership routes
	spaceMemberships: () => '/:space_username/memberships',
	manageMembership: () => '/:space_username/memberships/:membership_id/manage',
	membershipType: () => '/:space_username/membership/type',
	createMembershipCommunity: () => '/:space_username/membership/create/community',
	createMembershipNFT: () => '/:space_username/membership/create/nft',
	editMembership: () => '/:space_username/memberships/:membership_id/edit',
	addMemMonthBenefit: () => '/:space_username/memberships/:membership_id/benefit/add',
	editMemMonthBenefit: () => '/:space_username/memberships/:membership_id/benefit/:benefit_id/edit',

	spaceTransactions: () => '/:space_username/transactions',

	spaceManageSpace: () => '/:space_username/manage',
	spaceManageProfile: () => '/:space_username/manage/profile',
	spaceManageHomePage: () => '/:space_username/manage/homepage',
	spaceManageInfoPages: () => '/:space_username/manage/info-pages',
	spaceManageCustomizeSpace: () => '/:space_username/manage/customize-space',
	spaceManageCustomizeNav: () => '/:space_username/manage/customize-navigation',
	spaceManageChannels: () => '/:space_username/manage/manage-channels',
	spaceManageQuestions: () => '/:space_username/manage/questions',
	spaceManageAccessGroups: () => '/:space_username/manage/access-groups',

	spaceSettings: () => '/:space_username/settings',

	// space profile tabs url

	spaceViewSpaceUpdate: () => '/:space_username/update/:update_id',
	spaceViewSpaceUpdateWithTitle: () => '/:space_username/update/:update_id/:title'
};

export default routes;
