import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {localizationEN, localizationJP} from "../constants/cs_localization";
import {TRANSLATOR_DEBUG} from "../constants/common";
import {getLocalizations} from "../services/space";

const response = await getLocalizations();
if (response.result) {
    // console.log('test------result-----')
    const localizations = response.localizations;

    const resources = {};
    localizations.forEach((localization)=>{
        const temp_arr = {};
        if (localization.items.length > 0) {
            localization.items.forEach((item)=>{
                temp_arr[item['slug']] = item['text'];
            });
        }
        // console.log(temp_arr)
        resources[localization['abbreviation'].toLowerCase()] = {translation: temp_arr};
        // console.log(resources)
    });

    i18n
        // detect user language
        // learn more: https://github.com/i18next/i18next-browser-languageDetector
        .use(LanguageDetector)
        // pass the i18n instance to react-i18next.
        .use(initReactI18next)
        // init i18next
        // for all options read: https://www.i18next.com/overview/configuration-options
        .init({
            debug: TRANSLATOR_DEBUG,
            fallbackLng: 'en',
            interpolation: {
                escapeValue: false, // not needed for react as it escapes by default
            },
            resources: resources
        });
}

export default i18n;
