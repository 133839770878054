import { createSlice} from "@reduxjs/toolkit";

export const languageSlice = createSlice({
    name: "language",
    initialState: {
        languageList: [{
            label: 'EN',
            value: 'en'
        }],
        selectedLanguage: null,
        languageIsLoaded: false
    },
    reducers: {
        setLanguageList: (state, action) => {
            state.languageList = action.payload;
        },
        setSelectedLanguage: (state, action) => {
            state.selectedLanguage = action.payload;
        },
        setLanguageIsLoaded: (state) => {
            state.languageIsLoaded = true;
        }
    }
});

export const { setLanguageList, setSelectedLanguage, setLanguageIsLoaded } = languageSlice.actions;

export default languageSlice.reducer;