import React, {memo, useCallback, useEffect, useRef} from 'react';
import EllipsisDropdown from './EllipsisDropdown';
import './Channel.scss';
import ReactPlayer from 'react-player/lazy';
import {Form} from 'antd';
import {useTranslation} from "react-i18next";
import { useState } from 'react';
import './Channel.scss';
import {useDispatch, useSelector} from "react-redux";
import {getDataByUsername} from "../../services/general";
import {ROOT_DOMAIN, VALIDATE_MESSAGES} from '../../constants/common';
import {createSpaceModelComment, getSpaceDataByDomain} from '../../services/space';
import {
    deleteSpaceComment,
    getSpaceChannel,
    getSpaceChannels,
    getSpaceDefaultChannel,
    searchInputTags,
    userPostHeartComment,
    userPostUnHeartComment
} from "../../services/space";
import {
    getSpaceChannelPosts,
    updateSpacePost,
    userHeartPost,
    userUnheartPost,
    deleteSpacePost,
    userPinPost,
    userUnpinPost,
    createSpacePost
} from '../../services/post'
import {useParams} from "react-router-dom";
import default_user_profile from "../../assets/images/default_user_profile.png";
import ChannelSharePost from "../../components/commons/Modals/ChannelSharePost";
import ChannelPostForm from "../../components/commons/Modals/ChannelPostForm";
import {AUDIO_FILETYPES, IMAGE_FILETYPES, UPLOAD_FILE_TYPE, VIDEO_FILETYPES} from "../../constants/file";
import Dragger from "antd/es/upload/Dragger";
import {checkImageValidity, checkVideoValidity, getFileExtension} from "../../utils/file";
import {Slide} from "react-slideshow-image";
import 'react-slideshow-image/dist/styles.css';
import {setPost} from "../../states/channelSlice";
import routes from "../../routes";
import {getPostDate} from "../../utils/DateTimeUtils";
import default_profile_card_bg from '../../assets/images/card_bg.png';
import {USER_VIEW_TYPES} from "../../constants/user";
import {setIsNotPublicUrl, setIsPublicUrl} from "../../states/spaceViewMode";

const Channel = () => {
    const { t, i18n } = useTranslation();
    const { guid } = useParams();
    const { authUserInfo, loading } = useSelector((state) => state.general);
    const [fetchedGuid, setFetchGuid] = useState(null);
    const dispatch = useDispatch();
    const [ spaceData, setSpaceData ] = useState(null);
    const [spaceUpdates, setSpaceUpdates] = useState(null);
    const [spaceUsername, setSpaceUsername] = useState(null);
    const [spaceChannel, setSpaceChannel] = useState(null);
    const [channelPostsIsFetching, setChannelPostsIsFetching] = useState(false);
    const [isViewingUserIsMember, setIsViewingUserIsMember] = useState(false);
    const [modelId, setModelId] = useState(null);
    const [commentLevelOneId, setCommentLevelOneId] = useState(null);
    const [subReplyId, setSubReplyId] = useState(null);
    const [commentReplyId, setCommentReplyId] = useState(null);
    const [replyToId, setReplyToId] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isBtnPostEnabled, setIsBtnPostEnabled] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [isHeartSubmitting, setIsHeartSubmitting] = useState(false);
    const last = useRef(null);
    const [selectedAction, setSelectedAction] = useState(null);
    const [profileBackgroundVideoBlobPreview, setProfileBackgroundVideoBlobPreview] = useState(null);

    const [postForm] = Form.useForm();
    const [commentForm] = Form.useForm();
    const [subCommentForm] = Form.useForm();
    const [subCommentReplyForm] = Form.useForm();
    const [searchForm] = Form.useForm();

    const [isAudioUpload, setIsAudioUpload] = useState(false);
    const [isActivationOpen, setActivationOpen] = useState(false);
    const [dataToEdit, setDataToEdit] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [posts, setPosts] = useState([]);
    const [isLoadMore, setIsLoadMore] = useState(false);
    const postIdToDelete = useRef(null);
    const commentIdToDelete = useRef(null);
    const subCommentIdToDelete = useRef(null);
    const subReplyIdToDelete = useRef(null);
    const itemElRef = useRef(new Array())
    const hasPinnedItem = itemElRef.current.some(item => item.is_pinned === true);

    const [backgroundBG, setBackgroundBG] = useState(null);
    const [cardBackgroundType, setCardBackgroundType] = useState(null);
    const [selectedPost, setSelectedPost] = useState(null);
    const [isShareModalOpen, setIsShareModalOpen] = useState(false);
    const [spaceChannels, setSpaceChannels] = useState([]);
    const [postValue, setPostValue] = useState('');
    const [tags, setTags] = useState([
        { id: 1, display: 'sports' },
        { id: 2, display: 'technology' },
        { id: 3, display: 'science' },
    ]);

    const [currentPaginationPage, setCurrentPaginationPage] = useState(1);
    const [previousPaginationPage, setPreviousPaginationPage] = useState(0);
    const [previousFetchItemsCount, setPreviousFetchItemsCount] = useState(0);
    const [maxPages, setMaxPages] = useState(0);
    const pageListCount = 5; // number of items per page
    const isFooterInViewPort = useSelector((state) => state.footerInView.isFooterInViewPort);

    const postRedux = useSelector((state) => state.channel.post);
    const editPostRedux = useSelector((state) => state.channel.editPost);
    const commentRedux = useSelector((state) => state.channel.comment);
    const editCommentRedux = useSelector((state) => state.channel.editComment);
    const subCommentRedux = useSelector((state) => state.channel.subComment);
    const editSubCommentRedux = useSelector((state) => state.channel.editSubComment);
    const subCommentReplyRedux = useSelector((state) => state.channel.subCommentReply);
    const editSubCommentReply = useSelector((state) => state.channel.editSubCommentReply);
    const subCommentSubReplyRedux = useSelector((state) => state.channel.subCommentSubReply);

    const [alert, setAlert] = useState(null);
    const [fileType, setFileType] = useState(UPLOAD_FILE_TYPE.IMAGE);
    const [imageFiles, setImageFiles] = useState([]);
    const [videoFiles, setVideoFiles] = useState([]);
    const [audioFile, setAudioFile] = useState(null);
    const [commentIdOpened, setCommentIdOpened] = useState(null);
    const [subCommentIdOpened, setSubCommentIdOpened] = useState(null);
    const [replyIdOpened, setReplyIdOpened] = useState(null);

    const setUserBackground = (user) => {
		if (user.background_photo || user.background_video) {
			if (user.background_photo) {
				const space_bg = {
					backgroundImage: 'url(' + user.background_photo + ')'
				};
				setBackgroundBG(space_bg);
				setCardBackgroundType('image');
			}
			if (user.background_video) {
				setProfileBackgroundVideoBlobPreview(user.background_video);
				setCardBackgroundType('video');
			}
		} else {
			const space_bg = {
				backgroundImage: 'url(' + default_profile_card_bg + ')'
			};
			setBackgroundBG(space_bg);
			setCardBackgroundType('image');
		}
	};

    const fetchViewData = useCallback(
        async (username) => {
            if (username) {
                try {
                    const data = {
                        username: username
                    };
                    if (authUserInfo) {
                        data['viewing_user_id'] = authUserInfo.id;
                    }
                    const response = await getDataByUsername(data);
                    if (response.space) {
                        if (response.is_public_view_mode) {
                            dispatch(setIsPublicUrl(true));
                        } else {
                            dispatch(setIsNotPublicUrl(false));
                        }

                        setSpaceData(response.space);
                        setUserBackground(response.space);
                    }
                } catch (error) {
                    console.log(error);
                }
            }
    },
    [authUserInfo]);

    const fetchSpaceDataByDomain = useCallback(
        async (domain) => {
            try {
                const data = {
                    space_domain: domain
                };

                if (authUserInfo) {
                    data['viewing_user_id'] = authUserInfo.id;
                }

                const response = await getSpaceDataByDomain(data);
                if (response.result) {
                    setSpaceData(response.space);
                }
            } catch (error) {
                console.log(error);
            }
        },
        [authUserInfo]
    );

    const handleNewData = (new_post) => {
        if(selectedAction === 'edit'){
            let post_index = itemElRef.current.findIndex(x => x.id === new_post.post.id);
            itemElRef.current[post_index] = new_post.post;
        }else{
            itemElRef.current.unshift(new_post.post)
        }
    }

    const handleChangeChannel = (username = null, guid) => {
        window.location.replace('/@' + (username ? username : spaceUsername) + '/channel/'+guid);
    }

    const fetchChannels = useCallback(async (spaceId, userId) => {
        const channel_param = {}
        if (!spaceId || !userId) {
            return;
        }
        channel_param.space_id = spaceId;
        channel_param.user_id = userId;

        const response = await getSpaceChannels(channel_param);
        if(response && response.result) {
            const channels = response.space_channels;
            if (channels) {
                setSpaceChannels(channels);
            }
        }
    })

    const handleSearchPosts = useCallback(async (searchText) => {
            setIsSubmitting(true);
            const channel_param = {
            }
            if (spaceChannel) {
                channel_param.channel_id = spaceChannel.id;
            }
            if(searchForm.getFieldValue('search') !== undefined && searchForm.getFieldValue('search').trim() !== ''){
                channel_param.search = searchForm.getFieldValue('search') ;
            }
            channel_param.viewing_user_id = 1;
            channel_param.page = currentPage;
            // const result = await getSpaceUpdates(params);
            const channel_posts = await getSpaceChannelPosts(channel_param);
            if(channel_posts && channel_posts.result){
                itemElRef.current = channel_posts.posts;
                setIsSubmitting(false);
            }
        }, [spaceChannel, itemElRef, currentPage]
    )

    const fetchDefaultChannelData = useCallback(async (spaceData) => {
            if (spaceData && spaceData.id) {
                const data = {
                    space_id: spaceData.id
                };
                const response = await getSpaceDefaultChannel(data);
                if (response) {
                    if (response.result) {
                        const channel = response.space_channel;
                        setSpaceChannel(channel);
                        setFetchGuid(channel.guid)
                    } else {
                        window.location.replace('/@' + spaceData.username + '/manage/manage-channels');
                    }
                    // handleChangeChannel(spaceData.username, channel.guid);
                }
            }
        },
        [spaceData]
    )

    const fetchGuidChannelData = useCallback(async (space, user) => {
            if (guid) {
                try {
                    const data = {
                        guid: guid,
                        user_id: user.id,
                        space_id: space.id,
                    };
                    const response = await getSpaceChannel(data);
                    if (response && response.result) {
                        setSpaceChannel(response.space_channel);
                    }
                    // setSpaceChannel(response.space_channel);
                } catch (error) {
                    console.log(error);
                }
            }
        }, [guid]
    )

    const getChannelPosts = useCallback(async (channel, space, pageNumber = 1) => {
        if (!channel) {
            return;
        }
        // if (!spaceUpdates || (spaceUpdates && spaceUpdates.length === 0) || isActivationOpen) {
        //     setChannelPostsIsFetching(true);
        // }
        try {
            setChannelPostsIsFetching(true);
            const params = {
                space_id: space.id,
                channel_id: channel.id,
                page: pageNumber,
                listCount: pageListCount,
            };

            if (authUserInfo) {
                params.viewing_user_id = authUserInfo.id;
            }
            const response = await getSpaceChannelPosts(params);
            const channelPost = response.posts;
            if (!spaceUpdates && response && channelPost && response.result) {
                setMaxPages(response.page_range);
                setPreviousFetchItemsCount(channelPost.length);
                channelPost.map(key => {
                    if(!posts.find( obj2 => obj2.id===key.id)){
                        posts.push(key)
                        itemElRef.current.push(key)
                    }
                })
            }
            setCurrentPaginationPage(pageNumber);
            setPreviousPaginationPage(currentPaginationPage);
            // if (response.result) {
            //     setSpaceUpdates(response.result);
            //     // setUpdatesViewingUserIsMember(result.viewing_user_is_member);
            // } else {
            //     setSpaceUpdates(null);
            // }
            setIsLoadMore(false);
            setTimeout(() => {
                setChannelPostsIsFetching(false);
            }, 1000);

        } catch (error) {
            console.log(error);
            setChannelPostsIsFetching(false);
        }
        },
        [
            authUserInfo,
            spaceUpdates,
            currentPage,
            itemElRef,
            posts,
            currentPaginationPage,
            previousPaginationPage,
            channelPostsIsFetching,
        ]
    );

    const handlePostSubmit = useCallback(async (values) => {
        setIsSubmitting(true);
        const update = {};
        update.model = 'post';
        update.channel_id = spaceChannel.id;
        update.title = values.post;
        update.content = '';
        update.attachments_type = '';
        update.attachements = null
        try {
            if (authUserInfo) {
                update.user_id = authUserInfo.id;
                update.space_id = spaceData.id;
                const response = await updateSpacePost(update);
                if (response && response.result) {
                    posts.push(response.post)
                    itemElRef.current.unshift(response.post)
                    setSpaceUpdates(null)
                    setIsSubmitting(false);
                    setIsBtnPostEnabled(false);
                    processPostsCount(true, spaceChannel);
                    postForm.resetFields();
                }
            }
        } catch (error) {
            console.log(error);
        }
        },
       [ spaceChannel, authUserInfo, spaceData, itemElRef, postForm]
    );

    const handleChanges = useCallback(() => {})

    const handleCommentChanges = useCallback(() => {})

    const handlePostChanges = useCallback((event) => {
        setIsBtnPostEnabled((imageFiles.length > 0 || videoFiles.length > 0 || audioFile) || event.target.value.length > 0);
    })

    const handleDropdownSelection = (selection, data, type) => {
        setSelectedAction(selection);
        if (selection === 'delete'){
            setDataToDelete(data.id, type).then();
        }else if (selection === 'edit'){
            setActivationOpen(true);
            setDataToEdit(data)
        }else{
            setDataToPin(data).then();
        }
    }

    const setIdsForDeletion = (postId, commentId=null, subCommentId=null, subreplyId = null) => {
        if (postId){
            postIdToDelete.current = postId;
        }
        if (commentId){
            commentIdToDelete.current = commentId; 
        }
        if (subCommentId){
            subCommentIdToDelete.current = subCommentId;
        }
    }

    const setDataToPin = useCallback(async (data) => {
        const params = {
            user_id: authUserInfo.id,
            post_id: data.id
        };
        let post_index = itemElRef.current.findIndex(x => x.id === data.id);
        setIsSubmitting(true);
        let post = {...data};
        if(data.is_pinned){
            const response = await userUnpinPost(params);
            post.is_pinned = false;
            itemElRef.current[post_index] = post;
            setIsSubmitting(false);
        }else{
            const response = await userPinPost(params);
            post.is_pinned = true;
            itemElRef.current[post_index] = post;
            setIsSubmitting(false);
        }}, [authUserInfo, itemElRef]
    )

    const processPostsCount = (isIncreaseCount, spaceChannel) => {
        setSpaceChannels(prev => {
            return prev.map((channel) => {
                if (channel.id === spaceChannel.id) {
                    return {...channel, posts_count: isIncreaseCount ? (channel.posts_count + 1) : channel.posts_count - 1};
                } else {
                    return channel;
                }
            });
        });
    }

    const setDataToDelete = useCallback(
        async (values, type) => {
        const data = {};
        setIsSubmitting(true);
        if(type === "post"){
            data.post_id = values;            
            const response = await deleteSpacePost(data);
            if (response && response.result) {
                let post_index = itemElRef.current.findIndex(x => x.id === values);
                itemElRef.current.splice(post_index, 1);
                processPostsCount(false, spaceChannel);
            }
           
        }else if(type === "comment"){
            data.comment_id = values;
            const response = await deleteSpaceComment(data);
            if (response && response.result) {
                let post_index = itemElRef.current.findIndex(x => x.id === postIdToDelete.current);
                let comment_index = itemElRef.current[post_index].comments.findIndex(x => x.id === commentIdToDelete.current);
                itemElRef.current[post_index].comments.splice(comment_index, 1);

            }
        }else if(type === "sub-comments"){
            data.comment_id = values;
            const response = await deleteSpaceComment(data);
            if (response && response.result) {
                let post_index = itemElRef.current.findIndex(x => x.id === postIdToDelete.current);
                let comment_index = itemElRef.current[post_index].comments.findIndex(x => x.id === commentIdToDelete.current);
                let sub_comment_index = itemElRef.current[post_index].comments[comment_index].replies.findIndex(x => x.id === subCommentIdToDelete.current);
                itemElRef.current[post_index].comments[comment_index].replies.splice(sub_comment_index, 1);

            }
        }
        else if(type === "sub-replies"){
            data.comment_id = values;
            const response = await deleteSpaceComment(data);
            if (response && response.result) {
                let post_index = itemElRef.current.findIndex(x => x.id === postIdToDelete.current);
                let comment_index = itemElRef.current[post_index].comments.findIndex(x => x.id === commentIdToDelete.current);
                let sub_comment_index = itemElRef.current[post_index].comments[comment_index].replies.findIndex(x => x.id === subCommentIdToDelete.current);
                let sub_reply_index = itemElRef.current[post_index].comments[comment_index].replies[sub_comment_index].sub_replies.findIndex(x => x.id === subReplyIdToDelete.current);
                itemElRef.current[post_index].comments[comment_index].replies[sub_comment_index].sub_replies.splice(sub_reply_index, 1);

            }
        }
        setIsSubmitting(false);
    }, [itemElRef, postIdToDelete, commentIdToDelete, subCommentIdToDelete, subReplyIdToDelete, spaceChannel, spaceChannels])
    
    const handleSubmitSubComment = useCallback(async (values) => {
			let okToSubmit = (subCommentForm.getFieldValue('reply') !== undefined && subCommentForm.getFieldValue('reply').trim() !== '');
            setIsSubmitting(false)
			if (okToSubmit) {
				setSubmitted(true);
				const data = {
					space_id: spaceChannel.id,
					model: 'post',
					model_id: modelId,
					comment: values.reply,
					reply_to_id: replyToId
				};
				if (authUserInfo) {
					data['user_id'] = authUserInfo.id;
				}
				const response = await createSpaceModelComment(data);
				if (response && response.result) {
					// updateCommentIdToReply(null);
					// fetchUpdatedComments();
                    let post_index = itemElRef.current.findIndex(x => x.id === modelId);
                    let comment_index = itemElRef.current[post_index].comments.findIndex(x => x.id === replyToId);
                    if(itemElRef.current[post_index].comments[comment_index].replies){
                        itemElRef.current[post_index].comments[comment_index].replies.unshift(response.comment);
                    }else{
                        itemElRef.current[post_index].comments[comment_index].replies = new Array();
                        itemElRef.current[post_index].comments[comment_index].replies.unshift(response.comment);
                    }
                    subCommentForm.resetFields();
                    setSpaceUpdates(null)
                    setIsSubmitting(false);
				}
				setSubmitted(false);
				// setDisableReplyButton(true);
			}
		},
		[authUserInfo, spaceChannel, commentReplyId, replyToId, modelId, itemElRef.current]
	);

    const handleSubmitSubReply = useCallback(async (values) => {
			let okToSubmit = (subCommentReplyForm.getFieldValue('reply') !== undefined && subCommentReplyForm.getFieldValue('reply').trim() !== '');
            setIsSubmitting(true)
			if (okToSubmit) {
				setSubmitted(true);
				const data = {
					space_id: spaceChannel.id,
					model: 'post',
					model_id: modelId,
					comment: values.reply,
					reply_to_id: replyToId
				};
                if (subReplyId) {
                    data['reply_to_sub_id'] = subReplyId;
                }
				if (authUserInfo) {
					data['user_id'] = authUserInfo.id;
				}
				const response = await createSpaceModelComment(data);
				if (response && response.result) {
                    let post_index = itemElRef.current.findIndex(x => x.id === modelId);
                    let comment_index = itemElRef.current[post_index].comments.findIndex(x => x.id === commentLevelOneId);
                    let reply_index = itemElRef.current[post_index].comments[comment_index].replies.findIndex(x => x.id === replyToId);
                    if(reply_index >= 0 && itemElRef.current[post_index].comments[comment_index].replies[reply_index].sub_replies){
                        itemElRef.current[post_index].comments[comment_index].replies[reply_index].sub_replies.push(response.comment);
                    } else{
                        let reply = {...itemElRef.current[post_index].comments[comment_index].replies[reply_index]};
                        reply.sub_replies = [];
                        itemElRef.current[post_index].comments[comment_index].replies[reply_index] = reply;
                        itemElRef.current[post_index].comments[comment_index].replies[reply_index].sub_replies.push(response.comment);
                    }
                    subCommentReplyForm.resetFields();
                    setIsSubmitting(false);
                    setSpaceUpdates(null);
                    setCommentLevelOneId(null);
				}
				setSubmitted(false);
				// setDisableReplyButton(true);
			}
		},
		[authUserInfo, spaceChannel, commentReplyId, replyToId, modelId, subReplyId, commentLevelOneId]
	);

    const handleCommentSubmit = useCallback(async (values) => {
			let okToSubmit = (commentForm.getFieldValue('comment') !== undefined && commentForm.getFieldValue('comment').trim() !== '');
			if (okToSubmit) {
                setIsSubmitting(true)
				setSubmitted(true);
				const data = {
					space_id: spaceData.id,
					model: 'post',
					model_id: modelId,
                    channel_id: spaceChannel.id,
					comment: values.comment
				};
				if (authUserInfo) {
					data['user_id'] = authUserInfo.id;
				}
				const response = await createSpaceModelComment(data);
                commentForm.resetFields();
				if (response && response.comment) {
                    let index = itemElRef.current.findIndex(x => x.id === modelId);
                    const post = itemElRef.current[index];
                    // console.log(post)
                    if(post.comments && post.comments.length === 0){
                        post.comments.push(response.comment);
                    } else {
                        if (!post.comments) {
                            post.comments = [];
                        }
                        post.comments.unshift(response.comment);
                    }
                    setSpaceUpdates(null)
                    setIsSubmitting(false);
                    setModelId(null);
				}
				setSubmitted(false);
			}
		},
		[authUserInfo, spaceData, modelId, itemElRef, itemElRef.current]
	);

    const searchInputTagsRequest = useCallback(async (search) => {
            if (!spaceUpdates || (spaceUpdates && spaceUpdates.length === 0) || isActivationOpen) {
                setChannelPostsIsFetching(true);
            }
            try {
                const params = {
                    search: search
                };
                // const channel_param = {
                // }
                // if (channel) {
                //     params.channel_id = channel.id;
                //     channel_param.channel_id = channel.id;
                // }
                // channel_param.viewing_user_id = 1;
                // channel_param.page = currentPage;
                // const result = await getSpaceUpdates(params);
                const response = await searchInputTags(params);
                if (response && response.result) {
                    const tags = response.tags;
                    tags.filter((tag)=>{
                        return {id: tag.id, display: tag.name}
                    })
                    setTags(tags);
                }
                setIsLoadMore(false);
                setChannelPostsIsFetching(false);
            } catch (error) {
                console.log(error);
                setChannelPostsIsFetching(false);
            }
        },
        [authUserInfo, spaceUpdates, currentPage, itemElRef]
    );

    const setReplyModelId = (modelId, replyId) => {
        setModelId(modelId);
        setReplyToId(replyId);
    }

    const setSubReplyModelId = (modelId, levelOneId, replyId) => {
        if (modelId) setModelId(modelId);
        if (levelOneId) setCommentLevelOneId(levelOneId);
        if (replyId) setReplyToId(replyId);
        // if (replyId) setCommentReplyId(replyId);
        // if (subReplyId) setSubReplyId(subReplyId);
    }

    const handleUploadFileChange = async (file) => {
        setAlert(null);
        const file_extension = getFileExtension(file);
        const objectUrl = URL.createObjectURL(file);

        if (VIDEO_FILETYPES.includes('.' + file_extension.toLowerCase())) {
            const vidValid = await checkVideoValidity(file, alert);
            if (!vidValid) {
                return false;
            }
            setFileType(UPLOAD_FILE_TYPE.VIDEO);
            setVideoFiles([{file: file, uri: objectUrl}]);
            setAudioFile(null);
            setImageFiles([]);
            setIsBtnPostEnabled(true);
        }
        if (IMAGE_FILETYPES.includes('.' + file_extension.toLowerCase())) {
            const imgValid = await checkImageValidity(file, alert);
            if (!imgValid) {
                return false;
            }
            setFileType(UPLOAD_FILE_TYPE.IMAGE);
            let imageArray = [];
            imageArray = imageArray.concat(imageFiles); // Combine arrays
            imageArray.push({file: file, uri: objectUrl});
            setImageFiles(imageArray);
            setVideoFiles([]);
            setAudioFile(null);
            setIsBtnPostEnabled(true);
        }
        if (AUDIO_FILETYPES.includes('.' + file_extension.toLowerCase())) {
            setFileType(UPLOAD_FILE_TYPE.AUDIO);
            setAudioFile({file: file, uri: objectUrl});
            setImageFiles([]);
            setVideoFiles([]);
            setIsBtnPostEnabled(true);
        }
    };

    const attachmentSubmit = useCallback(async (values) => {
        setIsSubmitting(true);
        const update = {};
        update.model = 'post';
        update.channel_id = spaceChannel.id;
        update.title = values.post ? values.post : '';
        update.content = '';
        const attachments = [];
        const attachment = {};
        if (audioFile){
            attachment.id = "null";
            attachments.push(attachment);
            update.attachments_type = 'audio'
            update.attachments = JSON.stringify(attachments);
            update.file_0 = audioFile.file;
        }
        if (imageFiles.length > 0){
            imageFiles.map((image, index) => {
                attachment.id = "null";
                attachments.push(attachment);
                update.attachments_type = 'image'
                update.attachments = JSON.stringify(attachments);
                const key = `file_${index}`; // Create a dynamic key (e.g., file_0, file_1, ...)
                update[key] = image.file; // Assign the file object to the dynamic key
            })
        }
        if (videoFiles.length > 0){
            videoFiles.map((video, index) => {
                attachment.id = "null";
                attachments.push(attachment);
                update.attachments_type = 'video'
                update.attachments = JSON.stringify(attachments);
                const key = `file_${index}`; // Create a dynamic key (e.g., file_0, file_1, ...)
                update[key] = video.file; // Assign the file object to the dynamic key
            })
        }
        try {
            if (authUserInfo) {
                update.user_id = authUserInfo.id;
                update.space_id = spaceData.id;
                const response = await createSpacePost(update);
                // handleNewData(response)
                if (response && response.result) {
                    postForm.resetFields();
                    dispatch(setPost(response.post));
                    setImageFiles([]);
                    setVideoFiles([]);
                    setAudioFile(null);
                    setIsBtnPostEnabled(true);
                    setIsSubmitting(false);
                } else {
                    window.scrollTo(0, 0);
                }
                // setModalOpen(false);
            }
        } catch (error) {
            console.log(error);
        }},[imageFiles, audioFile, videoFiles, fileType]
    );

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess('ok');
        });
    };

    // const handleScroll = (event) => {
    //     if(document.documentElement.clientHeight + window.scrollY >=
    //         (document.documentElement.scrollHeight ||
    //             document.documentElement.clientHeight)){
    //                 setCurrentPage(currentPage + 1)
    //             }
    // };
   
    const handleSeeMorePosts = () => {
        setIsLoadMore(true);
        setSpaceUpdates(null)
        setCurrentPage(currentPage + 1);
    }

    const toggleHeart = useCallback(async (values, state) => {
        if(!state){
            state = false;
        }
        if (authUserInfo) {
            const data = {
                user_id: authUserInfo.id,
                post_id: values
            };
            setIsHeartSubmitting(true);
            let response = {}
            if(state){
                response = await userUnheartPost(data);
            } else{
                response = await userHeartPost(data);
            }
            if(response && response.result){
                setIsHeartSubmitting(false);
                let post = response.post;
                if (post) {
                    let post_index = itemElRef.current.findIndex(x => x.id === post.id);
                    let newState = JSON.parse(JSON.stringify(itemElRef.current));
                    newState[post_index].hearted_by_users = post.hearted_by_users;
                    newState[post_index].hearts_count = post.hearts_count;
                    newState[post_index].is_hearted = !state;
                    // Update the state with the modified copy
                    itemElRef.current = newState;
                }
            } else {
                setIsHeartSubmitting(false);
            }
        }
    }, [itemElRef, authUserInfo])

    const toggleHeartComment = useCallback(async (post_id, state, thread_id=null, sub_thread_id=null, sub_thread_reply_id=null) => {
        state = !state;
        if (authUserInfo) {
            const data = {
                user_id: authUserInfo.id,
                post_id: post_id
            };
            if (thread_id) {
                data.thread_id = thread_id;
            }
            if (sub_thread_id) {
                data.sub_thread_id = sub_thread_id;
            }
            if (sub_thread_reply_id) {
                data.sub_thread_reply_id = sub_thread_reply_id;
            }
            setIsHeartSubmitting(true);
            let response = {}
            if (state) {
                response = await userPostHeartComment(data);
            } else{
                response = await userPostUnHeartComment(data);
            }
            if(response && response.result){
                setIsHeartSubmitting(false);
                let comment = response.comment;
                if (comment) {
                    let post_index = itemElRef.current.findIndex(x => x.id === post_id);
                    if (thread_id && sub_thread_id == null && sub_thread_reply_id === null) {
                        let thread_index = itemElRef.current[post_index].comments.findIndex(x => x.id === thread_id);
                        itemElRef.current[post_index].comments[thread_index].hearted_by_users = comment?.hearted_by_users
                        itemElRef.current[post_index].comments[thread_index].hearts_count = comment?.hearts_count
                        itemElRef.current[post_index].comments[thread_index].is_hearted = comment?.is_hearted

                    } else if (thread_id && sub_thread_id && sub_thread_reply_id === null) {

                        let thread_index = itemElRef.current[post_index].comments.findIndex(x => x.id === thread_id);
                        let sub_thread_index = itemElRef.current[post_index].comments[thread_index].replies.findIndex(x => x.id === sub_thread_id);
                        // comments[thread_index].replies[sub_thread_index] = comment
                        itemElRef.current[post_index].comments[thread_index].replies[sub_thread_index].hearted_by_users = comment?.hearted_by_users
                        itemElRef.current[post_index].comments[thread_index].replies[sub_thread_index].hearts_count = comment?.hearts_count
                        itemElRef.current[post_index].comments[thread_index].replies[sub_thread_index].is_hearted = comment?.is_hearted

                    } else if (thread_id && sub_thread_id && sub_thread_reply_id) {

                        let thread_index = itemElRef.current[post_index].comments.findIndex(x => x.id === thread_id);
                        let sub_thread_index = itemElRef.current[post_index].comments[thread_index].replies.findIndex(x => x.id === sub_thread_id);
                        let sub_thread_reply_index = itemElRef.current[post_index].comments[thread_index].replies[sub_thread_index].sub_replies.findIndex(x => x.id === sub_thread_reply_id);
                        itemElRef.current[post_index].comments[thread_index].replies[sub_thread_index].sub_replies[sub_thread_reply_index] = comment

                    }
                }
            } else {
                setIsHeartSubmitting(false);
            }
        }
    }, [authUserInfo, itemElRef])

    const handlePostKeypress = (event) => {
        if (!event.shiftKey && event.key === 'Enter') {
            event.preventDefault();
            subCommentForm.submit();
        }
    };

    const handleMentionChanges = (event, newValue, newPlainTextValue, mentions) => {
        // console.log('event');
        // console.log(event);
        // console.log('newValue');
        // console.log(newValue);
        // console.log('newPlainTextValue');
        // console.log(newPlainTextValue);
        // console.log('mentions');
        // console.log(mentions);
        let finalValue = newValue;

        // Add "@" and "#" symbols to the mentions
        for (let mention of mentions) {
            if (mention.display) {
                const oldMention = mention.display;
                const newMention = mention.trigger + mention.display;
                finalValue = finalValue.replace(oldMention, newMention);
            }
        }
        setPostValue(newValue);
    }

    const renderMentionChanges = (entry, search, highlightedDisplay, index, focused) => {
        return <div>@{highlightedDisplay}</div>;
    }

    const renderTagsChanges = (entry, search, highlightedDisplay, index, focused) => {
        return <div>#{highlightedDisplay}</div>;
    };

    const users = [
        { id: 1, display: 'James' },
        { id: 2, display: 'Jill' },
        { id: 3, display: 'John' },
        // Add more users here
    ];

    useEffect(() => {
        const nextPage = currentPaginationPage + 1;
        // only trigger next page fetch when:
        // - footer is in view port(scrolled to bottom)
        // - specified items count per page not be below last fetched data list
        const hasMorePages = currentPaginationPage < maxPages;
        if (!channelPostsIsFetching && isFooterInViewPort && (pageListCount === previousFetchItemsCount || hasMorePages)) {
            setChannelPostsIsFetching(true);
            getChannelPosts(spaceChannel, spaceData, nextPage).then((r) => {});
        }
    }, [
        spaceChannel,
        spaceData,
        isFooterInViewPort,
        currentPaginationPage,
        previousPaginationPage,
        previousFetchItemsCount,
        maxPages,
    ]);

    useEffect(()=> {
        if (postRedux) {
            itemElRef.current.unshift(postRedux)
        }
    },[postRedux])

    useEffect(()=> {
        if (editPostRedux) {
            let post_index = itemElRef.current.findIndex(x => x.id === editPostRedux.id);
            // Create a copy of the current state
            // prevent Cannot assign to read only property '0' of object error
            let newState = JSON.parse(JSON.stringify(itemElRef.current));
            newState[post_index] = editPostRedux;
            // Update the state with the modified copy
            itemElRef.current = newState;
        }
    },[editPostRedux])

    useEffect(()=> {
    },[commentRedux])

    useEffect(()=> {
        if (editCommentRedux) {
            let post_index = itemElRef.current.findIndex(x => x.id === editCommentRedux.model_id);
            let comment_index = itemElRef.current[post_index].comments.findIndex(x => x.id === editCommentRedux.id);
            // Create a copy of the current state
            // prevent Cannot assign to read only property '0' of object error
            let newState = JSON.parse(JSON.stringify(itemElRef.current));
            newState[post_index].comments[comment_index] = editCommentRedux;
            // Update the state with the modified copy
            itemElRef.current = newState;
        }
    },[editCommentRedux])

    useEffect(()=> {
    },[subCommentRedux])

    useEffect(()=> {
        if (editSubCommentRedux) {
            let post_index = itemElRef.current.findIndex(x => x.id === editSubCommentRedux.model_id);
            let comment_index = itemElRef.current[post_index].comments.findIndex(x => x.id === editSubCommentRedux.reply_to_id);
            let sub_comment_index = itemElRef.current[post_index].comments[comment_index].replies.findIndex(x => x.id === editSubCommentRedux.id);
            // Create a copy of the current state
            // prevent Cannot assign to read only property '0' of object error
            let newState = JSON.parse(JSON.stringify(itemElRef.current));
            newState[post_index].comments[comment_index].replies[sub_comment_index] = editSubCommentRedux;
            // Update the state with the modified copy
            itemElRef.current = newState;
        }
    },[editSubCommentRedux])

    useEffect(()=> {
        if (editSubCommentReply) {
            let post_index = itemElRef.current.findIndex(x => x.id === editSubCommentReply.model_id);
            let comment_index = itemElRef.current[post_index].comments.findIndex(x => x.id === editSubCommentReply.reply_to_sub_id);
            let sub_comment_index = itemElRef.current[post_index].comments[comment_index].replies.findIndex(x => x.id === editSubCommentReply.reply_to_id);
            let reply_index = itemElRef.current[post_index].comments[comment_index].replies[sub_comment_index].sub_replies.findIndex(x => x.id === editSubCommentReply.id);
            // Create a copy of the current state
            // prevent Cannot assign to read only property '0' of object error
            let newState = JSON.parse(JSON.stringify(itemElRef.current));
            newState[post_index].comments[comment_index].replies[sub_comment_index].sub_replies[reply_index] = editSubCommentReply;
            // Update the state with the modified copy
            itemElRef.current = newState;
        }
    },[editSubCommentReply])

    useEffect(() => {
        // console.log(spaceData)
        if (spaceData) {
            if(!isActivationOpen) {
                setChannelPostsIsFetching(true);
            }
            getChannelPosts(spaceChannel, spaceData).then();
            if(currentPage > 1){
                const element = document.getElementById('last');
                if(element && isLoadMore){
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }
        }
    }, [spaceChannel,  currentPage], spaceData);

    useEffect(() => {
        if(!isActivationOpen) {
            setDataToEdit(null);
        }
    },[isActivationOpen])

    useEffect(() => {
        if (spaceData && authUserInfo) {
            setIsViewingUserIsMember(spaceData.viewing_user_is_member);
            fetchChannels(spaceData.id, authUserInfo.id).then();
            if (!spaceData.channels_enable && spaceData.owner === authUserInfo.id) {
                window.location.replace('/@' + spaceData.username + '/manage/manage-channels');
            } else {
                if (guid) {
                    fetchGuidChannelData(spaceData, authUserInfo).then();
                } else {
                    fetchDefaultChannelData(spaceData).then();
                    // fetchFirstChannelData(spaceData.id).then();
                }
            }
        }
    }, [spaceData, guid, authUserInfo]);

    useEffect(() => {
        const path = window.location.pathname;
        if (path && path.startsWith('/@') && path.length > 3) {
            let directory = path.replace('/@', '');
            const split = directory.split('/');
            const username = split[0];
            setSpaceUsername(username);
        }
    }, []);

    useEffect(() => {
        if (spaceUsername) {
            fetchViewData(spaceUsername).then()
        }
    }, [spaceUsername])

    useEffect(() => {
        if (ROOT_DOMAIN !== window.location.hostname) {
            fetchSpaceDataByDomain(window.location.hostname).then(() => {});
        }
    }, []);

    useEffect(() => {
        setCommentReplyId(commentReplyId);
        setModelId(modelId);
        setSubReplyId(subReplyId);
    }, [modelId, commentReplyId, subReplyId])

    useEffect(() => {
    }, [
        spaceChannels,
        spaceChannel,
        channelPostsIsFetching,
        isSubmitting,
        dataToEdit,
        selectedAction,
        itemElRef,
        isSubmitting,
        spaceUpdates,
        postIdToDelete,
        commentIdToDelete,
        subCommentIdToDelete,
        commentIdOpened,
        subCommentIdOpened
    ]);

    useEffect(()=>{
    },[imageFiles, audioFile], videoFiles)

    return (
        <>
            {(spaceData && spaceChannel && isViewingUserIsMember) ?
                // SECTION Container 1
                <section id="cs-space-channels-channel-c1" className={`p-section-safeview ${spaceData && spaceData.pro_style && spaceData.pro_style?.channels_theme === 'main'? 'theme-transparent' : 'theme-alt-transparent'}`}>
                    {/*CSJS Set theme class based on {space_theme_channels} value of "theme" or "theme-alt" and apply inline style for background image/position if applicable.*/}
                    {/*CSJS If theme or theme-alt has an image then add bg-cover-* to class based on {theme_X_background_position} ("theme_X" means "theme" or "theme_alt" based on*/}
                    {/*the applied theme setting) value where * sets background position: center (default), top, bottom, left, right, left-top, left-bottom, right-top, or right-bottom*/}
                    {/*as inline class. Same for Background-image URL for style="background image:" where URL {theme_X_background_image} is applicable.*/}
                    <div className="centerframe-grid lg:gap-2gutter">
                        <ChannelPostForm
                            itemElRef={itemElRef}
                            dataToEdit={dataToEdit}
                            setDataToEdit={setDataToEdit}
                            isAudioUpload={isAudioUpload}
                            setIsAudioUpload={setIsAudioUpload}
                            modalOpen={isActivationOpen}
                            setModalOpen={setActivationOpen}
                            handleNewData={handleNewData}
                            user={authUserInfo}
                            spaceChannel={spaceChannel}
                            spaceData={spaceData}
                            activationStatus={'active'}
                        />
                        <ChannelSharePost
                            post={selectedPost}
                            modalOpen={isShareModalOpen}
                            setModalOpen={setIsShareModalOpen}
                        />

                        <div className="cs-feed-col block-xl space-y-0 order-2 lg:order-1">
                            {/*BEGIN - CHANNEL HEADER - FEED INSTANCE*/}
                            <div className="cs-stack-channel-header stack-col mb-article hidden lg:flex">
                                 {/*BEGIN - CHANNEL HEADER INFO*/}
                                <div className="cs-stack-channel-header-info stack-col-min">
                                    <div className="stack-col sm:stack-row split">
                                        {/*CSJS <span> text is {space_channels_channelname} for specific Channel*/}
                                        <h2>#{spaceChannel && spaceChannel.name ? spaceChannel.name : ''}</h2>
                                        {/*CSJS Display up to 5 most recent active Member profile avatars, based on channel activity in past 24 hours.*/}
                                        <div className="stack-avatar">
                                            {spaceData && spaceData.members && spaceData.members.length > 0 && spaceData.members.slice(0, 10).map((member, index) => (
                                                <div key={index} className="relative avatar-gradient" style={{zIndex: (spaceData.members.length > 10 ? 10 : spaceData.members.length ) - (index + 1)}}
                                                    // className={`z-${(spaceData.memberships.length > 10 ? 10 : spaceData.memberships.length ) - (index + 1)}`}
                                                >
                                                    <img alt="member-thumb" className="hex object-cover h-8 w-8" src={member.profile.photo_thumb ? member.profile.photo_thumb : default_user_profile} />
                                                    {/*<i className="fa-solid fa-badge-check absolute bottom-0 right-0 block h-4 w-4 rounded-full gradient-color-txt text-white"/>*/}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="stack-col-base sm:stack-row split">
                                        {/*CSJS Display count based on channel activity in past 24 hours.*/}
                                        <p>
                                            {itemElRef && itemElRef.current ? itemElRef.current.length : 0}
                                            &nbsp;New
                                            &nbsp;{itemElRef && itemElRef.current && itemElRef.current.length > 1 ? 'Posts' : 'Post'}
                                        </p>
                                        {/*CSJS Display count based on channel activity in past 24 hours.*/}
                                        <p>
                                            {spaceData && spaceData.members ? spaceData.members.length : 0}
                                            &nbsp;{spaceData && spaceData.members && spaceData.members.length > 1 ? 'Members' : 'Member'}
                                            &nbsp;Active
                                        </p>
                                    </div>
                                </div>
                                {/*/END - CHANNEL HEADER INFO*/}
                                {/*BEGIN - POST FORM*/}
                                <div className="cs-post-form block-full card">
                                    <div className="stack-row">
                                        {authUserInfo && authUserInfo.profile && (
                                            <div className="cs-post-form-avatar shrink-0">
                                                <img className="h-16 w-16 rounded-full object-cover italic"
                                                     src={authUserInfo && authUserInfo.profile.photo_thumb ? authUserInfo.profile.photo_thumb : default_user_profile}
                                                     alt=""
                                                />
                                                {/*<i className="fa-solid fa-badge-check absolute bottom-0 right-0 block h-4 w-4 rounded-full gradient-color-txt text-white"/>*/}
                                            </div>
                                        )}
                                        <Form className="stack-col w-full"
                                              form={postForm}
                                              name="postForm"
                                              onFinish={ imageFiles.length > 0 || videoFiles.length > 0 || audioFile ? attachmentSubmit : handlePostSubmit }
                                              onChange={handleChanges}
                                              validateMessages={VALIDATE_MESSAGES}
                                        >
                                            <Form.Item
                                                name="post"
                                                id="post"
                                                className="w-full !m-0 !p-0"
                                                // validateTrigger="onBlur"
                                                // rules={[{ required: true }]}
                                            >
                                                <div className="form-textarea  !m-0 !p-0">
                                                     <textarea
                                                         placeholder={`Message #${spaceChannel.name}`}
                                                         onChange={handlePostChanges}
                                                         rows={3}
                                                     />
                                                    {/*<TextareaAutosize*/}
                                                    {/*    placeholder="Message #Friends"*/}
                                                    {/*    maxRows={3}*/}
                                                    {/*    className="mt-8 focus:outline-none text-xl resize-none items-center rounded-md placeholder:text-gray-400 sm:leading-6 w-full no-scrollbar"*/}
                                                    {/*    onChange={handlePostChanges}*/}
                                                    {/*    onKeyPress={handlePostKeypress}*/}
                                                    {/*/>*/}
                                                </div>
                                            </Form.Item>

                                            { ((imageFiles && imageFiles.length > 0) || (videoFiles && videoFiles.length > 0)) &&
                                                <div className="cs-stack-attachments stack-row-wrap">
                                                    {(imageFiles && imageFiles.length > 0) && imageFiles.map((image, index) => (
                                                        <div key={index} className="form-input-media sm air aspect-cs-preview corner-i" style={ {backgroundImage: 'url(' + image.uri + ')'} }>
                                                            <a>
                                                                <i className="fa-solid fa-circle-xmark"
                                                                   onClick={()=>{
                                                                       imageFiles.splice(index, 1); // Remove the element at the specified index
                                                                       setImageFiles([...imageFiles]); // Update the state with the modified array
                                                                   }}
                                                                />
                                                            </a>
                                                        </div>
                                                    ))}
                                                    {(videoFiles && videoFiles.length > 0) && videoFiles.map((file, index) => (
                                                        <div key={index} className="sm air aspect-video corner-i relative">
                                                            <ReactPlayer
                                                                playing={true}
                                                                loop={true}
                                                                controls={true}
                                                                muted={true}
                                                                width='100%'
                                                                height='100%'
                                                                className="form-input-media sm air aspect-cs-preview corner-i max-h-element"
                                                                url={file.uri}
                                                            />
                                                            <i className="fa-solid fa-circle-xmark absolute -right-1 -top-1 cursor-pointer"
                                                               onClick={()=>{
                                                                   videoFiles.splice(index, 1); // Remove the element at the specified index
                                                                   setVideoFiles([...videoFiles]); // Update the state with the modified array
                                                               }}
                                                            />
                                                        </div>

                                                    ))}
                                                </div>
                                            }
                                            {audioFile &&
                                                <div className="sm air w-72 corner-i relative">
                                                    <audio controls>
                                                        <source src={audioFile} type="audio/mpeg" />
                                                    </audio>
                                                    <i className="fa-solid fa-circle-xmark absolute -right-1 -top-1 cursor-pointer"
                                                       onClick={()=>{
                                                           setAudioFile(null);
                                                       }}
                                                    />
                                                </div>
                                            }
                                            <div className="stack-col sm:stack-row split">
                                                <div className="stack-row w-full">
                                                    <a className="button-icon-square-gradient-air">
                                                        <span className="sr-only">Attach Image</span>
                                                        <i className="fa-light fa-photo-film">
                                                            <Dragger
                                                                className="w-full h-full block left-0 top-0 bg-transparent bg-none absolute"
                                                                accept={VIDEO_FILETYPES.concat(IMAGE_FILETYPES)}
                                                                showUploadList="false"
                                                                beforeUpload={handleUploadFileChange}
                                                                customRequest={dummyRequest}
                                                            />
                                                        </i>
                                                    </a>
                                                    {/*<a className="button-icon-square-gradient-air">*/}
                                                    {/*    <span className="sr-only">Attach Link</span>*/}
                                                    {/*    <i className="fa-light fa-link"></i>*/}
                                                    {/*</a>*/}
                                                    <a className="button-icon-square-gradient-air">
                                                        <span className="sr-only">Attach Music</span>
                                                        <i className="fa-light fa-music">
                                                            <Dragger
                                                                className="w-full h-full block left-0 top-0 bg-transparent bg-none absolute"
                                                                accept={AUDIO_FILETYPES}
                                                                showUploadList="false"
                                                                beforeUpload={handleUploadFileChange}
                                                                customRequest={dummyRequest}
                                                            />
                                                        </i>
                                                    </a>
                                                    {/*<a className="button-icon-square-gradient-air">*/}
                                                    {/*    <span className="sr-only">Attach Poll</span>*/}
                                                    {/*    <i className="fa-light fa-poll-people"/>*/}
                                                    {/*</a>*/}
                                                </div>
                                                <Form.Item className='w-full !m-0 !p-0'>
                                                    <button className="primary-gradient w-full"
                                                            disabled={isSubmitting || !isBtnPostEnabled}
                                                            type="submit"
                                                    >
                                                        <span><p>{t('button_channelpost')}</p></span>
                                                    </button>
                                                </Form.Item>
                                                {/*<button type="submit" className="primary-gradient w-full"><span><p>Post</p></span></button>*/}
                                            </div>

                                        </Form>
                                    </div>
                                </div>
                                {/*/END - POST FORM*/}
                            </div>
                            {/*/END - CHANNEL HEADER - FEED INSTANCE*/}

                            <div className="cs-stack-feed-col stack-col">

                                <div className="stack-col sm:stack-row split">
                                    <h3>Newest Active Posts</h3>
                                </div>

                                {spaceChannel && itemElRef.current && itemElRef.current.map((data, index) => (
                                    <article key={index} className="cs-post block-full card-base">
                                        <div className="stack-col p-article">
                                            <div className="cs-stack-post-header stack-row split">
                                                <div className="stack-row">
                                                    <a href="fam-profile.html" className="cs-post-avatar relative inline-block">
                                                        <img className="h-16 w-16 rounded-full object-cover"
                                                             src={data.author && data.author.profile && data.author.profile.photo_thumb
                                                                 ? data.author.profile.photo_thumb : default_user_profile}
                                                             alt=""
                                                        />
                                                    </a>
                                                    <hgroup className="title">
                                                        <h4>
                                                            <a href="fam-profile.html">
                                                                @{data?.author?.profile?.username ? data?.author?.profile?.username : data?.author?.profile?.full_name}
                                                            </a>
                                                        </h4>
                                                        <p className="text-p4 sm:text-p3">{getPostDate(data?.created_on)}</p>
                                                    </hgroup>
                                                </div>
                                                <a className="button-icon-base-gradient">
                                                    {authUserInfo && authUserInfo.id === data.user_id &&
                                                        <EllipsisDropdown
                                                            handleDropdownSelection={handleDropdownSelection}
                                                            data={data}
                                                            type={"post"}
                                                            is_pinned={data.is_pinned}
                                                            isShowPinOnly={false}
                                                            className="text-black"/>
                                                    }
                                                </a>
                                            </div>
                                            <p>
                                                {data?.title}
                                            </p>
                                        </div>

                                        {(data && data.attachments) &&
                                            <a className="flex justify-center">
                                                {(data.attachments.videos && data.attachments.videos.length > 0) ? (
                                                        <ReactPlayer
                                                            playing={true}
                                                            loop={true}
                                                            controls={true}
                                                            muted={true}
                                                            width='100%'
                                                            height='100%'
                                                            className="max-h-element"
                                                            url={data.attachments.videos[0].video}
                                                        />
                                                    )
                                                    :
                                                    <>
                                                        {(data.attachments.images && data.attachments.images.length > 0) ? (
                                                                <div className="slide-container w-full">
                                                                    <Slide
                                                                        arrows={data.attachments.images.length > 1}
                                                                        indicators={data.attachments.images.length > 1}
                                                                        autoplay={false}
                                                                    >
                                                                        {data.attachments.images.map((slideImage, index)=> (
                                                                            <img key={index}
                                                                                src={slideImage.image_medium} alt={`Slide ${index + 1}`}
                                                                                className="object-cover object-center group-hover:opacity-75 aspect-square w-full max-h-element"
                                                                            />
                                                                        ))}
                                                                    </Slide>
                                                                </div>
                                                            )
                                                            :
                                                            <>
                                                                {(data.attachments.audios && data.attachments.audios.length > 0) &&
                                                                    <audio controls className="">
                                                                        <source src={data.attachments.audios[0].audio} type="audio/mpeg" />
                                                                    </audio>
                                                                }
                                                            </>
                                                        }
                                                    </>
                                                }
                                            </a>
                                        }

                                        <div className="stack-col p-article">
                                            <div className="cs-stack-post-actions stack-row split">
                                                {/* CSJS Toggle to fa-solid when liked/clicked by User. fa-light state is unliked/not toggled. */}
                                                <div className="cs-stack-comment-hearts stack-row">
                                                    <a className="button-icon-base-gradient"
                                                       onClick={() => toggleHeart(data.id, data.is_hearted)}
                                                    >
                                                        <span className="sr-only">Love Post</span>
                                                        <i className={`${data.is_hearted ? 'fa-solid' : 'fa-light'} fa-heart`}/>
                                                    </a>
                                                    {data.hearts_count > 0 &&
                                                        <p>{data.hearts_count}</p>
                                                    }
                                                </div>

                                                <a className="button-icon-base-gradient"
                                                   onClick={()=>setIsShareModalOpen(true)}
                                                >
                                                    <span className="sr-only">Share Post</span>
                                                    <i className="fa-light fa-share"/>
                                                </a>
                                            </div>
                                            <div className="cs-stack-post-activity stack-row-wrap split">
                                                <div className="stack-col sm:stack-row">
                                                    <div className="stack-avatar">
                                                        {data && data?.hearted_by_users && data?.hearted_by_users.length > 0 && data?.hearted_by_users.slice(0, 3).map((user, index) => (
                                                            <div key={index} className="avatar-gradient relative inline-block">
                                                                <img alt="member-thumb" src={user.photo_thumb ? user.photo_thumb : default_user_profile} />
                                                                {/*<i className="fa-solid fa-badge-check absolute bottom-0 right-0 block h-4 w-4 rounded-full gradient-color-txt text-white"/>*/}
                                                            </div>
                                                        ))}
                                                    </div>
                                                    {data && data?.hearted_by_users && data?.hearted_by_users.length > 0 && (
                                                        <p>
                                                            Loved by
                                                            {data && data?.hearted_by_users && data.hearts_count > 0 && data.hearts_count > 3 ?
                                                                <>
                                                                    {data?.hearted_by_users.slice(0,3).map((user, index) => (
                                                                        <a key={index} className='overflow-hidden ...'>
                                                                            &nbsp;@{user.username ? user.username : user.full_name}
                                                                        </a>
                                                                    ))}
                                                                </>
                                                                :
                                                                <>
                                                                    {data && data?.hearted_by_users && data.hearts_count > 0 && data?.hearted_by_users.map((user, index) => (
                                                                        <a key={index} className='overflow-hidden ...'>
                                                                            {data.hearts_count === 2 &&
                                                                                <>
                                                                                    &nbsp;{1 === index ? ' and ' : ''}
                                                                                </>
                                                                            }
                                                                            &nbsp;@{user.username ? user.username : user.full_name}
                                                                            {data.hearts_count === 3 &&
                                                                                <>
                                                                                    &nbsp;{0 === index ? ', ' :  1 === index ? ', and' : ''}
                                                                                </>
                                                                            }
                                                                        </a>
                                                                    ))}
                                                                </>
                                                            }
                                                            {(data && data?.hearted_by_users && data.hearts_count > 0 && data.hearts_count > 3) &&
                                                                <a>
                                                                    &nbsp;and {data.hearts_count - 3} {(data.hearts_count - 3) > 1 ? 'others' : 'other'}
                                                                </a>
                                                            }
                                                        </p>
                                                    )}

                                                </div>
                                                <p>
                                                    {(data && data.comments) ? data.comments.length : '' } {(data && data.comments && data.comments.length > 1) ? 'comments' : 'comment' }
                                                </p>
                                            </div>
                                            <div className="cs-stack-post-comment-form stack-row">
                                                <div className="avatar-gradient shrink-0">
                                                    <img
                                                        src={authUserInfo && authUserInfo.profile.photo_thumb ? authUserInfo.profile.photo_thumb : default_user_profile}
                                                        alt=""
                                                    />
                                                    {/*<i className="fa-solid fa-badge-check absolute bottom-0 right-0 block h-4 w-4 rounded-full gradient-color-txt text-white"/>*/}
                                                </div>
                                                <Form className="w-full form-input-combo"
                                                      form={commentForm}
                                                      name="commentForm"
                                                      onFinish={handleCommentSubmit}
                                                      onChange={handleCommentChanges}
                                                      validateMessages={VALIDATE_MESSAGES}
                                                >
                                                    <Form.Item
                                                        name="comment"
                                                        id="comment"
                                                        className="w-full !p-0 !m-0"
                                                    >
                                                        <input
                                                            placeholder={`Comment as @${authUserInfo?.profile?.username ? authUserInfo?.profile?.username : ''}`}
                                                            // maxRows={4}
                                                            // onChange={handlePostChanges}
                                                            onClick={() => setModelId(data.id)}
                                                            // onKeyPress={handlePostKeypress}
                                                        />
                                                    </Form.Item>
                                                    <button
                                                        disabled={isSubmitting}
                                                        type="submit"
                                                        className={'secondary-gradient'}
                                                    >
                                                        <span><p>
                                                            Post</p></span>
                                                    </button>
                                                </Form>
                                            </div>

                                            {/*BEGIN - COMMENT - DOUBLE NESTED REPLIES EXAMPLE*/}
                                            {data.comments && data.comments.map((comment, index) => (
                                                <div key={index} className="cs-comment stack-col">
                                                    <div className="stack-row">
                                                        <a href="fam-profile.html" className="cs-comment-avatar relative shrink-0">
                                                            <img src={comment?.commenter?.photo_thumb ? comment?.commenter.photo_thumb : default_user_profile}
                                                                alt=""
                                                            />
                                                            {/*<i className="fa-solid fa-badge-check absolute bottom-0 right-0 block h-4 w-4 rounded-full gradient-color-txt text-white"/>*/}
                                                        </a>

                                                        <div className="cs-comment-thread stack-col">
                                                            <div className="cs-stack-comment-body stack-col-base">
                                                                <div className="cs-stack-comment-header stack-row split">
                                                                    <div className="stack-row">
                                                                        <div className="cs-stack-comment-published stack-col-base sm:stack-row">
                                                                            <h4>
                                                                                <a href="fam-profile.html">
                                                                                    @{comment?.commenter?.username ? comment?.commenter?.username : (comment?.commenter?.full_name ? comment?.commenter?.full_name : '')}
                                                                                </a>
                                                                            </h4>
                                                                            <p>
                                                                                {getPostDate(comment?.created_on)}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    {authUserInfo && authUserInfo.id === comment?.user_id &&
                                                                        <a className='button-icon-base-gradien' onClick={() => setIdsForDeletion(data.id, comment?.id)}>
                                                                            <EllipsisDropdown handleDropdownSelection={handleDropdownSelection} data={comment} type={"comment"}/>
                                                                        </a>
                                                                    }
                                                                </div>
                                                                <p className="cs-comment-body">
                                                                    {comment && (
                                                                        comment?.comment
                                                                    )}
                                                                </p>
                                                                {/*{commentId !== comment?.id && (*/}
                                                                <div className="cs-stack-comment-actions stack-row split">
                                                                    {/*CSJS Toggle to fa-solid when liked/clicked by User. fa-light state is unliked/not toggled.*/}
                                                                    <div className="cs-stack-comment-hearts stack-row">
                                                                        <a className="button-icon-base-gradient"
                                                                           onClick={() => toggleHeartComment(data?.id, comment?.is_hearted, comment?.id)}
                                                                        >
                                                                            <span className="sr-only">Love Post</span>
                                                                            <i className={`${comment?.is_hearted ? 'fa-solid' : 'fa-light'} fa-heart`}/>
                                                                        </a>
                                                                        {(comment?.hearted_by_users && comment?.hearted_by_users.length > 0) &&
                                                                            <p>{comment?.hearted_by_users.length}</p>
                                                                        }
                                                                    </div>
                                                                    <a className="button-icon-base-gradient" onClick={() => {
                                                                        subCommentForm.resetFields();
                                                                        subCommentReplyForm.resetFields();
                                                                        setSubCommentIdOpened(null);
                                                                        setCommentIdOpened(commentIdOpened === comment?.id ? null : comment?.id);
                                                                    }}>
                                                                        <span className="sr-only">Post Reply</span>
                                                                        <i className={`${commentIdOpened === comment?.id ? 'fa-solid' : 'fa-light' } fa-reply`}/>
                                                                    </a>
                                                                </div>
                                                                {/*)}*/}
                                                            </div>
                                                            {commentIdOpened === comment?.id && (
                                                                <div className="cs-stack-post-comment-form stack-row">
                                                                    <a href="fam-profile.html" className="cs-comment-avatar shrink-0 relative">
                                                                        <img
                                                                            src={authUserInfo && authUserInfo.profile.photo_thumb ? authUserInfo.profile.photo_thumb : default_user_profile}
                                                                            alt=""
                                                                        />
                                                                        {/*<i className="fa-solid fa-badge-check absolute bottom-0 right-0 block h-4 w-4 rounded-full gradient-color-txt text-white"/>*/}
                                                                    </a>
                                                                    <Form className="w-full form-input-combo"
                                                                          form={subCommentForm}
                                                                          name="subCommentForm"
                                                                          onFinish={handleSubmitSubComment}
                                                                        // onChange={handleCommentChanges()}
                                                                          validateMessages={VALIDATE_MESSAGES}
                                                                    >
                                                                        <Form.Item
                                                                            name="reply"
                                                                            id="reply"
                                                                            className="w-full !p-0 !m-0"
                                                                        >
                                                                            <input
                                                                                placeholder={`Comment as @${authUserInfo?.profile?.username ? authUserInfo?.profile?.username : ''}`}
                                                                                // maxRows={4}
                                                                                // onChange={handlePostChanges}
                                                                                onClick={() => setReplyModelId(comment?.model_id, comment?.id)}
                                                                                // onKeyPress={handlePostKeypress}
                                                                            />
                                                                        </Form.Item>
                                                                        <button
                                                                            disabled={isSubmitting}
                                                                            type="submit"
                                                                            className={'secondary-gradient'}
                                                                        >
                                                                            <span><p>Reply</p></span>
                                                                        </button>
                                                                    </Form>
                                                                </div>
                                                            )}
                                                            {/*BEGIN - Thread Reply - Nesting Point*/}

                                                            {/*BEGIN - COMMENT - SINGLE NESTED COMMENT*/}
                                                            {comment && comment?.replies && comment?.replies.map((reply, index) => (
                                                                <div key={index} className="cs-comment stack-col">
                                                                    <div className="stack-row">
                                                                        <a href="fam-profile.html" className="cs-comment-avatar relative shrink-0">
                                                                            <img
                                                                                src={reply?.commenter?.photo_thumb ? reply.commenter.photo_thumb : default_user_profile}
                                                                                alt=""
                                                                            />
                                                                            {/*<i className="fa-solid fa-badge-check absolute bottom-0 right-0 block h-4 w-4 rounded-full gradient-color-txt text-white"/>*/}
                                                                        </a>
                                                                        <div className="cs-comment-thread stack-col">
                                                                            <div className="cs-stack-comment-body stack-col-base">
                                                                                <div className="cs-stack-comment-header stack-row split">
                                                                                    <div className="stack-row">
                                                                                        <div className="cs-stack-comment-published stack-col-base sm:stack-row">
                                                                                            <h4>
                                                                                                <a href={reply?.commenter?.url}>
                                                                                                    @{reply?.commenter?.username ? reply?.commenter?.username :
                                                                                                    (reply?.commenter?.full_name ? reply?.commenter?.full_name : '')}
                                                                                                </a>
                                                                                            </h4>
                                                                                            <p>{getPostDate(reply?.created_on)}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                    {authUserInfo && authUserInfo.id === reply.user_id &&
                                                                                        <a className='button-icon-base-gradient' onClick={() => setIdsForDeletion(data.id, comment?.id)}>
                                                                                            <EllipsisDropdown handleDropdownSelection={handleDropdownSelection} data={reply} type={"sub-comments"}/>
                                                                                        </a>
                                                                                    }
                                                                                </div>
                                                                                <p className="cs-comment-body">
                                                                                    {reply && (
                                                                                        reply.comment
                                                                                    )}
                                                                                </p>
                                                                                {/*{commentId !== reply?.id && (*/}
                                                                                    <div className="cs-stack-comment-actions stack-row split">
                                                                                        {/*CSJS Toggle to fa-solid when liked/clicked by User. fa-light state is unliked/not toggled.*/}
                                                                                        <div className="cs-stack-comment-hearts stack-row">
                                                                                            <a className="button-icon-base-gradient"
                                                                                               onClick={() => toggleHeartComment(data?.id, reply?.is_hearted, comment?.id, reply?.id)}
                                                                                            >
                                                                                                <span className="sr-only">Love Post</span>
                                                                                                <i className={`${reply?.is_hearted ? 'fa-solid' : 'fa-light'} fa-heart`}/>
                                                                                            </a>
                                                                                            {reply && reply?.hearted_by_users && reply?.hearted_by_users.length > 0 &&
                                                                                                <p>{reply?.hearted_by_users.length}</p>
                                                                                            }
                                                                                        </div>
                                                                                        <a className="button-icon-base-gradient"
                                                                                           onClick={() => {
                                                                                               subCommentReplyForm.resetFields();
                                                                                               subCommentForm.resetFields();
                                                                                               setCommentIdOpened(null);
                                                                                               setReplyIdOpened(null);
                                                                                               setSubCommentIdOpened(subCommentIdOpened === reply?.id ? null : reply?.id);
                                                                                           }}
                                                                                        >
                                                                                            <span className="sr-only">Post Reply</span>
                                                                                            <i className={`${subCommentIdOpened === reply?.id ? 'fa-solid' : 'fa-light'} fa-reply`}/>
                                                                                        </a>
                                                                                    </div>
                                                                                    {/*)*/}
                                                                                {/*}*/}
                                                                                {subCommentIdOpened === reply?.id && (
                                                                                    <div className="cs-stack-post-comment-form stack-row">
                                                                                        <a href="fam-profile.html" className="cs-comment-avatar shrink-0 relative">
                                                                                            <img
                                                                                                src={authUserInfo && authUserInfo.profile.photo_thumb ? authUserInfo.profile.photo_thumb : default_user_profile}
                                                                                                alt=""
                                                                                            />
                                                                                            {/*<i className="fa-solid fa-badge-check absolute bottom-0 right-0 block h-4 w-4 rounded-full gradient-color-txt text-white"/>*/}
                                                                                        </a>
                                                                                        <Form className="w-full form-input-combo"
                                                                                              form={subCommentReplyForm}
                                                                                              name="subCommentRepyForm"
                                                                                              id="subCommentRepyForm"
                                                                                              onFinish={handleSubmitSubReply}
                                                                                            // onChange={handleCommentChanges()}
                                                                                              validateMessages={VALIDATE_MESSAGES}
                                                                                        >
                                                                                            <Form.Item
                                                                                                name="reply"
                                                                                                id="reply"
                                                                                                className="w-full !p-0 !m-0"
                                                                                            >
                                                                                                <input
                                                                                                    placeholder={`Comment as @${authUserInfo?.profile?.username ? authUserInfo?.profile?.username : ''}`}
                                                                                                    // maxRows={4}
                                                                                                    // onChange={handlePostChanges}
                                                                                                    onClick={() => setSubReplyModelId(comment?.model_id, comment?.id, reply?.id, null)}
                                                                                                    // onKeyPress={handlePostKeypress}
                                                                                                />
                                                                                            </Form.Item>

                                                                                            <button
                                                                                                disabled={isSubmitting}
                                                                                                type="submit"
                                                                                                className={'secondary-gradient'}
                                                                                            >
                                                                                                <span><p>Reply</p></span>
                                                                                            </button>
                                                                                        </Form>
                                                                                    </div>
                                                                                )}

                                                                            </div>
                                                                            {/*BEGIN - Thread Reply - Nesting Point*/}

                                                                            {/*BEGIN - COMMENT - DOUBLE NESTED COMMENT*/}
                                                                            {reply && reply.sub_replies && reply.sub_replies.map((sub_reply, index) => (
                                                                                <div key={index} className="cs-comment stack-col">
                                                                                    <div className="stack-row">
                                                                                        <a href="fam-profile.html" className="cs-comment-avatar relative shrink-0">
                                                                                            <img
                                                                                                src={sub_reply?.commenter?.photo_thumb ? sub_reply?.commenter?.photo_thumb  : default_user_profile}
                                                                                                alt=""
                                                                                            />
                                                                                            {/*<i className="fa-solid fa-badge-check absolute bottom-0 right-0 block h-4 w-4 rounded-full gradient-color-txt text-white"/>*/}
                                                                                        </a>
                                                                                        <div className="cs-comment-thread stack-col">
                                                                                            <div className="cs-stack-comment-body stack-col-base">
                                                                                                <div className="cs-stack-comment-header stack-row split">
                                                                                                    <div className="stack-row">
                                                                                                        <div className="cs-stack-comment-published stack-col-base sm:stack-row">
                                                                                                            <h4>
                                                                                                                <a href={sub_reply?.commenter?.url} className="gradient-color-txt font-bold">
                                                                                                                @{sub_reply?.commenter?.username ? sub_reply?.commenter?.username :
                                                                                                                (sub_reply?.commenter?.full_name ? sub_reply?.commenter?.full_name : '')}
                                                                                                                </a>
                                                                                                            </h4>
                                                                                                            <p>{getPostDate(reply?.created_on)}</p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    {authUserInfo && authUserInfo.id === reply.user_id &&
                                                                                                        <a className='button-icon-base-gradient' onClick={() => setIdsForDeletion(data?.id, comment?.id, reply?.id, sub_reply?.id)}>
                                                                                                            <EllipsisDropdown handleDropdownSelection={handleDropdownSelection} data={sub_reply} type={"sub-replies"}/>
                                                                                                        </a>
                                                                                                    }
                                                                                                </div>
                                                                                                <p className="cs-comment-body">
                                                                                                    {reply && (
                                                                                                        sub_reply.comment
                                                                                                    )}
                                                                                                </p>
                                                                                                {/*{commentId !== reply?.id && (*/}
                                                                                                    <div className="cs-stack-comment-actions stack-row split">
                                                                                                        {/*CSJS Toggle to fa-solid when liked/clicked by User. fa-light state is unliked/not toggled.*/}
                                                                                                        <div className="cs-stack-comment-hearts stack-row">
                                                                                                            <a className="button-icon-base-gradient"
                                                                                                               onClick={() => toggleHeartComment(data?.id, sub_reply?.is_hearted, comment?.id, reply?.id, sub_reply?.id)}
                                                                                                            >
                                                                                                                <span className="sr-only">Love Post</span>
                                                                                                                <i className={`${sub_reply?.is_hearted ? 'fa-solid' : 'fa-light'} fa-heart`}/>
                                                                                                            </a>
                                                                                                            {sub_reply && sub_reply?.hearted_by_users && sub_reply?.hearted_by_users.length > 0 &&
                                                                                                                <p>{sub_reply?.hearted_by_users.length}</p>
                                                                                                            }
                                                                                                        </div>
                                                                                                        {/*CSJS fa-solid class change when toggled/active. fa-light when inactive.*/}
                                                                                                        <a className="button-icon-base-gradient"
                                                                                                           onClick={() => {
                                                                                                               subCommentReplyForm.resetFields();
                                                                                                               subCommentForm.resetFields();
                                                                                                               setReplyIdOpened(replyIdOpened === sub_reply?.id ? null : sub_reply?.id);
                                                                                                               setCommentIdOpened(null);
                                                                                                               setSubCommentIdOpened(null);
                                                                                                           }
                                                                                                               // showSubCommentSubReply(sub_reply?.id)
                                                                                                           }
                                                                                                        >
                                                                                                            <span className="sr-only">Post Reply</span>
                                                                                                            <i className={`${replyIdOpened === sub_reply?.id ? 'fa-solid' : 'fa-light'} fa-reply`}/>
                                                                                                        </a>
                                                                                                    </div>
                                                                                                {/*)}*/}

                                                                                            </div>
                                                                                            {/*BEGIN - Thread Reply - Nesting Point*/}
                                                                                            {/*/END - Thread Reply - Nesting Point*/}
                                                                                        </div>
                                                                                    </div>
                                                                                    {replyIdOpened === sub_reply?.id && (
                                                                                        <div className="cs-stack-post-comment-form stack-row">
                                                                                            <a href="fam-profile.html" className="cs-comment-avatar shrink-0 relative">
                                                                                                <img
                                                                                                    src={authUserInfo && authUserInfo.profile.photo_thumb ? authUserInfo.profile.photo_thumb : default_user_profile}
                                                                                                    alt=""
                                                                                                />
                                                                                                {/*<i className="fa-solid fa-badge-check absolute bottom-0 right-0 block h-4 w-4 rounded-full gradient-color-txt text-white"/>*/}
                                                                                            </a>
                                                                                            <Form className="w-full form-input-combo"
                                                                                                  form={subCommentReplyForm}
                                                                                                  name="subCommentRepyForm"
                                                                                                  id="subCommentRepyForm"
                                                                                                  onFinish={handleSubmitSubReply}
                                                                                                // onChange={handleCommentChanges()}
                                                                                                  validateMessages={VALIDATE_MESSAGES}
                                                                                            >
                                                                                                <Form.Item
                                                                                                    name="reply"
                                                                                                    id="reply"
                                                                                                    className="w-full !p-0 !m-0"
                                                                                                >
                                                                                                    <input
                                                                                                        name="comment"
                                                                                                        placeholder={`Comment as @${authUserInfo?.profile?.username ? authUserInfo?.profile?.username : ''}`}
                                                                                                        // maxRows={4}
                                                                                                        // onChange={handlePostChanges}
                                                                                                        onClick={() => setSubReplyModelId(comment?.model_id, comment?.id, reply?.id)}
                                                                                                        // onKeyPress={handlePostKeypress}
                                                                                                    />
                                                                                                </Form.Item>

                                                                                                <button
                                                                                                    disabled={isSubmitting}
                                                                                                    type="submit"
                                                                                                    className={'secondary-gradient'}
                                                                                                >
                                                                                                    <span><p>Reply</p></span>
                                                                                                </button>
                                                                                            </Form>
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                                )
                                                                            )}
                                                                            {/*/END COMMENT - DOUBLE NESTED COMMENT*/}
                                                                            {/*/END - Thread Reply - Nesting Point*/}
                                                                        </div>
                                                                    </div>
                                                                    {/*BEGIN - Thread Reply - Inline Point*/}
                                                                    {/*/END - Thread Reply - Inline Point*/}
                                                                </div>
                                                                )
                                                            )}
                                                            {/*/END COMMENT - SINGLE NESTED COMMENT*/}
                                                            {/*/END - Thread Reply - Nesting Point*/}
                                                        </div>
                                                    </div>
                                                    {/*BEGIN - Thread Reply - Inline Point*/}
                                                    {/*/END - Thread Reply - Inline Point*/}
                                                </div>
                                                )
                                            )}
                                            {/*/END COMMENT - DOUBLE NESTED REPLIES EXAMPLE*/}
                                        </div>
                                    </article>
                                ))}

                            </div>
                        </div>

                        <aside className="cs-aside-col block-md space-y-0 order-1 lg:order-2">

                            {/* BEGIN - CHANNEL HEADER - ASIDE INSTANCE */}
                            <div className="cs-stack-channel-header stack-col mb-article lg:hidden">
                                {/*BEGIN - CHANNEL HEADER INFO*/}
                                <div className="cs-stack-channel-header-info stack-col-min">
                                    <div className="stack-col sm:stack-row split">
                                        {/*CSJS <span> text is {space_channels_channelname} for specific Channel */}
                                        <h2>#{spaceChannel && spaceChannel.name ? spaceChannel.name : ''}</h2>
                                        {/*CSJS Display up to 5 most recent active Member profile avatars, based on channel activity in past 24 hours.*/}
                                        <div className="stack-avatar">
                                            {spaceData && spaceData.members && spaceData.members.length > 0 && spaceData.members.slice(0, 10).map((member, index) => (
                                                <div key={index} className="relative avatar-gradient" style={{zIndex: (spaceData.members.length > 10 ? 10 : spaceData.members.length ) - (index + 1)}}
                                                    // className={`z-${(spaceData.memberships.length > 10 ? 10 : spaceData.memberships.length ) - (index + 1)}`}
                                                >
                                                    <img alt="member-thumb" className="hex object-cover h-8 w-8" src={member.profile.photo_thumb ? member.profile.photo_thumb : default_user_profile} />
                                                    {/*<i className="fa-solid fa-badge-check absolute bottom-0 right-0 block h-4 w-4 rounded-full gradient-color-txt text-white"/>*/}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="stack-col-base sm:stack-row split">
                                        <p>
                                            {itemElRef && itemElRef.current ? itemElRef.current.length : 0}
                                            &nbsp;New
                                            &nbsp;{itemElRef && itemElRef.current && itemElRef.current.length > 1 ? 'Posts' : 'Post'}
                                        </p>
                                        {/*CSJS Display count based on channel activity in past 24 hours.*/}
                                        <p>
                                            {spaceData && spaceData.members ? spaceData.members.length : 0}
                                            &nbsp;{spaceData && spaceData.members && spaceData.members.length > 1 ? 'Members' : 'Member'}
                                            &nbsp;Active
                                        </p>{/*CSJS Display count based on channel activity in past 24 hours.*/}
                                    </div>
                                </div>
                                {/*/END - CHANNEL HEADER INFO*/}
                                {/*BEGIN - POST FORM*/}
                                <div className="cs-post-form block-full card">
                                    <div className="stack-row">
                                        <div className="cs-post-form-avatar shrink-0">
                                            <img className="h-16 w-16 rounded-full object-cover italic"
                                                 src={authUserInfo && authUserInfo.profile.photo_thumb ? authUserInfo.profile.photo_thumb : default_user_profile}
                                                 alt=""
                                            />
                                            {/*<i className="fa-solid fa-badge-check absolute bottom-0 right-0 block h-4 w-4 rounded-full gradient-color-txt text-white"/>*/}
                                        </div>

                                        <Form className="stack-col w-full"
                                              form={postForm}
                                              name="postForm"
                                              onFinish={ imageFiles.length > 0 || videoFiles.length > 0 || audioFile ? attachmentSubmit : handlePostSubmit }
                                              onChange={handleChanges}
                                              validateMessages={VALIDATE_MESSAGES}
                                        >
                                            <Form.Item
                                                name="post"
                                                id="post"
                                                className="w-full"
                                                // validateTrigger="onBlur"
                                                // rules={[{ required: true }]}
                                            >
                                                <div className="form-textarea">
                                                     <textarea
                                                         placeholder={`Message #${spaceChannel.name}`}
                                                         onChange={handlePostChanges}
                                                         rows={3}
                                                     />
                                                    {/*<TextareaAutosize*/}
                                                    {/*    placeholder="Message #Friends"*/}
                                                    {/*    maxRows={3}*/}
                                                    {/*    className="mt-8 focus:outline-none text-xl resize-none items-center rounded-md placeholder:text-gray-400 sm:leading-6 w-full no-scrollbar"*/}
                                                    {/*    onChange={handlePostChanges}*/}
                                                    {/*    onKeyPress={handlePostKeypress}*/}
                                                    {/*/>*/}
                                                </div>
                                            </Form.Item>

                                            { ((imageFiles && imageFiles.length > 0) || (videoFiles && videoFiles.length > 0)) &&
                                                <div className="cs-stack-attachments stack-row-wrap">
                                                    {(imageFiles && imageFiles.length > 0) && imageFiles.map((image, index) => (
                                                        <div key={index} className="form-input-media sm air aspect-cs-preview corner-i" style={ {backgroundImage: 'url(' + image.uri + ')'} }>
                                                            <a>
                                                                <i className="fa-solid fa-circle-xmark"
                                                                   onClick={()=>{
                                                                       imageFiles.splice(index, 1); // Remove the element at the specified index
                                                                       setImageFiles([...imageFiles]); // Update the state with the modified array
                                                                   }}
                                                                />
                                                            </a>
                                                        </div>
                                                    ))}
                                                    {(videoFiles && videoFiles.length > 0) && videoFiles.map((file, index) => (
                                                        <div key={index} className="sm air aspect-video corner-i relative">
                                                            <ReactPlayer
                                                                playing={true}
                                                                loop={true}
                                                                controls={true}
                                                                muted={true}
                                                                width='100%'
                                                                height='100%'
                                                                className="form-input-media sm air aspect-cs-preview corner-i max-h-element"
                                                                url={file.uri}
                                                            />
                                                            <i className="fa-solid fa-circle-xmark absolute -right-1 -top-1 cursor-pointer"
                                                               onClick={()=>{
                                                                   videoFiles.splice(index, 1); // Remove the element at the specified index
                                                                   setVideoFiles([...videoFiles]); // Update the state with the modified array
                                                               }}
                                                            />
                                                        </div>

                                                    ))}
                                                </div>
                                            }
                                            {audioFile &&
                                                <div className="sm air w-72 corner-i relative">
                                                    <audio controls>
                                                        <source src={audioFile} type="audio/mpeg" />
                                                    </audio>
                                                    <i className="fa-solid fa-circle-xmark absolute -right-1 -top-1 cursor-pointer"
                                                       onClick={()=>{
                                                           setAudioFile(null);
                                                       }}
                                                    />
                                                </div>
                                            }
                                            <div className="stack-col sm:stack-row split">
                                                <div className="stack-row w-full">
                                                    <a className="button-icon-square-gradient-air">
                                                        <span className="sr-only">Attach Image</span>
                                                        <i className="fa-light fa-photo-film">
                                                            <Dragger
                                                                className="w-full h-full block left-0 top-0 bg-transparent bg-none absolute"
                                                                accept={VIDEO_FILETYPES.concat(IMAGE_FILETYPES)}
                                                                showUploadList="false"
                                                                beforeUpload={handleUploadFileChange}
                                                                customRequest={dummyRequest}
                                                            />
                                                        </i>
                                                    </a>
                                                    {/*<a className="button-icon-square-gradient-air">*/}
                                                    {/*    <span className="sr-only">Attach Link</span>*/}
                                                    {/*    <i className="fa-light fa-link"></i>*/}
                                                    {/*</a>*/}
                                                    <a className="button-icon-square-gradient-air">
                                                        <span className="sr-only">Attach Music</span>
                                                        <i className="fa-light fa-music">
                                                            <Dragger
                                                                className="w-full h-full block left-0 top-0 bg-transparent bg-none absolute"
                                                                accept={AUDIO_FILETYPES}
                                                                showUploadList="false"
                                                                beforeUpload={handleUploadFileChange}
                                                                customRequest={dummyRequest}
                                                            />
                                                        </i>
                                                    </a>
                                                    {/*<a className="button-icon-square-gradient-air">*/}
                                                    {/*    <span className="sr-only">Attach Poll</span>*/}
                                                    {/*    <i className="fa-light fa-poll-people"/>*/}
                                                    {/*</a>*/}
                                                </div>
                                                <Form.Item className='w-full'>
                                                    <button className="primary-gradient w-full"
                                                            disabled={isSubmitting || !isBtnPostEnabled}
                                                            type="submit"
                                                    >
                                                        <span><p>{t('button_channelpost')}</p></span>
                                                    </button>
                                                </Form.Item>
                                                {/*<button type="submit" className="primary-gradient w-full"><span><p>Post</p></span></button>*/}
                                            </div>

                                        </Form>
                                    </div>
                                </div>
                                {/*/END - POST FORM*/}
                            </div>
                            {/*/END - CHANNEL HEADER - ASIDE INSTANCE*/}

                            <div className="cs-stack-channel-aside stack-col">
                                <div className="cs-stack-channel-tools stack-col order-2 lg:order-1">
                                    <Form
                                        form={searchForm}
                                        name="searchForm"
                                        id="searchForm"
                                        onFinish={handleSearchPosts}
                                        validateMessages={VALIDATE_MESSAGES}
                                    >
                                        <div className="form-input-combo w-full">
                                            <Form.Item
                                                name="search"
                                                id="search"
                                                className="w-full !p-0 !m-0"
                                                // validateTrigger="onBlur"
                                                // rules={[{ required: true }]}
                                            >
                                                <input
                                                    type="search"
                                                    placeholder="Search posts"
                                                    name="searchText"
                                                    // value={searchText}
                                                    // onBlur={() => getMembersRequest()}
                                                />
                                            </Form.Item>

                                            <button type="submit"
                                                    className="secondary-gradient"
                                                    onClick={() => {
                                                        // clearTimeout(searchInputTimer);
                                                        // setSearchInputTimer(null);
                                                        // getMembersRequest().then((r) => {});
                                                    }}
                                            >
                                                <span><p>Search</p></span>
                                            </button>{/*CSJS Integrate into search tool.*/}

                                        </div>
                                    </Form>
                                </div>


                                <div className="cs-stack-categories stack-col order-1 lg:order-2">
                                    <h3>Channels</h3>
                                    <div className="cs-stack-category-links stack-row-wrap lg:stack-col">
                                        {spaceChannels && spaceChannels.length > 0 && spaceChannels.map((channel, index) => (
                                            <a key={index}
                                                 className={`${channel.guid === guid || channel.guid === fetchedGuid || channel.is_locked ? ' border-nav-color ':' cursor-pointer '} button-secondary-gradient squared`}
                                                 onClick={()=>{
                                                     if (channel.guid !== guid && !channel.is_locked) {
                                                         handleChangeChannel(spaceData.username, channel.guid)
                                                     }
                                                 }}
                                            >
                                                 <span className="stack-row-base split">
                                                     <p>{channel.is_locked ? <i className="ml-2 fa-light fa-lock"/> : ''}{channel.name}</p><p className="cs-count">+{channel.posts_count}</p>
                                                </span>
                                            </a>
                                        ))}
                                    </div>
                                </div>


                                {hasPinnedItem &&
                                    <div className="cs-stack-pinned stack-col order-4 lg:order-3">
                                        <h3>Pinned</h3>
                                        {spaceChannel && itemElRef && itemElRef.current && itemElRef?.current?.filter(data => data.is_pinned)?.map((data, index) => (
                                            <div key={index}>
                                                <article className='cs-post cs-pinned block-full card-base'>
                                                    <div className='stack-col p-article'>
                                                        <div className='cs-stack-post-header stack-row split'>
                                                            <div className="stack-row">
                                                                <a href={data?.author?.profile?.username ? routes.viewUserSpace() + data?.author?.profile?.username : ""} className="cs-post-avatar relative inline-block">
                                                                    <img
                                                                        className="h-16 w-16 rounded-full object-cover"
                                                                        src={data?.author && data?.author?.profile?.photo_thumb
                                                                            ? data?.author.profile?.photo_thumb
                                                                            : default_user_profile}
                                                                        alt=""
                                                                    />
                                                                    {/*<i className="fa-solid fa-badge-check absolute bottom-0 right-0 block h-4 w-4 rounded-full gradient-color-txt text-white"/>*/}
                                                                </a>
                                                                <hgroup className="title">
                                                                    <h4><a href={data?.author?.profile?.username ? routes.viewUserSpace() + data?.author?.profile?.username : ""}>@{data?.author?.profile?.username ? data?.author?.profile?.username :
                                                                        (data?.author?.profile?.full_name ? data?.author?.profile?.full_name : '')}</a></h4>
                                                                    <p>{getPostDate(data?.created_on)}</p>
                                                                </hgroup>
                                                            </div>
                                                            <a className="button-icon-base-gradient">
                                                                {authUserInfo && authUserInfo.id === data.user_id &&
                                                                    <EllipsisDropdown
                                                                        handleDropdownSelection={handleDropdownSelection}
                                                                        data={data}
                                                                        type={"post"}
                                                                        dataToEditCopy={null}
                                                                        is_pinned={data.is_pinned}
                                                                        isShowPinOnly={true}
                                                                        className="text-black"/>
                                                                }
                                                            </a>
                                                        </div>
                                                    </div>
                                                    {(data && data.attachments) &&
                                                        <a className="flex justify-center">
                                                            {(data.attachments.videos && data.attachments.videos.length > 0) ? (
                                                                    <ReactPlayer
                                                                        playing={true}
                                                                        loop={true}
                                                                        controls={true}
                                                                        muted={true}
                                                                        width='100%'
                                                                        height='100%'
                                                                        className="max-h-element"
                                                                        url={data.attachments.videos[0].video}
                                                                    />
                                                                )
                                                                :
                                                                <>
                                                                    {(data.attachments.images && data.attachments.images.length > 0) ? (
                                                                            <div className="slide-container w-full">
                                                                                <Slide
                                                                                    arrows={data.attachments.images.length > 1}
                                                                                    indicators={data.attachments.images.length > 1}
                                                                                    autoplay={false}
                                                                                >
                                                                                    {data.attachments.images.map((slideImage, index)=> (
                                                                                        <img key={index}
                                                                                             src={slideImage.image_medium} alt={`Slide ${index + 1}`}
                                                                                             className="object-cover object-center group-hover:opacity-75 aspect-square w-full max-h-element"
                                                                                        />
                                                                                    ))}
                                                                                </Slide>
                                                                            </div>
                                                                            // <img
                                                                            //     src={data.attachments.images[0].image_large}
                                                                            //     className="object-cover object-center group-hover:opacity-75 aspect-square w-full max-h-element"
                                                                            // />
                                                                        )
                                                                        :
                                                                        <>
                                                                            {(data.attachments.audios && data.attachments.audios.length > 0) &&
                                                                                <audio controls className="justify-center">
                                                                                    <source src={data.attachments.audios[0].audio} type="audio/mpeg" />
                                                                                </audio>
                                                                            }
                                                                        </>
                                                                    }
                                                                </>
                                                            }
                                                        </a>
                                                    }
                                                    <div className="stack-col p-article">
                                                        <p>{data?.title}</p>
                                                    </div>
                                                </article>
                                            </div>
                                        ))}
                                    </div>
                                }

                                <div className="cs-stack-trending stack-col order-3 lg:order-4">
                                    {/*<h3>Trending Tags</h3>*/}
                                    {/*<div className="cs-stack-tags stack-row-wrap">*/}
                                    {/*    <a href="#browseTag" itemProp="articleSection" className="chip-secondary-gradient"><span><p>Tokyo</p></span></a>*/}
                                    {/*    <a href="#browseTag" itemProp="articleSection" className="chip-secondary-gradient"><span><p>Vidcon</p></span></a>*/}
                                    {/*    <a href="#browseTag" itemProp="articleSection" className="chip-secondary-gradient"><span><p>Music</p></span></a>*/}
                                    {/*    <a href="#browseTag" itemProp="articleSection" className="chip-secondary-gradient"><span><p>Japan</p></span></a>*/}
                                    {/*    <a href="#browseTag" itemProp="articleSection" className="chip-secondary-gradient"><span><p>Best Drinks</p></span></a>*/}
                                    {/*    <a href="#browseTag" itemProp="articleSection" className="chip-secondary-gradient"><span><p>Meetups</p></span></a>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </aside>
                    </div>
                </section>
                :
                <></>
            }
        </>
	);
};

export default memo(Channel);