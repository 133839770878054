import React, { memo, useCallback, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {getMembershipDetails, getSpaceMembership, getSpaceUsernameByDomain} from '../../services/space';
import default_items_img from '../../assets/images/form_background.png';
import routes from '../../routes';
import { getSpaceInfo } from '../../states/spaceGlobal/actions';
import { Button } from 'antd';
import {getCommunityBtnText, getDetailsGift, getDetailsMonthlyPrice, getDetailsPrice} from "../../components/commons/helpers/MembershipItemHelpers";
import {getPaymentType, MembershipItemUtils, PaymentType} from "../../utils/MembershipItemUtils";
import {checkMembershipItemStatus} from "../../services/payment_method";
import {MEMBERSHIP_TYPES} from "../../constants/space";
import ReactPlayer from "react-player/lazy";
import {ROOT_DOMAIN} from "../../constants/common";

function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
}
const SpaceBuyMembership = (props) => {
	const { hasGradient, url } = props;
	const currentLocation = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [searchParams] = useSearchParams();
	const membershipId = searchParams.get('id');
	const [membership, setMembership] = useState(null);
	const [submitted, setSubmitted] = useState(false);
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const { isPublicUrl } = useSelector((state) => state.spaceViewMode);
	const [loadMembershipOnce, setLoadMembershipOnce] = useState(false);
	const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
	const [initialLoad, setInitialLoad] = useState(true);
	const [isMembershipLoaded, setMembershipLoaded] = useState(false);
	const [collectionAssetType, setCollectionAssetType] = useState('image');
	const [collectionAssetBGImage, setCollectionAssetBGImage] = useState(null);
	const [spaceName, setSpaceName] = useState(null);
	const [isDisabled, setIsDisabled] = useState(false);
	const [status, setStatus] = useState('');
	const [spaceUsername, setSpaceUsername] = useState(null);

	const getMembershipDetailsRequest = useCallback(async (domain) => {
		const data = {
			space_domain: domain
		}
		const response = await getSpaceUsernameByDomain(data);
		if (response && response.result) {
			setSpaceUsername(response.space_username);
		}

	}, []);

	// const getUrl = (url) => {
	//     if (url.startsWith('https://')) {
	//         return url.slice(8); // Remove the first 8 characters (https://)
	//     }
	//     if (url.startsWith('http://')) {
	//         return url.slice(7); // Remove the first 7 characters (http://)
	//     }
	//     return url;
	// }

	const scrollToClick = () => {
		const element = document.getElementById('cs-space-product-item-c2');
		element.scrollIntoView({ behavior: 'smooth' });
	}

	const getMembership = useCallback(async (space_id, membership_id) => {
		if (!membership_id) return;
		const data = {
			space_id: space_id,
			membership_id: membership_id
		}
		if (authUserInfo) {
			data['viewing_user_id'] = authUserInfo.id;
		}
		const response = await getSpaceMembership(data);
		if (response && response.result) {
			const membership = response.membership;
			setMembership(membership)
			// setIsPaid(membership.is_paid);
			setMembershipLoaded(true);
			if (membership.photo || membership.video) {
				if (membership.photo) {
					const bg = {
						backgroundImage: 'url(' + membership.photo + ')',
						backgroundPosition: 'center',
						backgroundRepeat: 'no-repeat',
						backgroundSize: 'cover'
					};
					setCollectionAssetBGImage(bg);
					setCollectionAssetType('image');
				}
				if (membership.video) {
					const bg = {
						backgroundImage: 'none'
					};
					setCollectionAssetBGImage(bg);
					setCollectionAssetType('video');
				}
			} else {
				const bg = {
					backgroundImage: 'url(' + default_items_img + ')',
					backgroundPosition: 'center',
					backgroundRepeat: 'no-repeat',
					backgroundSize: 'cover'
				};
				setCollectionAssetBGImage(bg);
				setCollectionAssetType('image');
			}
		}}, [authUserInfo]
	);

	const checkStatus = useCallback(async (space_id, membership_id) => {
		if (!membership_id) return;
		const data = {
			space_id: space_id,
			membership_id: membership_id
		}
		if (authUserInfo) {
			data['user_id'] = authUserInfo.id;
		}
		const response = await checkMembershipItemStatus(data);
		if (response && response.result) {
			const transaction = response.stripe_transaction;
			const sessionStatus = response.session_status;
			if (transaction) {
				if (sessionStatus !== 'open') {
					setStatus(transaction.status);
				}
				setIsDisabled(transaction.status === 'active' || transaction.status === 'expired');
			}
		}
	},[authUserInfo]);

	const proceedMembershipPayment = useCallback(async () => {
		setSubmitted(true);
		if (MembershipItemUtils.isOAuth(loading, authUserInfo)){
			if (spaceUsername) {
				let url = routes.spaceMembershipPaymentMethod();
				const fin_url =
					url.replace(':space_username', '@' + spaceUsername) + '?id=' + membershipId;
				navigate(fin_url);
				setSubmitted(false);
			}
		} else {

			let url = routes.spaceMembershipPaymentMethod();
			const fin_url =
				url.replace(':space_username', '@' + spaceUsername) + '?id=' + membershipId;
			navigate(fin_url);
			setSubmitted(false);
		}

	}, [spaceUsername]);

	const goToSpace = useCallback(async () => {
		const url = routes.viewUserSpace() + spaceUsername;
		window.location.replace(url);
	}, [spaceUsername]);

	useEffect(() => {
		if (spaceUsername) {
			if (spaceInfoLoading && spaceInfoLoading === 'done' && !isMembershipLoaded) {
				if (spaceInfo && spaceInfo.id) {
					getMembership(spaceInfo.id, membershipId).then((r) => {});
					setSpaceName(spaceInfo.name);
				}
			} else {
				if (!spaceInfoLoading && initialLoad) {
					setInitialLoad(false);
					dispatch(getSpaceInfo(spaceUsername));
				}
				if (spaceInfoLoading === 'failed') {
					navigate(routes.createSpace());
				}
			}
		}

		if (membership != null) {
			if (authUserInfo != null && !loadMembershipOnce) {
				setLoadMembershipOnce(true);
				getMembership(spaceInfo.id, membershipId).then((r) => {});
				checkStatus(spaceInfo.id, membershipId).then(r =>{} );
			}
			if (authUserInfo != null && !isDisabled) {
				if (membership.creator === authUserInfo.id) {
					setIsDisabled(true);
				}
			}
		}
	}, [
		currentLocation.pathname,
		authUserInfo,
		loading,
		spaceInfo,
		spaceInfoLoading,
		spaceUsername,
		initialLoad,
		membershipId,
		membership,
		isMembershipLoaded,
		loadMembershipOnce,
		isDisabled,
		status
	]);

	useEffect(() => {
		if (ROOT_DOMAIN !== window.location.hostname) {
			getMembershipDetailsRequest(window.location.hostname).then(r => {});
		} else {
			const path = currentLocation.pathname;
			if (path && path.startsWith('/@') && path.length > 3) {
				const clean_path = path.replace('/@', '');
				const path_exp = clean_path.split('/');
				setSpaceUsername(path_exp[0]);
			}
		}
	}, [currentLocation.pathname]);

	useEffect(() => {
		if (isPublicUrl) {
			setIsDisabled(true);
		}
	}, [isPublicUrl]);

	return (
		<>
			{!membership ? (
				<section id="page-content-loading-overlay" className="screen-modal p-section-safeview theme-transparent">
					<div className="centerframe-flex frame-modal">
						<div className="block-lg modal-block">
							<i className="fa-regular fa-solar-system"/>
						</div>
					</div>
				</section>
			) : (
				<section id="cs-space-product-membership-c1" className="p-section-safeview theme">
					<div className="centerframe-grid gap-2gutter">
						<div id="cs-product-cover-media" className={`block-lg rounded-card`}>
							<a>
								{membership && membership.video ? (
									<ReactPlayer
										playing={true}
										loop={true}
										muted={true}
										controls={false}
										width="100%"
										height="592px"
										className={`videoPreview max-h-element`}
										url={membership.video}
									/>
								) : (
									<img className="max-h-element rounded-card" src={membership.photo}/>
								)}
							</a>
						</div>
						<div id="cs-product-info" className="block-lg">
							<hgroup className="stack-col">
								<h2>
									{membership ? membership.name : ''}
								</h2>
								<p className="cs-product-info-summary">
									{membership ? membership.summary : ''}
								</p>
								<p className="cs-product-info-listing">
									{membership ? membership.listing_text : ''}
								</p>
							</hgroup>
							<div className="cs-product-pricing-details stack-row">
								{membership && membership.type === MEMBERSHIP_TYPES.NFT_COLLECTION ? (
									<div className="stack-col-base">
										<h2>Invite Only</h2>
										<p>NFT Required</p>
									</div>
								) : (
									<div className="stack-col-base">
										{/*
											For paid: CSJS This is the upfront price. It can be different from the renewal prices.
										*/}
										<h2>
											{getPaymentType(membership) === PaymentType.onetime ? (
												<>
													${(membership.price != null ? parseFloat(membership.price).toFixed(2) : 0)}
												</>
											) : getPaymentType(membership) === PaymentType.subscription ? (
												<>
													{/*${(parseFloat(membership?.first_benefit?.monthly_price).toFixed(2) ?? 0)}*/}
													${(membership.price != null ? parseFloat(membership.price).toFixed(2) : 0)}
												</>
											) : (
												'Free'
											)}
										</h2>
										{getPaymentType(membership) === PaymentType.subscription ? (
											<p className="cs-product-renewal-summary-cost">
												+ ${(parseFloat(membership?.first_benefit?.monthly_price).toFixed(2) ?? 0)}/month or less
											</p>
										) : (
											<>
												{/*
												 CSJS This is the single 1 month price "$[price]/month or less" or "No Expiry"
												 if no monthly cost. This line only exists for memberships, not items.
											*/}
												<p className="cs-product-renewal-summary-cost">
													No Expiry
												</p>
											</>
										)}
										{getPaymentType(membership) === PaymentType.onetime ? (
											<p>
												{/*24 of 88 Available*/}
											</p>
										) : getPaymentType(membership) === PaymentType.subscription ? (
											<p>Open Edition</p>
										) : (
											<p>Open Edition</p>
										)}

										{/*
											 CSJS "Open Edition" if unlimited or "[XX] of [YY] available" if limited.
										*/}
										{/*<p> 24 of 88 Available</p>*/}


									</div>
								)}
								<div className="stack-col-base">
									<h4><i className="fa-regular fa-square-user"/>Membership</h4>
									{/*
									 	CSJS This "Renewable" if there is an expiration, and "Lifetime" if no expiration".
										This line only exists for memberships, not items.
									*/}
									{getPaymentType(membership) !== PaymentType.subscription &&
										<h4><i className="fa-regular fa-infinity"/>Lifetime</h4>
									}
									{getPaymentType(membership) === PaymentType.subscription &&
										<h4><i className="fa-regular fa-arrows-rotate"/>Renewable</h4>
									}
									{/*{getPaymentType(membership) === PaymentType.subscription &&*/}
									{membership.benefits_count > 0 &&
										<h4><i className="fa-regular fa-gift"/>{membership.benefits_count} Benefit{membership.benefits_count > 1? 's': ''}</h4>
									}
								</div>
							</div>
							<div id="cs-stack-product-actions" className="stack-row">
								<a href="#purchaseProduct"
								   onClick={proceedMembershipPayment}
								   className={classNames("button-primary-gradient", submitted || isDisabled ? 'pointer-events-none' : '')}
								>
									<span><p>{getCommunityBtnText(authUserInfo, membership, true, false, status, true)}</p></span>
								</a>
								<a href="#purchaseProduct" className="button-secondary-gradient" onClick={scrollToClick}><span><p>Details</p></span></a>
							</div>
						</div>

					</div>
				</section>
			)}
			<section id="cs-space-product-item-c2" className="p-section-safeview theme-transparent">
				<div className="centerframe-grid gap-2gutter">

					<div className="block-full">
						<div className="cs-rte-output space-y-card">
							<p>{membership && membership.description &&
								membership.description
							}</p>
						</div>
					</div>
					<div className="block-full">
						<div id="cs-product-benefits" className="stack-col">
							<div className="datarow title">
								<h3>Benefits</h3>
								<p>This Membership provides Benefits for {' '}<a href={routes.viewUserSpace() + spaceUsername} target="_blank"><i className="fa-solid fa-solar-system"></i> {spaceName}</a></p>
							</div>
							<div className="datarow">
								<p>Monthly Access</p>
								<p>The {spaceName} Membership is a
									{' '}
									{getPaymentType(membership) === PaymentType.subscription &&
										<>
											subscription
										</>
									}
									{getPaymentType(membership) === PaymentType.free &&
										<>
											lifetime
										</>
									}
									{getPaymentType(membership) === PaymentType.onetime &&
										<>
											lifetime
										</>
									}{' '}
									membership.
									<span>
										{getPaymentType(membership) === PaymentType.subscription &&
											<>

											</>
										}
										{getPaymentType(membership) === PaymentType.free &&
											<>
												No Expiry
											</>
										}
										{getPaymentType(membership) === PaymentType.onetime &&
											<>
												No Expiry
											</>
										}
									</span>
								</p>
							</div>
							{/* Hide this no live data at the moment */}
							{/*<div className="datarow">*/}
							{/*    <p>100,000 Community Points</p>*/}
							{/*    <p>Add 100k community points to your account and use them for unlockable benefits and limited items! <span>1 redemption</span></p>*/}
							{/*</div>*/}
						</div>
						{/* Hide this no live data at the moment */}
						{/*<div id="cs-product-traits" className="stack-col">*/}
						{/*    <div className="datarow title">*/}
						{/*        <h3>Traits</h3>*/}
						{/*        <p>This Item has 3 traits</p>*/}
						{/*    </div>*/}
						{/*    <div className="datarow">*/}
						{/*        <p>Hidden Content</p>*/}
						{/*        <p>Viewable by Owner</p>*/}
						{/*    </div>*/}
						{/*    <div className="datarow">*/}
						{/*        <p>Properties</p>*/}
						{/*        <p>Status: VIP</p>*/}
						{/*    </div>*/}
						{/*    <div className="datarow">*/}
						{/*        <p>Boosts</p>*/}
						{/*        <p>+100k Community Points</p>*/}
						{/*    </div>*/}
						{/*</div>*/}

						<div id="cs-product-technicals" className="stack-row-wrap">
							<div className="datarow title">
								<h3>Additional Details</h3>
								<p>Space and technical details</p>
							</div>
							<div className="datarow">
								<p>Connected Space</p>
								<p>{spaceName}</p>
							</div>
							<div className="datarow">
								<p>Space URL</p>
								<p><a href={url} target="_blank">{url}</a></p>
							</div>
							<div className="datarow">
								<p>Account Limit</p>
								{membership && (
									<p>{membership.max_per_account}</p>
								)}
							</div>
							{/*<div className="datarow">*/}
							{/*    <p>Transferable</p>*/}
							{/*    <p>No, By NFT Ownership</p>*/}
							{/*</div>*/}
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default memo(SpaceBuyMembership);
