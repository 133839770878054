import React, {memo} from "react";
import {getSitewideLink} from "../../../../../utils/sitewideLinks";
import {getPostDate} from "../../../../../utils/DateTimeUtils";
import moment from "moment";

const MembershipDetails = (parentsProps) => {
    const { user, space, space_bg, membership, status, toggleSlide, goToSpace } = parentsProps;

    return (
        <>
            <div className="cs-dash-card cs-banner-card block-full card cs-bg-swap"
                 style={space_bg}>
                <div className="cs-banner-card-shader p-0 sm:p-card rounded-card">
                    <div className="formrow">
                        <div className="cs-banner-card-avatar rounded-t-card sm:rounded-card">
                            <img src={membership.photo_medium} />
                        </div>

                        <div className="cs-banner-card-info stack-col p-card sm:p-0">
                            <hgroup>
                                {membership &&
                                    <>
                                        <h3>{space.name}</h3>
                                        <h4>{membership.individual_title}</h4>
                                        <p>{membership.summary}</p>
                                    </>
                                }
                            </hgroup>
                            <div className="cs-banner-card-info-stats">
                                <h4>Membership Details</h4>
                                <div className="stack-row">
                                    <div className="cs-banner-card-info-stats-status">
                                        <p>@{user ? user.profile.username : ''}</p>
                                        {/*<p>{status.charAt(0).toUpperCase() + status.slice(1)}</p>*/}
                                        {(membership.payment_transaction && membership?.payment_transaction?.activated_on) &&
                                            <p>Joined {moment(membership?.payment_transaction?.created_on).format('MMMM DD, YYYY')}</p>
                                        }
                                    </div>
                                    {/*<div className="cs-banner-card-info-stats-ranked">*/}
                                    {/*    <p><i className="fa-regular fa-star"></i> 9,492 Points</p>*/}
                                    {/*    <p><i className="fa-regular fa-ranking-star"></i> #1 Rank</p>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>

                        <div className="cs-banner-card-controls stack-col p-card pt-0 sm:p-0">
                            <a className="button gradient secondary distribute"
                               onClick={() => {
                                   if (status === 'expired')
                                       toggleSlide();
                                   else
                                       goToSpace().then(r => {});
                               }}
                            >
                            <span>
                                <i className="fa-light fa-solar-system"></i>
                                <p>Access Space</p>
                                <i className="fa-light fa-arrow-up-right-from-square"></i>
                            </span>
                            </a>
                            <a href="#" className="button gradient secondary"><span><p>View Transactions</p></span></a>
                            {getSitewideLink('get_support', 'button gradient secondary', true)}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default memo(MembershipDetails);