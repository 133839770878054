/************************************
 *                                  *
 *                                  *
 *        Membership and Item       *
 *                                  *
 *           View Helpers           *
 *                                  *
 *                                  *
 ************************************/

import {CurrencyType, getPaymentType, PaymentType, RenewalOptions} from "../../../utils/MembershipItemUtils";
import {add, format} from "date-fns";
import moment from "moment";
import {MEMBERSHIP_TYPES} from "../../../constants/space";
import {CUSTOM_REGEX} from "../../../constants/custom_regex";
import React from "react";
import {useSelector} from "react-redux";

/**
 * Enumeration Membership/Item Types
 * @type {{item: string, membership: string}}
 */
export const MemberItemType = {
    membership: 'membership',
    item: 'item'
}

export const DateDayCondition = {
    default: 0,
    addOneDay: 1,
    deductOneDay: -1,
}

/**
 * Get the current symbol of the currency
 * @param currency
 * @returns {string}
 */
const getCurrencySymbol = (currency) => {
    if (currency === CurrencyType.USD) {
        return '$';
    }
    return '$';
}

/**
 * Returns Membership Price for Community Membership or Item
 * @param {boolean} isSubscription
 * @param {MEMBERSHIP_TYPES} type
 * @param {object} membershipItemDetails
 * @returns {string}
 */
export const getMembershipItemPrice = (isSubscription, type, membershipItemDetails) => {

    //Parse string into float
    const membershipPrice = membershipItemDetails.price == null ? 0 : parseFloat(membershipItemDetails.price);

    if (type === MEMBERSHIP_TYPES.NFT_COLLECTION) {
        //NFT
        return 'Free with NFT';
    } else {
        //Community
        /*
        Free membership with subscription
        or
        Free membership NO subscription
        */
        if (membershipPrice === 0) {
            return getCurrencySymbol(membershipItemDetails.currency)+ '0.00 ' + membershipItemDetails.currency;
        }
        /*
        Paid membership NO subscription
        or
        Paid membership with subscription
        */
        return getCurrencySymbol(membershipItemDetails.currency) + parseFloat(membershipItemDetails.price).toFixed(2) + ' ' + membershipItemDetails.currency;
    }
}

/**
 * Returns Membership and Item payment button text
 * @param {boolean} isSubscription
 * @param {MEMBERSHIP_TYPES} type
 * @param {[{}]} selectedMonthlyPrice
 * @param membershipItem
 * @returns {string}
 */
export const getButtonPaymentText = (isSubscription, type, selectedMonthlyPrice, membershipItem = null) => {
    if (type === MEMBERSHIP_TYPES.NFT_COLLECTION) {
        //NFT
        if (isSubscription) {
            if (selectedMonthlyPrice === RenewalOptions.renewal_none) {
                return 'Claim ' + (memberItemType === MemberItemType.item ? 'Item' : 'Membership')
            } else {
                return 'Claim ' + (memberItemType === MemberItemType.item ? 'Item' : 'Membership') + ' + Authorize Subscription'
            }
        }
        return 'Claim '+ (memberItemType === MemberItemType.item ? 'Item' : 'Membership')
    } else {
        if (membershipItem && getPaymentType(membershipItem) === PaymentType.free) {
            return 'Agree & Continue';
        } else {
            return 'Proceed to Payment';
        }
        //Community
        // return 'Purchase ' + (memberItemType === MemberItemType.item ? 'Item' : 'Membership');

    }
}

/**
 * Returns Membership Included Months
 * @param includedMonths
 * @returns {string}
 */
export const getIncludedMonths = (includedMonths) => {
    return includedMonths + ' Month'+ (includedMonths > 1 ? 's' : '');
}

/**
 * Returns the expiration date from the created date into the included months
 * Note: includedMonths default value is 1. Min includedMonths is 1.
 * @param {number} includedMonths
 * @param {number} dateDayCondition
 * @returns {string}
 */
export const getExpirationDate = (includedMonths, dateDayCondition) => {
    const date = moment(new Date());
    let createdDateWithIncludedMonths = add(new Date(date) , {months: includedMonths});
    if (DateDayCondition.addOneDay === dateDayCondition) {
        createdDateWithIncludedMonths = add(new Date(date.add(1, 'days')), {months: includedMonths});
    } else if (DateDayCondition.deductOneDay === dateDayCondition) {
        createdDateWithIncludedMonths = add(new Date(date.subtract(1, 'days')), {months: includedMonths});
    }
    // const createdDateWithIncludedMonths = add(isAddOneDay ? (new Date( date.add(1, 'days'))) : new Date(date) , {months: includedMonths});
    return format(createdDateWithIncludedMonths, 'MMMM d, yyyy');
}


/**
 * Returns the expiration date object
 * @param {number} includedMonths
 * @param {boolean} isAddOneDay
 * @returns {Date}
 */
export const getExpirationDateData = (includedMonths, isAddOneDay) => {
    const date = moment(new Date());
    return add(isAddOneDay ? (new Date(date.add(1, 'days'))) : new Date(date) , {months: includedMonths});
}


/**
 * Returns parse Array Object of monthly selection included with the discount pricing
 * for the recurring Monthly Dropdown
 * @param {object} membershipItemDetails
 * @param {boolean} isIncludeRenewOff
 * @param {boolean} isRenewMembership
 * @returns {[{}]}
 *
 * TAKE NOTE:
 *  Comment out the 3, 6, 12 Months price & discount display options for now (cyan box). Only display the “1 Month” and “Auto-Renew Off” options.
 */
export const autoRenewParser = (membershipItemDetails, isIncludeRenewOff = true, isRenewMembership = false) => {
    if (membershipItemDetails.enable_expiration_and_subscription && membershipItemDetails.first_benefit) {
        const currency = membershipItemDetails.first_benefit.currency;
        const monthlyPrice = parseFloat(membershipItemDetails.first_benefit.monthly_price).toFixed(2);
        const renewalListSelection = [];
        // Add the auto-renewal first in the array
        if (isIncludeRenewOff) {
            renewalListSelection.push({
                label: RenewalOptions.renewal_none,
                value: RenewalOptions.renewal_none,
                months: 0,
            })
        }
        //Example: Monthly Price = $2, Renewal Discount = 10%
        for (let count = 0; count < 4; count++) {
            // let discountTotalPrice = 0;
            if (count === 1) {
                //ex. "$54 USD for 3 Months" {$60 - 10% off (= $54)}
                const multiplier = 3;
                // const totalPrice = (monthlyPrice * multiplier);
                // const renewalDiscountPercentage = membershipItemDetails.first_benefit.renewal_discount / 100;
                // const discount = totalPrice * renewalDiscountPercentage;
                const discountTotalPrice = parseFloat(membershipItemDetails.first_benefit.three_month_renewal).toFixed(2);
                // const label = `3 months ${isRenewMembership ? '@': 'at'} ` + getCurrencySymbol(currency) + discountTotalPrice + ' each';
                const label = '3 Months 10% off';
                renewalListSelection.push({
                    label: label,
                    value: discountTotalPrice,
                    multiplier: multiplier,
                    months: 3,
                    currency:  CurrencyType.USD,
                    currency_symbol: getCurrencySymbol(currency)
                })
            } else if (count === 2){
                //ex. "$102 USD for 6 Months" {$120 - 15% off (= $102)}
                const multiplier = 6;
                // const totalPrice = (monthlyPrice * multiplier);
                // // const discount = totalPrice * (renewalDiscountPercentage * 2); // code is changed by adding 5 percent in 6 months
                // const renewalDiscountPercentage = (membershipItemDetails.first_benefit.renewal_discount + 5) / 100;
                // const discount = totalPrice * renewalDiscountPercentage;
                const discountTotalPrice = parseFloat(membershipItemDetails.first_benefit.six_month_renewal).toFixed(2);
                const label = `6 months ${isRenewMembership ? '@': 'at'} ` + getCurrencySymbol(currency) + discountTotalPrice + ' each';
                renewalListSelection.push({
                    label: label,
                    value: discountTotalPrice,
                    multiplier: multiplier,
                    months: 6,
                    currency:  CurrencyType.USD,
                    currency_symbol: getCurrencySymbol(currency)
                })
                // console.log('price: '+monthlyPrice * multiplier);
            } else if (count === 3){
                //ex. "$180 USD for 12 Months" {$240 - 25% off (= $180)}
                const multiplier = 12;
                // const totalPrice = (monthlyPrice * multiplier);
                // // const discount = totalPrice * (renewalDiscountPercentage * 3); // code is changed by adding 10 percent in 12 months
                // const renewalDiscountPercentage = (membershipItemDetails.first_benefit.renewal_discount + 15) / 100;
                // const discount = totalPrice * renewalDiscountPercentage;
                const discountTotalPrice = parseFloat(membershipItemDetails.first_benefit.twelve_month_renewal).toFixed(2);

                // const label = isRenewMembership ? '12 months @ ' + getCurrencySymbol(currency) + discountTotalPrice + ' each' :
                //     getCurrencySymbol(currency) + discountTotalPrice.toFixed(2) + ' ' + currency + ' ' + Recurring.twelveMonths
                const label = `12 months ${isRenewMembership ? '@': 'at'} ` + getCurrencySymbol(currency) + discountTotalPrice + ' each';

                renewalListSelection.push({
                    label: label,
                    value: discountTotalPrice,
                    multiplier: multiplier,
                    months: 12,
                    currency:  CurrencyType.USD,
                    currency_symbol: getCurrencySymbol(currency)
                })
                // console.log('price: '+monthlyPrice * multiplier);
            } else  {
                const discountTotalPrice = parseFloat(membershipItemDetails.first_benefit.monthly_price).toFixed(2);
                // const label = isRenewMembership ? '1 month @ ' + getCurrencySymbol(currency) + discountTotalPrice + ' each' :
                //     getCurrencySymbol(currency) + discountTotalPrice + ' ' + currency + ' ' + Recurring.oneMonth

                const label = `1 month ${isRenewMembership ? '@': 'at'} ` + getCurrencySymbol(currency) + discountTotalPrice + ' each';
                renewalListSelection.push({
                    label: label,
                    value: discountTotalPrice,
                    disabled: isIncludeRenewOff ? monthlyPrice < 3 : false, //disabled 1 month if monthly price is lesser than 3 dollars
                    multiplier: 1,
                    months: 1,
                    currency:  CurrencyType.USD,
                    currency_symbol: getCurrencySymbol(currency)
                })
            }
        }
        return renewalListSelection;
    }
    return [];
}
// Old Code
// export const autoRenewParser = (membershipItemDetails, isIncludeRenewOff = true, isRenewMembership = false) => {
//     if (membershipItemDetails.enable_expiration_and_subscription && membershipItemDetails.first_benefit) {
//         const currency = membershipItemDetails.first_benefit.currency;
//         const monthlyPrice = parseFloat(membershipItemDetails.first_benefit.monthly_price).toFixed(2);
//         const renewalListSelection = [];
//         // Add the auto-renewal first in the array
//         if (isIncludeRenewOff) {
//             renewalListSelection.push({
//                 label: RenewalOptions.renewal_none,
//                 value: RenewalOptions.renewal_none,
//                 months: 0,
//             })
//         }
//         //Example: Monthly Price = $2, Renewal Discount = 10%
//         for (let count = 0; count < 4; count++) {
//             // let discountTotalPrice = 0;
//             if (count === 1) {
//                 //ex. "$54 USD for 3 Months" {$60 - 10% off (= $54)}
//                 const multiplier = 3;
//                 const totalPrice = (monthlyPrice * multiplier);
//                 const renewalDiscountPercentage = membershipItemDetails.first_benefit.renewal_discount / 100;
//                 const discount = totalPrice * renewalDiscountPercentage;
//                 const discountTotalPrice = parseFloat(totalPrice - discount).toFixed(2);
//                 // const label = `3 months ${isRenewMembership ? '@': 'at'} ` + getCurrencySymbol(currency) + discountTotalPrice + ' each';
//                 const label = '3 Months 10% off';
//                 renewalListSelection.push({
//                     label: label,
//                     value: discountTotalPrice,
//                     multiplier: multiplier,
//                     months: 3,
//                     currency:  CurrencyType.USD,
//                     currency_symbol: getCurrencySymbol(currency)
//                 })
//             } else if (count === 2){
//                 //ex. "$102 USD for 6 Months" {$120 - 15% off (= $102)}
//                 const multiplier = 6;
//                 const totalPrice = (monthlyPrice * multiplier);
//                 // const discount = totalPrice * (renewalDiscountPercentage * 2); // code is changed by adding 5 percent in 6 months
//                 const renewalDiscountPercentage = (membershipItemDetails.first_benefit.renewal_discount + 5) / 100;
//                 const discount = totalPrice * renewalDiscountPercentage;
//                 const discountTotalPrice = parseFloat(totalPrice - discount).toFixed(2);
//                 const label = `6 months ${isRenewMembership ? '@': 'at'} ` + getCurrencySymbol(currency) + discountTotalPrice + ' each';
//                 renewalListSelection.push({
//                     label: label,
//                     value: discountTotalPrice,
//                     multiplier: multiplier,
//                     months: 6,
//                     currency:  CurrencyType.USD,
//                     currency_symbol: getCurrencySymbol(currency)
//                 })
//                 // console.log('price: '+monthlyPrice * multiplier);
//             } else if (count === 3){
//                 //ex. "$180 USD for 12 Months" {$240 - 25% off (= $180)}
//                 const multiplier = 12;
//                 const totalPrice = (monthlyPrice * multiplier);
//                 // const discount = totalPrice * (renewalDiscountPercentage * 3); // code is changed by adding 10 percent in 12 months
//                 const renewalDiscountPercentage = (membershipItemDetails.first_benefit.renewal_discount + 15) / 100;
//                 const discount = totalPrice * renewalDiscountPercentage;
//                 const discountTotalPrice = parseFloat(totalPrice - discount).toFixed(2);
//
//                 // const label = isRenewMembership ? '12 months @ ' + getCurrencySymbol(currency) + discountTotalPrice + ' each' :
//                 //     getCurrencySymbol(currency) + discountTotalPrice.toFixed(2) + ' ' + currency + ' ' + Recurring.twelveMonths
//                 const label = `12 months ${isRenewMembership ? '@': 'at'} ` + getCurrencySymbol(currency) + discountTotalPrice + ' each';
//
//                 renewalListSelection.push({
//                     label: label,
//                     value: discountTotalPrice,
//                     multiplier: multiplier,
//                     months: 12,
//                     currency:  CurrencyType.USD,
//                     currency_symbol: getCurrencySymbol(currency)
//                 })
//                 // console.log('price: '+monthlyPrice * multiplier);
//             } else  {
//                 const discountTotalPrice = parseFloat(monthlyPrice).toFixed(2);
//                 // const label = isRenewMembership ? '1 month @ ' + getCurrencySymbol(currency) + discountTotalPrice + ' each' :
//                 //     getCurrencySymbol(currency) + discountTotalPrice + ' ' + currency + ' ' + Recurring.oneMonth
//
//                 const label = `1 month ${isRenewMembership ? '@': 'at'} ` + getCurrencySymbol(currency) + discountTotalPrice + ' each';
//                 renewalListSelection.push({
//                     label: label,
//                     value: discountTotalPrice,
//                     disabled: isIncludeRenewOff ? monthlyPrice < 3 : false, //disabled 1 month if monthly price is lesser than 3 dollars
//                     multiplier: 1,
//                     months: 1,
//                     currency:  CurrencyType.USD,
//                     currency_symbol: getCurrencySymbol(currency)
//                 })
//             }
//         }
//         return renewalListSelection;
//     }
//     return [];
// }

/**
 * Returns parse Array Object of monthly selection included with the discount pricing
 * for the recurring Monthly Dropdown
 * @param {object} membershipItemDetails
 * @param recurringType
 * @returns {[{}]}
 */
export const getRecurringPrice = (membershipItemDetails, recurringType) => {
    if (membershipItemDetails.enable_expiration_and_subscription && membershipItemDetails.first_benefit) {
        const currency = membershipItemDetails.first_benefit.currency;
        const monthlyPrice = parseFloat(membershipItemDetails.first_benefit.monthly_price).toFixed(2);
        if (recurringType === RenewalOptions.monthly) {
            return {
                label: getCurrencySymbol(currency) + parseFloat(membershipItemDetails.first_benefit.monthly_price).toFixed(2),
                discounted_price: parseFloat(membershipItemDetails.first_benefit.monthly_price).toFixed(2)
            };
        } else if (recurringType === RenewalOptions.quarterly) {
            return {
                label: getCurrencySymbol(currency) + parseFloat(membershipItemDetails.first_benefit.three_month_renewal).toFixed(2),
                discounted_price: parseFloat(membershipItemDetails.first_benefit.three_month_renewal).toFixed(2)
            };
        } else if (recurringType === RenewalOptions.biannually) {
            return {
                label: getCurrencySymbol(currency) + parseFloat(membershipItemDetails.first_benefit.six_month_renewal).toFixed(2),
                discounted_price: parseFloat(membershipItemDetails.first_benefit.six_month_renewal).toFixed(2)
            };        } else if (recurringType === RenewalOptions.annually) {
            return {
                label: getCurrencySymbol(currency) + parseFloat(membershipItemDetails.first_benefit.twelve_month_renewal).toFixed(2),
                discounted_price: parseFloat(membershipItemDetails.first_benefit.twelve_month_renewal).toFixed(2)
            };        } else {
            // Else None (Not renewable )
            return ''
        }
    }
    return '';
}
// Old Code
// export const getRecurringPrice = (membershipItemDetails, recurringType) => {
//     if (membershipItemDetails.enable_expiration_and_subscription && membershipItemDetails.first_benefit) {
//         const currency = membershipItemDetails.first_benefit.currency;
//         const monthlyPrice = parseFloat(membershipItemDetails.first_benefit.monthly_price).toFixed(2);
//         if (recurringType === RenewalOptions.monthly) {
//             return {
//                 label: getCurrencySymbol(currency) + parseFloat(monthlyPrice).toFixed(2),
//                 discounted_price: parseFloat(monthlyPrice).toFixed(2)
//             };
//         } else if (recurringType === RenewalOptions.quarterly) {
//             const multiplier = 3;
//             const totalPrice = (monthlyPrice * multiplier);
//             const renewalDiscountPercentage = membershipItemDetails.first_benefit.renewal_discount / 100;
//             const discount = totalPrice * renewalDiscountPercentage;
//             return {
//                 label: getCurrencySymbol(currency) + parseFloat(totalPrice - discount).toFixed(2),
//                 discounted_price: parseFloat(totalPrice - discount).toFixed(2)
//             };
//         } else if (recurringType === RenewalOptions.biannually) {
//             const multiplier = 6;
//             const totalPrice = (monthlyPrice * multiplier);
//             const renewalDiscountPercentage = (membershipItemDetails.first_benefit.renewal_discount + 5) / 100;
//             const discount = totalPrice * renewalDiscountPercentage;
//             return {
//                 label: getCurrencySymbol(currency) + parseFloat(totalPrice - discount).toFixed(2),
//                 discounted_price: parseFloat(totalPrice - discount).toFixed(2)
//             };        } else if (recurringType === RenewalOptions.annually) {
//             const multiplier = 12;
//             const totalPrice = (monthlyPrice * multiplier);
//             const renewalDiscountPercentage = (membershipItemDetails.first_benefit.renewal_discount + 15) / 100;
//             const discount = totalPrice * renewalDiscountPercentage;
//             return {
//                 label: getCurrencySymbol(currency) + parseFloat(totalPrice - discount).toFixed(2),
//                 discounted_price: parseFloat(totalPrice - discount).toFixed(2)
//             };        } else {
//             // Else None (Not renewable )
//             return ''
//         }
//     }
//     return '';
// }

/**
 * Returns the selected months count
 * @param {object} selectedMonthlyPrice
 * @param {Array.<Object>} monthlyPrices
 */
export const getSelectedMonth = (selectedMonthlyPrice, monthlyPrices) => {
    if (monthlyPrices.length === 0) return 0;
    let months = 0;
    monthlyPrices.map((monthly)=> {
        if (monthly.value === selectedMonthlyPrice) {
            months = monthly.months;
        }
    });
    return months;
}

/**
 * Returns the parse monthly recurring with expiration text
 * @param {object} selectedMonthlyPrice
 * @param {Array.<Object>} autoMonthlyPrice
 * @param {string} currency
 * @param {string} expiration
 * @param {string} includedMonths
 * @returns {string}
 */
export const getExpirationMonthlyStart = (selectedMonthlyPrice, autoMonthlyPrice= [], currency, expiration, includedMonths) => {
    if (selectedMonthlyPrice === RenewalOptions.renewal_none) {
        return 'No auto-renewal, expiring '+ expiration;
    } else  {
        let expirationMonthlyStart = null;
        autoMonthlyPrice.map((monthly)=> {
            if (monthly.value === selectedMonthlyPrice) {
                let monthlyValue = parseFloat(monthly.value).toFixed(2);

                const date = moment(new Date());
                // console.log('monthly.multiplier: '+monthly.multiplier)

                const computedExpirationDate = add(new Date(date.add(1, 'days')), {months: includedMonths});
                // const computedExpirationDate = add(new Date(date.add(1, 'days')), {months: monthly.multiplier + includedMonths});
                // const computedExpirationDate = add(new Date(), {months: monthly.multiplier + includedMonths});
                // console.log('computedExpirationDate: '+computedExpirationDate)
                const formattedDate = format(computedExpirationDate, 'MMMM d, yyyy')
                // console.log('formattedDate: '+formattedDate)
                if (monthly.multiplier === 1) {
                    return expirationMonthlyStart = '$'+ monthlyValue +' '+ currency + ' every 1 Month recurring renewal starting '+ (formattedDate) +' until canceled.'
                } else if (monthly.multiplier === 3) {
                    return expirationMonthlyStart = '$'+ monthlyValue +' '+ currency + ' every 3 Months recurring renewal starting '+ (formattedDate) +' until canceled.'
                } else if (monthly.multiplier === 6) {
                    return expirationMonthlyStart = '$'+ monthlyValue +' '+ currency + ' every 6 Months recurring renewal starting '+ (formattedDate) +' until canceled.'
                } else if (monthly.multiplier === 12) {
                    return expirationMonthlyStart = '$'+ monthlyValue +' '+ currency + ' every 12 Months  recurring renewal starting '+ (formattedDate) +' until canceled'
                }
            }
        });
        return expirationMonthlyStart;
    }
}

/**
 * Returns the button title text in community
 * @param user
 * @param {object} membershipItem
 * @param {boolean} isViewDetails
 * @param {boolean} isJoined
 * @param {string} status
 * @param isOneWord
 * @returns {string}
 */
export const getCommunityBtnText = (user, membershipItem, isViewDetails, isJoined = false, status = '', isOneWord = false) => {
    if (!membershipItem) {
        return 'Buy';
    }

    // let isOwner;
    // if (user)
    //     if (membershipItem.creator === user.id)
    //         isOwner = true;
    //     else
    //         isOwner = false;
    // else
    //     isOwner = false;

    const price = parseFloat(membershipItem.price).toFixed(2);

    let currency = membershipItem.currency != null ? 'USD' : membershipItem.currency;
    if (membershipItem.benefits != null && membershipItem.benefits.length > 0) {
        currency = membershipItem.benefits[0].currency != null ? 'USD' : membershipItem.benefits[0].currency;
    } else if (membershipItem.first_benefit != null){
        currency = membershipItem.first_benefit.currency != null ? 'USD' : membershipItem.first_benefit.currency;
    }

    if (membershipItem.type === MEMBERSHIP_TYPES.NFT_COLLECTION) {
        if (status === 'expired') return 'Expired';
        if (status === 'active') return 'Claimed';
        if (status === 'inactive') return 'Activate';
        if (membershipItem.collection_type === 'membership')
            return isOneWord ? 'Claim' : 'Claim Membership';
        return isOneWord ? 'Claim' : 'Claim Item';
    } else {
        if (status === 'expired') return 'Expired';
        if (status === 'active') return 'Joined';
        if (status === 'inactive') return 'Activate';
        if (membershipItem.collection_type === 'membership') {
            if ((price === null || Number(price) === 0) && !membershipItem.enable_expiration_and_subscription) {
                return isOneWord ? 'Join' : 'Join for Free';
            } else if ((price === null || Number(price) === 0) && membershipItem.enable_expiration_and_subscription) {
                return isOneWord ? 'Join' : 'Join for Free';
            } else if ((price !== null && Number(price) > 0) && !membershipItem.enable_expiration_and_subscription) {
                return isOneWord ? 'Buy' : 'Buy for '+ getCurrencySymbol(currency) + price + ' '  + membershipItem.currency;
                // return 'Join for '+ getCurrencySymbol(currency) + price + ' '  + membershipItem.currency;
            } else  if ((price !== null && Number(price) > 0) && membershipItem.enable_expiration_and_subscription) {
                return isOneWord ? 'Buy' : 'Buy for '+ getCurrencySymbol(currency) + price + ' '  + membershipItem.currency;
                // return 'Join for '+ getCurrencySymbol(currency) + price + ' '  + membershipItem.currency;
            }
        } else {
            if ((price === null || Number(price) === 0) && !membershipItem.enable_expiration_and_subscription) {
                return isOneWord ? 'Get' : 'Get for Free';
            } else if ((price === null || Number(price) === 0) && membershipItem.enable_expiration_and_subscription) {
                return isOneWord ? 'Get' : 'Get for Free';
            } else if ((price !== null && Number(price) > 0) && !membershipItem.enable_expiration_and_subscription) {
                return isOneWord ? 'Buy' : 'Buy for '+ getCurrencySymbol(currency) + price + ' '  + membershipItem.currency;
            } else if ((price !== null && Number(price) > 0) && membershipItem.enable_expiration_and_subscription) {
                return isOneWord ? 'Buy' : 'Buy for '+ getCurrencySymbol(currency) + price + ' '  + membershipItem.currency;
            }
        }
    }
    return 'Join';
}

/**
 * Returns the Details layout price
 * @param {object} membershipItem
 * @returns {string}
 */
export const getDetailsPrice = (membershipItem) => {
    const price = parseFloat(membershipItem.price).toFixed(2);
    const isSubscription = membershipItem.enable_expiration_and_subscription;
    let currency = membershipItem.currency != null ? 'USD' : membershipItem.currency;

    if (membershipItem.type === MEMBERSHIP_TYPES.NFT_COLLECTION) {
        return 'Free with NFT';
    } else {
        if ((price === null || price === 0) && isSubscription) {
            return 'Free';
        } else if ((price !== null && price > 0)) {
            return getCurrencySymbol(currency) + price + ' '  + membershipItem.currency;
        }
    }
    return 'Free';
}

/**
 * Returns the Details layout monthly price (Details page line 2)
 * @param {object} membershipItem
 * @param {boolean} isMembership
 * @returns {string}
 */
export const getDetailsMonthlyPrice = (isMembership, membershipItem) => {
    const isSubscription = membershipItem.enable_expiration_and_subscription;
    let currency = membershipItem.currency != null ? 'USD' : membershipItem.currency;
    if (membershipItem.first_benefit != null) {
        currency = membershipItem.first_benefit.currency != null ? 'USD' : membershipItem.first_benefit.currency;
    }

    if (membershipItem.type === MEMBERSHIP_TYPES.NFT_COLLECTION) {
        if (isSubscription && membershipItem.first_benefit != null) {
            const price = parseFloat(membershipItem.first_benefit.monthly_price).toFixed(2);
            return getCurrencySymbol(currency) + price+' '+membershipItem.first_benefit.currency+'/Month Renewal';
        }
        return 'Claim using wallet';
    } else {
        if (isMembership) {
            if (isSubscription && membershipItem.first_benefit != null) {
                const price = parseFloat(membershipItem.first_benefit.monthly_price).toFixed(2);
                return getCurrencySymbol(currency) + price+' '+membershipItem.first_benefit.currency+'/Month Renewal';
            } else if (!isSubscription && membershipItem.price === 0) {
                //  if price = Free and no subscription (Community Membership)
                return '';
            } else if (!isSubscription && membershipItem.price > 0) {
                //  if price > 0 and no subscription(Community Membership)
                return '';
            } else {
                return '';
            }
        }
    }
    return '';
}


/***
 * Returns the price for the membeship or item in the manage dashboard
 * @param isMembership
 * @param membershipItem
 * @returns {string}
 */
export const getManageDetailsPrice = (isMembership, membershipItem) => {
    const isSubscription = membershipItem.enable_expiration_and_subscription;
    let price = parseFloat(membershipItem.price).toFixed(2);

    let currency = membershipItem.currency != null ? 'USD' : membershipItem.currency;
    let renewalDiscountPercentage = 0;

    const multiplier = 12;
    let totalPrice = 0;
    let discount = 0;
    let discountTotalPrice = 0;

    if (membershipItem.first_benefit != null) {
        currency = membershipItem.first_benefit.currency != null ? 'USD' : membershipItem.first_benefit.currency;
        renewalDiscountPercentage = membershipItem.first_benefit.renewal_discount / 100;
        const monthlyPrice = parseFloat(membershipItem.first_benefit.monthly_price).toFixed(2);
        totalPrice = (monthlyPrice * multiplier);
        discount = totalPrice * (renewalDiscountPercentage * 3);
        discountTotalPrice = parseFloat((totalPrice - discount).toFixed(2));
    }

    if (membershipItem.type === MEMBERSHIP_TYPES.NFT_COLLECTION) {
        if (isSubscription && membershipItem.first_benefit != null) {
            return getCurrencySymbol(currency) + discountTotalPrice+' '+membershipItem.first_benefit.currency+'/month';
        }
        return 'Free with NFT';
    } else {
        if ((price !== null && price > 0 && !isSubscription)) {
            return getCurrencySymbol(currency) + price + ' '  + membershipItem.currency;
        } else if (isSubscription && membershipItem.first_benefit != null) {
            return getCurrencySymbol(currency) + discountTotalPrice+' '+membershipItem.first_benefit.currency+'/month';
        } else if (!isSubscription && membershipItem.price === 0) {
            //  if price = Free and no subscription (Community Membership)
            return 'Free';
        }else {
            return 'Free';
        }
    }
}

/**
 * Returns the Details gift information
 * @param {boolean} isMembership
 * @param {object} membershipItem
 * @returns {string}
 */
export const getDetailsGift = (isMembership, membershipItem) => {
    if (membershipItem.type === MEMBERSHIP_TYPES.NFT_COLLECTION) {
        return `NFT ${isMembership ? 'Membership' : 'Item'}`;
    } else {
        return `Community ${isMembership ? 'Membership' : 'Item'}`;
    }
}

//force monthly price to accept only 2 decimal places.
export const setBenefitMonthlyPrice =  (e, formField, fieldName) => {
    let value = e.target.value;
    return formField.setFieldValue(fieldName,value.match(CUSTOM_REGEX.TWO_DECIMAL_PLACES)[0]);
}


/**
 * Returns the left image of the Payment Checkout.
 * @param {string} collectionAssetType
 * @param {Object} itemMemberDetails
 * @returns {JSX.Element}
 */
export const membershipItemImage = (collectionAssetType, itemMemberDetails) => {
    return (
        <>
            {collectionAssetType && collectionAssetType === 'image' && (
                <img
                    src={itemMemberDetails?.photo}
                    alt="space-image"
                    className="xsm:left-image-radius lg:left-image"
                />
            )}
            {collectionAssetType && collectionAssetType === 'video' && (
                // <ReactPlayer
                //     playing={true}
                //     loop={true}
                //     muted={true}
                //     className="xsm:left-image-radius lg:left-image videoPreview"
                //     controls={false}
                //     url={itemMemberDetails.video}
                // />
                <video
                    autoPlay
                    muted
                    loop
                    className="xsm:left-image-radius lg:left-image"
                >
                    <source
                        src={itemMemberDetails.video}
                        type="video/mp4"
                    />
                </video>
            )}
        </>
    );
}

/***
 * getCheckoutType function will return the checkout type strings:
 * @param itemMembershipObj
 * @returns {string}(No Charge (Free), One-time charge (One-time payment), upfront charge (Subscription))
 */
export const getCheckoutTypeText = (itemMembershipObj = {}) => {
    if (itemMembershipObj) {
        let price = itemMembershipObj.price ?? 0;
        let isSubscription = itemMembershipObj.enable_expiration_and_subscription;
        // Check if the price is a string
        if (typeof price === 'string') {
            // Convert the string to a float
            price = parseFloat(price);
        }
        if (isSubscription) {
            return 'upfront charge'
        }
        if (price === 0) {
            return 'No charge'
        } else {
            return 'One-time charge'
        }
    } else {
        // returns default empty string
        return '';
    }
}