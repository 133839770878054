import React, { memo, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getSpaceInformation } from '../../../services/space';
import parse from 'html-react-parser';
import SpaceContact from '../CommonLayouts/SpaceContact/SpaceContact';
import { Button } from 'antd';
import routes from '../../../routes';
import { useLocation } from 'react-router-dom';
import SocialLinks from "../../../components/commons/SocialLinks";
import ReactPlayer from "react-player/lazy";
import {getSitewideLink} from "../../../utils/sitewideLinks";
import {ROOT_DOMAIN} from "../../../constants/common";
import {useTranslation} from "react-i18next";

const SpaceInformationPages = (props) => {
	const { hash } = useLocation();
	const { t, i18n } = useTranslation();
	const { spaceData, isPro, activeTab } = props;
	const { authUserInfo, loading } = useSelector((state) => state.general);
	const { isPublicUrl } = useSelector((state) => state.spaceViewMode);
	// const { spaceInfo, spaceInfoLoading } = useSelector((state) => state.space);
	const [spaceInformation, setSpaceInformation] = useState();
	const [spaceFAQ, setSpaceFAQ] = useState();
	const [isInformationLoading, setInformationLoading] = useState(true);
	const [showFAQ, setShowFAQ] = useState(true);
	const pro_styles = spaceData.pro_style;
	const [theme, setTheme] = useState('');
	const [backgroundVideo, setBackgroundVideo] = useState();
	const [backgroundImage, setBackgroundImage] = useState();

	const fetchSpaceInformation = useCallback(
		async (activeTab) => {
			try {
				if (spaceData.id) {
					let page_title = 'About';
					if (activeTab === 'support') {
						page_title = 'Get Support';
					} else if (activeTab === 'faq') {
						page_title = 'Frequently Asked Questions';
					} else if (activeTab === 'terms-services') {
						page_title = 'Terms of Service';
					} else if (activeTab === 'privacy-policy') {
						page_title = 'Privacy Policy';
					} else if (activeTab === 'community-guidelines') {
						page_title = 'Community Guidelines';
					}

					const slug = (page_title + spaceData.id).toLowerCase().replace(/\s/g, '');
					const data = {
						space_id: spaceData.id,
						page_slug: slug
					};

					const response = await getSpaceInformation(data);
					if (response && response.result) {
						if (response.space_information) {
							setSpaceInformation(response.space_information);
							if (activeTab === 'support' || activeTab === 'faq') {
								await fetchFAQ();
							}
						}
					}
				}
			} catch (error) {
				console.log(error);
			}
		},
		[authUserInfo]
	);

	const redirectToPages = (url) => {
		if (url.includes('http')) {
			window.location.href = url;
		} else {
			if (ROOT_DOMAIN !== window.location.hostname) {
				window.location.href = url;
			} else {
				if (spaceData && spaceData.domain_name && !spaceData.public_url_string) {
					const isLocal = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
					const protocol = isLocal ? 'http://' : 'https://';
					window.location.href = `${protocol}${spaceData.domain_name}${url}`;
				} else {
					window.location.href = routes.viewUserSpace() + (isPublicUrl ? spaceData?.public_url_string : spaceData?.username) + url;
				}
			}
		}
	};

	const redirectToSection = (anchor) => {
		setTimeout(function () {
			const element = document.getElementById(anchor.replace('#', ''));
			element?.scrollIntoView({ behavior: 'smooth' });
		}, 800);
	};

	const fetchFAQ = useCallback(async () => {
		try {
			if (spaceData.id) {
				const slug = ('Frequently Asked Questions' + spaceData.id)
					.toLowerCase()
					.replace(/\s/g, '');
				const data = {
					space_id: spaceData.id,
					page_slug: slug
				};

				const response = await getSpaceInformation(data);
				if (response && response.result) {
					if (response.space_information) {
						setSpaceFAQ(response.space_information);
					}
				}
			}
		} catch (error) {
			console.log(error);
		}
	}, [authUserInfo]);

	useEffect(() => {
		if (activeTab) {
			fetchSpaceInformation(activeTab).then(r =>{} );
		}
	}, [ activeTab]);

	useEffect(() => {
		redirectToSection(hash);
	}, [hash]);

	useEffect(() => {
		redirectToSection(hash);
	}, [isInformationLoading, spaceInformation, spaceFAQ]);

	useEffect(() => {
		if (spaceData)
			if (spaceData.pro_style) {
				setTheme(spaceData.pro_style.information_theme === 'main' ? 'theme-transparent' : 'theme-alt-transparent');
				setBackgroundVideo(spaceData.pro_style.information_theme === 'main' ? spaceData.pro_style.light_background_video : spaceData.pro_style.dark_background_video)
				setBackgroundImage(spaceData.pro_style.information_theme === 'main' ? spaceData.pro_style.light_background_image : spaceData.pro_style.dark_background_image)
			}
	}, [spaceData]);

	useEffect(() => {

	}, [isPublicUrl]);

	return (
		<>
			<section id="cs-space-terms-c1" className={`${theme} ${backgroundVideo ? '!p-0 relative ' : 'p-section-safeview '}`}
					 style={ {backgroundImage: 'url(' + (backgroundVideo ? '' : backgroundImage) + ')'} }
			>
				{backgroundVideo &&
					<ReactPlayer
						playing={true}
						loop={true}
						muted={true}
						controls={false}
						width="100%"
						height="100%"
						className={`videoPreview absolute`}
						url={backgroundVideo}
					/>
				}
				<div className={`centerframe-grid gap-2gutter ${backgroundVideo ? 'relative p-section-safeview' : ''}`}>
					{activeTab === 'terms-services' && (
						<>
							<article className="cs-terms-article block-full order-1">
								<div className="cs-stack-title-icons stack-row-wrap">
									<h2><span>Terms</span> of Service</h2>
									{(spaceData && spaceData?.social_links) && (
										<SocialLinks
											socialLinks={spaceData.social_links}
											parentClassName="cs-stack-link-icons stack-row"
											childClassName="button-icon-round-gradient"
										/>
									)}
								</div>

								{spaceInformation && spaceInformation.page_content && (
									<div className="cs-terms-content-body card stack-col">
										<p>{parse(spaceInformation.page_content)}</p>
									</div>
								)}

							</article>
							<article className="cs-terms-article block-full order-2">
								<p>
									This site is operated by Commonspace Platforms, Inc. By using this site, all users agree to additional terms of use specified at {getSitewideLink('terms_of_service_sign_up', "")}, {getSitewideLink('privacy_policy_sign_up', "")}, and  {getSitewideLink('community_guideline_sign_up', "")}.
								</p>
							</article>
							<div id="cs-space-support-links" className="grid-base col-span-full order-3">
								<article
									className="cs-featured-support block-md card"
									onClick={(event) => redirectToPages('/faq')}
								>
									<a className="rounded-card p-card">
										<hgroup>
											<p><i className="fa-solid fa-comments-question-check"></i></p>
											<h4>{t('card_faq')}</h4>
										</hgroup>
									</a>
								</article>
								<article
									className="cs-featured-support block-md card"
									onClick={(event) => redirectToPages('/contact')}
								>
									<a className="rounded-card p-card">
										<hgroup>
											<p><i className="fa-solid fa-envelope"></i></p>
											<h4>{t('card_contact')}</h4>
										</hgroup>
									</a>
								</article>
								<article
									className="cs-featured-support block-md card"
									onClick={(event) => redirectToPages('/support')}
								>
									<a className="rounded-card p-card">
										<hgroup>
											<p><i className="fa-solid fa-life-ring"></i></p>
											<h4>{t('card_support')}</h4>
										</hgroup>
									</a>
								</article>
							</div>
						</>
					)}

					{activeTab === 'privacy-policy' && (
						<>
							<article className="cs-privacy-article block-full order-1">
								<div className="cs-stack-title-icons stack-row-wrap">
									<h2>
										<span>Privacy</span> Policy
									</h2>
									{(spaceData && spaceData?.social_links) && (
										<SocialLinks
											socialLinks={spaceData.social_links}
											parentClassName="cs-stack-link-icons stack-row"
											childClassName="button-icon-round-gradient"
										/>
									)}
								</div>

								{spaceInformation && spaceInformation.page_content && (
									<div className="cs-privacy-content-body card stack-col">
										<p>{parse(spaceInformation.page_content)}</p>
									</div>
								)}
							</article>
							<div
								id="cs-space-support-links"
								className="grid-base col-span-full order-2"
							>
								<article
									className="cs-featured-support block-md card"
									onClick={(event) => redirectToPages('/faq')}
								>
									<a className="rounded-card p-card">
										<hgroup>
											<p>
												<i className="fa-solid fa-comments-question-check"></i>
											</p>
											<h4>{t('card_faq')}</h4>
										</hgroup>
									</a>
								</article>
								<article
									className="cs-featured-support block-md card"
									onClick={(event) => redirectToPages('/contact')}
								>
									<a className="rounded-card p-card">
										<hgroup>
											<p>
												<i className="fa-solid fa-envelope"></i>
											</p>
											<h4>{t('card_contact')}</h4>
										</hgroup>
									</a>
								</article>
								<article
									className="cs-featured-support block-md card"
									onClick={(event) => redirectToPages('/support')}
								>
									<a className="rounded-card p-card">
										<hgroup>
											<p>
												<i className="fa-solid fa-life-ring"></i>
											</p>
											<h4>{t('card_support')}</h4>
										</hgroup>
									</a>
								</article>
							</div>
						</>
					)}
					{activeTab === 'faq' && (
						<>
							<article className="cs-faq-article block-full order-1">
								<div className="cs-stack-title-icons stack-row-wrap">
									<h2>
										<span>{spaceData.name}</span> FAQ
									</h2>
									{(spaceData && spaceData?.social_links) && (
										<SocialLinks
											socialLinks={spaceData.social_links}
											parentClassName="cs-stack-link-icons stack-row"
											childClassName="button-icon-round-gradient"
										/>
									)}
								</div>

								{spaceFAQ && spaceFAQ.page_content && (
									<div className="cs-faq-content-body card stack-col">
										<p>{parse(spaceFAQ.page_content)}</p>
									</div>
								)}
							</article>
							<div
								id="cs-space-support-links"
								className="grid-base col-span-full order-2"
							>
								<article
									className="cs-featured-support block-md card"
									onClick={(event) => redirectToPages('/community-guidelines')}
								>
									<a className="rounded-card p-card">
										<hgroup>
											<p>
												<i className="fa-solid fa-users"></i>
											</p>
											<h4>{t('card_communityguidelines')}</h4>
										</hgroup>
									</a>
								</article>

								<article
									className="cs-featured-support block-md card"
									onClick={(event) => redirectToPages('/contact')}
								>
									<a className="rounded-card p-card">
										<hgroup>
											<p>
												<i className="fa-solid fa-envelope"></i>
											</p>
											<h4>{t('card_contact')}</h4>
										</hgroup>
									</a>
								</article>

								<article
									className="cs-featured-support block-md card"
									onClick={(event) => redirectToPages('/support')}
								>
									<a className="rounded-card p-card">
										<hgroup>
											<p>
												<i className="fa-solid fa-life-ring"></i>
											</p>
											<h4>{t('card_support')}</h4>
										</hgroup>
									</a>
								</article>
							</div>
						</>
					)}
					{activeTab === 'about' && (
						<>
							<article className="cs-about-article block-full order-1">
								<div className="cs-stack-title-icons stack-row-wrap">
									<h2><span>About</span>{' ' + spaceData.name}</h2>
									{(spaceData && spaceData?.social_links) && (
										<SocialLinks
											socialLinks={spaceData.social_links}
											parentClassName="cs-stack-link-icons stack-row"
											childClassName="button-icon-round-gradient"
										/>
									)}
								</div>
								{spaceInformation && spaceInformation.page_content && (
									<div className="cs-about-content-body card stack-col">
										<p>{parse(spaceInformation.page_content)}</p>
									</div>
								)}
							</article>
							<div id="cs-space-support-links" className="grid-base col-span-full order-2">
								<article
									className="cs-featured-support block-md card"
									onClick={(event) => redirectToPages('/faq')}
								>
									<a className="rounded-card p-card">
										<hgroup>
											<p><i className="fa-solid fa-comments-question-check"></i></p>
											<h4>{t('card_faq')}</h4>
										</hgroup>
									</a>
								</article>
								<article
									className="cs-featured-support block-md card"
									onClick={(event) => redirectToPages('/contact')}
								>
									<a className="rounded-card p-card">
										<hgroup>
											<p><i className="fa-solid fa-envelope"></i></p>
											<h4>{t('card_contact')}</h4>
										</hgroup>
									</a>
								</article>
								<article
									className="cs-featured-support block-md card"
									onClick={(event) => redirectToPages('/support')}
								>
									<a className="rounded-card p-card">
										<hgroup>
											<p><i className="fa-solid fa-life-ring"></i></p>
											<h4>{t('card_support')}</h4>
										</hgroup>
									</a>
								</article>
							</div>
						</>
					)}
					{activeTab === 'contact' && (
						<>
							<article className="cs-contact-form block-full order-1">
								<div className="cs-stack-title-icons stack-row-wrap">
									<h2><span>Contact</span>{' ' + spaceData.name}</h2>
									{(spaceData && spaceData?.social_links) && (
										<SocialLinks
											socialLinks={spaceData.social_links}
											parentClassName="cs-stack-link-icons stack-row"
											childClassName="button-icon-round-gradient"
										/>
									)}
								</div>

								<SpaceContact
									spaceData={spaceData}
								/>
								{/*<div className="cs-stack-contact-form stack-col">*/}
								{/*	<div className="stack-row">*/}
								{/*		<div className="form-input air">*/}
								{/*			<label htmlFor="name">Name</label>*/}
								{/*			<input type="text" name="name" id="name" autoComplete="name" required/>*/}
								{/*		</div>*/}
								{/*		<div className="form-input air">*/}
								{/*			<label htmlFor="email">Email</label>*/}
								{/*			<input type="email" name="email" id="email" autoComplete="email" required/>*/}
								{/*		</div>*/}
								{/*	</div>*/}
								{/*	<div className="form-input air">*/}
								{/*		<label htmlFor="subject">Subject</label>*/}
								{/*		<input type="text" name="subject" id="subject" autoComplete="subject"/>*/}
								{/*	</div>*/}
								{/*	<div className="form-textarea air">*/}
								{/*		<label htmlFor="message">Message</label>*/}
								{/*		<textarea id="message" name="message" rows="3"></textarea>*/}
								{/*	</div>*/}
								{/*</div>*/}
							</article>
							<div id="cs-space-support-links" className="grid-base col-span-full order-2">
								<article
									className="cs-featured-support block-md card"
									onClick={(event) => redirectToPages('/faq')}
								>
									<a className="rounded-card p-card">
										<hgroup>
											<p><i className="fa-solid fa-comments-question-check"></i></p>
											<h4>{t('card_faq')}</h4>
										</hgroup>
									</a>
								</article>
								<article
									className="cs-featured-support block-md card"
									onClick={(event) => redirectToPages('/community-guidelines')}
								>
									<a className="rounded-card p-card">
										<hgroup>
											<p><i className="fa-solid fa-users"></i></p>
											<h4>{t('card_communityguidelines')}</h4>
										</hgroup>
									</a>
								</article>
								<article
									className="cs-featured-support block-md card"
									onClick={(event) => redirectToPages('/support')}
								>
									<a className="rounded-card p-card">
										<hgroup>
											<p><i className="fa-solid fa-life-ring"></i></p>
											<h4>{t('card_support')}</h4>
										</hgroup>
									</a>
								</article>
							</div>
						</>
					)}
					{activeTab === 'community-guidelines' && (
						<>
							<article className="cs-support-article block-full order-1">
								<div className="cs-stack-title-icons stack-row-wrap">
									<h2><span>{spaceData?.name}</span> Community Guidelines</h2>
									{(spaceData && spaceData?.social_links) && (
										<SocialLinks
											socialLinks={spaceData.social_links}
											parentClassName="cs-stack-link-icons stack-row"
											childClassName="button-icon-round-gradient"
										/>
									)}
								</div>

								{spaceInformation && spaceInformation.page_content && (
									<div className="cs-terms-content-body card stack-col">
										<p>{parse(spaceInformation.page_content)}</p>
									</div>
								)}

							</article>
							<div id="cs-space-support-links" className="grid-base col-span-full order-2">
								<article
									className="cs-featured-support block-md card"
									onClick={(event) => redirectToPages('/faq')}
								>
									<a className="rounded-card p-card">
										<hgroup>
											<p><i className="fa-solid fa-comments-question-check"></i></p>
											<h4>{t('card_faq')}</h4>
										</hgroup>
									</a>
								</article>
								<article
									className="cs-featured-support block-md card"
									onClick={(event) => redirectToPages('/contact')}
								>
									<a className="rounded-card p-card">
										<hgroup>
											<p><i className="fa-solid fa-envelope"></i></p>
											<h4>{t('card_contact')}</h4>
										</hgroup>
									</a>
								</article>
								<article
									className="cs-featured-support block-md card"
									onClick={(event) => redirectToPages('/support')}
								>
									<a className="rounded-card p-card">
										<hgroup>
											<p><i className="fa-solid fa-life-ring"></i></p>
											<h4>{t('card_support')}</h4>
										</hgroup>
									</a>
								</article>
							</div>
						</>
					)}

					{activeTab === 'support' && (
						<>
							<article className="cs-support-article block-full order-1">
								<div className="cs-stack-title-icons stack-row-wrap">
									<h2><span>{spaceData.name}</span> {t('title_support')}
									</h2>
									{(spaceData && spaceData?.social_links) && (
										<SocialLinks
											socialLinks={spaceData.social_links}
											parentClassName="cs-stack-link-icons stack-row"
											childClassName="button-icon-round-gradient"
										/>
									)}
								</div>

								{spaceInformation && spaceInformation.page_content && (
									<div className="cs-support-content-body card stack-col">
										<p>{parse(spaceInformation.page_content)}</p>
									</div>
								)}

							</article>
							<div id="cs-space-support-links" className="grid-base col-span-full order-2">
								<article
									className="cs-featured-support block-md card"
									onClick={(event) => redirectToPages('/faq')}
								>
									<a className="rounded-card p-card">
										<hgroup>
											<p><i className="fa-solid fa-comments-question-check"></i></p>
											<h4>{t('card_faq')}</h4>
										</hgroup>
									</a>
								</article>
								<article
									className="cs-featured-support block-md card"
									onClick={(event) => redirectToPages('/contact')}
								>
									<a className="rounded-card p-card">
										<hgroup>
											<p><i className="fa-solid fa-envelope"></i></p>
											<h4>{t('card_contact')}</h4>
										</hgroup>
									</a>
								</article>
								<article
									className="cs-featured-support block-md card"
									onClick={(event) => redirectToPages('/community-guidelines')}
								>
									<a className="rounded-card p-card">
										<hgroup>
											<p><i className="fa-solid fa-users"></i></p>
											<h4>{t('card_communityguidelines')}</h4>
										</hgroup>
									</a>
								</article>
							</div>
						</>
					)}
				</div>
			</section>
			{/*{activeTab === 'terms-services' && (*/}
			{/*	<section id="cs-space-terms-c1" className="p-section-safeview theme-transparent">*/}
			{/*		<div className="centerframe-grid gap-2gutter">*/}
			{/*			<article className="cs-terms-article block-full order-1">*/}
			{/*				<div className="cs-stack-title-icons stack-row-wrap">*/}
			{/*					<h2><span>Terms</span> of Service</h2>*/}
			{/*					{(spaceData && spaceData?.social_links) && (*/}
			{/*						<SocialLinks*/}
			{/*							socialLinks={spaceData.social_links}*/}
			{/*							parentClassName="cs-stack-link-icons stack-row"*/}
			{/*							childClassName="button-icon-round-gradient"*/}
			{/*						/>*/}
			{/*					)}*/}
			{/*					/!*<div*!/*/}
			{/*					/!*	className="cs-stack-link-icons stack-row">*!/*/}
			{/*					/!*	<a href="#xtwitter" target="_blank"*!/*/}
			{/*					/!*	   className="button-icon-round-gradient"><span><p><i*!/*/}
			{/*					/!*		className="fa-brands fa-x-twitter"></i></p></span></a>*!/*/}
			{/*					/!*	<a href="#instagram" target="_blank"*!/*/}
			{/*					/!*	   className="button-icon-round-gradient"><span><p><i*!/*/}
			{/*					/!*		className="fa-brands fa-instagram"></i></p></span></a>*!/*/}
			{/*					/!*	<a href="#facebook" target="_blank"*!/*/}
			{/*					/!*	   className="button-icon-round-gradient"><span><p><i*!/*/}
			{/*					/!*		className="fa-brands fa-facebook-f"></i></p></span></a>*!/*/}
			{/*					/!*	<a href="#linkedin" target="_blank"*!/*/}
			{/*					/!*	   className="button-icon-round-gradient"><span><p><i*!/*/}
			{/*					/!*		className="fa-brands fa-linkedin-in"></i></p></span></a>*!/*/}
			{/*					/!*</div>*!/*/}
			{/*				</div>*/}

			{/*				{spaceInformation && spaceInformation.page_content && (*/}
			{/*					<div className="cs-terms-content-body card stack-col">*/}
			{/*						<p>{parse(spaceInformation.page_content)}</p>*/}
			{/*					</div>*/}
			{/*				)}*/}

			{/*			</article>*/}
			{/*			<div id="cs-space-support-links" className="grid-base col-span-full order-2">*/}
			{/*				<article*/}
			{/*					className="cs-featured-support block-md card"*/}
			{/*					onClick={(event) => redirectToPages('/faq')}*/}
			{/*				>*/}
			{/*					<a className="rounded-card p-card">*/}
			{/*						<hgroup>*/}
			{/*							<p><i className="fa-solid fa-comments-question-check"></i></p>*/}
			{/*							<h4>FAQ</h4>*/}
			{/*						</hgroup>*/}
			{/*					</a>*/}
			{/*				</article>*/}
			{/*				<article*/}
			{/*					className="cs-featured-support block-md card"*/}
			{/*					onClick={(event) => redirectToPages('/contact')}*/}
			{/*				>*/}
			{/*					<a className="rounded-card p-card">*/}
			{/*						<hgroup>*/}
			{/*							<p><i className="fa-solid fa-envelope"></i></p>*/}
			{/*							<h4>Contact</h4>*/}
			{/*						</hgroup>*/}
			{/*					</a>*/}
			{/*				</article>*/}
			{/*				<article*/}
			{/*					className="cs-featured-support block-md card"*/}
			{/*					onClick={(event) => redirectToPages('/support')}*/}
			{/*				>*/}
			{/*					<a className="rounded-card p-card">*/}
			{/*						<hgroup>*/}
			{/*							<p><i className="fa-solid fa-life-ring"></i></p>*/}
			{/*							<h4>Support</h4>*/}
			{/*						</hgroup>*/}
			{/*					</a>*/}
			{/*				</article>*/}
			{/*			</div>*/}
			{/*		</div>*/}
			{/*	</section>*/}
			{/*)}*/}

			{/*{activeTab === 'privacy-policy' && (*/}
			{/*	<section*/}
			{/*		id="cs-space-privacy-c1"*/}
			{/*		className="p-section-safeview theme-transparent"*/}
			{/*	>*/}
			{/*		<div className="centerframe-grid gap-2gutter">*/}
			{/*			<article className="cs-privacy-article block-full order-1">*/}
			{/*				<div className="cs-stack-title-icons stack-row-wrap">*/}
			{/*					<h2>*/}
			{/*						<span>Privacy</span> Policy*/}
			{/*					</h2>*/}
			{/*					{(spaceData && spaceData?.social_links) && (*/}
			{/*						<SocialLinks*/}
			{/*							socialLinks={spaceData.social_links}*/}
			{/*							parentClassName="cs-stack-link-icons stack-row"*/}
			{/*							childClassName="button-icon-round-gradient"*/}
			{/*						/>*/}
			{/*					)}*/}
			{/*					/!*<div className="cs-stack-link-icons stack-row">*!/*/}
			{/*					/!*	<a*!/*/}
			{/*					/!*		href="#xtwitter"*!/*/}
			{/*					/!*		target="_blank"*!/*/}
			{/*					/!*		className="button-icon-round-gradient"*!/*/}
			{/*					/!*	>*!/*/}
			{/*					/!*			<span>*!/*/}
			{/*					/!*				<p>*!/*/}
			{/*					/!*					<i className="fa-brands fa-x-twitter"></i>*!/*/}
			{/*					/!*				</p>*!/*/}
			{/*					/!*			</span>*!/*/}
			{/*					/!*	</a>*!/*/}
			{/*					/!*	<a*!/*/}
			{/*					/!*		href="#instagram"*!/*/}
			{/*					/!*		target="_blank"*!/*/}
			{/*					/!*		className="button-icon-round-gradient"*!/*/}
			{/*					/!*	>*!/*/}
			{/*					/!*			<span>*!/*/}
			{/*					/!*				<p>*!/*/}
			{/*					/!*					<i className="fa-brands fa-instagram"></i>*!/*/}
			{/*					/!*				</p>*!/*/}
			{/*					/!*			</span>*!/*/}
			{/*					/!*	</a>*!/*/}
			{/*					/!*	<a*!/*/}
			{/*					/!*		href="#facebook"*!/*/}
			{/*					/!*		target="_blank"*!/*/}
			{/*					/!*		className="button-icon-round-gradient"*!/*/}
			{/*					/!*	>*!/*/}
			{/*					/!*			<span>*!/*/}
			{/*					/!*				<p>*!/*/}
			{/*					/!*					<i className="fa-brands fa-facebook-f"></i>*!/*/}
			{/*					/!*				</p>*!/*/}
			{/*					/!*			</span>*!/*/}
			{/*					/!*	</a>*!/*/}
			{/*					/!*	<a*!/*/}
			{/*					/!*		href="#linkedin"*!/*/}
			{/*					/!*		target="_blank"*!/*/}
			{/*					/!*		className="button-icon-round-gradient"*!/*/}
			{/*					/!*	>*!/*/}
			{/*					/!*			<span>*!/*/}
			{/*					/!*				<p>*!/*/}
			{/*					/!*					<i className="fa-brands fa-linkedin-in"></i>*!/*/}
			{/*					/!*				</p>*!/*/}
			{/*					/!*			</span>*!/*/}
			{/*					/!*	</a>*!/*/}
			{/*					/!*</div>*!/*/}
			{/*				</div>*/}

			{/*				{spaceInformation && spaceInformation.page_content && (*/}
			{/*					<div className="cs-privacy-content-body card stack-col">*/}
			{/*						<p>{parse(spaceInformation.page_content)}</p>*/}
			{/*					</div>*/}
			{/*				)}*/}
			{/*			</article>*/}
			{/*			<div*/}
			{/*				id="cs-space-support-links"*/}
			{/*				className="grid-base col-span-full order-2"*/}
			{/*			>*/}
			{/*				<article*/}
			{/*					className="cs-featured-support block-md card"*/}
			{/*					onClick={(event) => redirectToPages('/faq')}*/}
			{/*				>*/}
			{/*					<a className="rounded-card p-card">*/}
			{/*						<hgroup>*/}
			{/*							<p>*/}
			{/*								<i className="fa-solid fa-comments-question-check"></i>*/}
			{/*							</p>*/}
			{/*							<h4>FAQ</h4>*/}
			{/*						</hgroup>*/}
			{/*					</a>*/}
			{/*				</article>*/}
			{/*				<article*/}
			{/*					className="cs-featured-support block-md card"*/}
			{/*					onClick={(event) => redirectToPages('/contact')}*/}
			{/*				>*/}
			{/*					<a className="rounded-card p-card">*/}
			{/*						<hgroup>*/}
			{/*							<p>*/}
			{/*								<i className="fa-solid fa-envelope"></i>*/}
			{/*							</p>*/}
			{/*							<h4>Contact</h4>*/}
			{/*						</hgroup>*/}
			{/*					</a>*/}
			{/*				</article>*/}
			{/*				<article*/}
			{/*					className="cs-featured-support block-md card"*/}
			{/*					onClick={(event) => redirectToPages('/support')}*/}
			{/*				>*/}
			{/*					<a className="rounded-card p-card">*/}
			{/*						<hgroup>*/}
			{/*							<p>*/}
			{/*								<i className="fa-solid fa-life-ring"></i>*/}
			{/*							</p>*/}
			{/*							<h4>Support</h4>*/}
			{/*						</hgroup>*/}
			{/*					</a>*/}
			{/*				</article>*/}
			{/*			</div>*/}
			{/*		</div>*/}
			{/*	</section>*/}
			{/*)}*/}
			{/*{activeTab === 'faq' && (*/}
			{/*	<section id="cs-space-faq-c1" className="p-section-safeview theme-transparent">*/}
			{/*		<div className="centerframe-grid gap-2gutter">*/}
			{/*			<article className="cs-faq-article block-full order-1">*/}
			{/*				<div className="cs-stack-title-icons stack-row-wrap">*/}
			{/*					<h2>*/}
			{/*						<span>{spaceData.name}</span> FAQ*/}
			{/*					</h2>*/}
			{/*					{(spaceData && spaceData?.social_links) && (*/}
			{/*						<SocialLinks*/}
			{/*							socialLinks={spaceData.social_links}*/}
			{/*							parentClassName="cs-stack-link-icons stack-row"*/}
			{/*							childClassName="button-icon-round-gradient"*/}
			{/*						/>*/}
			{/*					)}*/}
			{/*					/!*<div className="cs-stack-link-icons stack-row">*!/*/}
			{/*					/!*	<a*!/*/}
			{/*					/!*		href="#xtwitter"*!/*/}
			{/*					/!*		target="_blank"*!/*/}
			{/*					/!*		className="button-icon-round-gradient"*!/*/}
			{/*					/!*	>*!/*/}
			{/*					/!*			<span>*!/*/}
			{/*					/!*				<p>*!/*/}
			{/*					/!*					<i className="fa-brands fa-x-twitter"></i>*!/*/}
			{/*					/!*				</p>*!/*/}
			{/*					/!*			</span>*!/*/}
			{/*					/!*	</a>*!/*/}
			{/*					/!*	<a*!/*/}
			{/*					/!*		href="#instagram"*!/*/}
			{/*					/!*		target="_blank"*!/*/}
			{/*					/!*		className="button-icon-round-gradient"*!/*/}
			{/*					/!*	>*!/*/}
			{/*					/!*			<span>*!/*/}
			{/*					/!*				<p>*!/*/}
			{/*					/!*					<i className="fa-brands fa-instagram"></i>*!/*/}
			{/*					/!*				</p>*!/*/}
			{/*					/!*			</span>*!/*/}
			{/*					/!*	</a>*!/*/}
			{/*					/!*	<a*!/*/}
			{/*					/!*		href="#facebook"*!/*/}
			{/*					/!*		target="_blank"*!/*/}
			{/*					/!*		className="button-icon-round-gradient"*!/*/}
			{/*					/!*	>*!/*/}
			{/*					/!*			<span>*!/*/}
			{/*					/!*				<p>*!/*/}
			{/*					/!*					<i className="fa-brands fa-facebook-f"></i>*!/*/}
			{/*					/!*				</p>*!/*/}
			{/*					/!*			</span>*!/*/}
			{/*					/!*	</a>*!/*/}
			{/*					/!*	<a*!/*/}
			{/*					/!*		href="#linkedin"*!/*/}
			{/*					/!*		target="_blank"*!/*/}
			{/*					/!*		className="button-icon-round-gradient"*!/*/}
			{/*					/!*	>*!/*/}
			{/*					/!*			<span>*!/*/}
			{/*					/!*				<p>*!/*/}
			{/*					/!*					<i className="fa-brands fa-linkedin-in"></i>*!/*/}
			{/*					/!*				</p>*!/*/}
			{/*					/!*			</span>*!/*/}
			{/*					/!*	</a>*!/*/}
			{/*					/!*</div>*!/*/}
			{/*				</div>*/}

			{/*				{spaceFAQ && spaceFAQ.page_content && (*/}
			{/*					<div className="cs-faq-content-body card stack-col">*/}
			{/*						<p>{parse(spaceFAQ.page_content)}</p>*/}
			{/*					</div>*/}
			{/*				)}*/}
			{/*			</article>*/}
			{/*			<div*/}
			{/*				id="cs-space-support-links"*/}
			{/*				className="grid-base col-span-full order-2"*/}
			{/*			>*/}
			{/*				<article*/}
			{/*					className="cs-featured-support block-md card"*/}
			{/*					onClick={(event) => redirectToPages('/community-guidelines')}*/}
			{/*				>*/}
			{/*					<a className="rounded-card p-card">*/}
			{/*						<hgroup>*/}
			{/*							<p>*/}
			{/*								<i className="fa-solid fa-users"></i>*/}
			{/*							</p>*/}
			{/*							<h4>Community Guidelines</h4>*/}
			{/*						</hgroup>*/}
			{/*					</a>*/}
			{/*				</article>*/}

			{/*				<article*/}
			{/*					className="cs-featured-support block-md card"*/}
			{/*					onClick={(event) => redirectToPages('/contact')}*/}
			{/*				>*/}
			{/*					<a className="rounded-card p-card">*/}
			{/*						<hgroup>*/}
			{/*							<p>*/}
			{/*								<i className="fa-solid fa-envelope"></i>*/}
			{/*							</p>*/}
			{/*							<h4>Contact</h4>*/}
			{/*						</hgroup>*/}
			{/*					</a>*/}
			{/*				</article>*/}

			{/*				<article*/}
			{/*					className="cs-featured-support block-md card"*/}
			{/*					onClick={(event) => redirectToPages('/support')}*/}
			{/*				>*/}
			{/*					<a className="rounded-card p-card">*/}
			{/*						<hgroup>*/}
			{/*							<p>*/}
			{/*								<i className="fa-solid fa-life-ring"></i>*/}
			{/*							</p>*/}
			{/*							<h4>Support</h4>*/}
			{/*						</hgroup>*/}
			{/*					</a>*/}
			{/*				</article>*/}
			{/*			</div>*/}
			{/*		</div>*/}
			{/*	</section>*/}
			{/*)}*/}
			{/*{activeTab === 'about' && (*/}
			{/*	<section id="cs-space-about-c1" className="p-section-safeview theme-transparent">*/}
			{/*		<div className="centerframe-grid gap-2gutter">*/}
			{/*			<article className="cs-about-article block-full order-1">*/}
			{/*				<div className="cs-stack-title-icons stack-row-wrap">*/}
			{/*					<h2><span>About</span>{' ' + spaceData.name}</h2>*/}
			{/*					{(spaceData && spaceData?.social_links) && (*/}
			{/*						<SocialLinks*/}
			{/*							socialLinks={spaceData.social_links}*/}
			{/*							parentClassName="cs-stack-link-icons stack-row"*/}
			{/*							childClassName="button-icon-round-gradient"*/}
			{/*						/>*/}
			{/*					)}*/}
			{/*					/!*<div className="cs-stack-link-icons stack-row">*!/*/}
			{/*					/!*	<a href="#xtwitter" target="_blank" className="button-icon-round-gradient"><span><p><i className="fa-brands fa-x-twitter"></i></p></span></a>*!/*/}
			{/*					/!*	<a href="#instagram" target="_blank" className="button-icon-round-gradient"><span><p><i className="fa-brands fa-instagram"></i></p></span></a>*!/*/}
			{/*					/!*	<a href="#facebook" target="_blank" className="button-icon-round-gradient"><span><p><i className="fa-brands fa-facebook-f"></i></p></span></a>*!/*/}
			{/*					/!*	<a href="#linkedin" target="_blank" className="button-icon-round-gradient"><span><p><i className="fa-brands fa-linkedin-in"></i></p></span></a>*!/*/}
			{/*					/!*</div>*!/*/}
			{/*				</div>*/}
			{/*				{spaceInformation && spaceInformation.page_content && (*/}
			{/*					<div className="cs-about-content-body card stack-col">*/}
			{/*						<p>{parse(spaceInformation.page_content)}</p>*/}
			{/*					</div>*/}
			{/*				)}*/}
			{/*			</article>*/}
			{/*			<div id="cs-space-support-links" className="grid-base col-span-full order-2">*/}
			{/*				<article*/}
			{/*					className="cs-featured-support block-md card"*/}
			{/*					onClick={(event) => redirectToPages('/faq')}*/}
			{/*				>*/}
			{/*					<a className="rounded-card p-card">*/}
			{/*						<hgroup>*/}
			{/*							<p><i className="fa-solid fa-comments-question-check"></i></p>*/}
			{/*							<h4>FAQ</h4>*/}
			{/*						</hgroup>*/}
			{/*					</a>*/}
			{/*				</article>*/}
			{/*				<article*/}
			{/*					className="cs-featured-support block-md card"*/}
			{/*					onClick={(event) => redirectToPages('/contact')}*/}
			{/*				>*/}
			{/*					<a className="rounded-card p-card">*/}
			{/*						<hgroup>*/}
			{/*							<p><i className="fa-solid fa-envelope"></i></p>*/}
			{/*							<h4>Contact</h4>*/}
			{/*						</hgroup>*/}
			{/*					</a>*/}
			{/*				</article>*/}
			{/*				<article*/}
			{/*					className="cs-featured-support block-md card"*/}
			{/*					onClick={(event) => redirectToPages('/support')}*/}
			{/*				>*/}
			{/*					<a className="rounded-card p-card">*/}
			{/*						<hgroup>*/}
			{/*							<p><i className="fa-solid fa-life-ring"></i></p>*/}
			{/*							<h4>Support</h4>*/}
			{/*						</hgroup>*/}
			{/*					</a>*/}
			{/*				</article>*/}
			{/*			</div>*/}
			{/*		</div>*/}
			{/*	</section>*/}
			{/*)}*/}
			{/*{activeTab === 'contact' && (*/}
			{/*	<section id="cs-space-contact-c1" className="p-section-safeview theme-transparent">*/}
			{/*		<div className="centerframe-grid gap-2gutter">*/}
			{/*			<article className="cs-contact-form block-full order-1">*/}
			{/*				<div className="cs-stack-title-icons stack-row-wrap">*/}
			{/*					<h2><span>Contact</span>{' ' + spaceData.name}</h2>*/}
			{/*					{(spaceData && spaceData?.social_links) && (*/}
			{/*						<SocialLinks*/}
			{/*							socialLinks={spaceData.social_links}*/}
			{/*							parentClassName="cs-stack-link-icons stack-row"*/}
			{/*							childClassName="button-icon-round-gradient"*/}
			{/*						/>*/}
			{/*					)}*/}
			{/*					/!*<div className="cs-stack-link-icons stack-row">*!/*/}
			{/*					/!*	<a href="#xtwitter" target="_blank" className="button-icon-round-gradient"><span><p><i className="fa-brands fa-x-twitter"></i></p></span></a>*!/*/}
			{/*					/!*	<a href="#instagram" target="_blank" className="button-icon-round-gradient"><span><p><i className="fa-brands fa-instagram"></i></p></span></a>*!/*/}
			{/*					/!*	<a href="#facebook" target="_blank" className="button-icon-round-gradient"><span><p><i className="fa-brands fa-facebook-f"></i></p></span></a>*!/*/}
			{/*					/!*	<a href="#linkedin" target="_blank" className="button-icon-round-gradient"><span><p><i className="fa-brands fa-linkedin-in"></i></p></span></a>*!/*/}
			{/*					/!*</div>*!/*/}
			{/*				</div>*/}

			{/*				<div className="cs-stack-contact-form stack-col">*/}
			{/*					<div className="stack-row">*/}
			{/*						<div className="form-input air">*/}
			{/*							<label htmlFor="name">Name</label>*/}
			{/*							<input type="text" name="name" id="name" autoComplete="name" required/>*/}
			{/*						</div>*/}
			{/*						<div className="form-input air">*/}
			{/*							<label htmlFor="email">Email</label>*/}
			{/*							<input type="email" name="email" id="email" autoComplete="email" required/>*/}
			{/*						</div>*/}
			{/*					</div>*/}
			{/*					<div className="form-input air">*/}
			{/*						<label htmlFor="subject">Subject</label>*/}
			{/*						<input type="text" name="subject" id="subject" autoComplete="subject"/>*/}
			{/*					</div>*/}
			{/*					<div className="form-textarea air">*/}
			{/*						<label htmlFor="message">Message</label>*/}
			{/*						<textarea id="message" name="message" rows="3"></textarea>*/}
			{/*					</div>*/}
			{/*				</div>*/}
			{/*			</article>*/}
			{/*			<div id="cs-space-support-links" className="grid-base col-span-full order-2">*/}
			{/*				<article*/}
			{/*					className="cs-featured-support block-md card"*/}
			{/*					onClick={(event) => redirectToPages('/faq')}*/}
			{/*				>*/}
			{/*					<a className="rounded-card p-card">*/}
			{/*						<hgroup>*/}
			{/*							<p><i className="fa-solid fa-comments-question-check"></i></p>*/}
			{/*							<h4>FAQ</h4>*/}
			{/*						</hgroup>*/}
			{/*					</a>*/}
			{/*				</article>*/}
			{/*				<article*/}
			{/*					className="cs-featured-support block-md card"*/}
			{/*					onClick={(event) => redirectToPages('/community-guidelines')}*/}
			{/*				>*/}
			{/*					<a className="rounded-card p-card">*/}
			{/*						<hgroup>*/}
			{/*							<p><i className="fa-solid fa-users"></i></p>*/}
			{/*							<h4>Community Guidelines</h4>*/}
			{/*						</hgroup>*/}
			{/*					</a>*/}
			{/*				</article>*/}
			{/*				<article*/}
			{/*					className="cs-featured-support block-md card"*/}
			{/*					onClick={(event) => redirectToPages('/support')}*/}
			{/*				>*/}
			{/*					<a className="rounded-card p-card">*/}
			{/*						<hgroup>*/}
			{/*							<p><i className="fa-solid fa-life-ring"></i></p>*/}
			{/*							<h4>Support</h4>*/}
			{/*						</hgroup>*/}
			{/*					</a>*/}
			{/*				</article>*/}
			{/*			</div>*/}
			{/*		</div>*/}
			{/*	</section>*/}
			{/*)}*/}
			{/*{activeTab === 'community-guidelines' && (*/}
			{/*	<section id="cs-space-guidelines-c1" className="p-section-safeview theme-transparent">*/}
			{/*		<div className="centerframe-grid gap-2gutter">*/}
			{/*			<article className="cs-support-article block-full order-1">*/}
			{/*				<div className="cs-stack-title-icons stack-row-wrap">*/}
			{/*					<h2><span>{spaceData?.name}</span> Community Guidelines</h2>*/}
			{/*					{(spaceData && spaceData?.social_links) && (*/}
			{/*						<SocialLinks*/}
			{/*							socialLinks={spaceData.social_links}*/}
			{/*							parentClassName="cs-stack-link-icons stack-row"*/}
			{/*							childClassName="button-icon-round-gradient"*/}
			{/*						/>*/}
			{/*					)}*/}
			{/*					/!*<div className="cs-stack-link-icons stack-row">*!/*/}
			{/*					/!*	<a href="#xtwitter" target="_blank" className="button-icon-round-gradient"><span><p><i className="fa-brands fa-x-twitter"></i></p></span></a>*!/*/}
			{/*					/!*	<a href="#instagram" target="_blank" className="button-icon-round-gradient"><span><p><i className="fa-brands fa-instagram"></i></p></span></a>*!/*/}
			{/*					/!*	<a href="#facebook" target="_blank" className="button-icon-round-gradient"><span><p><i className="fa-brands fa-facebook-f"></i></p></span></a>*!/*/}
			{/*					/!*	<a href="#linkedin" target="_blank" className="button-icon-round-gradient"><span><p><i className="fa-brands fa-linkedin-in"></i></p></span></a>*!/*/}
			{/*					/!*</div>*!/*/}
			{/*				</div>*/}

			{/*				<div className="cs-support-content-body card stack-col">*/}
			{/*					<p>RTE CONTENT GOES HERE.</p>*/}
			{/*				</div>*/}

			{/*			</article>*/}
			{/*			<div id="cs-space-support-links" className="grid-base col-span-full order-2">*/}
			{/*				<article*/}
			{/*					className="cs-featured-support block-md card"*/}
			{/*					onClick={(event) => redirectToPages('/faq')}*/}
			{/*				>*/}
			{/*					<a className="rounded-card p-card">*/}
			{/*						<hgroup>*/}
			{/*							<p><i className="fa-solid fa-comments-question-check"></i></p>*/}
			{/*							<h4>FAQ</h4>*/}
			{/*						</hgroup>*/}
			{/*					</a>*/}
			{/*				</article>*/}
			{/*				<article*/}
			{/*					className="cs-featured-support block-md card"*/}
			{/*					onClick={(event) => redirectToPages('/contact')}*/}
			{/*				>*/}
			{/*					<a className="rounded-card p-card">*/}
			{/*						<hgroup>*/}
			{/*							<p><i className="fa-solid fa-envelope"></i></p>*/}
			{/*							<h4>Contact</h4>*/}
			{/*						</hgroup>*/}
			{/*					</a>*/}
			{/*				</article>*/}
			{/*				<article*/}
			{/*					className="cs-featured-support block-md card"*/}
			{/*					onClick={(event) => redirectToPages('/support')}*/}
			{/*				>*/}
			{/*					<a className="rounded-card p-card">*/}
			{/*						<hgroup>*/}
			{/*							<p><i className="fa-solid fa-life-ring"></i></p>*/}
			{/*							<h4>Support</h4>*/}
			{/*						</hgroup>*/}
			{/*					</a>*/}
			{/*				</article>*/}
			{/*			</div>*/}
			{/*		</div>*/}
			{/*	</section>*/}
			{/*)}*/}

			{/*{activeTab === 'support' && (*/}
			{/*	<section id="cs-space-guidelines-c1" className="p-section-safeview theme-transparent">*/}
			{/*		<div className="centerframe-grid gap-2gutter">*/}
			{/*			<article className="cs-support-article block-full order-1">*/}
			{/*				<div className="cs-stack-title-icons stack-row-wrap">*/}
			{/*					<h2><span>{spaceData.name}</span> Support*/}
			{/*					</h2>*/}
			{/*					{(spaceData && spaceData?.social_links) && (*/}
			{/*						<SocialLinks*/}
			{/*							socialLinks={spaceData.social_links}*/}
			{/*							parentClassName="cs-stack-link-icons stack-row"*/}
			{/*							childClassName="button-icon-round-gradient"*/}
			{/*						/>*/}
			{/*					)}*/}
			{/*					/!*<div*!/*/}
			{/*					/!*	className="cs-stack-link-icons stack-row">*!/*/}
			{/*					/!*	<a href="#xtwitter" target="_blank"*!/*/}
			{/*					/!*	   className="button-icon-round-gradient"><span><p><i*!/*/}
			{/*					/!*		className="fa-brands fa-x-twitter"></i></p></span></a>*!/*/}
			{/*					/!*	<a href="#instagram" target="_blank"*!/*/}
			{/*					/!*	   className="button-icon-round-gradient"><span><p><i*!/*/}
			{/*					/!*		className="fa-brands fa-instagram"></i></p></span></a>*!/*/}
			{/*					/!*	<a href="#facebook" target="_blank"*!/*/}
			{/*					/!*	   className="button-icon-round-gradient"><span><p><i*!/*/}
			{/*					/!*		className="fa-brands fa-facebook-f"></i></p></span></a>*!/*/}
			{/*					/!*	<a href="#linkedin" target="_blank"*!/*/}
			{/*					/!*	   className="button-icon-round-gradient"><span><p><i*!/*/}
			{/*					/!*		className="fa-brands fa-linkedin-in"></i></p></span></a>*!/*/}
			{/*					/!*</div>*!/*/}
			{/*				</div>*/}

			{/*				{spaceInformation && spaceInformation.page_content && (*/}
			{/*					<div className="cs-support-content-body card stack-col">*/}
			{/*						<p>{parse(spaceInformation.page_content)}</p>*/}
			{/*					</div>*/}
			{/*				)}*/}

			{/*			</article>*/}
			{/*			<div id="cs-space-support-links" className="grid-base col-span-full order-2">*/}
			{/*				<article*/}
			{/*					className="cs-featured-support block-md card"*/}
			{/*					onClick={(event) => redirectToPages('/faq')}*/}
			{/*				>*/}
			{/*					<a className="rounded-card p-card">*/}
			{/*						<hgroup>*/}
			{/*							<p><i className="fa-solid fa-comments-question-check"></i></p>*/}
			{/*							<h4>FAQ</h4>*/}
			{/*						</hgroup>*/}
			{/*					</a>*/}
			{/*				</article>*/}
			{/*				<article*/}
			{/*					className="cs-featured-support block-md card"*/}
			{/*					onClick={(event) => redirectToPages('/contact')}*/}
			{/*				>*/}
			{/*					<a className="rounded-card p-card">*/}
			{/*						<hgroup>*/}
			{/*							<p><i className="fa-solid fa-envelope"></i></p>*/}
			{/*							<h4>Contact</h4>*/}
			{/*						</hgroup>*/}
			{/*					</a>*/}
			{/*				</article>*/}
			{/*				<article*/}
			{/*					className="cs-featured-support block-md card"*/}
			{/*					onClick={(event) => redirectToPages('/community-guidelines')}*/}
			{/*				>*/}
			{/*					<a className="rounded-card p-card">*/}
			{/*						<hgroup>*/}
			{/*							<p><i className="fa-solid fa-users"></i></p>*/}
			{/*							<h4>Community Guidelines</h4>*/}
			{/*						</hgroup>*/}
			{/*					</a>*/}
			{/*				</article>*/}
			{/*			</div>*/}
			{/*		</div>*/}
			{/*	</section>*/}
			{/*)}*/}
		</>
	);
};
export default memo(SpaceInformationPages);
