import {ROOT_DOMAIN, STORAGE_KEYS} from "../constants/common";
import routes from "../routes";
import {getDataByUsername} from "../services/general";
import {createFullRoute} from "./redirects";
import {getLocalStorage} from "./storage";
import {setIsNotPublicUrl, setIsPublicUrl} from "../states/spaceViewMode";
import {useDispatch} from "react-redux";

const prevent_custom_domain_pages = [
    routes.signUp(), routes.login(), routes.forgotPassword(), routes.emailVerification(),
    routes.registerMemberProfile(),

    routes.createSpace(), routes.updateSpace(), routes.createSpaceAddDetailsPage(),
    routes.createSpaceAddMembershipPage(), routes.createSpaceAddCommunityPage(),
    routes.createSpaceAddMembershipTraitsPage(), routes.createSpaceAddMembershipBenefitPage(),
    routes.createSpaceBasicCompletePage(), routes.createSpaceAddFirstItemPage(),
    routes.createSpacePostUpdate(), routes.createCommunityFeatures(), routes.createSpaceInviteTeam(),
    routes.createSpaceUpgradePlan(), routes.createCustomizeProSpace(), routes.createCustomizeProNav(),
    routes.createSpaceLaunch(), routes.createSpaceLaunchCheckList(), routes.createSpaceAddBusiness(),
    routes.createSpaceAddPages(), routes.createSpacePayment(), routes.createSpaceBilling(),
    routes.createSpacePrevLaunch(),

    routes.spaceDashboard(), routes.spaceActivity(), routes.spaceMembers(), routes.manageMember(),
    routes.spaceUpdates(), routes.spaceBenefits(),
    routes.spaces(), routes.manageSpaceMembership(), routes.manageSpaceMembershipExpired(),
    routes.collections(), routes.viewCollection(), routes.viewCollectionItem(), routes.viewCollectionItem(),

    routes.profile(), routes.profileEdit(), routes.profileView(), routes.profileEditAboutSection(),
    routes.profileManageFollowers(), routes.profileManageContact(), routes.profileManageVisibility(),
    routes.settings(), routes.settings2FA(), routes.settingsNotificationPreferences(), routes.settingsPaymentMethods(),
    routes.settingsMagicWallet(), routes.settingsTransactions(), routes.alchemyLabolatory(),
    routes.registerSpaceMemberProfile(),

    routes.createUpdate(), routes.editUpdate(),

    routes.spaceItems(), routes.manageItem(), routes.itemType(), routes.createItemCommunity(), routes.createItemNFT(),
    routes.editItem(), routes.addItemMonthBenefit(), routes.editItemMonthBenefit(),

    routes.spaceMemberships(), routes.manageMembership(), routes.membershipType(), routes.createMembershipCommunity(),
    routes.createMembershipNFT(), routes.editMembership(), routes.addMemMonthBenefit(), routes.editMemMonthBenefit(),

    routes.spaceTransactions(),

    routes.spaceManageSpace(), routes.spaceManageProfile(), routes.spaceManageHomePage(), routes.spaceManageInfoPages(),
    routes.spaceManageCustomizeSpace(), routes.spaceManageCustomizeNav(), routes.spaceManageChannels(), routes.spaceManageQuestions(),
    routes.spaceManageAccessGroups(),

    routes.spaceSettings()
];

export const customDomainRedirectChecker = async (path, query_string=null) => {
    const dispatch = useDispatch();

    if (ROOT_DOMAIN !== window.location.hostname) {
        if (path && path.startsWith('/@') && path.length > 3) {
            let username = path.replace('/@', '').split('/')[0];

            const split_path = path.split('/');
            let url_path = '/:space_username/';

            if (split_path.length > 3) {
                const full_href_path = window.location.href
                let cleaned_path = full_href_path.split('/@' + username)[1];
                window.location.href  = createFullRoute(window.location.hostname, cleaned_path);
            } else {
                let p_index = 0;
                split_path.forEach((item, index) => {
                    if (index > 1) {
                        url_path += item + (split_path.length === (p_index + 1) ? '' : '/')
                    }
                    p_index++;
                });

                if (query_string) {
                    url_path = url_path + '?' + query_string;
                }

                if (prevent_custom_domain_pages.includes(url_path)) {
                    url_path = url_path.replace('/:space_username', '/@' + username);
                    console.log(70);
                    console.log(url_path);
                    // console.log(createFullRoute(window.location.hostname, cleaned_path));
                    // window.location.href  = createFullRoute(window.location.hostname, cleaned_path);
                } else {
                    const prevent_urls = [
                        '/:space_username/login',
                        '/:space_username/sign-up',
                        '/:space_username/forgot-password',
                        '/:space_username/verify-email'
                    ];

                    if (prevent_urls.includes(url_path)) {
                        url_path = url_path.replace('/:space_username', '');
                        window.location.href  = createFullRoute(ROOT_DOMAIN, url_path);
                    } else {
                        url_path = url_path.replace('/:space_username', '');
                        window.location.href  = createFullRoute(window.location.hostname, url_path);
                    }
                }
            }
        } else {
            if (prevent_custom_domain_pages.includes(path)) {
                let redirect_url = createFullRoute(ROOT_DOMAIN, path);
                let is_rd = false;
                const prevent_urls = [routes.login(), routes.signUp(), routes.forgotPassword(), routes.emailVerification()];

                if (prevent_urls.includes(path)) {
                    const from_domain = btoa(window.location.hostname);
                    redirect_url = redirect_url + '?rd=' + from_domain;
                    is_rd = true;
                }

                // TODO: Fix issue where page not loading due to console error 'Failed to set the 'href' property on 'Location': 'http://.......'
                // if (is_rd && path === routes.login()) {
                //     if (accessToken) {
                //         redirect_url = redirect_url + '&rd_at=' + accessToken;
                //     }
                //
                //     if (refreshToken) {
                //         redirect_url = redirect_url + '&rd_rt=' + refreshToken;
                //     }
                // }

                if (query_string) {
                    if (is_rd) {
                        redirect_url = redirect_url + '&' + query_string;
                    } else {
                        redirect_url = redirect_url + '?' + query_string;
                    }
                }

                window.location.href = redirect_url;
            } else {
                if (path.startsWith('/space/create/')) {
                    const space_create_path_cleaned = path.replace('/space/create/', '');
                    const space_create_path_split = space_create_path_cleaned.split('/');
                    const space_create_path_username = space_create_path_split[0];
                    const space_create_path_username_url = '/space/create/' + space_create_path_username + '/' + space_create_path_split[1];
                    window.location.href = createFullRoute(ROOT_DOMAIN, space_create_path_username_url);
                }
            }
        }
    } else {
        if (path && path.startsWith('/@') && path.length > 3) {
            let username = path.replace('/@', '').split('/')[0];
            const split_path = path.split('/');
            let url_path = '/:space_username/';
            let p_index = 0;

            split_path.forEach((item, index) => {
                if (index > 1) {
                    url_path += item + (split_path.length === (p_index + 1) ? '' : '/')
                }
                p_index++;
            });

            const data = {
                username: username
            };

            const response = await getDataByUsername(data);
            if (response.result && response.space) {
                if (response.is_public_view_mode) {
                    dispatch(setIsPublicUrl(true));
                } else {
                    dispatch(setIsNotPublicUrl(false));
                }

                const space_data = response.space;
                let is_rd = false;
                const prevent_urls = [
                    '/:space_username/login',
                    '/:space_username/sign-up',
                    '/:space_username/forgot-password'
                ];

                if (prevent_urls.includes(url_path)) {
                    if (space_data.domain_name) {
                        let redirect_url = createFullRoute(ROOT_DOMAIN, url_path);

                        if (prevent_urls.includes(url_path)) {
                            const from_domain = btoa(space_data.domain_name);
                            redirect_url = redirect_url + '?rd=' + from_domain;
                            is_rd = true;
                        }

                        redirect_url = redirect_url.replace('/:space_username', '');

                        if (query_string) {
                            if (is_rd) {
                                redirect_url = redirect_url + '&' + query_string;
                            } else {
                                redirect_url = redirect_url + '?' + query_string;
                            }
                        }

                        window.location.href = redirect_url;
                    }
                } else {
                    if (space_data.domain_name) {
                        let redirect_url = createFullRoute(space_data.domain_name, url_path);
                        let in_regex_matches = false;
                        const regex = /\d+/g;
                        const matches = url_path.match(regex);

                        if (matches && matches.length > 0) {
                            const membership_id_replace_path = url_path.replace(matches[0], ':membership_id');
                            const item_id_replace_path = url_path.replace(matches[0], ':item_id');
                            const update_id_replace_path = url_path.replace(matches[0], ':update_id');

                            if (prevent_custom_domain_pages.includes(membership_id_replace_path)
                                || prevent_custom_domain_pages.includes(item_id_replace_path) ||
                                prevent_custom_domain_pages.includes(update_id_replace_path)) {
                                in_regex_matches = true;
                            }
                        }

                        if (!prevent_custom_domain_pages.includes(url_path) && !in_regex_matches) {
                            redirect_url = redirect_url.replace('/:space_username', '');

                            if (query_string) {
                                redirect_url = redirect_url + '?' + query_string;
                            }

                            window.location.href = redirect_url;
                        }
                    }
                }
            }
        }
    }
};