import default_bg from '../../src/assets/images/form_background.png';
import ReactPlayer from 'react-player/lazy';

export const displayDataContent = (data, isSpaceMember, field = null, listDisplay = false) => {
	let value = '';
	// console.log('data.visibility')
	// console.log(data.visibility)
	// console.log('isSpaceMember')
	// console.log(isSpaceMember)
	if (data) {
		if (data.visibility === 'members') {
			if (isSpaceMember) {
				if (field) {
					if (field === 'cover') {
						if (listDisplay) {
							if (data.cover_video) {
								value = (
									<ReactPlayer
										playing={true}
										loop={true}
										controls={false}
										muted={true}
										className="videoPreview rounded-card"
										url={data.cover_video}
									/>
								);
							} else {
								const cover = data.cover_photo_medium
									? data.cover_photo_medium
									: default_bg;
								value = <img src={cover} className="max-h-element rounded-card"/>;
							}
						} else {
							if (data.cover_video) {
								value = (
									<video autoPlay muted loop className="update-video">
										<source src={data.cover_video} type="video/mp4" />
									</video>
								);
							} else {
								value = <img className="img-fluid max-h-element rounded-card" src={data.cover_photo_medium} />;
							}
						}
					} else if (field === 'title' && data.is_locked) {
						if (data.public_title && data.public_title !== '') {
							value = data.public_title;
						} else {
							value = data[field];
						}
					} else {
						value = data[field];
					}
				} else {
					value = true;
				}
			} else {
				if (field) {
					if (field === 'title') {
						if (data.public_title && data.public_title !== '') {
							value = data.public_title;
						} else {
							// no display
						}
					}
					if (field === 'preview_text') {
						if (data.public_preview_text && data.public_preview_text !== '') {
							value = data.public_preview_text;
						} else {
							// no display
						}
					}
					if (field === 'cover') {
						if (listDisplay) {
							if (data.public_cover_video) {
								value = (
									<ReactPlayer
										playing={true}
										loop={true}
										controls={false}
										muted={true}
										className="videoPreview"
										url={data.public_cover_video}
									/>
								);
							} else {
								const cover = data.public_cover_photo_medium
									? data.public_cover_photo_medium
									: default_bg;
								value = <img src={cover} className='max-h-element rounded-card' />;
							}
						} else {
							if (data.public_cover_video) {
								value = (
									<video autoPlay muted loop className="update-video">
										<source src={data.public_cover_video} type="video/mp4" />
									</video>
								);
							} else {
								value = (
									<img
										className="img-fluid max-h-element rounded-card"
										src={data.public_cover_photo_medium}
									/>
								);
							}
						}
					}
				} else {
					value = true;
				}
			}
		}
		if (data.visibility === 'public') {
			if (isSpaceMember) {
				if (field) {
					if (field === 'cover') {
						if (listDisplay) {
							if (data.cover_video) {
								value = (
									<ReactPlayer
										playing={true}
										loop={true}
										controls={false}
										muted={true}
										className="videoPreview"
										url={data.cover_video}
									/>
								);
							} else {
								const cover = data.cover_photo_medium
									? data.cover_photo_medium
									: default_bg;
								value = <img src={cover} className='max-h-element rounded-card'/>;
							}
						} else {
							if (data.cover_video) {
								value = (
									<video autoPlay muted loop className="update-video">
										<source src={data.cover_video} type="video/mp4" />
									</video>
								);
							} else {
								value = <img className="img-fluid max-h-element rounded-card" src={data.cover_photo_medium} />;
							}
						}
					} else {
						value = data[field];
					}
				} else {
					value = true;
				}
			} else {
				if (field) {
					if (field === 'title') {
						if (data.public_title && data.public_title !== '') {
							value = data.public_title;
						} else {
							value = data.title;
						}
					}
					if (field === 'preview_text') {
						if (data.public_preview_text && data.public_preview_text !== '') {
							value = data.public_preview_text;
						} else {
							value = data.preview_text;
						}
					}
					if (field === 'cover') {
						if (listDisplay) {
							if (data.public_cover_video || data.public_cover_photo_medium) {
								if (data.public_cover_video) {
									value = (
										<ReactPlayer
											playing={true}
											loop={true}
											controls={false}
											muted={true}
											className="videoPreview"
											url={data.public_cover_video}
										/>
									);
								} else {
									const cover = data.public_cover_photo_medium
										? data.public_cover_photo_medium
										: default_bg;
									value = <img src={cover} className='max-h-element rounded-card'/>;
								}
							} else {
								if (data.cover_video) {
									value = (
										<ReactPlayer
											playing={true}
											loop={true}
											controls={false}
											muted={true}
											className="videoPreview"
											url={data.cover_video}
										/>
									);
								} else {
									const cover = data.cover_photo_medium
										? data.cover_photo_medium
										: default_bg;
									value = <img src={cover} className='max-h-element rounded-card'/>;
								}
							}
						} else {
							if (data.public_cover_video || data.public_cover_photo_medium) {
								if (data.public_cover_video) {
									value = (
										<video autoPlay muted loop className="update-video">
											<source
												src={data.public_cover_video}
												type="video/mp4"
											/>
										</video>
									);
								} else {
									value = (
										<img
											className="img-fluid max-h-element rounded-card"
											src={data.public_cover_photo_medium}
										/>
									);
								}
							} else {
								if (data.cover_video) {
									value = (
										<video autoPlay muted loop className="update-video">
											<source src={data.cover_video} type="video/mp4" />
										</video>
									);
								} else {
									value = (
										<img className="img-fluid max-h-element rounded-card" src={data.over_photo_medium} />
									);
								}
							}
						}
					}
				} else {
					value = true;
				}
			}
		}
	}
	return value;
};
