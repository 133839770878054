import {memo, useCallback, useEffect, useState} from "react";
import {getDataByUsername} from "../../services/general";
import {Button, Form, Input} from "antd";
import {MAGIC_CONNECT_KEY, MAGIC_CONNECT_NETWORK, MAGIC_OAUTH_KEY, VALIDATE_MESSAGES} from "../../constants/common";
import Alert from "react-bootstrap/Alert";
import routes from "../../routes";
import {useDispatch, useSelector} from "react-redux";
import {Magic} from "magic-sdk";
import {ConnectExtension} from "@magic-ext/connect";
import {OAuthExtension} from "@magic-ext/oauth";
import Web3 from "web3";
import {checkMfaEnabled, checkSmsEnabled, dualCheck2FA, requestSmsOtp} from "../../services/user";
import {login, magic_login} from "../../services/auth";
import {setAccessToken} from "../../utils/common";
import {getAuthUserInfo} from "../../states/general/actions";
import {getLocalStorage, removeLocalStorage} from "../../utils/storage";
import {useSearchParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {setIsNotPublicUrl, setIsPublicUrl} from "../../states/spaceViewMode";


const SpaceLogin = (props) => {
    const { t, i18n } = useTranslation();
    const { username, spaceViewData } = props;
    const [space, setSpace] = useState(null);
    const [params] = useSearchParams();
    const [isSpaceLoaded, setIsSpaceLoaded] = useState(false);
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { authUserInfo, loading } = useSelector((state) => state.general);
    const [isMagicLink, setIsMagicLink] = useState(false);
    const [alert, setAlert] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [isLoginBtn, setLoginBtn] = useState(false);
    const [formIsOnFocus, setFormIsOnFocus] = useState(true);
    const [formHasError, setFormHasError] = useState(false);
    const [hasMfaEnabled, setHasMfaEnabled] = useState(false);
    const { size } = useSelector((state) => state.classSize);
    const [margin, setMargin] = useState();
    const [hasEmailSupplied, setHasEmailSupplied] = useState(false);

    const fetchViewData = useCallback(
        async (username) => {
            try {
                setIsSpaceLoaded(false);
                const data = {
                    username: username
                };

                const response = await getDataByUsername(data);
                if (response.result) {
                    if (response.is_public_view_mode) {
                        dispatch(setIsPublicUrl(true));
                    } else {
                        dispatch(setIsNotPublicUrl(false));
                    }

                    setSpace(response.space);
                    spaceViewData(response.space);
                } else {

                }
                setIsSpaceLoaded(true);

            } catch (error) {
                console.log(error);
            }
        },
        [username, space]
    );

    useEffect(()=>{
        if (!isSpaceLoaded) {
            fetchViewData(username).then(() => {});
        }
    },[isSpaceLoaded])

    // Magic Link Web 3
    const magic = new Magic(MAGIC_CONNECT_KEY, {
        network: MAGIC_CONNECT_NETWORK,
        locale: 'en_US',
        extensions: [new ConnectExtension()]
    });
    const magicOauth = new Magic(MAGIC_OAUTH_KEY, {
        network: MAGIC_CONNECT_NETWORK,
        locale: 'en_US',
        extensions: [new OAuthExtension()]
    });
    window.magic = magic;
    const web3 = new Web3(magic.rpcProvider);

    const handleFormOnBlur = useCallback(async () => {
        setAlert(null);

        await form
            .validateFields()
            .then(() => {
                setFormHasError(false);
            })
            .catch((errors) => {
                setFormHasError(true);
            });
    }, []);

    const handleFormChanges = useCallback(async () => {
        setAlert(null);

        await form
            .validateFields()
            .then(() => {
                setFormHasError(false);
            })
            .catch((errors) => {
                setFormHasError(true);
            });
    }, []);

    const handleSubmit = useCallback(
        async (values) => {
            try {
                setLoginBtn(true);
                console.log(hasMfaEnabled);
                if (hasMfaEnabled) {
                    console.log('MFA Enabled');
                    values.user_id = authUserInfo.id;
                    await handleOtp(values);
                } else {
                    console.log('MFA Not Enabled');
                    await loginUser(values);
                }
            } catch (error) {
                console.log(error);
            }
        },
        [hasMfaEnabled]
    );

    const supplyEmail = () => {
        const fields = form.getFieldsValue();
        if (!formHasError) {
            setHasEmailSupplied(true);
            form.resetFields();
            form.setFieldsValue({ email: String(fields.email) });
        }
    };
    const checkHas2FA = async () => {
        try {
            let values = {
                user_id: authUserInfo.id
            };
            const mfa_async = checkMfaEnabled(values);
            const sms_async = checkSmsEnabled(values);
            const mfa_res = await mfa_async;
            const sms_res = await sms_async;
            if (mfa_res.result || sms_res.result) {
                setHasMfaEnabled(true);
            } else {
                setHasMfaEnabled(false);
                window.location.replace(routes.dashboard());
            }
            console.log(mfa_res.message);
            console.log(sms_res.message);
        } catch (error) {
            console.log(error);
        }
    };

    const requestSMS = async () => {
        let values = {
            user_id: authUserInfo.id
        };
        const result = await requestSmsOtp(values);
        if (result.result) {
            setAlert({
                variant: 'success',
                message: 'OTP sent to your number.'
            });
        } else {
            setAlert({
                variant: 'danger',
                message: 'OTP send failed.'
            });
        }
    };

    const handleOtp = async (values) => {
        try {
            // let values = {
            //     user_id: authUserInfo.id
            // };
            let result = await dualCheck2FA(values);
            if (result.result) {
                form.resetFields();
                setAlert({
                    variant: 'success',
                    message: 'Log in successful!'
                });
                setLoginBtn(false);
                window.location.replace(routes.dashboard());
            } else {
                setAlert({
                    variant: 'danger',
                    message: 'OTP Code is incorrect.'
                });
                setLoginBtn(false);
            }
        } catch (error) {
            setLoginBtn(false);
            console.log(error);
        }
    };

    // Web 3 Login
    const web3Login = async () => {
        setSubmitted(true);
        setIsMagicLink(true);

        await magic.connect.disconnect().catch((e) => {
            // console.log(e);
        });

        web3.eth
            .getAccounts()
            .then((accounts) => {
                loginMagic({ user_public_address: accounts?.[0] });
            })
            .catch((error) => {
                setSubmitted(false);
                setIsMagicLink(false);
            });
    };

    const loginUser = async (values) => {
        const result = await login(values);
        await processLogin(result);
    };

    const loginMagic = async (values) => {
        const result = await magic_login(values);
        await processLogin(result);
    };

    const processLogin = async (result) => {
        // console.log(result);
        if (result) {
            if (result.result) {
                if (result.access_token) {
                    setAccessToken(result);
                }

                if (params.get('rd')) {
                    const from_domain_decoded = atob(params.get('rd'));
                    let redirect_url = 'https://';
                    if (from_domain_decoded === 'localhost') {
                        redirect_url = 'http://localhost:3004/login?rd_at=' + result.access_token + '&rd_rt=' + result.refresh_token;
                    } else {
                        redirect_url = 'https://' + from_domain_decoded + '/login?rd_at=' + result.access_token + '&rd_rt=' + result.refresh_token;
                    }

                    window.location.href = redirect_url;
                } else {
                    dispatch(getAuthUserInfo());
                }
            } else {
                if (result.message) {
                    setAlert({ variant: 'danger', message: result.message });
                } else {
                    setAlert({
                        variant: 'danger',
                        message: 'User not found.Please try again.'
                    });
                }
            }
        }
        setLoginBtn(false);
        setSubmitted(false);
        setIsMagicLink(false);
    };

    useEffect(() => {
        document.title = 'Commonspace Login';
        setMargin(() => {
            if (size !== 'lg') {
                return '4';
            } else {
                return '5';
            }
        });
        if (loading && loading === 'done' && authUserInfo) {
            // window.location.replace(routes.dashboard());
            setSubmitted(false);
            checkHas2FA().then(() => {});
            if (getLocalStorage('redirect_link')) {
                removeLocalStorage('redirect_link')
            }
        }
    }, [authUserInfo, loading, hasMfaEnabled, isMagicLink, size, margin]);

    return (
        <main id="cs-platform-main" className="cs-main main-theme">
            <section id="cs-space-auth-c1" className="py-0 px-0 sm:py-section-safeview theme-transparent">
                <div className="cs-centerframe-split">
                    <div className="cs-centerframe-split-left order-2 sm:order-1">
                        <div className="p-section-safeview sm:pr-gutter">
                            <div className="cs-stack-auth-form stack-col sm:max-w-half-breakpoint-less-gutter">
                                <hgroup className="heading">
                                    {hasMfaEnabled && (
                                        <h2>{t('secure_enabled')}</h2>
                                    )}
                                    {!hasMfaEnabled && !hasEmailSupplied && (
                                        <h2>{t('login_header')}</h2>
                                    )}
                                    {!hasMfaEnabled && hasEmailSupplied && (
                                        <h2>{t('login_with_email')}</h2>
                                    )}

                                    {hasMfaEnabled && (
                                        <p>
                                            {t('enter_the_2fa')}
                                        </p>
                                    )}

                                    {!hasMfaEnabled && !hasEmailSupplied && (
                                        <p className='text-p1'>
                                            {t('login_header_details')}
                                            {/*Enter your email/username or use your wallet to login. If you have 2 Factor Authentication enabled, be sure to have your method available.*/}
                                        </p>
                                    )}
                                    {!hasMfaEnabled && hasEmailSupplied && (
                                        <p className='text-p1'>
                                            {t('password_associated_email')}
                                        </p>
                                    )}

                                </hgroup>

                                <Form
                                    form={form}
                                    name="loginForm"
                                    validateMessages={VALIDATE_MESSAGES}
                                    onFocus={() => {
                                        setFormIsOnFocus(true);
                                    }}
                                    onBlur={() => {
                                        setFormIsOnFocus(false);
                                        handleFormOnBlur().then(() => {
                                        });
                                    }}
                                    autoComplete="off"
                                    onChange={handleFormChanges}
                                    onFinish={handleSubmit}
                                    className={
                                        (formIsOnFocus
                                            ? 'hide-antd-error-messages z-0'
                                            : 'otp relative z-0') + ' w-full stack-form-col !p-0 !m-0'
                                    }
                                >
                                    {!hasMfaEnabled && (
                                        <>
                                            <Form.Item
                                                name="email"
                                                className={`w-full stack-form-col !p-0 !m-0`}
                                                validateTrigger="onBlur"
                                                rules={[{ required: true, type: 'email' }]}
                                            >
                                                <div className="form-input unlabeled">
                                                    <label htmlFor="email">{t('username_placeholder')}</label>
                                                    <input id="email" name="email" type="email" autoComplete="email" required placeholder={t('username_placeholder')}/>
                                                </div>

                                            </Form.Item>
                                            {(form.getFieldsError()[0]?.errors.length > 0) && (
                                                <div className="alert callout warning !m-0">
                                                    <div className="action">
                                                        <i className="fa-solid fa-triangle-exclamation"></i>
                                                    </div>
                                                    <div className="content">
                                                        <div>
                                                            <h6>{form.getFieldsError()[0]?.errors.length > 0 ? form.getFieldsError()[0]?.errors : ''}</h6>
                                                        </div>
                                                    </div>

                                                </div>
                                            )}
                                        </>
                                    )}
                                    {!hasMfaEnabled && (
                                        <>
                                            <Form.Item
                                                name="password"
                                                className={`w-full stack-form-col !m-0`}
                                                validateTrigger="onBlur"
                                                rules={[{ required: true }]}
                                            >
                                                <div className="form-input-combo">
                                                    <label htmlFor="password">{t('password')}</label>
                                                    <input id="password" name="password" type="password" autoComplete="current-password" required placeholder={t('password')}/>
                                                    <button  disabled={submitted || formHasError} type="submit" className="secondary-gradient">
                                                        <span><p>{isLoginBtn && (
                                                            <i className="fa-solid fa-spinner fa-spin z-0 mr-2"></i>
                                                        )}
                                                            {t('login_header')}</p></span>
                                                    </button>
                                                </div>

                                            </Form.Item>
                                            {(form.getFieldsError()[1]?.errors.length > 0) && (
                                                <div className="alert callout warning !m-0">
                                                    <div className="action">
                                                        <i className="fa-solid fa-triangle-exclamation"></i>
                                                    </div>
                                                    <div className="content">
                                                        <div>
                                                            <h6>{form.getFieldsError()[1]?.errors}</h6>
                                                        </div>
                                                    </div>

                                                </div>
                                            )}
                                        </>
                                    )}
                                    {hasMfaEnabled && (
                                            <Form.Item
                                                name="otp"
                                                rules={[{required: true}]}
                                            >
                                                <div className="form-input unlabeled">
                                                    <label htmlFor="otp">{t('login_2fa_code')}</label>
                                                    <input id="otp" name="otp" type="number" required placeholder={t('login_2fa_code')}/>
                                                </div>
                                            </Form.Item>
                                    )}
                                    {(alert && alert.message) && (
                                        <div className="alert callout warning !m-0">
                                            <div className="action">
                                                <i className="fa-solid fa-triangle-exclamation"></i>
                                            </div>
                                            <div className="content">
                                                <div>
                                                    <h6>{(alert && alert.message) ? alert.message : ''}</h6>
                                                </div>
                                            </div>

                                        </div>
                                    )}
                                    {hasMfaEnabled && (
                                        <button  disabled={submitted} type="submit" className="secondary-gradient">
                                            <span><p>
                                            {submitted && (
                                                <i className="fa-solid fa-spinner fa-spin"></i>
                                            )}
                                                {t('confirm_login')}
                                            </p></span>
                                        </button>
                                    )}
                                    {!hasMfaEnabled && (
                                        <button onClick={web3Login} disabled={submitted} type="submit" className="secondary-gradient !hidden">
                                            <i
                                                className={
                                                    isMagicLink
                                                        ? 'fa-solid fa-spinner fa-spin mr-2'
                                                        : 'fa-solid fa-wallet mr-2'
                                                }
                                            ></i>
                                            {t('login_with_wallet')}
                                        </button>
                                    )}
                                </Form>
                                <p>
                                    {t('login_2_factor')}
                                </p>
                                <p>
                                    {t('login_trouble_logging_in')}{' '}
                                    <a href={routes.viewUserSpace() + username + '/forgot-password'}>{t('login_reset')}</a>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="cs-centerframe-split-right order-1 sm:order-2">
                        <div className="lg:pl-half-gutter">
                            {(space && space.background_photo_medium) &&
                                <img
                                    className="sm:max-h-element"
                                    src={space.background_photo_medium}
                                />
                            }
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}

export default memo(SpaceLogin);